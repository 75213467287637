import { combineReducers, AnyAction } from 'redux';
import { reducer as form } from 'redux-form';
import { ThunkAction } from 'redux-thunk';

// Types
import { IApplication, IUser } from 'src/types/core';

// General Reducers
import uiReducer from './ui';
import user from './current_user';
import appsReducer from './apps';
import { organizationsReducer, OrganizationsState } from './organizations';
import currentAppReducer from './current_app';
import notifications from './notifications';
import notificationsFetched from './notifications_fetched';
import storeAppsReducer from './store_apps';
import contactForm from './contact_form';
import notificationBanner from './notification_banner';
import groupMessages from './group_messages';
import groups from './groups';
import analytics from './analytics';
import checkUpdate from './check_update';
import rollup from './rollup';
import debugReducer from './debug';
import { eventsReducer, EventsState } from './events';

// Administration Reducers
import appMemberships from './app_memberships';
import emailPreferences from './email_preferences';
import downloads from './downloads';

// Dashboard Reducers
import dashboardReducer from './dashboard';
import { insightsReducers, InsightsState } from './insights';
import { tagsReducer, TagsState } from './tags';
import { notesReducer, NotesState } from './notes';
import { promptsMultiAppsReducer, PromptsState } from './prompts-multi-apps';
import { loveDialogReducer, LoveDialogState } from './love-dialog';
import { ratingPromptReducer, RatingPromptState } from './rating-prompt';

// Conversations Reducers
import { conversationsReducer } from './conversations/conversations';
import attachmentLightbox from './attachment_lightbox';

// Actions
import { UPDATE_CURRENT_APP_SUCCESS } from '../actions/session';
import { ISurveysV12ReduxState, surveysV12 } from './surveys-v12';
import { ISurveysMultiAppsReduxState, surveysMultiAppsReducer } from './surveys-multi-apps';
import { initiatorsReducer, InitiatorsState } from './initiators';

export const countryFilterReset = (state: any, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_CURRENT_APP_SUCCESS:
      return {
        ...state,
        values: {
          'star-ratings-filter': {
            'country-filter': '',
          },
        },
      };
    default:
      return state;
  }
};

export interface State {
  apps: Record<string, IApplication> & { loading: boolean; fullyFetched: boolean };
  currentApp: string;
  initiators: InitiatorsState;
  insights: InsightsState;
  loveDialog: LoveDialogState;
  notes: NotesState;
  promptsMultiApps: PromptsState;
  organizations: OrganizationsState;
  ratingPrompt: RatingPromptState;
  surveysV12: ISurveysV12ReduxState;
  surveysMultiApps: ISurveysMultiAppsReduxState;
  tags: TagsState;
  user: IUser;
  dashboard: Record<string, any>;
  events: EventsState;
}

export type DispatchFunc<T = any> = (action: T | ThunkAction<Promise<any>, State, T, any>) => Promise<T>;

export type GetState = () => State;

export const combinedReducers = combineReducers<State>({
  ui: uiReducer,
  currentApp: currentAppReducer,
  user,
  apps: appsReducer,
  appMemberships,
  emailPreferences,
  downloads,
  organizations: organizationsReducer,
  notificationsFetched,
  notifications,
  storeApps: storeAppsReducer,
  contactForm,
  notificationBanner,
  groupMessages,
  groups,
  form: form.plugin({
    'star-ratings-intl': countryFilterReset,
  }),
  insights: insightsReducers,
  tags: tagsReducer,
  dashboard: dashboardReducer,
  conversations: conversationsReducer,
  attachmentLightbox,
  analytics,
  checkUpdate,
  rollup,
  surveysV12,
  notes: notesReducer,
  promptsMultiApps: promptsMultiAppsReducer,
  loveDialog: loveDialogReducer,
  ratingPrompt: ratingPromptReducer,
  initiators: initiatorsReducer,
  surveysMultiApps: surveysMultiAppsReducer,
  events: eventsReducer,
} as Record<keyof State, any>);

export const rootReducer = (state: State, action: AnyAction) => {
  const intermediateState = combinedReducers(state, action);
  const finalState = debugReducer(intermediateState, action);
  return finalState;
};
