import { Component } from 'react';
import fullPath from '../../utils/full_path';
import JWT from '../../utils/jwt';
import Session from '../../utils/shared_session';
import Lock from './auth0';

import './styles/login.scss';

class Login extends Component {
  componentDidMount() {
    const label = new URLSearchParams(window.location.search).get('utm_campaign');
    /* istanbul ignore else */
    if (label) {
      Session.set('utm_campaign', label);
    }
    if (JWT.valid()) {
      window.location.assign(fullPath('/loading'));
      return;
    }
    this.auth0Login();
  }

  auth0Login() {
    Lock.show();
  }

  render() {
    return null;
  }
}

export default Login;
