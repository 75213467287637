import { __, append, assoc, assocPath, clone, contains, dissocPath, equals, isEmpty, ifElse, indexBy, lensPath, merge, mergeDeepLeft, mergeDeepRight, pick, prop, set, view, without, uniqBy, reject } from 'ramda';
import Avatar from 'avatar-initials';

// actions
import { UPDATE_CURRENT_APP_PENDING, UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';
import * as actions from 'src/actions/conversations';
import * as groupActions from 'src/actions/groups';

// tags v2
import {
  ADD_CONVERSATION_TAG,
  RENAME_CONVERSATION_TAG,
  REMOVE_CONVERSATION_TAG,
} from 'src/actions/conversations/tags';

import filterConversations from 'src/utils/filter_conversations';
import { conversationsInitialState } from './conversations.state';

const uniqByKey = (key, listData) => uniqBy((item) => item[key], listData);
const getCleanedUpSelectedConvs = (selected, notSelectedConv) => selected.filter(conv => conv !== notSelectedConv);

export const conversationsReducer = (state = conversationsInitialState, action = {}, currentAppId) => {
  if (!currentAppId && typeof window === 'object') {
    try {
      currentAppId = window.location.pathname.split('/')[2];
    } catch (e) {
      /* istanbul ignore next */
      console.warn('Global window object is missing or invalid.');
    }
  }

  // Check for old requests and filter them out.
  if (currentAppId && action && action.meta && action.meta.appId && action.meta.appId !== currentAppId) {
    /* istanbul ignore else */
    if (!['UPDATE_CURRENT_APP_PENDING', 'UPDATE_CURRENT_APP_SUCCESS'].includes(action.type)) {
      return state;
    }
  }

  switch (action.type) {
    case actions.REQUEST_CONVERSATION_COUNTS:
      return merge(state, {
        countsLoading: true,
        countsFetchError: false,
      });
    case actions.RECEIVE_CONVERSATION_COUNTS:
      return merge(state, {
        counts: action.payload,
        countsLoading: false,
        countsFetchError: false,
      });
    case actions.ERROR_REQUESTING_CONVERSATION_COUNTS:
      return merge(state, {
        countsLoading: false,
        countsFetchError: true,
      });
    case actions.REQUEST_CONVERSATIONS:
      return merge(state, {
        loading: true,
        fetchError: false,
      });
    case actions.RECEIVE_CONVERSATIONS: {
      const { payload = {}, meta } = action;

      const isSameSorting = equals(state.sortBy, meta.sort);
      let conversationList = isSameSorting ? state.conversationList : {};
      if (payload.conversations && !isEmpty(payload.conversations)) {
        const newConversationsData = indexBy(prop('id'), payload.conversations);
        conversationList = mergeDeepRight(conversationList, newConversationsData);
      }

      const sortBy = meta.sort ? meta.sort : state.sortBy;

      return {
        ...state,
        loading: false,
        fetchError: false,
        searchPending: false,
        conversationList,
        sortBy,
        hasMore: action.payload.has_more,
      };
    }
    case actions.FETCH_CONVERSATION_SUCCESS: {
      const oldConversation = pick(['assignee', 'groups', 'messages'], state.conversationList[action.meta.conversationId] || {});
      const newConversation = mergeDeepRight(oldConversation, {
        ...action.payload,
      });

      return {
        ...state,
        loading: false,
        fetchError: false,
        searchPending: false,
        conversationList: {
          ...state.conversationList,
          [action.meta.conversationId]: newConversation,
        },
      };
    }
    case actions.FETCH_CONVERSATION_MESSAGES_PENDING: {
      const newState = dissocPath(['conversationList', action.meta.conversationId, 'needsUpdateFrom'], state);
      return assocPath(['conversationList', action.meta.conversationId, 'messages'], [], merge(newState, {
        loadingMessages: true,
        fetchErrorMessages: false,
      }));
    }
    case actions.FETCH_CONVERSATION_MESSAGES_SUCCESS: {
      const newState = dissocPath(['conversationList', action.meta.conversationId, 'needsUpdateFrom'], state);
      return assocPath(['conversationList', action.meta.conversationId, 'messages'], action.payload.messages, merge(newState, {
        loadingMessages: false,
        fetchErrorMessages: false,
      }));
    }
    case actions.FETCH_CONVERSATION_MESSAGES_FAILURE: {
      const newState = dissocPath(['conversationList', action.meta.conversationId, 'needsUpdateFrom'], state);
      return merge(newState, {
        loadingMessages: false,
        fetchErrorMessages: true,
      });
    }
    case actions.POST_CONVERSATION_MESSAGE_PENDING:
      return merge(state, {
        postingMessages: true,
        postErrorMessages: false,
      });
    case actions.POST_CONVERSATION_MESSAGE_SUCCESS:
      return merge(state, {
        postingMessages: false,
        postErrorMessages: false,
      });
    case actions.POST_CONVERSATION_MESSAGE_FAILURE:
      return merge(state, {
        postingMessages: false,
        postErrorMessages: true,
      });
    case actions.ERROR_REQUESTING_CONVERSATIONS:
      return merge(state, {
        loading: false,
        fetchError: true,
      });
    case actions.SET_CONVERSATION_FILTER: {
      const { filter, filterLink } = action.payload;
      const conversationList = state.filter === filter ? state.conversationList : {};
      let title = state.title;
      switch (true) {
        case /^assignee_id:.*ongoing$/.test(filter):
          title = 'My Ongoing';
          break;
        case /^assignee_id:.*starred_by/.test(filter):
          title = 'My Starred';
          break;
        case /^assignee_id:.*state:archived/.test(filter):
          title = 'My Archived';
          break;
        case /^assignee_id:[a-f0-9]{24}$/.test(filter):
          title = 'My All';
          break;
        case /^ongoing$/.test(filter):
          title = 'Everyone\'s Ongoing';
          break;
        case /^starred_by/.test(filter):
          title = 'Everyone\'s Starred';
          break;
        case /^state:archived$/.test(filter):
          title = 'Everyone\'s Archived';
          break;
        case /^all$/.test(filter):
          title = 'Everyone\'s All';
          break;
        case /^inbox$/.test(filter):
          title = 'Inbox';
          break;
        default:
          break;
      }

      return merge(state, {
        filter,
        title,
        filterLink,
        conversationList,
        conversationSelected: [],
        searchPending: false,
        searchPhrase: '',
        searchResults: [],
      });
    }
    case actions.PUSHER_RECEIVED_CONVERSATION: {
      // pusher is not FSA-compliant, this is out of our hands
      const oldConversation = pick(['assignee', 'groups', 'messages'], state.conversationList[action.data.id] || {});
      const newConversation = mergeDeepLeft(oldConversation, {
        assignee: null,
        ...action.data,
      });
      const newState = assocPath(['conversationList', newConversation.id], newConversation, state);
      return {
        ...newState,
        counts: {
          ...state.counts,
          inbox: state.counts.inbox + 1,
        },
      };
    }
    case actions.PUSHER_RECEIVED_CONVERSATION_METADATA: {
      let newState = clone(state);
      // Find the conversation
      const conversation = clone(newState.conversationList[action.data.id]);
      if (typeof conversation !== 'undefined') {
        // Check the latest message ID to determine if we need to fetch.
        if (conversation.latest_msg && conversation.latest_msg.id !== action.data.latest_message_id) {
          newState = assocPath(['conversationList', action.data.id, 'needsUpdateFrom'], action.data.latest_message_id, newState);
        }
        // Update Assignee
        if (action.data.assignee) {
          newState = assocPath(['conversationList', action.data.id, 'assignee'], action.data.assignee, newState);
        }
        // Update State
        if (action.data.state) {
          newState = assocPath(['conversationList', action.data.id, 'state'], action.data.state, newState);
        }
        return newState;
      }
      return state;
    }
    case actions.SET_CONVERSATION_TITLE:
      return assoc('title', action.payload.title, state);
    case actions.SET_CONVERSATION_ASSIGNEE_SUCCESS: {
      if (action.payload.appMember) {
        const newAssignee = {
          id: action.payload.appMember.user_id,
          name: action.payload.appMember.name || '----------',
          profile_photo: Avatar.gravatarUrl({ email: action.payload.appMember.email }),
        };
        return {
          ...assocPath(['conversationList', action.payload.conversationId, 'assignee'], newAssignee, state),
          conversationSelected: getCleanedUpSelectedConvs(state.conversationSelected, action.payload.conversationId),
        };
      }
      return state;
    }
    case actions.SET_CONVERSATION_STATE_SUCCESS:
      return {
        ...assocPath(['conversationList', action.payload.conversationId, 'state'], action.payload.state, state),
        conversationSelected: getCleanedUpSelectedConvs(state.conversationSelected, action.payload.conversationId),
      };
    case actions.TOGGLE_CONVERSATION_SELECTED: {
      const toggleSelected = ifElse(
        contains(action.payload.conversationId, __),
        without(action.payload.conversationId, __),
        append(action.payload.conversationId, __)
      );

      const conversationSelected = toggleSelected(state.conversationSelected);

      return {
        ...state,
        conversationSelected,
        showSubMenu: conversationSelected.length > 0,
      };
    }
    case actions.SET_CONVERSATION_STARRED: {
      const stateWithStarredConv = assocPath(['conversationList', action.payload.conversationId, 'starred'], true, state);
      return {
        ...stateWithStarredConv,
        counts: {
          ...stateWithStarredConv.counts,
          my_starred: stateWithStarredConv.counts.my_starred + 1,
          everyone_starred: stateWithStarredConv.counts.everyone_starred + 1,
        },
      };
    }
    case actions.SET_CONVERSATION_UNSTARRED: {
      const stateWithUnstarredConv = assocPath(['conversationList', action.payload.conversationId, 'starred'], false, state);
      return {
        ...stateWithUnstarredConv,
        counts: {
          ...stateWithUnstarredConv.counts,
          my_starred: stateWithUnstarredConv.counts.my_starred - 1,
          everyone_starred: stateWithUnstarredConv.counts.everyone_starred - 1,
        },
      };
    }
    case actions.REQUEST_CONVERSATION_SEARCH_PENDING:
      return {
        ...state,
        searchPending: true,
      };
    case actions.REQUEST_CONVERSATION_SEARCH_SUCCESS: {
      const searchResults = action.payload.data.map(({ customer_tags = [], ...result }) => ({
        id: result.conversation_id,
        key: `${result.conversation_id}-${result.id}`,
        latest_msg: {
          body: result.content,
          created_at: new Date(result.created_on_date).valueOf(),
        },
        person: {
          email: result.sender.email,
          gravatar_url: result.sender.profile_image_url,
          id: result.sender.id,
          name: result.sender.name,
        },
        tags: customer_tags.map(tag => ({ tag })),
      }));

      return {
        ...state,
        searchPending: false,
        searchPhrase: action.meta.searchPhrase,
        searchResults,
      };
    }
    case actions.REQUEST_CONVERSATION_SEARCH_FAILURE:
      return {
        ...state,
        searchPending: false,
        searchResults: [],
      };
    case actions.CANCEL_SEARCH:
      return {
        ...state,
        searchPhrase: '',
        searchPending: false,
        searchResults: [],
      };
    case actions.SET_CONVERSATION_ORDER:
      const { sortBy, order } = action.payload; // eslint-disable-line
      return assoc('sortBy', { sortBy, order }, state);
    case actions.SET_CONVERSATION_SELECTION: {
      let conversationSelected = [];
      switch (action.payload.selection) {
        case 'all':
          conversationSelected = filterConversations({ filter: 'all', sortBy: state.sortBy }, state.conversationList);
          break;
        case 'open':
          conversationSelected = filterConversations({ filter: 'open', sortBy: state.sortBy }, state.conversationList);
          break;
        case 'waiting':
          conversationSelected = filterConversations({ filter: 'waiting', sortBy: state.sortBy }, state.conversationList);
          break;
        case 'unassigned':
          conversationSelected = filterConversations({ filter: 'unassigned', sortBy: state.sortBy }, state.conversationList);
          break;
        case 'unrepliable':
          conversationSelected = filterConversations({ filter: 'unrepliable', sortBy: state.sortBy }, state.conversationList);
          break;
        case 'none':
        default:
          break;
      }

      return {
        ...state,
        conversationSelected,
        showSubMenu: conversationSelected.length > 0,
      };
    }
    case groupActions.RECEIVE_FETCH_GROUP_CONVERSATIONS: {
      let conversationList = state.conversationList;
      if (action.payload && !isEmpty(action.payload)) {
        conversationList = mergeDeepLeft(state.conversationList, indexBy(prop('id'), action.payload));
      }
      return {
        ...state,
        loading: false,
        fetchError: false,
        conversationList,
      };
    }
    case groupActions.RECEIVE_REMOVE_CONVERSATION_FROM_GROUP: {
      if (action.meta.groupId) {
        const groupsLens = lensPath(['conversationList', action.meta.conversationId, 'groups']);
        const groups = view(groupsLens, state);
        const newGroups = groups.filter(g => g.id !== action.meta.groupId);

        return set(groupsLens, newGroups, state);
      }
      return state;
    }
    case groupActions.RECEIVE_ADD_CONVERSATION_TO_GROUP: {
      if (action.payload) {
        const groupsLens = lensPath(['conversationList', action.meta.conversationId, 'groups']);
        const groups = view(groupsLens, state);
        groups.push(action.payload);

        return set(groupsLens, groups, state);
      }
      return state;
    }
    case UPDATE_CURRENT_APP_PENDING:
    case UPDATE_CURRENT_APP_SUCCESS:
      return merge(state, {
        conversationList: {},
        conversationSelected: [],
        countsFetchError: false,
        countsLoading: true,
        counts: {
          inbox: 0,
          my_ongoing: 0,
          my_starred: 0,
          my_archived: 0,
          my_all: 0,
          everyone_ongoing: 0,
          everyone_starred: 0,
          everyone_archived: 0,
          everyone_all: 0,
        },
        fetchError: false,
        fetchErrorMessages: false,
        loading: true,
        loadingMessages: true,
        searchPending: false,
        searchResults: [],
        tagUpdatePending: false,
      });

    case ADD_CONVERSATION_TAG.SUCCESS: {
      const { conversationId, elasticsearchId, tagName } = action.meta;
      const conversation = { ...state.conversationList[conversationId] };

      const messages = conversation.messages.map(item => {
        if (item.id === elasticsearchId) {
          return {
            ...item,
            customer_tags: uniqByKey('tagName', [
              ...(item.customer_tags || []),
              { tagName },
            ]),
          };
        }
        return item;
      });

      const tags = uniqByKey('tag', [...conversation.tags, { tag: tagName }]);

      const conversationList = {
        ...state.conversationList,
        [conversationId]: {
          ...conversation,
          tags,
          messages,
        },
      };

      return {
        ...state,
        conversationList,
      };
    }

    case RENAME_CONVERSATION_TAG.SUCCESS: {
      const { conversationId, elasticsearchId, tagName, newTagName } = action.meta;
      const conversation = { ...state.conversationList[conversationId] };

      const messages = conversation.messages.map(message => {
        if (message.id === elasticsearchId) {
          const tagIndex = message.customer_tags.findIndex((tag) => tag.tagName === tagName);
          if (tagIndex !== -1) {
            message.customer_tags[tagIndex].tagName = newTagName;
            return {
              ...message,
              customer_tags: uniqByKey('tagName', message.customer_tags),
            };
          }
          return message;
        }
        return message;
      });

      const tags = uniqByKey(
        'tag',
        conversation.tags.map(tag => (tag.tagName === tagName ? ({ ...tag, tagName: newTagName }) : tag))
      );

      const conversationList = {
        ...state.conversationList,
        [conversationId]: {
          ...conversation,
          tags,
          messages,
        },
      };

      return {
        ...state,
        conversationList,
      };
    }

    case REMOVE_CONVERSATION_TAG.SUCCESS: {
      const { conversationId, elasticsearchId, tagName } = action.meta;
      const conversation = { ...state.conversationList[conversationId] };

      const rejectByKey = (key, tagsData) => reject((tag) => tag[key] === tagName, tagsData);
      const messages = conversation.messages.map(message => {
        if (message.id === elasticsearchId) {
          return {
            ...message,
            customer_tags: rejectByKey('tagName', message.customer_tags),
          };
        }
        return message;
      });

      const tags = rejectByKey('tag', conversation.tags);

      const conversationList = {
        ...state.conversationList,
        [conversationId]: {
          ...conversation,
          tags,
          messages,
        },
      };

      return {
        ...state,
        conversationList,
      };
    }

    case actions.DELETE_ATTACHMENT_PENDING: {
      return { ...state };
    }

    case actions.DELETE_ATTACHMENT_SUCCESS: {
      const newAttachment = {
        ...state.conversationList[action.payload.conversationId].messages.find(m => m.id === action.payload.messageId).attachments.find(a => a.url.includes(action.payload.attachmentId)),
        url: 'apptentive-removed-attachment',
        removing_status: 'success',
      };

      const newMsg = {
        ...state.conversationList[action.payload.conversationId].messages.find(m => m.id === action.payload.messageId),
        attachments: [
          ...state.conversationList[action.payload.conversationId].messages.find(m => m.id === action.payload.messageId).attachments.filter(a => !a.url.includes(action.payload.attachmentId)),
          newAttachment,
        ],
      };

      return {
        ...state,
        conversationList: {
          ...state.conversationList,
          [action.payload.conversationId]: {
            ...state.conversationList[action.payload.conversationId],
            messages: [
              ...state.conversationList[action.payload.conversationId].messages.filter(m => m.id !== action.payload.messageId),
              newMsg,
            ],
          },
        },
      };
    }

    case actions.DELETE_ATTACHMENT_FAILURE: {
      const newAttachment = {
        ...state.conversationList[action.payload.conversationId].messages.find(m => m.id === action.payload.messageId).attachments.find(a => a.url.includes(action.payload.attachmentId)),
        removing_status: 'failure',
      };

      const newMsg = {
        ...state.conversationList[action.payload.conversationId].messages.find(m => m.id === action.payload.messageId),
        attachments: [
          ...state.conversationList[action.payload.conversationId].messages.find(m => m.id === action.payload.messageId).attachments.filter(a => !a.url.includes(action.payload.attachmentId)),
          newAttachment,
        ],
      };

      return {
        ...state,
        conversationList: {
          ...state.conversationList,
          [action.payload.conversationId]: {
            ...state.conversationList[action.payload.conversationId],
            messages: [
              ...state.conversationList[action.payload.conversationId].messages.filter(m => m.id !== action.payload.messageId),
              newMsg,
            ],
          },
        },
      };
    }

    default:
      return state;
  }
};

export default conversationsReducer;
