import { reset } from 'redux-form';
import { createAction, createActions } from 'redux-actions';
import { tryCatch } from 'ramda';

import { InsightsService, MongoInteractionService } from 'src/services';
import { getCurrentUserId } from 'src/selectors/user';
import { fetchApp } from '../apps';
import { appIdIdentity, jsonIdentity, errorIdentity } from '../utils/identities';
import conversationPersistence from '../../utils/conversation_persistence';

// Selectors
import { getConversationList } from '../../selectors/conversations';
import { getCurrentStoreAppId } from '../../selectors/current_app';
import { AggsType } from '../../reducers/insights';
import { isAbortError } from '../../api/util';

// Pusher Actions
export const PUSHER_RECEIVED_CONVERSATION = 'PUSHER_RECEIVED_CONVERSATION';
export const PUSHER_RECEIVED_CONVERSATION_METADATA = 'PUSHER_RECEIVED_CONVERSATION_METADATA';

// Fetching Conversation Counts
export const REQUEST_CONVERSATION_COUNTS = 'REQUEST_CONVERSATION_COUNTS';
export const RECEIVE_CONVERSATION_COUNTS = 'RECEIVE_CONVERSATION_COUNTS';
export const ERROR_REQUESTING_CONVERSATION_COUNTS = 'ERROR_REQUESTING_CONVERSATION_COUNTS';

export const { requestConversationCounts, receiveConversationCounts, errorRequestingConversationCounts } = createActions({
  REQUEST_CONVERSATION_COUNTS: [
    appIdIdentity,
    appIdIdentity,
  ],
  RECEIVE_CONVERSATION_COUNTS: [
    jsonIdentity,
    appIdIdentity,
  ],
  ERROR_REQUESTING_CONVERSATION_COUNTS: [
    errorIdentity,
    appIdIdentity,
  ],
});

export const fetchConversationCounts = (appId) => (dispatch, getState, { api }) => {
  dispatch(requestConversationCounts({ appId }));
  return api.fetchConversationCounts(appId)
    .then(json => dispatch(receiveConversationCounts({ appId, json })))
    .catch(error => dispatch(errorRequestingConversationCounts({ appId, error })));
};

// Fetching Conversations
export const REQUEST_CONVERSATIONS = 'REQUEST_CONVERSATIONS';
export const RECEIVE_CONVERSATIONS = 'RECEIVE_CONVERSATIONS';
export const ERROR_REQUESTING_CONVERSATIONS = 'ERROR_REQUESTING_CONVERSATIONS';

const requestConversationsIdentity = ({ appId, page, limit, filter, sort }) => ({ appId, page, limit, filter, sort });

export const { requestConversations, receiveConversations, errorRequestingConversations } = createActions({
  REQUEST_CONVERSATIONS: [
    requestConversationsIdentity,
    appIdIdentity,
  ],
  RECEIVE_CONVERSATIONS: [
    jsonIdentity,
    requestConversationsIdentity,
  ],
  ERROR_REQUESTING_CONVERSATIONS: [
    errorIdentity,
    requestConversationsIdentity,
  ],
});

/**
 * @param {*} GetConversationsParams
 */
export const fetchConversations = ({ appId, page, limit, filter, sort }) => (dispatch, getState, { api }) => dispatch(fetchApp(appId))
  .then(() => {
    const params = { appId, page, limit, filter, sort };
    dispatch(requestConversations(params));
    return api.fetchConversationsWithTags(params)
      .then(json => dispatch(receiveConversations({ appId, page, limit, filter, sort, json })))
      .catch(error => dispatch(errorRequestingConversations({ appId, page, limit, filter, sort, error })));
  });

// Setting Filter
export const SET_CONVERSATION_FILTER = 'SET_CONVERSATION_FILTER';

export const setConversationFilter = createAction(
  SET_CONVERSATION_FILTER,
  ({ userId, filter, filterLink }) => ({ userId, filter, filterLink }),
  appIdIdentity,
);

// Setting Conversation Assignee
export const SET_CONVERSATION_ASSIGNEE_SUCCESS = 'SET_CONVERSATION_ASSIGNEE_SUCCESS';
export const SET_CONVERSATION_ASSIGNEE_FAILURE = 'SET_CONVERSATION_ASSIGNEE_FAILURE';

export const { setConversationAssigneeSuccess, setConversationAssigneeFailure } = createActions({
  SET_CONVERSATION_ASSIGNEE_SUCCESS: [
    ({ conversationId, appMember }) => ({ conversationId, appMember }),
    appIdIdentity,
  ],
  SET_CONVERSATION_ASSIGNEE_FAILURE: [
    errorIdentity,
    ({ conversationId, appMember }) => ({ conversationId, appMember }),
  ],
});

export const setConversationAssignee = (conversationId, appMember) => (dispatch, getState, { api }) => api.setConversationAssignee(conversationId, appMember)
  .then(_json => dispatch(setConversationAssigneeSuccess({ conversationId, appMember })))
  .catch(error => dispatch(setConversationAssigneeFailure({ conversationId, appMember, error })));

// Setting Conversation State
export const SET_CONVERSATION_STATE_SUCCESS = 'SET_CONVERSATION_STATE_SUCCESS';
export const SET_CONVERSATION_STATE_FAILURE = 'SET_CONVERSATION_STATE_FAILURE';

export const { setConversationStateSuccess, setConversationStateFailure } = createActions({
  SET_CONVERSATION_STATE_SUCCESS: [
    ({ conversationId, state, userId }) => ({ conversationId, state, userId }),
    appIdIdentity,
  ],
  SET_CONVERSATION_STATE_FAILURE: [
    errorIdentity,
    ({ conversationId, state, userId }) => ({ conversationId, state, userId }),
  ],
});

export const setConversationState = (appId, conversationId, state, userId) => (dispatch, getState, { api }) => api.setConversationState(conversationId, state)
  .then(_json => dispatch(setConversationStateSuccess({ appId, conversationId, state, userId })))
  .catch((error) => {
    dispatch(setConversationStateFailure({ appId, conversationId, state, userId, error }));
    return Promise.resolve();
  })
  .then(() => dispatch(fetchConversationCounts(appId)));

// Toggle Selecting Conversation
export const TOGGLE_CONVERSATION_SELECTED = 'TOGGLE_CONVERSATION_SELECTED';
export const toggleSelectConversation = (conversationId) => ({
  type: TOGGLE_CONVERSATION_SELECTED,
  payload: { conversationId },
  meta: { conversationId },
});

// Starring Conversation
export const SET_CONVERSATION_STARRED = 'SET_CONVERSATION_STARRED';
export const ERROR_SETTING_CONVERSATION_STARRED = 'ERROR_SETTING_CONVERSATION_STARRED';
export const { setConversationStarred, errorSettingConversationStarred } = createActions({
  SET_CONVERSATION_STARRED: [
    ({ conversationId }) => ({ conversationId }),
    appIdIdentity,
  ],
  ERROR_SETTING_CONVERSATION_STARRED: [
    errorIdentity,
    ({ conversationId, appId }) => ({ conversationId, appId }),
  ],
});
export const starConversation = (conversationId) => (dispatch, getState, { api }) => api.starConversation(conversationId)
  .then(_json => dispatch(setConversationStarred({ conversationId })))
  .catch((error) => {
    dispatch(errorSettingConversationStarred({ conversationId, error }));
  });

// Unstarring Conversation
export const SET_CONVERSATION_UNSTARRED = 'SET_CONVERSATION_UNSTARRED';
export const ERROR_SETTING_CONVERSATION_UNSTARRED = 'ERROR_SETTING_CONVERSATION_UNSTARRED';
export const { setConversationUnstarred, errorSettingConversationUnstarred } = createActions({
  SET_CONVERSATION_UNSTARRED: [
    ({ conversationId }) => ({ conversationId }),
    appIdIdentity,
  ],
  ERROR_SETTING_CONVERSATION_UNSTARRED: [
    errorIdentity,
    ({ conversationId, appId }) => ({ conversationId, appId }),
  ],
});
export const unstarConversation = (conversationId) => (dispatch, getState, { api }) => api.unstarConversation(conversationId)
  .then(_json => dispatch(setConversationUnstarred({ conversationId })))
  .catch((error) => {
    dispatch(errorSettingConversationUnstarred({ conversationId, error }));
  });

// Conversation Search
export const CANCEL_SEARCH = 'CANCEL_CONVERSATION_SEARCH';
export const cancelSearch = () => ({ type: CANCEL_SEARCH });

export const REQUEST_CONVERSATION_SEARCH_PENDING = 'REQUEST_CONVERSATION_SEARCH_PENDING';
export const REQUEST_CONVERSATION_SEARCH_SUCCESS = 'REQUEST_CONVERSATION_SEARCH_SUCCESS';
export const REQUEST_CONVERSATION_SEARCH_FAILURE = 'REQUEST_CONVERSATION_SEARCH_FAILURE';
export const { requestConversationSearchPending, requestConversationSearchSuccess, requestConversationSearchFailure } = createActions({
  REQUEST_CONVERSATION_SEARCH_PENDING: [
    ({ appId, searchPhrase }) => ({ appId, searchPhrase }),
    appIdIdentity,
  ],
  REQUEST_CONVERSATION_SEARCH_SUCCESS: [
    ({ json }) => (json || { data: [] }),
    ({ appId, searchPhrase }) => ({ appId, searchPhrase }),
  ],
  REQUEST_CONVERSATION_SEARCH_FAILURE: [
    errorIdentity,
    ({ searchPhrase, appId }) => ({ searchPhrase, appId }),
  ],
});

const tryInvokeTags = tryCatch((s) => decodeURIComponent(s).split('tags: ')[1].split(',').map(tag => tag.trim()), () => []);
export const fetchConversationSearch = (appId, searchPhrase) => (dispatch, getState) => {
  dispatch(requestConversationSearchPending({ appId, searchPhrase }));

  const tags = searchPhrase.startsWith('tags') ? tryInvokeTags(searchPhrase) : null;

  return InsightsService.fetchInsightsData({
    appId,
    storeAppId: getCurrentStoreAppId(getState()),
    per: 200,
    regions: [],
    types: [AggsType.MESSAGE],
    text: tags ? '' : searchPhrase,
    tags: tags || [],
  })
    .then((json) => dispatch(requestConversationSearchSuccess({ appId, searchPhrase, json: json.page })))
    .catch(error => {
      if (isAbortError(error)) {
        return;
      }
      dispatch(requestConversationSearchFailure({ error, searchPhrase, appId }));
    });
};

export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_ERROR = 'FETCH_CONVERSATION_ERROR';
export const { fetchConversationSuccess, fetchConversationError } = createActions({
  FETCH_CONVERSATION_SUCCESS: [
    jsonIdentity,
    ({ conversationId, appId }) => ({ conversationId, appId }),
  ],
  FETCH_CONVERSATION_ERROR: [
    errorIdentity,
    ({ conversationId, appId }) => ({ conversationId, appId }),
  ],
});
export const fetchConversation = (appId, conversationId) => (dispatch, getState, { api }) => {
  const state = getState();
  if (getConversationList(state)) {
    const cachedConversation = getConversationList(state)[conversationId];
    if (cachedConversation && cachedConversation.device && cachedConversation.person) {
      return Promise.resolve(cachedConversation);
    }
  }
  return api.fetchConversationWithTags(appId, conversationId)
    .then(json => dispatch(fetchConversationSuccess({ conversationId, json, appId })))
    .catch(error => dispatch(fetchConversationError({ error, conversationId, appId })));
};

export const FETCH_CONVERSATION_MESSAGES_PENDING = 'FETCH_CONVERSATION_MESSAGES_PENDING';
export const FETCH_CONVERSATION_MESSAGES_SUCCESS = 'FETCH_CONVERSATION_MESSAGES_SUCCESS';
export const FETCH_CONVERSATION_MESSAGES_FAILURE = 'FETCH_CONVERSATION_MESSAGES_FAILURE';
export const { fetchConversationMessagesPending, fetchConversationMessagesSuccess, fetchConversationMessagesFailure } = createActions({
  FETCH_CONVERSATION_MESSAGES_PENDING: [
    ({ conversationId, from }) => ({ conversationId, from }),
    ({ conversationId, from }) => ({ conversationId, from }),
  ],
  FETCH_CONVERSATION_MESSAGES_SUCCESS: [
    jsonIdentity,
    ({ conversationId, from }) => ({ conversationId, from }),
  ],
  FETCH_CONVERSATION_MESSAGES_FAILURE: [
    errorIdentity,
    ({ conversationId, from }) => ({ conversationId, from }),
  ],
});
export const fetchConversationMessages = (conversationId, from) => (dispatch, getState, { api }) => {
  dispatch(fetchConversationMessagesPending({ conversationId, from }));
  return api.fetchConversationMessagesWithTags(conversationId, from)
    .then(json => dispatch(fetchConversationMessagesSuccess({ json, conversationId, from })))
    .catch(error => dispatch(fetchConversationMessagesFailure({ error, conversationId, from })));
};

export const POST_CONVERSATION_MESSAGE_PENDING = 'POST_CONVERSATION_MESSAGE_PENDING';
export const POST_CONVERSATION_MESSAGE_SUCCESS = 'POST_CONVERSATION_MESSAGE_SUCCESS';
export const POST_CONVERSATION_MESSAGE_FAILURE = 'POST_CONVERSATION_MESSAGE_FAILURE';
export const { postConversationMessagePending, postConversationMessageSuccess, postConversationMessageFailure } = createActions({
  POST_CONVERSATION_MESSAGE_PENDING: [
    ({ conversationId }) => ({ conversationId }),
    ({ conversationId, userId, formData }) => ({ conversationId, userId, formData }),
  ],
  POST_CONVERSATION_MESSAGE_SUCCESS: [
    jsonIdentity,
    ({ conversationId, userId, formData }) => ({ conversationId, userId, formData }),
  ],
  POST_CONVERSATION_MESSAGE_FAILURE: [
    errorIdentity,
    ({ conversationId, userId, formData }) => ({ conversationId, userId, formData }),
  ],
});
export const postConversationMessage = (conversationId, userId, formData) => (dispatch, getState, { api }) => {
  dispatch(postConversationMessagePending({ conversationId, userId, formData }));
  return api.postConversationMessage(conversationId, formData)
    .then((json) => {
      dispatch(postConversationMessageSuccess({ json, conversationId, userId, formData }));
      dispatch(setConversationStateSuccess({ conversationId, state: 'waiting', userId }));
      dispatch(reset('message-submit'));
      conversationPersistence.remove(`${userId}-${conversationId}`);
      fetchConversationMessages(conversationId)(dispatch, getState, { api });
      return json;
    })
    .catch(error => dispatch(postConversationMessageFailure({ error, conversationId, userId, formData })));
};

// Conversation Ordering
export const SET_CONVERSATION_ORDER = 'SET_CONVERSATION_ORDER';
export const setConversationsOrder = createAction(
  SET_CONVERSATION_ORDER,
  ({ sortBy, order }) => ({ sortBy, order }),
  appIdIdentity,
);

// Conversation Selection
export const SET_CONVERSATION_SELECTION = 'SET_CONVERSATION_SELECTION';
export const setConversationsSelection = createAction(
  SET_CONVERSATION_SELECTION,
  ({ selection }) => ({ selection }),
  appIdIdentity,
);

// Conversation Section Title
export const SET_CONVERSATION_TITLE = 'SET_CONVERSATION_TITLE';
export const setConversationTitle = createAction(
  SET_CONVERSATION_TITLE,
  (title) => ({ title }),
  appIdIdentity,
);

// Forward via Email
export const SHARING_MESSAGE_VIA_EMAIL_PENDING = 'SHARING_MESSAGE_VIA_EMAIL_PENDING';
export const SHARING_MESSAGE_VIA_EMAIL_SUCCESS = 'SHARING_MESSAGE_VIA_EMAIL_SUCCESS';
export const SHARING_MESSAGE_VIA_EMAIL_FAILURE = 'SHARING_MESSAGE_VIA_EMAIL_FAILURE';
const { sharingMessageViaEmailPending, sharingMessageViaEmailSuccess, sharingMessageViaEmailFailure } = createActions({
  SHARING_MESSAGE_VIA_EMAIL_PENDING: [
    ({ conversationId, messageId, formFields }) => ({ conversationId, messageId, formFields }),
    appIdIdentity,
  ],
  SHARING_MESSAGE_VIA_EMAIL_SUCCESS: [
    ({ conversationId, messageId, formFields }) => ({ conversationId, messageId, formFields }),
    appIdIdentity,
  ],
  SHARING_MESSAGE_VIA_EMAIL_FAILURE: [
    errorIdentity,
    ({ conversationId, messageId, formFields }) => ({ conversationId, messageId, formFields }),
  ],
});

export const shareMessageViaEmail = (conversationId, messageId, formFields) => (dispatch, getState, { api }) => {
  dispatch(sharingMessageViaEmailPending({ conversationId, messageId, formFields }));
  return api.shareMessageViaEmail(conversationId, messageId, formFields)
    .then(() => dispatch(sharingMessageViaEmailSuccess({ conversationId, messageId, formFields })))
    .catch(error => dispatch(sharingMessageViaEmailFailure({ conversationId, messageId, formFields, error })));
};

export const DELETE_ATTACHMENT_PENDING = 'DELETE_ATTACHMENT_PENDING';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAILURE = 'DELETE_ATTACHMENT_FAILURE';

const deleteAttachmentPending = ({ appId, conversationId, messageId, attachmentId }) => ({
  type: DELETE_ATTACHMENT_PENDING,
  payload: { appId, conversationId, messageId, attachmentId },
});

const deleteAttachmentSuccess = ({ appId, conversationId, messageId, attachmentId }) => ({
  type: DELETE_ATTACHMENT_SUCCESS,
  payload: { appId, conversationId, messageId, attachmentId },
});

const deleteAttachmentFailure = ({ appId, conversationId, messageId, attachmentId }, error) => ({
  type: DELETE_ATTACHMENT_FAILURE,
  payload: { appId, conversationId, messageId, attachmentId },
  error,
});

export const deleteAttachment = ({ appId, conversationId, messageId, attachmentId }) => (dispatch, getState) => {
  dispatch(deleteAttachmentPending({ appId, conversationId, messageId, attachmentId }));
  const state = getState();
  const currentUserId = getCurrentUserId(state);
  return MongoInteractionService.deleteAttachment({
    user_id: currentUserId,
    app_id: appId,
    conversation_id: conversationId,
    message_id: messageId,
    attachment_id: attachmentId,
  })
    .then(() => dispatch(deleteAttachmentSuccess({ appId, conversationId, messageId, attachmentId })))
    .catch(error => dispatch(deleteAttachmentFailure({ appId, conversationId, messageId, attachmentId }, error)));
};
