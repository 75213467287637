import React, { useState, useRef, useCallback, useEffect, useMemo, ReactNode } from 'react';
import { bemPrefix } from 'src/utils';
import { useOnOutsideClick } from 'src/hooks';
import { preventEventStop, stopEventPropagation } from 'src/components/utils/prevent-event';
import { GtmID } from 'src/types/google-tag-manager';
import { usePopoverProps, SimplePopover } from '../popover';
import { DialogButtons, DialogButtonsProps } from '../dialog';
import { InputText, InputTextProps } from '../input/input-text';

import './editable-text.scss';

interface ConfirmationTextProps {
  value: string;
  onCancelEdit(e?: React.SyntheticEvent): void;
}
export interface EditableTextProps {
  text: string;
  isEditing: boolean;
  showTextOnHover?: boolean;
  className?: string;
  required?: InputTextProps['required'];
  minLength?: InputTextProps['minLength'];
  maxLength?: InputTextProps['maxLength'];
  confirmationText?: React.ReactNode | React.FC<ConfirmationTextProps>;
  textElement?: React.ReactElement;
  confirmationGtmId?: GtmID;
  isEqualValue?(newValue: string, oldValue: string): boolean;
  submitColor?: DialogButtonsProps['submitColor'];
  tooltipError?: InputTextProps['tooltipError'];
  setInputError?(message: string): void;
  onKeyDown?: InputTextProps['onKeyDown'];
  onApplyText?(text: string, e: React.SyntheticEvent): void;
  onCancel(e: React.SyntheticEvent): void;
}

const bem = bemPrefix('editable-text');

const isSameText = (newValue: string, oldValue: string) => newValue === oldValue;
const isFunction = (children: React.ReactNode): boolean => typeof children === 'function';

export const EditableText: React.FC<EditableTextProps> = ({
  className = '',
  isEditing,
  isEqualValue = isSameText,
  required = true,
  onKeyDown,
  onApplyText,
  onCancel,
  confirmationText,
  confirmationGtmId,
  textElement,
  submitColor,
  tooltipError,
  minLength,
  maxLength,
  setInputError = () => null,
  showTextOnHover = false,
  ...props
}) => {
  const [value, setValue] = useState(props.text);
  const { isOpenPopover, showPopover, closePopover } = usePopoverProps();
  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>();

  const onChanging = useCallback((newValue: string) => {
    setValue(newValue);
    setInputError('');
  }, []);

  const onSubmitEdit = useCallback(
    (e: React.SyntheticEvent) => {
      closePopover();
      preventEventStop(e);
      onApplyText && onApplyText(value, e);
    },
    [value]
  );

  const onCancelEdit = useCallback(
    (e: React.SyntheticEvent) => {
      setValue(props.text);
      closePopover();
      preventEventStop(e);
      onCancel(e);
    },
    [onCancel, setValue, closePopover]
  );

  const onInputEnter = useCallback(
    (e: React.SyntheticEvent) => {
      const validInput = inputRef.current ? inputRef.current.validity.valid : false;
      const validLength = minLength ? value.trim().length >= minLength : true;
      const isValid = required ? validLength && validInput : true;
      if (isValid && !isEqualValue(value.trim(), props.text)) {
        if (confirmationText) {
          setValue(value.trim());
          showPopover();
        } else {
          onApplyText && onApplyText(value, e);
        }
      } else {
        onCancelEdit(e);
      }
    },
    [value, props.text, confirmationText, required, inputRef.current]
  );

  const onInputEscape = useCallback(
    (e: React.SyntheticEvent) => {
      if (!isOpenPopover) {
        onCancelEdit(e);
      }
    },
    [onCancelEdit, isOpenPopover]
  );

  const textNode = useMemo(
    () => (textElement ? (
      React.cloneElement(textElement, {
        key: 'textElement',
        className: [textElement.props.className || '', bem('name-text')].join(' '),
        title: showTextOnHover ? undefined : value,
      })
    ) : (
      <span className={bem('name-text')} title={showTextOnHover ? undefined : value} key="textElement">
        {value}
      </span>
    )),
    [textElement, value]
  );

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing, inputRef.current]);

  useEffect(() => {
    if (!inputRef.current) {
      closePopover();
    }
  }, [inputRef.current]);

  useOnOutsideClick([inputRef, popoverRef], onInputEscape);

  const handleConfirmText = () => {
    if (isFunction(confirmationText as ReactNode)) {
      const fn = confirmationText as Function;
      return fn?.({ value, onCancelEdit })
    }
    return confirmationText
  }

  return (
    <>
      <div className={`${bem()} ${className}`}>
        {isEditing ? (
          <InputText
            className={bem('rename-input')}
            innerRef={inputRef}
            value={value}
            disabled={isOpenPopover}
            tooltipError={tooltipError}
            required={required}
            maxLength={maxLength}
            minLength={minLength}
            onClick={isEditing ? stopEventPropagation : undefined}
            onChange={onChanging}
            onEnter={onInputEnter}
            onEscape={onInputEscape}
            onKeyDown={onKeyDown}
            preventDefault
          />
        ) : (
          [textNode, showTextOnHover && value && <div className={bem('hover-text')}>{value}</div>]
        )}
      </div>
      {isEditing && isOpenPopover && (
        <SimplePopover
          className={bem('popover')}
          innerRef={(r: HTMLDivElement) => (popoverRef.current = r)}
          targetEl={inputRef.current}
          isOpen={isOpenPopover}
          onClose={onCancelEdit}
          placement={SimplePopover.PopoverPlacement.top}
        >
          <div className={bem('confirmation-text')} onClick={stopEventPropagation}>
            {handleConfirmText()}
          </div>
          {onApplyText && (
            <DialogButtons
              onClick={stopEventPropagation}
              gtmId={confirmationGtmId}
              className={bem('dialog-buttons')}
              onSubmit={onSubmitEdit}
              onCancel={onCancelEdit}
              submitColor={submitColor}
            />
          )}
        </SimplePopover>
      )}
    </>
  );
};

EditableText.displayName = 'EditableText';

export function useEditableTextProps(value: boolean = false) {
  const [isEditing, setIsEditing] = useState(value);
  const [tooltipError, setInputError] = useState('');

  useEffect(() => {
    if (!isEditing) {
      setInputError('');
    }
  }, [isEditing]);

  return {
    isEditing,
    setIsEditing,
    tooltipError,
    setInputError,
  };
}
