import { daysAgo } from 'src/utils/time';
import { getCurrentAppId } from '../selectors/current_app';

export const REQUEST_LOVE_RATIO_OVER_TIME = 'REQUEST_LOVE_RATIO_OVER_TIME';
export const RECEIVED_LOVE_RATIO_OVER_TIME = 'RECEIVED_LOVE_RATIO_OVER_TIME';
export const ERROR_REQUESTING_LOVE_RATIO_OVER_TIME = 'ERROR_REQUESTING_LOVE_RATIO_OVER_TIME';

export const REQUEST_LOVE_RATIO_AGGREGATION = 'REQUEST_LOVE_RATIO_AGGREGATION';
export const RECEIVED_LOVE_RATIO_AGGREGATION = 'RECEIVED_LOVE_RATIO_AGGREGATION';
export const ERROR_REQUESTING_LOVE_RATIO_AGGREGATION = 'ERROR_REQUESTING_LOVE_RATIO_AGGREGATION';

export const REQUEST_LOVE_RATIO_PREVIOUS_PERIOD = 'REQUEST_LOVE_RATIO_PREVIOUS_PERIOD';
export const RECEIVED_LOVE_RATIO_PREVIOUS_PERIOD = 'RECEIVED_LOVE_RATIO_PREVIOUS_PERIOD';
export const ERROR_REQUESTING_LOVE_RATIO_PREVIOUS_PERIOD = 'ERROR_REQUESTING_LOVE_RATIO_PREVIOUS_PERIOD';

export const REQUEST_RATINGS_DIALOG_AGGREGATION = 'REQUEST_RATINGS_DIALOG_AGGREGATION';
export const RECEIVED_RATINGS_DIALOG_AGGREGATION = 'RECEIVED_RATINGS_DIALOG_AGGREGATION';
export const ERROR_REQUESTING_RATINGS_DIALOG_AGGREGATION = 'ERROR_REQUESTING_RATINGS_DIALOG_AGGREGATION';

export const REQUEST_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'REQUEST_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';
export const RECEIVED_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'RECEIVED_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';
export const ERROR_REQUESTING_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'ERROR_REQUESTING_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';

export const REQUEST_RATINGS_DIALOG_OVER_TIME = 'REQUEST_RATINGS_DIALOG_OVER_TIME';
export const RECEIVED_RATINGS_DIALOG_OVER_TIME = 'RECEIVED_RATINGS_DIALOG_OVER_TIME';
export const ERROR_REQUESTING_RATINGS_DIALOG_OVER_TIME = 'ERROR_REQUESTING_RATINGS_DIALOG_OVER_TIME';

export const REQUEST_APPLE_RATINGS_DIALOG_AGGREGATION = 'REQUEST_APPLE_RATINGS_DIALOG_AGGREGATION';
export const RECEIVED_APPLE_RATINGS_DIALOG_AGGREGATION = 'RECEIVED_APPLE_RATINGS_DIALOG_AGGREGATION';
export const ERROR_REQUESTING_APPLE_RATINGS_DIALOG_AGGREGATION = 'ERROR_REQUESTING_APPLE_RATINGS_DIALOG_AGGREGATION';

export const REQUEST_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'REQUEST_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';
export const RECEIVED_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'RECEIVED_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';
export const ERROR_REQUESTING_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'ERROR_REQUESTING_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';

export const REQUEST_APPLE_RATINGS_DIALOG_OVER_TIME = 'REQUEST_APPLE_RATINGS_DIALOG_OVER_TIME';
export const RECEIVED_APPLE_RATINGS_DIALOG_OVER_TIME = 'RECEIVED_APPLE_RATINGS_DIALOG_OVER_TIME';
export const ERROR_REQUESTING_APPLE_RATINGS_DIALOG_OVER_TIME = 'ERROR_REQUESTING_APPLE_RATINGS_DIALOG_OVER_TIME';

export const REQUEST_IN_APP_RATINGS_DIALOG_AGGREGATION = 'REQUEST_IN_APP_RATINGS_DIALOG_AGGREGATION';
export const RECEIVED_IN_APP_RATINGS_DIALOG_AGGREGATION = 'RECEIVED_IN_APP_RATINGS_DIALOG_AGGREGATION';
export const ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_AGGREGATION = 'ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_AGGREGATION';

export const REQUEST_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'REQUEST_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';
export const RECEIVED_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'RECEIVED_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';
export const ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD = 'ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD';

export const REQUEST_IN_APP_RATINGS_DIALOG_OVER_TIME = 'REQUEST_IN_APP_RATINGS_DIALOG_OVER_TIME';
export const RECEIVED_IN_APP_RATINGS_DIALOG_OVER_TIME = 'RECEIVED_IN_APP_RATINGS_DIALOG_OVER_TIME';
export const ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_OVER_TIME = 'ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_OVER_TIME';

const requestEnjoymentData = (apiMethod, requestAction, receivedAction, errorAction, label = '') => (start = (daysAgo(30)), end = (new Date())) => (dispatch, getState, { api }) => {
  const state = getState();
  const appId = getCurrentAppId(state);
  dispatch({
    type: requestAction,
    payload: { appId, start, end },
    meta: { appId },
  });

  return api[apiMethod](appId, start, end, label)
    .then(json => dispatch({
      type: receivedAction,
      payload: { data: json.data || [] },
      meta: { appId, start, end },
    }))
    .catch(error => dispatch({
      type: errorAction,
      payload: error,
      error: true,
      meta: { appId, start, end },
    }));
};

export const fetchRatingsDialogAggregation = requestEnjoymentData(
  'fetchRatingsDialogAggregation',
  REQUEST_RATINGS_DIALOG_AGGREGATION,
  RECEIVED_RATINGS_DIALOG_AGGREGATION,
  ERROR_REQUESTING_RATINGS_DIALOG_AGGREGATION
);

export const fetchRatingsDialogAggregationPreviousPeriod = requestEnjoymentData(
  'fetchRatingsDialogAggregationPreviousPeriod',
  REQUEST_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
  RECEIVED_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
  ERROR_REQUESTING_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD
);

export const fetchRatingsDialogOvertime = requestEnjoymentData(
  'fetchRatingsDialogOvertime',
  REQUEST_RATINGS_DIALOG_OVER_TIME,
  RECEIVED_RATINGS_DIALOG_OVER_TIME,
  ERROR_REQUESTING_RATINGS_DIALOG_OVER_TIME
);

export const fetchAppleRatingsDialogAggregation = requestEnjoymentData(
  'fetchAppleRatingsDialogAggregation',
  REQUEST_APPLE_RATINGS_DIALOG_AGGREGATION,
  RECEIVED_APPLE_RATINGS_DIALOG_AGGREGATION,
  ERROR_REQUESTING_APPLE_RATINGS_DIALOG_AGGREGATION
);

export const fetchAppleRatingsDialogAggregationPreviousPeriod = requestEnjoymentData(
  'fetchAppleRatingsDialogAggregationPreviousPeriod',
  REQUEST_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
  RECEIVED_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
  ERROR_REQUESTING_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD
);

export const fetchAppleRatingsDialogOvertime = requestEnjoymentData(
  'fetchAppleRatingsDialogOvertime',
  REQUEST_APPLE_RATINGS_DIALOG_OVER_TIME,
  RECEIVED_APPLE_RATINGS_DIALOG_OVER_TIME,
  ERROR_REQUESTING_APPLE_RATINGS_DIALOG_OVER_TIME
);

export const fetchInAppRatingsDialogAggregation = requestEnjoymentData(
  'fetchInAppRatingsDialogAggregation',
  REQUEST_IN_APP_RATINGS_DIALOG_AGGREGATION,
  RECEIVED_IN_APP_RATINGS_DIALOG_AGGREGATION,
  ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_AGGREGATION
);

export const fetchInAppRatingsDialogAggregationPreviousPeriod = requestEnjoymentData(
  'fetchInAppRatingsDialogAggregationPreviousPeriod',
  REQUEST_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
  RECEIVED_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
  ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD
);

export const fetchInAppRatingsDialogOvertime = requestEnjoymentData(
  'fetchInAppRatingsDialogOvertime',
  REQUEST_IN_APP_RATINGS_DIALOG_OVER_TIME,
  RECEIVED_IN_APP_RATINGS_DIALOG_OVER_TIME,
  ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_OVER_TIME
);
