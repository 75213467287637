/* eslint-disable import/no-import-module-exports */
import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/root';
import * as AtriumAPI from '../../api';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancer(
  applyMiddleware(thunkMiddleware.withExtraArgument({ api: AtriumAPI })),
);

export default () => {
  const store = createStore(rootReducer, enhancer);
  /* istanbul ignore next */
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/root', () => {
      const nextRootReducer = require('../reducers/root'); // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
};
