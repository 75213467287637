import { Rest } from 'src/api/rest';
import { conversationsInitialState } from 'src/reducers/conversations/conversations.state';

import { atriumCall, report } from './util';

export const fetchConversationCounts = (appId) => atriumCall(`/apps/${appId}/conversation_counts`, {
  method: 'GET',
  error: ex => {
    report('[API] Error Fetching Conversation Counts for', appId, ex);
  },
});

// https://beapi.apptentive.com/apps/:app_id/conversations?page=0&limit=50&filter=inbox&sort=latest_consumer_msg.created_at
export const fetchConversations = (params) => {
  const { appId, page = 0, limit = 50, filter = 'inbox', sort = conversationsInitialState.sortBy } = params;

  return Rest.httpGet(`/apps/${appId}/conversations`, {
    query: { page, limit, filter, sort: sort.sortBy, order: sort.order },
    errorTitle: `[API] Error Fetching Conversations for ${JSON.stringify({ page, limit, filter, sort })}`,
  }).promise;
};

export const fetchConversation = (appId, conversationId) => atriumCall(`/conversations/${conversationId}`, {
  method: 'GET',
  error: ex => {
    report('[API] Error Fetching Conversation for', appId, 'conversationId', conversationId, ex);
  },
});

export const fetchConversationMessages = (conversationId, from) => atriumCall(`/conversations/${conversationId}/messages${from ? `?from=${from}` : ''}`, {
  method: 'GET',
  error: ex => {
    report('[API] Error Fetching Conversation Messages for conversationId', conversationId, from, ex);
  },
});

export const postConversationMessage = (conversationId, formData) => atriumCall(`/conversations/${conversationId}/messages`, {
  method: 'POST',
  setContentType: 'delete',
  body: formData,
  error: ex => {
    report('[API] Error Posting Conversation Message for conversationId', conversationId, ex);
  },
});

// https://beapi.apptentive.com/conversations/:conversation_id/assign PUT { user_id: :user_id }
export const setConversationAssignee = (conversationId, assignee) => atriumCall(`/conversations/${conversationId}/assign`, {
  method: 'PUT',
  setContentType: 'application/x-www-form-urlencoded; charset=UTF-8',
  body: JSON.stringify({ user_id: assignee.user_id }),
  error: ex => {
    report('[API] Error Setting Conversation Assignee', assignee, 'for', conversationId, ex);
  },
});

// https://beapi.apptentive.com/conversations/:conversation_id/change_state PUT { state: :state }
export const setConversationState = (conversationId, state) => atriumCall(`/conversations/${conversationId}/change_state`, {
  method: 'PUT',
  setContentType: 'application/x-www-form-urlencoded; charset=UTF-8',
  body: JSON.stringify({ state }),
  error: ex => {
    report('[API] Error Setting Conversation State for', conversationId, ex);
  },
});

// https://beapi.apptentive.com/conversations/:app_id/star PUT
export const starConversation = (conversationId) => atriumCall(`/conversations/${conversationId}/star`, {
  method: 'PUT',
  error: ex => {
    report('[API] Error Setting Conversation Starred for', conversationId, ex);
  },
});

// https://beapi.apptentive.com/conversations/:app_id/unstar PUT
export const unstarConversation = (conversationId) => atriumCall(`/conversations/${conversationId}/unstar`, {
  method: 'PUT',
  error: ex => {
    report('[API] Error Setting Conversation Unstarred for', conversationId, ex);
  },
});

export const deleteConversationMessage = (conversationId, messageId) => atriumCall(`/conversations/${conversationId}/messages/${messageId}`, {
  method: 'DELETE',
  error: ex => {
    report('[API] Error Deleting message for conversation', conversationId, 'message', messageId, ex);
  },
});

export const shareMessageViaEmail = (conversationId, messageId, formFields) => atriumCall(`/conversations/${conversationId}/messages/${messageId}/forward`, {
  method: 'POST',
  body: JSON.stringify(formFields),
  error: ex => {
    report('[API] Error Forwaring Message Via Email for conversation', conversationId, 'message', messageId, ex);
  },
});
