import React from 'react';
import { InteractionType, Platform } from 'src/types/core';
import { Icon } from 'src/components/molecules';

import './interaction-no-responses.scss';

interface InteractionNoDataResponsesProps {
  className?: string;
  type: InteractionType;
  platform?: Platform;
}

const NO_DATA_INFO: Record<string, string> = {
  [InteractionType.Survey]: 'Reports will display soon after the Survey has been responded to.',
  [InteractionType.TextModal]: 'Reports will display soon after the Note has been viewed.',
  [InteractionType.Initiator]:
    'Reports will display soon after any of the events connected to the Initiator have triggered.',
};

export const InteractionNoDataResponses: React.FC<InteractionNoDataResponsesProps> = ({
  className = '',
  type,
  platform,
}) => (
  <div className={`interaction-no-responses ${className}`}>
    <Icon name={platform === Platform.Web ? 'iconWebNoReportData' : 'noReportData'} />
    <h2>No data received yet.</h2>
    <p>
      {NO_DATA_INFO[type] === NO_DATA_INFO[InteractionType.TextModal]
        ? NO_DATA_INFO[type].replace('Note', 'Prompt')
        : NO_DATA_INFO[type]}
    </p>
  </div>
);
