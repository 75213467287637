import { Rest, API } from 'src/api';
import { SurveyV12Item, FetchSurveysV12Resp, SurveyResponseStat, SurveyTextAnswers } from 'src/reducers/surveys-v12';
import { BaseService } from './base-service';

export const SURVEYS_PAGINATION_LIMIT = 20

export class SurveyService extends BaseService {
  static create(appId: string, survey: SurveyV12Item, apiVersion: string): Promise<SurveyV12Item> {
    return Rest.httpPost(API.SURVEY.CREATE(appId, apiVersion), {
      body: survey,
      errorTitle: '[API] Error POST survey item',
    }).promise;
  }

  static fetchSurvey(appId: string, surveyId: string): Promise<SurveyV12Item> {
    return Rest.httpGet(API.SURVEY.GET(appId, surveyId), {
      errorTitle: '[API] Error GET survey item',
    }).promise;
  }

  static update(appId: string, survey: SurveyV12Item, apiVersion: string): Promise<SurveyV12Item> {
    return Rest.httpPut(API.SURVEY.UPDATE(appId, survey.id, apiVersion), {
      body: survey,
      errorTitle: '[API] Error PUT update survey item',
    }).promise;
  }

  static getSurveyStats(appId: string, surveyId: string): Promise<SurveyResponseStat[]> {
    return Rest.httpGet(API.SURVEY.STATS(appId, surveyId), {
      errorTitle: '[API] Error GET survey stats',
    }).promise;
  }
  /**
   * @param pagination only for single questionId [questionIds]
   */
  static getTextAnswers(
    appId: string,
    surveyId: string,
    questionIds: string[],
    pagination?: { limit: number; page: number },
  ): Promise<Record<string, SurveyTextAnswers>> {
    const requests = questionIds.map(
      (id) =>
        Rest.httpGet(API.SURVEY.TEXT_ANSWERS(appId, surveyId, id), {
          query: pagination,
          errorTitle: '[API] Error GET survey text answers',
        }).promise,
    );

    return Promise.all(requests).then((results) =>
      results.reduce((acc, res, i) => ({ ...acc, [questionIds[i]]: res }), {}),
    );
  }

  static fetchSurveys(appId: string, page: number = 1, isActive = true): Promise<FetchSurveysV12Resp> {
    return Rest.httpGet(API.SURVEY.ALL(appId, page, isActive), {
      errorTitle: '[API] Error GET surveys list',
    }).promise;
  }

  static cloneSurveyToApp(
    appId: string,
    surveyId: string,
    options: { cloneTargeting: boolean },
  ): Promise<{ app_title: string; survey: { active: boolean; id: string } }> {
    return Rest.httpPost(API.SURVEY.CLONE(appId, surveyId), {
      body: { options },
      errorTitle: '[API] Error POST clone survey to appId',
    }).promise;
  }
}
