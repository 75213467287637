// Update Current User Email Preferences
export const UPDATE_USER_EMAIL_PREFERENCES_PENDING = 'UPDATE_USER_EMAIL_PREFERENCES_PENDING';
export const updateUserEmailPreferencesPending = (userId, data) => ({
  type: UPDATE_USER_EMAIL_PREFERENCES_PENDING,
  payload: data,
  meta: { data, userId },
});
export const UPDATE_USER_EMAIL_PREFERENCES_SUCCESS = 'UPDATE_USER_EMAIL_PREFERENCES_SUCCESS';
export const updateUserEmailPreferencesSuccess = (userId, data, json) => ({
  type: UPDATE_USER_EMAIL_PREFERENCES_SUCCESS,
  payload: json,
  meta: { data, userId },
});
export const UPDATE_USER_EMAIL_PREFERENCES_FAILURE = 'UPDATE_USER_EMAIL_PREFERENCES_FAILURE';
export const updateUserEmailPreferencesFailure = (userId, data, error) => ({
  type: UPDATE_USER_EMAIL_PREFERENCES_FAILURE,
  payload: error,
  error: true,
  meta: { data, userId },
});
export const updateUserEmailPreferences = (userId, data) => (dispatch, getState, { api }) => {
  dispatch(updateUserEmailPreferencesPending(userId, data));
  return api.updateUserEmailPreferences(userId, data)
    .then(json => dispatch(updateUserEmailPreferencesSuccess(userId, data, json)))
    .catch(ex => dispatch(updateUserEmailPreferencesFailure(userId, data, ex)));
};

// Fetch Email Preferences
export const FETCH_EMAIL_PREFERENCES_PENDING = 'FETCH_EMAIL_PREFERENCES_PENDING';
export const fetchEmailPreferencesPending = (userId) => ({
  type: FETCH_EMAIL_PREFERENCES_PENDING,
  payload: { userId },
  meta: { userId },
});
export const FETCH_EMAIL_PREFERENCES_SUCCESS = 'FETCH_EMAIL_PREFERENCES_SUCCESS';
export const fetchEmailPreferencesSuccess = (userId, json) => ({
  type: FETCH_EMAIL_PREFERENCES_SUCCESS,
  payload: json,
  meta: { userId },
});
export const FETCH_EMAIL_PREFERENCES_FAILURE = 'FETCH_EMAIL_PREFERENCES_FAILURE';
export const fetchEmailPreferencesFailure = (userId, error) => ({
  type: FETCH_EMAIL_PREFERENCES_FAILURE,
  payload: error,
  error: true,
  meta: { userId },
});
export const fetchEmailPreferences = (userId) => (dispatch, getState, { api }) => {
  dispatch(fetchEmailPreferencesPending(userId));
  return api.fetchEmailPreferences(userId)
    .then(json => dispatch(fetchEmailPreferencesSuccess(userId, json)))
    .catch(ex => dispatch(fetchEmailPreferencesFailure(userId, ex)));
};
