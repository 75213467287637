import { ITagAction } from '../tags';

export enum ADD_CONVERSATION_TAG {
  PENDING = 'ADD_CONVERSATION_TAG_PENDING',
  SUCCESS = 'ADD_CONVERSATION_TAG_SUCCESS',
  ERROR = 'ADD_CONVERSATION_TAG_ERROR',
}

export const addTagPending: ITagAction<any, any> = (payload, meta) => ({
  type: ADD_CONVERSATION_TAG.PENDING,
  payload,
  meta,
});
export const addTagSuccess: Function = (conversationId: string): ITagAction<any, any> => (payload, meta) => ({
  type: ADD_CONVERSATION_TAG.SUCCESS,
  payload,
  meta: {
    ...meta,
    conversationId,
  },
});
export const addTagError: ITagAction<any, any> = (payload, meta) => ({
  type: ADD_CONVERSATION_TAG.ERROR,
  payload,
  meta,
});

export enum RENAME_CONVERSATION_TAG {
  PENDING = 'RENAME_CONVERSATION_TAG_PENDING',
  SUCCESS = 'RENAME_CONVERSATION_TAG_SUCCESS',
  ERROR = 'RENAME_CONVERSATION_TAG_ERROR',
}

export const renameTagPending: ITagAction<any, any> = (payload, meta) => ({
  type: RENAME_CONVERSATION_TAG.PENDING,
  payload,
  meta,
});
export const renameTagSuccess = (conversationId: string): ITagAction<any, { newTagName: string; tagName: string }> => (
  payload,
  meta
) => ({
  type: RENAME_CONVERSATION_TAG.SUCCESS,
  payload,
  meta: {
    ...meta,
    conversationId,
  },
});
export const renameTagError: ITagAction<any, any> = (payload, meta) => ({
  type: RENAME_CONVERSATION_TAG.ERROR,
  payload,
  meta,
});

export enum REMOVE_CONVERSATION_TAG {
  PENDING = 'REMOVE_CONVERSATION_TAG_PENDING',
  SUCCESS = 'REMOVE_CONVERSATION_TAG_SUCCESS',
  ERROR = 'REMOVE_CONVERSATION_TAG_ERROR',
}

export const removeTagPending: ITagAction<any, any> = (payload, meta) => ({
  type: REMOVE_CONVERSATION_TAG.PENDING,
  payload,
  meta,
});
export const removeTagSuccess = (conversationId: string): ITagAction<any, any> => (payload, meta) => ({
  type: REMOVE_CONVERSATION_TAG.SUCCESS,
  payload,
  meta: {
    ...meta,
    conversationId,
  },
});
export const removeTagError: ITagAction<any, any> = (payload, meta) => ({
  type: REMOVE_CONVERSATION_TAG.ERROR,
  payload,
  meta,
});
