import { any, isEmpty, pick, values } from 'ramda';
import { InitiatorItem } from 'src/reducers/initiators/initiators.types';
import { InteractionType } from 'src/types/core';
import { eventNameToHuman } from 'src/utils/events';

const defaultModel: InitiatorItem = {
  active: false,
  events: [],
  name: '',
  workflow_id: undefined,
  workflow_name: undefined,
  view_count: 0,
};

export class InitiatorModel {
  static Defaults = defaultModel;

  static getInitialSection(model?: InitiatorItem): 'targeting' | 'reporting' | 'content' {
    const isInitiatorJustCreated = localStorage.getItem('isInitiatorJustCreated');
    if (isInitiatorJustCreated) {
      localStorage.removeItem('isInitiatorJustCreated');
      return 'targeting';
    }

    if (model && InitiatorModel.hasViews(model)) return 'reporting';

    return 'content';
  }

  // README: https://github.com/apptentive/pupum/blob/0145af596ea3a6375aa44563fda2c65351374219/legacy/assets/views/initiators/index.js#L97
  static invokeEvents({ events }: InitiatorItem): string[] {
    if (Array.isArray(events)) {
      return (events || []).map((code) => eventNameToHuman(code));
    }
    if (typeof events === 'string') {
      return [eventNameToHuman(events)];
    }
    return [];
  }

  static isValid(model: InitiatorItem): boolean {
    const requiredProps = pick(['name', 'workflow_id', 'workflow_name'], model);

    return !any(isEmpty)(values(requiredProps));
  }

  static setData(model: InitiatorItem, data: Partial<InitiatorItem>): InitiatorItem {
    return { ...model, ...data };
  }

  static invokeInteractions(triggeredBy: InitiatorItem['triggered_by']) {
    return {
      notes: triggeredBy ? triggeredBy.filter((item) => item.type === InteractionType.TextModal) : [],
    };
  }

  static hasViews(model: InitiatorItem) {
    return (model.view_count || 0) > 0;
  }
}
