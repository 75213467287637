import React, { useMemo, useCallback } from 'react';

import { connect, useSelector } from 'react-redux';
import { State } from 'src/reducers';
import { getFanSignalsCountsByDashboard } from 'src/selectors/fan-signals.selectors';
import {
  getCurrentAppPrimaryCIDKey,
  isCurrentAppUserMemberAdmin,
  getCurrentAppPlatform,
  getCurrentAppMembershipRole,
} from 'src/selectors/current_app';
import { bemPrefix, useFeatureSettings } from 'src/utils';

import { SimpleDropdown } from 'src/components/molecules';
import { useCIDActions } from 'src/settings/customer_id/useCidActions';
import { Platform } from 'src/types/core';
import { getDashboardDateRange } from 'src/selectors/dashboard';
import { eventMetrics, Events } from 'src/actions/event-metrics';
import { FS_STATUS_COUNT_MAP, FanSignalDayCount } from 'src/services/fan-signals-service';
import { AppMemberRole } from "../../../reducers/apps.types";

import './fan-signals-shifted-actions.scss';

export interface FanSignalsShiftedActionsProps {
  fanSignalCounts: FanSignalDayCount[];
  withPrimaryCid: boolean;
  isAdmin: boolean;
  platform: Platform;
  role: string;
  sendEvent(event: Events): void;
}

export function invokeShiftedToRiskCounts(data: FanSignalDayCount[]): number {
  return data.reduce((acc, item) => (acc += item[FS_STATUS_COUNT_MAP.LostFan] || 0), 0);
}

export const ShiftedToRiskCountLabel: React.FC<{ count: number }> = ({ count }) => (
  <span className={'shifted-to-risk-count'}>{Intl.NumberFormat().format(count)}</span>
);

const bem = bemPrefix('fan-signals-shifted-actions');

enum ActionType {
  Export = 'export',
  Survey = 'survey',
  Insights = 'insights',
}

const insightsAction = {
  label: 'Review Insights',
  value: ActionType.Insights,
};

const surveyAction = {
  label: 'Create Survey',
  value: ActionType.Survey,
};

const exportAction = {
  label: 'Export The Data',
  value: ActionType.Export,
};

export const FanSignalsShiftedActions: React.FC<FanSignalsShiftedActionsProps> = ({
  fanSignalCounts,
  withPrimaryCid,
  isAdmin,
  platform,
  role,
  sendEvent,
}) => {
  const dashboardRange = useSelector(getDashboardDateRange) as { startDate: number; endDate: number };
  const count = useMemo(() => invokeShiftedToRiskCounts(fanSignalCounts), [fanSignalCounts]);
  const { runExportData, createSurvey, reviewInsights } = useCIDActions();
  const { flag, hasFeature } = useFeatureSettings();
  const hasUnlimExportFlag = hasFeature(flag.UNLIMITED_FAN_SIGNALS_EXPORT);
  const isReporter = role === AppMemberRole.Reporter;

  const getActions = useCallback(() => {
    let actions = [insightsAction];

    if (platform !== Platform.Web && !isReporter) {
      actions = [...actions, surveyAction];
    }

    return [...actions, exportAction];
  }, [hasUnlimExportFlag, platform, isReporter]);

  const handleExport = () => {
    sendEvent(Events.EXPORT_SIGNALS_TAKE_ACTION_DROPDOWN);
    runExportData(hasUnlimExportFlag);
  };

  const onSelectAction = useCallback(
    (value: ActionType) => {
      switch (value) {
        case ActionType.Export: {
          handleExport();
          break;
        }
        case ActionType.Survey: {
          createSurvey();
          break;
        }
        case ActionType.Insights:
          reviewInsights();
          break;
        default:
          break;
      }
    },
    [dashboardRange]
  );

  const hasCidAccess = isAdmin || isReporter;

  if (!count || !withPrimaryCid) {
    return null;
  }

  if (!hasCidAccess) {
    return null;
  }

  return (
    <div className={bem('')}>
      <p>
        <ShiftedToRiskCountLabel count={count} /> fans Shifted to Risk in the last 30 days!
      </p>
      <SimpleDropdown
        placeholder="TAKE ACTION"
        className={bem('select-dropdown')}
        options={getActions()}
        onSelect={onSelectAction}
      />
    </div>
  );
};

export const FanSignalsShiftedActionsContainer = connect<
  Omit<FanSignalsShiftedActionsProps, 'sendEvent'>,
  Pick<FanSignalsShiftedActionsProps, 'sendEvent'>
>(
  (state: State) => ({
    fanSignalCounts: getFanSignalsCountsByDashboard(state),
    withPrimaryCid: !!getCurrentAppPrimaryCIDKey(state),
    isAdmin: isCurrentAppUserMemberAdmin(state),
    platform: getCurrentAppPlatform(state),
    role: getCurrentAppMembershipRole(state),
  }),
  (dispatch: Function) => ({
    sendEvent: (event: Events) => dispatch(eventMetrics.sendEvent(event)),
  })
)(FanSignalsShiftedActions);
