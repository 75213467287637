import React, { useEffect, useState, useCallback } from 'react';

import { bemPrefix } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';

import { usePopoverProps, SimplePopover } from '../popover';
import { DotsMenu, DotsMenuProps } from '../dots-menu';
import { Icon, IconName } from '../icons';

import './dots-menu-actions.scss';

interface Handler {
  label: string;
  icon?: IconName;
  disabled?: boolean;
  gtmId?: GtmID;
  onClick(): React.ReactNode | void;
}

export interface DotsMenuActionsProps extends Omit<DotsMenuProps, 'children'> {
  handlers: Handler[];
}

const bem = bemPrefix('dots-menu-actions');

export const DotsMenuActions: React.FC<DotsMenuActionsProps> = ({
  handlers,
  isOpen,
  onMenuToggle,
  className,
  placement = SimplePopover.PopoverPlacement.bottom,
}) => {
  const { isOpenPopover, closePopover, showPopover } = usePopoverProps(isOpen);
  const [content, setContent] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    if (!isOpenPopover) {
      closePopover();
    }
    if (isOpen !== isOpenPopover) {
      isOpen ? showPopover() : closePopover();
    }
  }, [isOpen, isOpenPopover, content]);

  const onHandlerClick = useCallback(
    (handler: Handler) => () => {
      if (handler.disabled) {
        return;
      }
      const newContent = handler.onClick() as any;
      if (React.isValidElement(newContent)) {
        setContent(newContent);
      } else {
        closePopover();
      }
    },
    [handlers, content]
  );

  const onDotsMenuToggle = useCallback(
    (show: boolean) => {
      if (onMenuToggle) {
        onMenuToggle(show);
      }
      if (!show) {
        setContent(null);
      }
    },
    [isOpenPopover, onMenuToggle, closePopover, showPopover]
  );

  return (
    <DotsMenu className={className} isOpen={isOpenPopover} onMenuToggle={onDotsMenuToggle} placement={placement}>
      {content || (
        <ul className={bem()}>
          {handlers.map((handler) => (
            <li
              key={handler.label}
              data-label={handler.label}
              className={bem('item', { disabled: !!handler.disabled })}
              onClick={onHandlerClick(handler)}
              gtm-id={handler.gtmId}
            >
              {handler.icon && <Icon className={bem('icon')} name={handler.icon} />}
              <span className={bem('label')}>{handler.label}</span>
            </li>
          ))}
        </ul>
      )}
    </DotsMenu>
  );
};

DotsMenuActions.displayName = 'DotsMenuActions';
