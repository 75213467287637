import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { bemPrefix } from 'src/utils';
import { useDropdownProps } from 'src/hooks';
import { CustomerIdSetupDialog } from 'src/settings/customer_id/customer-id-setup-dialog';
import { invokeShiftedToRiskCounts } from 'src/dashboard/components/fan-signals-graph-actions';
import { DownloadFanSignalsExportDialog } from 'src/dashboard/components/download-fan-signals-dialog-options/download-fan-signals-export-dialog';
import { DownloadFanSignalsInfoDialog } from 'src/dashboard/components/download-fan-signals-dialog-options/download-fan-signals-info-dialog';
import { Events } from 'src/actions/event-metrics';
import { FanSignalDayCount } from 'src/services/fan-signals-service';
import { Dialog } from '../dialog';

import './download-button.scss';

export interface DownloadButtonProps {
  open?: boolean;
  downloadDialog?: React.ReactElement<{
    onClose: () => void;
    onViewChangeId?(id: string): void;
    onShowExportModal: () => void;
  }>;
  isModal?: boolean;
  fanSignalCounts?: FanSignalDayCount[];
  hasSeenExportInfoModal?: boolean;
  isReporter?: boolean;
  acknowledgeFsExportInfoModal?(): void;
  sendEvent?(event: Events): void;
}

const noop = () => null;
const bem = bemPrefix('export-download-button');

export const DownloadButton: React.FC<DownloadButtonProps> = ({
  open = false,
  downloadDialog,
  isModal = false,
  fanSignalCounts,
  hasSeenExportInfoModal = true,
  isReporter,
  acknowledgeFsExportInfoModal = noop,
  sendEvent = noop,
}) => {
  const { isOpenDropdown, viewId, setViewId, showDropdown, closeDropdown } = useDropdownProps(open);
  const [width, setWidth] = useState(160);
  const [showModal, setShowModal] = useState(false);
  const [showUnlimitedExportInfoModal, setShowUnlimitedExportInfoModal] = useState(false);
  const [showExportInfoModal, setShowExportInfoModal] = useState(false);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const dialogRef = useRef<HTMLDivElement | null>(null);

  const isOpen = isOpenDropdown ? 'opened' : 'closed';

  useLayoutEffect(() => {
    if (!isOpenDropdown) {
      setWidth(160);
      return;
    }
    if (dialogRef.current) {
      const newWidth = dialogRef.current.children[0].getBoundingClientRect().width;
      setWidth(Math.max(160, newWidth));
    }
  }, [dialogRef, dialogRef.current, isOpenDropdown, viewId]);

  const onCustomerClick = useCallback(() => setShowModal(true), []);
  const onCloseModal = useCallback(() => setShowModal(false), []);
  const counts = useMemo(() => fanSignalCounts && invokeShiftedToRiskCounts(fanSignalCounts), [fanSignalCounts]);

  const onExportlinkClick = useCallback(() => {
    setShowUnlimitedExportInfoModal(true);
  }, []);
  const onCloseUnlimitedExportInfoModal = useCallback(() => setShowUnlimitedExportInfoModal(false), []);

  const onCloseExportInfoModal = useCallback(() => {
    setShowExportInfoModal(false);
    if (sendEvent) {
      sendEvent(Events.EXPORT_SIGNALS_INFO_MODAL_HAS_SEEN);
    }
    if (acknowledgeFsExportInfoModal) {
      acknowledgeFsExportInfoModal();
    }
  }, []);

  const onClickButtonHandler = useCallback(() => {
    if (!hasSeenExportInfoModal) {
      return showExportInfoModal ? setShowExportInfoModal(false) : setShowExportInfoModal(true);
    }
    if (isModal) {
      return onCustomerClick();
    }
    return showDropdown();
  }, [showExportInfoModal, setShowExportInfoModal, isModal, onCustomerClick, showDropdown]);

  return (
    <div className={bem('', viewId ? 'options' : isOpen)} ref={targetRef} style={{ width }}>
      {/* eslint-disable-next-line */}
      <div className={bem('button')} onClick={onClickButtonHandler}>
        <span className={bem('title')}>Export</span>
      </div>
      {isOpenDropdown && (
        <Dialog
          innerRef={dialogRef}
          isOpen={isOpenDropdown}
          viewId={viewId}
          targetEl={targetRef.current}
          onClose={closeDropdown}
          withArrow={false}
        >
          <div className={bem('dialog')}>
            {downloadDialog
              && React.cloneElement(downloadDialog, {
                onClose: closeDropdown,
                onViewChangeId: setViewId,
                onShowExportModal: onExportlinkClick,
              })}
          </div>
        </Dialog>
      )}
      <CustomerIdSetupDialog showModal={showModal} shiftedCounts={counts || 0} onCloseModal={onCloseModal} isReporter={isReporter} isExport />
      <DownloadFanSignalsExportDialog
        showModal={showUnlimitedExportInfoModal}
        onCloseModal={onCloseUnlimitedExportInfoModal}
        sendEvent={sendEvent}
      />
      <DownloadFanSignalsInfoDialog onCloseModal={onCloseExportInfoModal} showModal={showExportInfoModal} />
    </div>
  );
};

DownloadButton.displayName = 'DownloadButton';
