import { Time, TimeMoment } from './Time';
import { DateLastPeriod, TIME_PERIOD_LABEL } from './time-period';
import { daysAgoInbound } from './time-utils';

const getLabelNameProps = (period: DateLastPeriod) => ({
  label: TIME_PERIOD_LABEL[period],
  name: period,
  endDate: Time.create().endOf('day'),
});

export const CUSTOM_PERIOD_NAME = 'custom';
export type CustomPeriodName = typeof CUSTOM_PERIOD_NAME;
/**
 * Return correct range for last period
 * 02.25.2020 - 7days => Should be range [02.19.2020 - 02.25.2020]
 * Because of days: [19, 20, 21, 22, 23, 24, 25]
 */
export interface TimePreset {
  label: string;
  name: DateLastPeriod | CustomPeriodName;
  endDate: TimeMoment | number;
  startDate: TimeMoment | number;
  isSelected?(range: Pick<TimePreset, 'startDate' | 'endDate'>): boolean;
  disabled?: boolean;
}

export const TIME_PRESETS: TimePreset[] = [
  {
    ...getLabelNameProps(DateLastPeriod.DAYS_7),
    startDate: Time.create(daysAgoInbound(7)).startOf('day'),
  },
  {
    ...getLabelNameProps(DateLastPeriod.DAYS_14),
    startDate: Time.create(daysAgoInbound(14)).startOf('day'),
  },
  {
    ...getLabelNameProps(DateLastPeriod.DAYS_30),
    startDate: Time.create(daysAgoInbound(30)).startOf('day'),
  },
  {
    ...getLabelNameProps(DateLastPeriod.DAYS_60),
    startDate: Time.create(daysAgoInbound(60)).startOf('day'),
  },
  {
    ...getLabelNameProps(DateLastPeriod.DAYS_90),
    startDate: Time.create(daysAgoInbound(90)).startOf('day'),
  },
  {
    ...getLabelNameProps(DateLastPeriod.DAYS_180),
    startDate: Time.create(daysAgoInbound(180)).startOf('day'),
  },
  {
    ...getLabelNameProps(DateLastPeriod.DAYS_365),
    startDate: Time.create(daysAgoInbound(365)).startOf('day'),
  },
];

export const getStartEndOptionByPeriod = (period: DateLastPeriod) => {
  const time = TIME_PRESETS.find((item) => item.name === period) as TimePreset;
  return {
    startDate: time.startDate.valueOf(),
    endDate: time.endDate.valueOf(),
  };
};
