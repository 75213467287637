import React from 'react';
import { RouteComponentProps } from 'react-router';
import { equals, isEmpty, isNil, omit, pickBy, symmetricDifference, uniq, uniqBy } from 'ramda';
import { InsightsBaseParams } from 'src/actions/insights/insights.actions';
import {
  ICurrentSearch,
  InsightsState,
  PhraseItem,
  AggsRating,
  AggsSentiment,
  AggsType,
  DEFAULT_INSIGHTS_FILTERS,
  FanSignalStatus,
} from 'src/reducers/insights';
import { debounce } from 'src/decorators';
import { LocationHistory, StoreActions, SubscribeDisposer } from 'src/store';
import { getDateRangePeriodName } from 'src/utils/time';

import { Features } from 'src/types/core';
import { DateRangeSelectorPickerProps } from 'src/components/date-range-selector-picker';

import {
  generateInsightsUrlQuery,
  getInsightsParamsFromUrlQuery,
  getDateRangeFromUrlQuery,
  prepareSerializedPhrases,
  resolveTypesState,
} from './insights-context.utils';
import { subscribeOnStoreActions } from './insights-context.listeners';
import { InsightsContextData, InsightsFilterParams, InsightsPageParams } from './insights-context.types';

interface InsightsContextMethods {
  setTypes(types: InsightsContextData['types']): void;
  setTags(tags: string[]): void;
  removeTag(tag: string): void;
  setFanSignals(signals: FanSignalStatus[]): void;
  removeFanSignal(signal: FanSignalStatus): void;
  setSurveyName(survey: string[]): void;
  removeSurveyName(survey: string): void;
  setText(text: string, tags?: string[]): void;
  setPhrases(phrases: InsightsContextData['phrases']): void;
  removePhrase(phrase: PhraseItem): void;
  setRating(rating: AggsRating): void;
  setRegions(regions: string[]): void;
  setSentiment(sentiment: AggsSentiment): void;
  setFilters(filters: Partial<InsightsFilterParams>): void;
  getAggregatedParams(): InsightsFilterParams;
  initializeApp(isNewApp: boolean): void;
}

const insightsContextInitialState: InsightsContextData = {
  tags: [],
  fs_state: [],
  survey_title: [],
  text: '',
  phrases: DEFAULT_INSIGHTS_FILTERS.phrases,
  types: DEFAULT_INSIGHTS_FILTERS.types,
  rating: DEFAULT_INSIGHTS_FILTERS.rating,
  regions: DEFAULT_INSIGHTS_FILTERS.regions,
  sentiment: DEFAULT_INSIGHTS_FILTERS.sentiment,
};

const noop = () => null;

const phrasesToString = (phrases: Pick<PhraseItem, 'phrase'>[] = []) => phrases.map(({ phrase }: PhraseItem) => phrase);

const validateTypes = (types: AggsType[], storeId: string): AggsType[] => (storeId ? types : types.filter((type) => type !== AggsType.REVIEW));

const resolveState = (data: Partial<InsightsContextData>, storeId: string) => ({
  ...insightsContextInitialState,
  tags: data.tags || insightsContextInitialState.tags,
  fs_state: data.fs_state || insightsContextInitialState.fs_state,
  survey_title: data.survey_title || insightsContextInitialState.survey_title,
  text: data.text || insightsContextInitialState.text,
  phrases: data.phrases || insightsContextInitialState.phrases,
  types: validateTypes(data.types || insightsContextInitialState.types, storeId),
  regions: data.regions || insightsContextInitialState.regions,
  sentiment: data.sentiment || insightsContextInitialState.sentiment,
  rating: data.rating || insightsContextInitialState.rating,
});

const initStateFromQuery = (
  location: LocationHistory,
  { isNewApp = false, storeId }: { isNewApp?: boolean; storeId: string }
): InsightsContextData => {
  const query = getInsightsParamsFromUrlQuery(location, isNewApp);
  return resolveState(query, storeId);
};

export const InsightsContext = React.createContext<InsightsContextData & InsightsContextMethods>({
  ...insightsContextInitialState,
  setTypes: noop,
  setTags: noop,
  removeTag: noop,
  setFanSignals: noop,
  removeFanSignal: noop,
  setSurveyName: noop,
  removeSurveyName: noop,
  setText: noop,
  setPhrases: noop,
  removePhrase: noop,
  setRating: noop,
  setRegions: noop,
  setSentiment: noop,
  setFilters: noop,
  initializeApp: noop,
  getAggregatedParams: noop as any,
});

export interface InsightsContextProviderProps extends RouteComponentProps {
  appId: string;
  storeId: string;
  features: Features[];
  startDate: number;
  endDate: number;
  dataPagination: InsightsState['dataPagination'];
  lastSearch: ICurrentSearch;
  fetchApp(appId: string): void;
  onInitInsights(): void;
  onCleanupInsights(): void;
  onApplyFilters(params: InsightsPageParams, resetPagination: boolean, pristinePagination: boolean): void;
  onTagUpdated(params: InsightsBaseParams): void;
  onUpdateDateRange: DateRangeSelectorPickerProps['onUpdateDateRange'];
}

export const getInsightsUrlFromFilters = (filters: Partial<InsightsFilterParams>, appId: string): string => {
  const phrases = prepareSerializedPhrases(filters.phrases || []);
  return generateInsightsUrlQuery(
    {
      ...insightsContextInitialState,
      ...(filters as InsightsFilterParams),
      phrases,
    },
    `/apps/${appId}/insights`
  );
};

export class InsightsContextProvider extends React.PureComponent<InsightsContextProviderProps, InsightsContextData> {
  static getInsightsUrlFromFilters = getInsightsUrlFromFilters;

  subscribers: SubscribeDisposer[] = [];

  storeActions = StoreActions.getInstance();

  constructor(props: InsightsContextProviderProps) {
    super(props);
    this.state = { ...initStateFromQuery(props.location as LocationHistory, { storeId: props.storeId }) };

    const { startDate, endDate } = getDateRangeFromUrlQuery(props.location as LocationHistory);
    if (startDate && endDate && !equals([props.startDate, props.endDate], [startDate, endDate])) {
      this.props.onUpdateDateRange(startDate, endDate, getDateRangePeriodName(startDate, endDate));
    }

    this.applyFilters();
  }

  componentDidMount() {
    this.props.onInitInsights();

    const subs = subscribeOnStoreActions(this.storeActions, {
      getFilters: () => ({ ...this.state }),
      setTags: this.setTags,
      removeTag: this.removeTag,
      refreshAggregatedTags: () => this.refreshAggregatedTags(),
      applyFilters: () => this.applyFilters(),
    });

    this.subscribers.push(...subs);
  }

  componentDidUpdate(prevProps: InsightsContextProviderProps) {
    const diffAppId = prevProps.appId !== this.props.appId;
    const diffFeatures = !isEmpty(symmetricDifference(prevProps.features, this.props.features));

    if (diffAppId || diffFeatures) {
      this.initializeApp(diffAppId);
    }
    if (diffAppId) {
      this.props.fetchApp(this.props.appId);
    }
  }

  componentWillUnmount() {
    this.subscribers.forEach((item) => item.dispose());
    this.subscribers = [];
    this.props.onCleanupInsights();
  }

  initializeApp: InsightsContextMethods['initializeApp'] = (isNewApp) => {
    this.setStateData(
      initStateFromQuery(this.props.location as LocationHistory, { isNewApp, storeId: this.props.storeId })
    );
  };

  private updateUrlQuery = (page: number) => {
    const { ...params } = this.state;
    const { startDate, endDate } = this.props;
    const query = generateInsightsUrlQuery({
      ...params,
      startDate,
      endDate,
      page,
    });

    window.history.pushState({}, '', query);
  };

  private setStateData = (data: Partial<InsightsContextData>) => {
    const pureData = pickBy<Partial<InsightsContextData>, InsightsContextData>(
      (prop) => !isNil(prop),
      resolveTypesState(data, this.state)
    );
    this.setState(pureData, () => this.applyFilters());
  };

  setTypes: InsightsContextMethods['setTypes'] = (types) => {
    const validTypes = validateTypes(types, this.props.storeId);
    this.setStateData({ types: validTypes });
  };

  setTags: InsightsContextMethods['setTags'] = (tags) => this.setStateData({ tags: uniq(tags) });
  removeTag: InsightsContextMethods['removeTag'] = (tag) => {
    if (this.state.tags.includes(tag)) {
      this.setTags(this.state.tags.filter((name) => name !== tag));
    }
  };

  setFanSignals: InsightsContextMethods['setFanSignals'] = (signals) => this.setStateData({ fs_state: uniq(signals) });
  removeFanSignal: InsightsContextMethods['removeFanSignal'] = (signal) => {
    if (this.state.fs_state.includes(signal)) {
      this.setFanSignals(this.state.fs_state.filter((value) => value !== signal));
    }
  };

  setSurveyName: InsightsContextMethods['setSurveyName'] = (survey) => this.setStateData({ survey_title: uniq(survey) });
  removeSurveyName: InsightsContextMethods['removeSurveyName'] = (survey) => {
    if (this.state.survey_title.includes(survey)) {
      this.setSurveyName(this.state.survey_title.filter((value) => value !== survey));
    }
  };

  setText: InsightsContextMethods['setText'] = (text, tags) => {
    const data = tags ? { tags: uniq([...this.state.tags, ...tags]), text: '' } : { text, tags: this.state.tags };
    this.setStateData(data);
  };

  setPhrases: InsightsContextMethods['setPhrases'] = (phrases) => {
    this.setStateData({ phrases: uniqBy(({ phrase }) => phrase, phrases) });
  };
  removePhrase: InsightsContextMethods['removePhrase'] = (phrase) => {
    this.setPhrases(this.state.phrases.filter((item) => item.phrase !== phrase.phrase));
  };

  setRating: InsightsContextMethods['setRating'] = (rating) => this.setStateData({ rating });

  setRegions: InsightsContextMethods['setRegions'] = (regions) => this.setStateData({ regions });

  setSentiment: InsightsContextMethods['setSentiment'] = (sentiment) => this.setStateData({ sentiment });

  setFilters: InsightsContextMethods['setFilters'] = ({ startDate, endDate, ...filters }) => {
    const phrases = prepareSerializedPhrases(filters.phrases || []);
    const data: InsightsContextData = resolveState({ ...filters, phrases }, this.props.storeId);
    if (startDate && endDate) {
      this.props.onUpdateDateRange(startDate, endDate, 'custom');
    }
    this.setStateData(data);
  };

  refreshAggregatedTags = () => this.props.onTagUpdated(this.getAggregatedParams());

  private shouldResetPage = () => {
    const { $pristine: pristine } = this.props.dataPagination;
    const search = {
      ...omit(['phrases'], this.state),
      phrases: phrasesToString(this.state.phrases),
    };
    return !pristine && !equals(search, this.props.lastSearch);
  };

  getAggregatedParams = (): InsightsFilterParams => ({
    ...omit(['phrases'], this.state),
    phrases: phrasesToString(this.state.phrases),
    startDate: this.props.startDate,
    endDate: this.props.endDate,
    storeAppId: this.props.storeId,
  });

  @debounce()
  private applyFilters() {
    const { page: currentPage, $pristine: pristine } = this.props.dataPagination;
    const resetPagination = this.shouldResetPage();
    const page = resetPagination ? 0 : currentPage;
    this.updateUrlQuery(page);

    const params = this.getAggregatedParams();
    this.props.onApplyFilters({ ...params, page }, resetPagination, pristine);
  }

  render() {
    return (
      <InsightsContext.Provider
        value={{
          ...this.state,
          setTypes: this.setTypes,
          setTags: this.setTags,
          removeTag: this.removeTag,
          setFanSignals: this.setFanSignals,
          removeFanSignal: this.removeFanSignal,
          setSurveyName: this.setSurveyName,
          removeSurveyName: this.removeSurveyName,
          setPhrases: this.setPhrases,
          removePhrase: this.removePhrase,
          setText: this.setText,
          setRating: this.setRating,
          setRegions: this.setRegions,
          setSentiment: this.setSentiment,
          setFilters: this.setFilters,
          initializeApp: this.initializeApp,
          getAggregatedParams: this.getAggregatedParams,
        }}
        {...this.props}
      />
    );
  }
}
