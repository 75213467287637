import React from 'react';
import { bemPrefix } from '../../../utils';

import './divider.scss';

const bem = bemPrefix('app-divider');

export interface DividerProps {
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({ className = '' }) => <div className={`${bem()} ${className}`} />;
