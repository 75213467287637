import React from 'react';
import { bemPrefix } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';

import './table-list-title.scss';

interface TableListTitleProps {
  className?: string;
  title: React.ReactNode;
  margined?: boolean;
  isHeader?: boolean;
  rightContent?: React.ReactNode;
  gtmId?: GtmID;
  onClick?(e: React.SyntheticEvent): void;
}

const bem = bemPrefix('table-list-title');

export const TableListTitle: React.FC<TableListTitleProps> = ({
  className = '',
  title,
  rightContent,
  gtmId,
  onClick,
  margined,
  isHeader,
}) => (
  <div
    className={`${bem('', { margined: !!margined, pointer: !!onClick })} ${className}`}
    onClick={onClick}
    gtm-id={gtmId}
  >
    <div className={bem('filter-label', { isHeader: !!isHeader })}>{title}</div>
    {React.isValidElement(rightContent) ? rightContent : <span>{rightContent}</span>}
  </div>
);
