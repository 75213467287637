import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  FORMAT_TEXT_COMMAND,
  TextFormatType,
  $getRoot,
  $insertNodes,
  $getSelection,
  $isRangeSelection,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_LOW,
} from 'lexical';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $isLinkNode } from '@lexical/link';
import { useMergeRefs } from '@floating-ui/react';

import { USE_FORM_TRIGGER_EVENT } from 'src/hooks';

import { bemPrefix } from 'src/utils';
import { Icon, IconName } from '../../icons';
import { addEditorStyling, FormatType, INLINE_FORMAT_TYPES, removeEditorStyling } from '../helpers';
import { EDIT_LINK_COMMAND } from './edit-link-plugin';

import './toolbar-plugin.scss';

const bem = bemPrefix('rich-text-editor-toolbar');

interface FormatButton {
  key: FormatType;
  ariaLabel: string;
  iconName: IconName;
  dataUrl?: string;
}

type onChangeHandler = (text: string) => void;

interface IProps {
  value: string;
  disabled?: boolean;
  isMenuHidden?: boolean;
  onChange: onChangeHandler;
  toolbarRef?: React.Ref<HTMLElement>;
}

const initRender = (editor: any, value: string) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(addEditorStyling(value), 'text/html');
  const nodes = $generateNodesFromDOM(editor, dom);
  $getRoot().select();
  $insertNodes(nodes);
};

const FORMAT_BUTTONS: FormatButton[] = [
  {
    key: 'bold',
    ariaLabel: 'Format Bold',
    iconName: 'bold',
  },
  {
    key: 'italic',
    ariaLabel: 'Format Italics',
    iconName: 'italic',
  },
  {
    key: 'underline',
    ariaLabel: 'Format Underline',
    iconName: 'underline',
  },
  {
    key: 'strikethrough',
    ariaLabel: 'Format Strikethrough',
    iconName: 'strikeThrough',
  },
  {
    key: 'orderedList',
    ariaLabel: 'Format Ordered List',
    iconName: 'orderedList',
  },
  {
    key: 'unorderedList',
    ariaLabel: 'Format Unordered List',
    iconName: 'unorderedList',
  },
  {
    key: 'insertLink',
    ariaLabel: 'Add/Remove Link',
    iconName: 'insertLink',
  },
];

function setsEqual<T>(set1: Set<T>, set2: Set<T>) {
  return set1.size === set2.size && [...set1].every((x) => set2.has(x));
}

export const ToolbarPlugin: FC<IProps> = ({ value, disabled, isMenuHidden = false, onChange, toolbarRef }) => {
  const [editor] = useLexicalComposerContext();
  const [enabledFormats, setEnabledFormats] = useState<Set<FormatType>>(new Set());

  const innerToolbarRef = useRef<HTMLDivElement>(null);
  const mergedToolbarRef = useMergeRefs([innerToolbarRef, toolbarRef]);

  const triggerUseFormEvent = useCallback(() => {
    if (innerToolbarRef.current) {
      innerToolbarRef.current.dispatchEvent(new Event(USE_FORM_TRIGGER_EVENT, { bubbles: true }));
    }
  }, [innerToolbarRef.current]);

  useEffect(() => {
    const isEditorDisabled = !editor.isEditable();
    if (isEditorDisabled !== disabled) {
      editor.setEditable(!disabled);
    }
  }, [disabled]);

  useEffect(() => {
    // trigger useForm event to validate form after value was changed and rich text attributes were updated
    setTimeout(() => triggerUseFormEvent(), 0);
  }, [value]);

  useEffect(() => {
    if (!value) {
      return;
    }

    editor.update(() => {
      initRender(editor, value);
    });
  }, []);

  const $updateEnabledToolbarButtons = useCallback(() => {
    const selection = $getSelection();

    if (!selection || !$isRangeSelection(selection)) return;

    const newEnabledFormats: Set<FormatType> = new Set();

    INLINE_FORMAT_TYPES.forEach((type) => {
      if (selection.hasFormat(type)) newEnabledFormats.add(type);
    });

    // Next, see if a matching node is touching the cursor.
    const anchorAndFocus = selection.getStartEndPoints();
    if (anchorAndFocus) {
      const nodeAtCursor = selection.getNodes()[0];

      if (nodeAtCursor) {
        [nodeAtCursor, ...nodeAtCursor.getParents()].forEach((node) => {
          if ($isListNode(node)) {
            switch (node.getListType()) {
              case 'number':
                newEnabledFormats.add('orderedList');
                break;
              case 'bullet':
                newEnabledFormats.add('unorderedList');
                break;
              default:
            }
          } else if ($isLinkNode(node)) {
            newEnabledFormats.add('insertLink');
          }
        });
      }
    }

    setEnabledFormats((oldEnabledFormats) =>
      setsEqual(newEnabledFormats, oldEnabledFormats) ? oldEnabledFormats : newEnabledFormats,
    );
  }, []);

  // TODO: This currently removes/readds the updateListener on each keypress when the value changes.
  // This is unlikely to be expensive, but it is clunky.
  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        $updateEnabledToolbarButtons();

        const htmlString = removeEditorStyling($generateHtmlFromNodes(editor, null));
        if (htmlString === value) {
          return;
        }

        onChange(htmlString);
      });
    });

    return () => removeUpdateListener();
  }, [onChange, value, editor]);

  useEffect(() => {
    const removeSelectionListener = editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        editor.getEditorState().read(() => {
          $updateEnabledToolbarButtons();
        });

        return false;
      },
      COMMAND_PRIORITY_LOW,
    );

    return () => removeSelectionListener();
  }, [editor]);

  const onOptionClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (disabled || !editor.isEditable()) {
      return;
    }

    const option = e.currentTarget.getAttribute('data-option') as FormatType;

    if (option === 'orderedList' || option === 'unorderedList') {
      if (enabledFormats.has(option)) {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      } else if (option === 'orderedList') {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      } else if (option === 'unorderedList') {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      }
      return;
    }

    if (option === 'insertLink') {
      editor.dispatchCommand(EDIT_LINK_COMMAND, undefined);

      return;
    }

    editor.dispatchCommand(FORMAT_TEXT_COMMAND, option as TextFormatType);
  };

  return (
    <div
      className={classNames({ [bem()]: true, hidden: isMenuHidden, 'disabled-state': disabled })}
      ref={mergedToolbarRef}
    >
      {!isMenuHidden && (
        <>
          {FORMAT_BUTTONS.map(({ key, ariaLabel, iconName }) => (
            <button
              key={key}
              onClick={onOptionClick}
              className={bem('button', { active: enabledFormats.has(key) })}
              aria-label={ariaLabel}
              data-option={key}
            >
              <Icon name={iconName} />
            </button>
          ))}
        </>
      )}
    </div>
  );
};
