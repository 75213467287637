import React, { useState, useRef, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { uniq, identity } from 'ramda';
import { bemPrefix } from 'src/utils';
import { SimplePopover, usePopoverProps } from '../popover';
import { DialogButtons } from '../dialog';
import { Icon } from '../icons';

import './feature-notification.scss';

const bem = bemPrefix('feature-notification');

enum Prefix {
  Insights = 'insights',
  Integrations = 'integrations',
  Interactions = 'interactions',
  Settings = 'settings',
}

// README: Use prefix enum of string. Diff cases of generating a labels and events
// See test: check event love and feedback payloads
export interface FeatureNotificationProps {
  featureLabel: string;
  mainContent: React.ReactNode;
  eventPrefix: Prefix | string;
  className?: string;
  portalDialogEl?: HTMLElement;
}

const isEnumPrefix = (prefix: FeatureNotificationProps['eventPrefix']): boolean => Object.values(Prefix).includes(prefix as any);

export const FeatureNotification: React.FC<FeatureNotificationProps> & { Prefix: typeof Prefix } = ({
  featureLabel,
  mainContent,
  className,
  eventPrefix = '',
  portalDialogEl,
}) => {
  const { isOpenPopover, closePopover, togglePopover } = usePopoverProps();
  const [isCommentForm, setIsCommentForm] = useState(false);
  const [tooltipComment, setTooltipComment] = useState('');
  const targetRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!isOpenPopover) {
      setIsCommentForm(false);
    }
  }, [isOpenPopover]);

  const onLoveIt = useCallback(() => {
    const label = featureLabel.toLowerCase().replace(/ /g, '_');
    const value = isEnumPrefix(eventPrefix)
      ? uniq([eventPrefix, label])
        .filter(identity)
        .join('_')
      : eventPrefix || label;
    ApptentiveSDK.engage(`${value}_feedback_love`);
    closePopover();
  }, []);

  const onSubmit = useCallback(() => {
    if (tooltipComment) {
      const feedbackPrefix = isEnumPrefix(eventPrefix) ? eventPrefix[0].toUpperCase() + eventPrefix.slice(1) : '';
      const title = feedbackPrefix ? `${feedbackPrefix} ${featureLabel}` : featureLabel;

      ApptentiveSDK.createMessage({
        body: `${title} Feedback:\n${tooltipComment}`,
        custom_data: {},
      });
    }
    closePopover();
  }, [tooltipComment]);

  const onComment = useCallback(() => setIsCommentForm(true), []);
  const onCancel = useCallback(() => closePopover(), []);

  const handleCommentText = (event: React.ChangeEvent<HTMLTextAreaElement>) => setTooltipComment(event.target.value);

  const handleToggle = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    togglePopover();
  }, []);

  const getSubmitHandler = () => (isCommentForm ? onSubmit : onLoveIt);
  const getCancelHandler = () => (isCommentForm ? onCancel : onComment);
  const getSubmitLabel = () => (isCommentForm ? 'SUBMIT' : 'LOVE IT!');
  const getCancelLabel = () => (isCommentForm ? 'CANCEL' : 'COMMENT');

  const getDialogNode = () => {
    const popoverNode = (
      <SimplePopover
        isOpen={isOpenPopover}
        targetEl={
          portalDialogEl && targetRef.current && !targetRef.current.offsetParent ? portalDialogEl : targetRef.current
        }
        onClose={closePopover}
        className={bem('tooltip')}
        placement={SimplePopover.PopoverPlacement.topStart}
        withArrow={false}
      >
        <div className={bem('tooltip-container')}>
          <div className={bem('tooltip__title')}>
            <Icon name="info" className={bem('tooltip__title-icon')} />
            <div className={bem('tooltip__title-info')}>
              <span className={bem('title-name')}>{featureLabel}</span>
              <span className={bem('title-feature')}>New Feature</span>
            </div>
          </div>
          <div className={bem('tooltip__body')}>
            {isCommentForm ? (
              <>
                <span>COMMENTS</span>
                <textarea className={bem('tooltip__body-input')} onChange={handleCommentText} maxLength={500} />
                <span>{`${500 - tooltipComment.length} characters remaining`}</span>
              </>
            ) : (
              mainContent
            )}
          </div>
          <DialogButtons
            className={bem('tooltip__buttons')}
            onSubmit={getSubmitHandler()}
            submitDisabled={isCommentForm ? !tooltipComment : false}
            onCancel={ApptentiveSDK ? getCancelHandler() : undefined}
            acceptLabel={ApptentiveSDK ? getSubmitLabel() : 'OK'}
            cancelLabel={getCancelLabel()}
          />
        </div>
      </SimplePopover>
    );

    return portalDialogEl ? ReactDOM.createPortal(popoverNode, portalDialogEl) : popoverNode;
  };

  return (
    <div className={classNames(bem(''), className)} onClick={(e) => e.stopPropagation()}>
      <span className={bem('icon')} onClick={handleToggle} ref={targetRef}>
        NEW
      </span>
      {isOpenPopover && getDialogNode()}
    </div>
  );
};

FeatureNotification.Prefix = Prefix;
