import React, { Component } from 'react';
import { resendInvitation } from '../../api';
import fullPath from '../../utils/full_path';
import '../../components/styles/molecules/alert_box.scss';

class ResendInvitation extends Component {
  state = {
    errors: [],
    submitting: false,
  };

  componentDidMount() {
    document.title = 'Welcome to Alchemer';
  }

  handleSubmit = event => {
    if (event) {
      event.preventDefault();
    }

    this.setState({ submitting: true, errors: [] });

    if (!this.form.elements.email.value) {
      this.setState({ errors: ['Email is required.', ...this.state.errors], submitting: false });
      return;
    }

    resendInvitation(this.form.elements.email.value).then(() => {
      window.location.assign(fullPath('/login'));
    }).catch(error => {
      const message = error && error.message ? error.message : error;
      this.setState({ errors: [message, ...this.state.errors], submitting: false });
    });
  };

  render() {
    return (
      <div className="invitation-edit auth onboarding-container">
        <div className="content-block-border content-block-welcome">
          <h1>Resend Invitation</h1>
        </div>
        <div className="content-block content-block-border">
          <div className="align-center">
            <form acceptCharset="UTF-8" className="invitation-edit align-left" name="resend-invite" onSubmit={this.handleSubmit} ref={(form) => this.form = form}>
              <fieldset>
                <div className="email">
                  <label htmlFor="email">Email</label>
                  <input type="email" name="email" className="email fs-hide" required size="30" aria-required />
                </div>
              </fieldset>
              <div className="submit-container align-center">
                {this.state.errors.length ? <div className="error-text">{this.state.errors.map(error => <p key={error}>{error}</p>)}</div> : null}
                <button className="alchemer large wide" type="submit" disabled={this.state.submitting}>Resend</button>
              </div>
            </form>
          </div>
        </div>
        <div className="content-block content-block-bottom align-center">
          <p className="terms-of-service">
            <span>By joining, you agree to Alchemer Mobile </span>
            <a href="https://www.apptentive.com/terms/">Terms & Conditions, </a>
            <a href="https://www.apptentive.com/privacy/">Privacy Policy, </a>
            <a href="https://www.apptentive.com/privacy/#cookies">Cookie Policy.</a>
          </p>
        </div>
      </div>
    );
  }
}

export default ResendInvitation;
