import React, { useMemo } from 'react';
import DatePicker from 'react-datepicker';

import { bemPrefix } from 'src/utils';
import { Time } from 'src/utils/time';
import { InputText } from './input-text';

import './input-date.scss';

export interface InputDatePickerProps {
  className?: string;
  open?: boolean;
  disabled?: boolean;
  selected: Date | undefined;
  startDate?: Date;
  endDate?: Date;
  maxDate?: Date;
  minDate?: Date;
  showMonthDropdown?: boolean;
  showYearDropdown?: boolean;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  onChange(date: Date): void;
  onInputClick?(...args: any): void;
  onClickOutside?(): void;
  inputFormat?: keyof typeof Time.FORMAT;
}

const bem = bemPrefix('input-date-picker');

export const InputDatePicker: React.FC<InputDatePickerProps> = ({
  className = '',
  open,
  disabled,
  selected,
  inputFormat = Time.FORMAT.ll,
  startDate,
  endDate,
  maxDate,
  minDate,
  selectsStart,
  selectsEnd,
  onChange,
  onInputClick,
  onClickOutside,
}) => {
  const inputValue = useMemo(() => {
    if (inputFormat && selected) {
      return Time.format(selected, inputFormat);
    }
    return undefined;
  }, [selected, inputFormat]);

  return (
    <DatePicker
      className={`${bem()} ${className}`}
      popperClassName={`${bem('', { showMonthYearDropdown: true })} ${className}`}
      calendarClassName={`${bem()} ${className}`}
      selected={selected}
      selectsStart={selectsStart}
      selectsEnd={selectsEnd}
      open={open}
      disabled={disabled}
      value={inputValue}
      onChange={onChange}
      onInputClick={onInputClick}
      nextMonthButtonLabel=""
      previousMonthButtonLabel=""
      onClickOutside={onClickOutside}
      startDate={startDate}
      endDate={endDate}
      maxDate={maxDate}
      minDate={minDate}
      required
      showMonthDropdown
      showYearDropdown
      disabledKeyboardNavigation
      customInput={<InputText />}
    />
  );
};

InputDatePicker.displayName = 'InputDatePicker';
