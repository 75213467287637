import React, { PropsWithChildren, useRef, useState } from 'react';
import { Icon, SimplePopover } from 'src/components/molecules';
import { Platform, SdkVersion } from 'src/types/core';
import { bemPrefix } from 'src/utils';
import { capitalize } from 'src/utils/titleize';

import './limited-audience.scss';

export enum Feature {
  SKIP_LOGIC = 'Skip Logic',
  TERMS_AND_CONDITIONS = 'Terms and Conditions',
  IMAGES = 'Images',
  RICH_TEXT = 'Rich Text',
}

export enum FeatureContext {
  PROMPTS = 'Prompts',
  SURVEYS = 'Surveys',
}

export interface LimitedAudienceProps {
  platform: Platform;
  feature: Feature;
  context: FeatureContext;
  isFeatureUsed: boolean;
  reachNum?: number;
  messageClassName?: string;
  className?: string;
}

export const SDK_VERSION_BY_FEATURE: Record<Feature, SdkVersion> = {
  [Feature.SKIP_LOGIC]: SdkVersion.SupportsSkipLogic,
  [Feature.TERMS_AND_CONDITIONS]: SdkVersion.SupportsTermsAndConditions,
  [Feature.IMAGES]: SdkVersion.SupportsRichContent,
  [Feature.RICH_TEXT]: SdkVersion.SupportsRichText,
};

export const FEATURE_NAME_BY_FEATURE: Record<Feature, string> = {
  [Feature.SKIP_LOGIC]: 'skip logic',
  [Feature.TERMS_AND_CONDITIONS]: 'terms and conditions or disclaimer text',
  [Feature.IMAGES]: 'images',
  [Feature.RICH_TEXT]: 'rich text',
};

const CONTEXT_NAME_BY_FEATURE_CONTEXT: Record<FeatureContext, string> = {
  [FeatureContext.PROMPTS]: 'prompts',
  [FeatureContext.SURVEYS]: 'surveys',
};

const lbem = bemPrefix('limited-audience');

export const LimitedAudience: React.FC<PropsWithChildren<LimitedAudienceProps>> = ({
  platform,
  feature,
  context,
  isFeatureUsed,
  reachNum = 0,
  messageClassName = '',
  className = '',
  children,
}) => {
  const iconRef = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (platform === Platform.Web) {
    return <>{children}</>;
  }

  const featureName = FEATURE_NAME_BY_FEATURE[feature];
  const sdkVersion = SDK_VERSION_BY_FEATURE[feature];
  const contextName = CONTEXT_NAME_BY_FEATURE_CONTEXT[context];

  return (
    <div className={`${lbem('container', { 'feature-used': isFeatureUsed })} ${className}`}>
      {children}
      {isFeatureUsed && (
        <>
          <div
            className={`${lbem('message', { 'no-reach': reachNum === 0 })} ${messageClassName}`}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
          >
            <span>LIMITED AUDIENCE</span>
            <Icon forwardRef={iconRef} name="faCircleQuestion" />
          </div>

          <SimplePopover
            className={lbem('disabled-popover')}
            isOpen={isTooltipOpen}
            targetEl={iconRef.current}
            withArrow
            placement={SimplePopover.PopoverPlacement.top}
          >
            <div>
              {capitalize(contextName)} with {featureName} will only show to customers on an app version with Alchemer
              Digital SDK {sdkVersion} or greater, currently {reachNum}% of your audience
            </div>
          </SimplePopover>
        </>
      )}
    </div>
  );
};

export interface NoAudienceProps {
  feature: Feature;
  context: FeatureContext;
  className?: string;
  targetRef: React.RefObject<HTMLElement>;
}

const nbem = bemPrefix('no-audience');

export const NoAudience: React.FC<PropsWithChildren<NoAudienceProps>> = ({
  feature,
  context,
  className = '',
  targetRef,
  children,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const featureName = FEATURE_NAME_BY_FEATURE[feature];
  const sdkVersion = SDK_VERSION_BY_FEATURE[feature];
  const contextName = CONTEXT_NAME_BY_FEATURE_CONTEXT[context];
  const requiresText = {
    [Feature.SKIP_LOGIC]: 'requires',
    [Feature.TERMS_AND_CONDITIONS]: 'require',
    [Feature.IMAGES]: 'require',
    [Feature.RICH_TEXT]: 'requires',
  }[feature];

  return (
    <div
      className={`${nbem('container')} ${className}`}
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      {children}
      <SimplePopover
        className={nbem('disabled-popover')}
        isOpen={isTooltipOpen}
        targetEl={targetRef.current}
        withArrow
        placement={SimplePopover.PopoverPlacement.top}
      >
        <div>
          {capitalize(featureName)} in {contextName} {requiresText} Alchemer Digital SDK {sdkVersion} or greater, which
          is not used in any app version currently installed by your customers.
        </div>
      </SimplePopover>
    </div>
  );
};
