import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { GetState } from 'src/reducers';
import { CriteriaTargeting } from 'src/types/core';

export const FETCH_TARGETING_PENDING = 'FETCH_TARGETING_PENDING';
export const FETCH_TARGETING_SUCCESS = 'FETCH_TARGETING_SUCCESS';
export const FETCH_TARGETING_FAILURE = 'FETCH_TARGETING_FAILURE';

export const fetchTargetingPending = (appId: string) => ({
  type: FETCH_TARGETING_PENDING,
  payload: { appId },
  meta: { appId },
});
export const fetchTargetingSuccess = (appId: string, json: CriteriaTargeting) => ({
  type: FETCH_TARGETING_SUCCESS,
  payload: json,
  meta: { appId },
});
export const fetchTargetingFailure = (appId: string, error: Error) => ({
  type: FETCH_TARGETING_FAILURE,
  payload: error,
  error: true,
  meta: { appId },
});

export interface TargetingApi {
  fetchTargeting: (appId: string) => Promise<CriteriaTargeting>;
}

export interface TargetingThunk {
  api: TargetingApi;
}

export const fetchTargeting = (appId: string) => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  _getState: GetState,
  { api }: TargetingThunk
): Promise<any> => {
  dispatch(fetchTargetingPending(appId));
  return api
    .fetchTargeting(appId)
    .then((json: CriteriaTargeting) => dispatch(fetchTargetingSuccess(appId, json)))
    .catch((error: Error) => dispatch(fetchTargetingFailure(appId, error)));
};
