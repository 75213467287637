import { useLayoutEffect } from 'react';
import { FeatureFlag, featureSettings, useFeatureSettings } from 'src/utils';

function setPageTitle({
  subTitle,
  oldTitle,
  hasContextualPageTitles,
}: {
  subTitle: string;
  oldTitle?: string;
  hasContextualPageTitles: boolean;
}): void {
  if (hasContextualPageTitles) {
    document.title = `${subTitle} - Alchemer Digital`;
  } else {
    document.title = oldTitle || 'Alchemer';
  }
}

/**
 * Directly set the page title (useful for legacy class components).
 *
 * @param [string] subTitle The leading part of the page title.
 * @param [object] options
 * @param [object.oldTitle] The title to set if the CONTEXTUAL_PAGE_TITLES feature isn't enabled.
 */
export function setPageTitleOnMount(subTitle: string, { oldTitle }: { oldTitle?: string } = {}): void {
  setPageTitle({
    subTitle,
    oldTitle,
    hasContextualPageTitles: featureSettings.get(FeatureFlag.CONTEXTUAL_PAGE_TITLES),
  });
}

/**
 * Hook to set the page title.
 *
 * @param [string] subTitle The leading part of the page title.
 * @param [object] options
 * @param [object.oldTitle] The title to set if the CONTEXTUAL_PAGE_TITLES feature isn't enabled.
 */
export function usePageTitle(subTitle: string, { oldTitle }: { oldTitle?: string } = {}): void {
  const { flag, hasFeature } = useFeatureSettings();

  const hasContextualPageTitles = hasFeature(flag.CONTEXTUAL_PAGE_TITLES);

  useLayoutEffect(() => {
    setPageTitle({
      subTitle,
      oldTitle,
      hasContextualPageTitles,
    });
  }, [subTitle, oldTitle, hasContextualPageTitles]);
}
