import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import { State } from 'src/reducers';

import { insights, InsightsBaseParams } from 'src/actions/insights/insights.actions';
import { tags } from 'src/actions/tags';
import { fetchApp } from 'src/actions/apps';
import { updateDashboardDateRange } from 'src/actions/index';

import { getCurrentSearch, getDataPagination } from 'src/selectors/insights.selectors';
import { getDashboardDateRange } from 'src/selectors/dashboard';
import { getCurrentStoreAppId, getCurrentAppId } from 'src/selectors/current_app';
import { getFeatures } from 'src/selectors/user';

import { InsightsContextProvider, InsightsContextProviderProps } from './insights-context';
import { InsightsPageParams } from './insights-context.types';

type Handlers =
  | 'fetchApp'
  | 'onApplyFilters'
  | 'onInitInsights'
  | 'onTagUpdated'
  | 'onCleanupInsights'
  | 'onUpdateDateRange';

type StateToProps = Omit<InsightsContextProviderProps, Handlers | keyof RouteComponentProps>;
type DispatchToProps = Pick<InsightsContextProviderProps, Handlers>;

export const InsightsContextProviderContainer = withRouter(
  connect(
    (state: State): StateToProps => ({
      ...getDashboardDateRange(state),
      appId: getCurrentAppId(state),
      storeId: getCurrentStoreAppId(state),
      features: getFeatures(state),
      dataPagination: getDataPagination(state),
      lastSearch: getCurrentSearch(state),
    }),
    (dispatch: any): DispatchToProps => ({
      fetchApp: (appId: string) => {
        dispatch(fetchApp(appId));
      },
      onInitInsights: () => {
        dispatch(insights.init());
        dispatch(tags.fetchAllTags());
        dispatch(tags.fetchAppTags());
        dispatch(tags.fetchMostUsedTags());
      },
      onCleanupInsights: () => {
        dispatch(insights.cleanup());
      },
      onApplyFilters: (params: InsightsPageParams, resetPagination: boolean, pristinePagination: boolean) => {
        if (pristinePagination || resetPagination) {
          dispatch(insights.fetchAggs(params));
          dispatch(insights.fetchSentimentAgg(params)); // separate action to fetch data for full range
          dispatch(insights.fetchTypesAgg(params)); // separate action to fetch counts for all types
          dispatch(insights.fetchFanSignalsAgg(params));
          dispatch(insights.fetchRegionsAgg(params));
          dispatch(insights.fetchPhrases(params));
          dispatch(insights.fetchSurveyNames(params));
          dispatch(tags.fetchAggregationTags(params));
        }
        dispatch(insights.fetchData(params, resetPagination));
      },
      onUpdateDateRange: (start, end, name) => {
        dispatch(updateDashboardDateRange(start, end, name));
      },
      onTagUpdated: (params: InsightsBaseParams) => {
        dispatch(tags.fetchAggregationTags(params));
      },
    })
  )(InsightsContextProvider)
);
