import React from 'react';
import PropTypes from 'prop-types';
import { DialogButtons } from './dialog';

import '../styles/molecules/xls_download_picker.scss';

export const DownloadOption = ({ className = '', type = 'checkbox', id, children, checked, onChange, name, disabled }) => (
  <div className={className}>
    <input id={id} name={name} type={type} checked={checked} onChange={onChange} disabled={disabled} />
    <label className="download-option" htmlFor={id}>{children}</label>
  </div>
);

DownloadOption.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
};

export const DownloadSelector = ({ className = '', children, onClose, submitHandler, disabled }) => {
  const submit = (e) => {
    if (e) {
      e.preventDefault();
    }
    submitHandler(e);
    onClose();
  };

  return (
    <div className={`xls-download-wrapper ${className}`}>
      <div className="xls-download-picker">
        <h3>Select Exports</h3>
        <p>Exports starting in or occurring in the selected time window.</p>
        <form onSubmit={submit}>
          <div className="download-options">
            {children}
          </div>
          <DialogButtons
            onSubmit={submit}
            onCancel={onClose}
            submitDisabled={disabled}
          />
        </form>
      </div>
    </div>
  );
};

DownloadSelector.propTypes = {
  className: PropTypes.string,
  submitHandler: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

DownloadSelector.defaultProps = {
  onClose: () => undefined,
};

export default DownloadSelector;

DownloadSelector.displayName = 'DownloadSelector';
