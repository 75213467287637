/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-no-bind */

import React from 'react';
import { isEmpty } from 'ramda';
import { bemPrefix } from 'src/utils';
import { Icon, InputLabel } from 'src/components/molecules';
import { AppEventList, SimpleEventItem } from 'src/reducers/events';
import { InteractionType } from 'src/types/core';
import { OrganizationApp } from 'src/reducers/organizations';

import { SearchableSelect, SearchableSelectProps, SearchableSelectOption } from '../select';
import { getSelectedItems } from './searchable-select-list-utils';
import { AppInfo } from '../imt-app-info';

import './searchable-select-list.scss';

const bem = bemPrefix('searchable-select-list');

const getDefaultOptionLabel = (item: SearchableSelectOption) => item.label || item.title || item.name || item.id;

const noop = () => null;

export interface SearchableSelectListProps
  extends Pick<SearchableSelectProps, 'options' | 'disabled' | 'placeholder' | 'getOptionLabel'> {
  className?: string;
  selectLabel?: SearchableSelectProps['label'] | [string, string];
  selectedListLabel?: string | [string, string];
  selected?: SearchableSelectOption['id'][];
  hideSelect?: boolean;
  required?: boolean;
  multipleTagretSelect?: boolean;
  apps?: OrganizationApp[];
  multipleEvents?: AppEventList[];
  eventsTitleList?: SimpleEventItem[];
  isMultiAppInteraction?: boolean;
  type: InteractionType;
  getSelectedOptionLabel?(item: SearchableSelectOption, isMultiAppInteraction: boolean): React.ReactNode;
  onChangeSelected(ids: SearchableSelectOption['id'][]): void;
  onChangeImtEvents?: (appIds: string[], eventLabel: string) => void;
}

interface SearchableSelectListState {
  selectedIds: string[];
}

export class SearchableSelectList extends React.PureComponent<SearchableSelectListProps, SearchableSelectListState> {
  constructor(props: SearchableSelectListProps) {
    super(props);

    this.state = {
      selectedIds: props.selected || [],
    };
  }

  onSelectOption = (option: SearchableSelectOption) => {
    this.setState({ selectedIds: [...this.state.selectedIds, option.id] }, () => {
      this.props.onChangeSelected(this.state.selectedIds);
    });
  };

  getAppIdsByEventLabel = (eventLabel: string) => {
    const { options, apps = [], multipleEvents = [], eventsTitleList = [], isMultiAppInteraction = false } = this.props;

    const allSelectedItems = getSelectedItems(
      [...this.state.selectedIds, eventLabel],
      isMultiAppInteraction,
      eventsTitleList,
      options,
      multipleEvents,
      apps,
    );

    const eventSelectedItem = allSelectedItems.find((item) => item.id === eventLabel);
    return eventSelectedItem?.apps?.map((app) => app.id) || [];
  };

  onMultipleSelectOption = (multipleEvent: SimpleEventItem) => {
    const selectedAppIds = this.getAppIdsByEventLabel(multipleEvent.label);
    this.setState({ selectedIds: [...this.state.selectedIds, multipleEvent.label] }, () => {
      this.props.onChangeImtEvents?.(selectedAppIds, multipleEvent.label);
    });
  };

  onRemoveOption = (optionId: string) => {
    const selectedAppIds = this.getAppIdsByEventLabel(optionId);
    const selected = this.state.selectedIds.filter((id) => id !== optionId);
    this.setState({ selectedIds: selected }, () => {
      this.props.isMultiAppInteraction
        ? this.props.onChangeImtEvents?.(selectedAppIds, optionId)
        : this.props.onChangeSelected(this.state.selectedIds);
    });
  };

  getElementLabels = () => {
    const getLabel = (labels: string | [string, string] = '', selected: any[]): string => {
      if (Array.isArray(labels)) {
        return isEmpty(selected) ? labels[0] : labels[1];
      }
      return labels;
    };

    return {
      selectLabel: getLabel(this.props.selectLabel, this.state.selectedIds),
      selectedListLabel: getLabel(this.props.selectedListLabel, this.state.selectedIds),
    };
  };

  isOptionDisabled = (option: SearchableSelectOption) =>
    this.state.selectedIds.includes(option.id) || !!option.isDisabled;
  isMultipleOptionDisabled = (multipleEvent: SimpleEventItem) => this.state.selectedIds.includes(multipleEvent.label);

  render() {
    const { selectedIds } = this.state;

    const {
      className = '',
      options,
      hideSelect,
      placeholder,
      disabled,
      multipleTagretSelect = false,
      apps = [],
      multipleEvents = [],
      eventsTitleList = [],
      isMultiAppInteraction = false,
      getOptionLabel = getDefaultOptionLabel,
    } = this.props;

    const { selectLabel, selectedListLabel } = this.getElementLabels();

    return (
      <div className={`${bem('', { 'select-visible': !hideSelect })} ${className}`}>
        {!isEmpty(selectedIds) && (
          <>
            {selectedListLabel && <InputLabel label={selectedListLabel} />}
            <SelectedList
              className={bem('selected-items')}
              items={getSelectedItems(
                selectedIds,
                isMultiAppInteraction,
                eventsTitleList,
                options,
                multipleEvents,
                apps,
              )}
              isMultiAppInteraction={isMultiAppInteraction}
              getOptionLabel={this.props.getSelectedOptionLabel || getOptionLabel}
              onRemove={disabled ? undefined : this.onRemoveOption}
            />
          </>
        )}
        {!hideSelect && (
          <SearchableSelect
            label={selectLabel}
            placeholder={placeholder}
            options={options}
            disabled={disabled}
            onSelect={this.onSelectOption}
            onMultipleSelect={this.onMultipleSelectOption}
            isOptionDisabled={this.isOptionDisabled}
            isMultipleOptionDisabled={this.isMultipleOptionDisabled}
            multipleTagretSelect={multipleTagretSelect}
            apps={apps}
            multipleEvents={multipleEvents}
            eventsTitleList={eventsTitleList}
            getOptionLabel={(option) => (
              <div className={bem('item-label', { selected: selectedIds.includes(option.id) })}>
                {getOptionLabel(option)}
              </div>
            )}
            type={this.props.type}
          />
        )}
        {this.props.required && <RequiredSelection items={this.state.selectedIds} />}
      </div>
    );
  }
}

const SelectedList: React.FC<{
  className: string;
  items: SearchableSelectOption[];
  isMultiAppInteraction?: boolean;
  getOptionLabel?(item: SearchableSelectOption, isMultiAppInteraction?: boolean): React.ReactNode;
  onRemove?(id: string): void;
}> = ({ className, items, isMultiAppInteraction, getOptionLabel, onRemove }) => {
  return (
    <div className={className}>
      {items.map((item) => (
        <div key={item.id} className={bem('selected-item')}>
          <span className="left">
            {getOptionLabel ? getOptionLabel(item, isMultiAppInteraction) : getDefaultOptionLabel(item)}
          </span>
          <div className="event-apps-container">
            <div className="event-apps">
              {item.apps?.map((app) => <AppInfo key={`${item.id}-${app.id}`} app={app} />)}
            </div>
            {onRemove && (
              <span className="right">
                <Icon name="remove" onClick={() => onRemove(item.id)} />
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

/**
 * Useful within a form
 */
const RequiredSelection: React.FC<{ items: string[] }> = ({ items }) => (
  <select style={{ display: 'none' }} value={items} onChange={noop} multiple required>
    {items.map((item) => (
      <option key={item} value={item} />
    ))}
  </select>
);
