import { daysAgo } from 'src/utils/time';
import { getCurrentAppId } from 'src/selectors/current_app';
import { checkForErrors } from './utils/check_for_errors.js';

export const REQUEST_DAU = 'REQUEST_DAU';
export const requestDAU = (appId) => ({
  type: REQUEST_DAU,
  payload: { appId },
  meta: { appId },
});

export const RECEIVE_DAU = 'RECEIVE_DAU';
export const receiveDAU = (appId, json) => {
  const data = json.data || [];
  return {
    type: RECEIVE_DAU,
    meta: { appId },
    payload: data.map((series) => ({
      ...series,
      datums: series.datums.map((datum) => ({
        date: new Date(datum.date),
        y: parseInt(datum.y, 10),
      })),
    })),
  };
};

export const ERROR_FETCHING_DAU = 'ERROR_FETCHING_DAU';
export const errorFetchingDAU = (appId, error) => ({
  type: ERROR_FETCHING_DAU,
  payload: error,
  error: true,
  meta: { appId },
});

// Daily Active Users Thunk action creator
export const fetchDAU =
  (start = daysAgo(30), end = new Date()) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    const appId = getCurrentAppId(state);
    // informs state that call is being made
    dispatch(requestDAU(appId));
    return api
      .fetchDAU(appId, start, end)
      .then(checkForErrors)
      .then((json) => dispatch(receiveDAU(appId, json)))
      .catch((error) => dispatch(errorFetchingDAU(appId, error)));
  };

export const REQUEST_NOTICES = 'REQUEST_NOTICES';
export const requestNotices = (userId) => ({
  type: REQUEST_NOTICES,
  payload: { userId },
  meta: { userId },
});

export const RECEIVED_NOTICES = 'RECEIVED_NOTICES';
export const receivedNotices = (userId, json) => ({
  type: RECEIVED_NOTICES,
  payload: json,
  meta: { userId },
});

export const ERROR_FETCHING_NOTICES = 'ERROR_FETCHING_NOTICES';
export const errorFetchingNotices = (userId, error) => ({
  type: ERROR_FETCHING_NOTICES,
  payload: error,
  error: true,
  meta: { userId },
});

export const fetchNotices =
  (userId) =>
  (dispatch, getState, { api }) => {
    dispatch(requestNotices(userId));
    return api
      .fetchNotices(userId)
      .then(checkForErrors)
      .then((json) => dispatch(receivedNotices(userId, json)))
      .catch((ex) => dispatch(errorFetchingNotices(userId, ex)));
  };

// Update Dashboard Date Range (Pupum)
export const UPDATE_DASHBOARD_DATE_RANGE = 'UPDATE_DASHBOARD_DATE_RANGE';
/**
 * @param {*} start number
 * @param {*} end number
 * @param {*} name DateLastPeriod | 'custom'
 */
export const updateDashboardDateRange = (start, end, name) => ({
  type: UPDATE_DASHBOARD_DATE_RANGE,
  payload: { start, end, name },
  meta: { start, end, name },
});

export const REQUEST_FAN_SIGNALS = 'REQUEST_FAN_SIGNALS';
const requestFanSignals = (appId) => ({
  type: REQUEST_FAN_SIGNALS,
  payload: { appId },
  meta: { appId },
});

export const REQUEST_FAN_SIGNALS_SUCCESS = 'REQUEST_FAN_SIGNALS_SUCCESS';
const requestFanSignalsSuccess = (appId) => ({
  type: REQUEST_FAN_SIGNALS_SUCCESS,
  payload: { success: true },
  meta: { appId },
});

export const REQUEST_FAN_SIGNALS_ERROR = 'REQUEST_FAN_SIGNALS_ERROR';
const requestFanSignalsError = (appId, error) => ({
  type: REQUEST_FAN_SIGNALS_ERROR,
  payload: { error },
  error: true,
  meta: { appId },
});

// Fan signals are handled in the Notification drawer, which manages its own state via websocket.
export const fetchFanSignals =
  (appId) =>
  (dispatch, _getState, { api }) => {
    dispatch(requestFanSignals(appId));
    return api
      .fetchFanSignals(appId)
      .then((_response) => dispatch(requestFanSignalsSuccess(appId)))
      .catch((er) => dispatch(requestFanSignalsError(appId, er)));
  };

// Interaction Counts (Event Stats Service)
export const REQUEST_INTERACTION_COUNT = 'REQUEST_INTERACTION_COUNT';
const requestInteractionCounts = (appId, start, end) => ({
  type: REQUEST_INTERACTION_COUNT,
  payload: { start, end },
  meta: { appId },
});

export const RECEIVED_INTERACTION_COUNT = 'RECEIVED_INTERACTION_COUNT';
const receivedInteractionCounts = (appId, start, end, json) => ({
  type: RECEIVED_INTERACTION_COUNT,
  payload: json,
  meta: { appId, start, end },
});

export const ERROR_FETCHING_INTERACTION_COUNT = 'ERROR_FETCHING_INTERACTION_COUNT';
const errorInteractionCounts = (appId, start, end, error) => ({
  type: ERROR_FETCHING_INTERACTION_COUNT,
  payload: error,
  error: true,
  meta: { appId, start, end },
});

export const fetchInteractionCounts =
  (start = daysAgo(30), end = new Date()) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    const appId = getCurrentAppId(state);
    dispatch(requestInteractionCounts(appId, start, end));
    return api
      .fetchInteractionCounts(appId, start, end)
      .then(checkForErrors)
      .then((json) => dispatch(receivedInteractionCounts(appId, start, end, json)))
      .catch((ex) => dispatch(errorInteractionCounts(appId, start, end, ex)));
  };

export const REQUEST_CURRENT_USER_FEATURES = 'REQUEST_CURRENT_USER_FEATURES';
export const RECEIVED_CURRENT_USER_FEATURES = 'RECEIVED_CURRENT_USER_FEATURES';
export const ERROR_CURRENT_USER_FEATURES = 'ERROR_CURRENT_USER_FEATURES';
export const receivedCurrentUserFeatures = (data, appId, userId) => ({
  type: RECEIVED_CURRENT_USER_FEATURES,
  payload: data,
  meta: { appId, userId },
});

export const fetchCurrentUserFeatures =
  (userId) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    const appId = getCurrentAppId(state);
    dispatch({ type: REQUEST_CURRENT_USER_FEATURES, payload: { userId }, meta: { appId } });
    return api
      .fetchCurrentUserFeatures(appId, userId)
      .then(checkForErrors)
      .then((json) => dispatch(receivedCurrentUserFeatures(json, appId, userId)))
      .catch((error) => dispatch({ type: ERROR_CURRENT_USER_FEATURES, payload: error, error: true, meta: { userId } }));
  };

export const REQUEST_SURVEY_COUNT = 'REQUEST_SURVEY_COUNT';
export const RECEIVED_SURVEY_COUNT = 'RECEIVED_SURVEY_COUNT';
export const ERROR_FETCHING_SURVEY_COUNT = 'ERROR_FETCHING_SURVEY_COUNT';
export const requestSurveyCounts = (appId, start, end) => ({
  type: REQUEST_SURVEY_COUNT,
  payload: { appId, start, end },
  meta: { appId, start, end },
});
export const receivedSurveyCounts = (appId, start, end, json) => ({
  type: RECEIVED_SURVEY_COUNT,
  payload: { data: json.data },
  meta: { appId, start, end },
});
export const errorSurveyCounts = (appId, start, end, error) => ({
  type: ERROR_FETCHING_SURVEY_COUNT,
  payload: error,
  error: true,
  meta: { appId, start, end },
});
export const fetchSurveyCounts =
  (start = daysAgo(30), end = new Date()) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    const appId = getCurrentAppId(state);
    dispatch(requestSurveyCounts(appId, start, end));
    return api
      .fetchSurveyCounts(appId, start, end)
      .then(checkForErrors)
      .then((json) => dispatch(receivedSurveyCounts(appId, start, end, json)))
      .catch((ex) => dispatch(errorSurveyCounts(appId, start, end, ex)));
  };

export const REQUEST_SURVEY_AGGREGATES = 'REQUEST_SURVEY_AGGREGATES';
export const RECEIVE_SURVEY_AGGREGATES = 'RECEIVE_SURVEY_AGGREGATES';
export const ERROR_FETCHING_SURVEY_AGGREGATES = 'ERROR_FETCHING_SURVEY_AGGREGATES';
export const requestSurveyAggregates = (appId, start, end) => ({
  type: REQUEST_SURVEY_AGGREGATES,
  payload: { appId, start, end },
  meta: { appId, start, end },
});
const receivedSurveyAggregates = (appId, start, end, json) => ({
  type: RECEIVE_SURVEY_AGGREGATES,
  payload: { data: json.data },
  meta: { appId, start, end },
});
export const errorSurveyAggregates = (appId, start, end, error) => ({
  type: ERROR_FETCHING_SURVEY_AGGREGATES,
  payload: error,
  error: true,
  meta: { appId, start, end },
});
export const fetchSurveyAggregates =
  (start = daysAgo(30), end = new Date()) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    const appId = getCurrentAppId(state);
    dispatch(requestSurveyAggregates(appId, start, end));
    return api
      .fetchSurveyAggregates(appId, start, end)
      .then(checkForErrors)
      .then((json) => dispatch(receivedSurveyAggregates(appId, start, end, json)))
      .catch((ex) => dispatch(errorSurveyAggregates(appId, start, end, ex)));
  };

// TODO: DRY up these action creators, they all look the same
export const REQUEST_NOTES_AGGREGATES = 'REQUEST_NOTES_AGGREGATES';
export const RECEIVE_NOTES_AGGREGATES = 'RECEIVE_NOTES_AGGREGATES';
export const ERROR_FETCHING_NOTES_AGGREGATES = 'ERROR_FETCHING_NOTES_AGGREGATES';
export const requestNotesAggregates = (appId, start, end) => ({
  type: REQUEST_NOTES_AGGREGATES,
  payload: { appId, start, end },
  meta: { appId, start, end },
});
const receivedNotesAggregates = (appId, start, end, json) => ({
  type: RECEIVE_NOTES_AGGREGATES,
  payload: { data: json.data },
  meta: { appId, start, end },
});
export const errorNotesAggregates = (appId, start, end, error) => ({
  type: ERROR_FETCHING_SURVEY_AGGREGATES,
  payload: error,
  error: true,
  meta: { appId, start, end },
});
export const fetchNotesAggregates =
  (start = daysAgo(30), end = new Date()) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    const appId = getCurrentAppId(state);
    dispatch(requestNotesAggregates(appId, start, end));
    return api
      .fetchNotesAggregates(appId, start, end)
      .then(checkForErrors)
      .then((json) => dispatch(receivedNotesAggregates(appId, start, end, json)))
      .catch((ex) => dispatch(errorNotesAggregates(appId, start, end, ex)));
  };

export const REQUEST_NOTES_COUNT = 'REQUEST_NOTES_COUNT';
export const RECEIVE_NOTES_COUNT = 'RECEIVE_NOTES_COUNT';
export const ERROR_FETCHING_NOTES_COUNT = 'ERROR_FETCHING_NOTES_COUNT';
export const requestNotesCounts = (appId, start, end) => ({
  type: REQUEST_NOTES_COUNT,
  payload: { appId, start, end },
  meta: { appId, start, end },
});
const receivedNotesCounts = (appId, start, end, json) => ({
  type: RECEIVE_NOTES_COUNT,
  payload: { data: json.data },
  meta: { appId, start, end },
});
export const errorNotesCounts = (appId, start, end, error) => ({
  type: ERROR_FETCHING_NOTES_COUNT,
  payload: error,
  meta: { appId, start, end },
});
export const fetchNotesCounts =
  (start = daysAgo(30), end = new Date()) =>
  (dispatch, getState, { api }) => {
    const state = getState();
    const appId = getCurrentAppId(state);
    dispatch(requestNotesCounts(appId, start, end));
    return api
      .fetchNotesCounts(appId, start, end)
      .then(checkForErrors)
      .then((json) => dispatch(receivedNotesCounts(appId, start, end, json)))
      .catch((ex) => dispatch(errorNotesCounts(appId, start, end, ex)));
  };

export const CREATE_REPORT_JOB = 'CREATE_REPORT_JOB';
export const ERROR_CREATING_REPORT_JOB = 'ERROR_CREATING_REPORT_JOB';
export const createReportJob =
  (appId, reporterClass, targetClass, targetId, query, fmt) =>
  (dispatch, getState, { api }) => {
    dispatch({ type: CREATE_REPORT_JOB, payload: { appId, reporterClass, targetClass, targetId } });
    return api
      .createReportJob(appId, reporterClass, targetClass, targetId, query, fmt)
      .then(checkForErrors)
      .catch((error) =>
        dispatch({
          type: ERROR_CREATING_REPORT_JOB,
          payload: error,
          error: true,
          meta: { appId, reporterClass, targetClass, targetId },
        }),
      );
  };

// Reach
export const REQUEST_REACH = 'REQUEST_REACH';
export const requestReach = (appId) => ({
  type: REQUEST_REACH,
  payload: appId,
  meta: { appId },
});
export const RECEIVED_REACH = 'RECEIVED_REACH';
export const receivedReach = (appId, data) => ({
  type: RECEIVED_REACH,
  payload: data,
  meta: { appId },
});
export const ERROR_FETCHING_REACH = 'ERROR_FETCHING_REACH';
export const errorFetchingReach = (appId, error) => ({
  type: ERROR_FETCHING_REACH,
  payload: error,
  error: true,
  meta: { appId },
});
export const fetchReach =
  (appId) =>
  (dispatch, getState, { api }) => {
    dispatch(requestReach(appId));
    return api
      .fetchReach(appId)
      .then((json) => dispatch(receivedReach(appId, json)))
      .catch((ex) => dispatch(errorFetchingReach(appId, ex)));
  };

// Date Annotations
export const REQUEST_DATE_ANNOTATIONS = 'REQUEST_DATE_ANNOTATIONS';
export const requestDateAnnotations = (appId) => ({
  type: REQUEST_DATE_ANNOTATIONS,
  payload: appId,
  meta: { appId },
});
export const RECEIVED_DATE_ANNOTATIONS = 'RECEIVED_DATE_ANNOTATIONS';

// eslint-disable-next-line
export const receivedDateAnnotations = (appId, data) => {
  return {
    type: RECEIVED_DATE_ANNOTATIONS,
    payload: data,
    meta: { appId },
  };
};

export const ERROR_FETCHING_DATE_ANNOTATION = 'ERROR_FETCHING_DATE_ANNOTATION';
export const errorFetchingDateAnnotations = (appId, error) => ({
  type: ERROR_FETCHING_REACH,
  payload: error,
  error: true,
  meta: { appId },
});

export const fetchDateAnnotations =
  (appId) =>
  (dispatch, getState, { api }) => {
    dispatch(requestDateAnnotations(appId));
    return api
      .fetchDateAnnotations(appId)
      .then((json) => dispatch(receivedDateAnnotations(appId, json)))
      .catch((ex) => dispatch(errorFetchingDateAnnotations(appId, ex)));
  };

// Create an App
export const CREATE_APP_PENDING = 'CREATE_APP_PENDING';
export const createAppPending = (orgId, data) => ({
  type: CREATE_APP_PENDING,
  payload: { orgId, data },
  meta: { orgId, data },
});
export const CREATE_APP_SUCCESS = 'CREATE_APP_SUCCESS';
export const createAppSuccess = (orgId, data, json) => ({
  type: CREATE_APP_SUCCESS,
  payload: json,
  meta: { orgId, data },
});
export const CREATE_APP_FAILURE = 'CREATE_APP_FAILURE';
export const createAppFailure = (orgId, data, error) => ({
  type: CREATE_APP_FAILURE,
  payload: error,
  error: true,
  meta: { orgId, data },
});
export const createApp =
  (orgId, data) =>
  (dispatch, getState, { api }) => {
    dispatch(createAppPending(orgId, data));
    return api
      .createApp(orgId, data)
      .then((json) => dispatch(createAppSuccess(orgId, data, json)))
      .catch((ex) => dispatch(createAppFailure(orgId, data, ex)));
  };

export const CLEAR_NEW_APP = 'CLEAR_NEW_APP';
export const clearNewApp = (appId) => ({
  type: CLEAR_NEW_APP,
  payload: { appId },
  meta: { appId },
});

// Update Current User
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const updateUserPending = (data) => ({
  type: UPDATE_USER_PENDING,
  payload: data,
  meta: { data },
});
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const updateUserSuccess = (data, json) => ({
  type: UPDATE_USER_SUCCESS,
  payload: json,
  meta: { data },
});
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const updateUserFailure = (data, error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
  error: true,
  meta: { data },
});
export const updateUser =
  (data) =>
  (dispatch, getState, { api }) => {
    dispatch(updateUserPending(data));
    return api
      .updateUser(data)
      .then((json) => dispatch(updateUserSuccess(data, json)))
      .catch((ex) => dispatch(updateUserFailure(data, ex)));
  };

// Trigger a Change Password Email
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const changePasswordPending = () => ({
  type: CHANGE_PASSWORD_PENDING,
  payload: {},
  meta: {},
});
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const changePasswordSuccess = (json) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: json,
  meta: {},
});
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const changePasswordFailure = (error) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: error,
  error: true,
  meta: {},
});
export const changePassword =
  () =>
  (dispatch, getState, { api }) => {
    dispatch(changePasswordPending());
    return api
      .changePassword()
      .then((json) => dispatch(changePasswordSuccess(json)))
      .catch((ex) => dispatch(changePasswordFailure(ex)));
  };
