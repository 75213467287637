/* eslint-disable no-underscore-dangle */
import { ISurveyQuestion } from 'src/types/core';
import { SurveyQuestionType, SurveyResponseStat } from './surveys.types';

export const toNumber = (value?: string | number): number => (value ? Number(value) : 0);

type QuestionOrAnswer = ISurveyQuestion | SurveyResponseStat;

const _isType = (item: QuestionOrAnswer, type: ISurveyQuestion['type']) => item.type === type;

export const isType = {
  Multichoice: (item: QuestionOrAnswer) => _isType(item, SurveyQuestionType.Multichoice),
  Multiselect: (item: QuestionOrAnswer) => _isType(item, SurveyQuestionType.Multiselect),
  Singleline: (item: QuestionOrAnswer) => _isType(item, SurveyQuestionType.Singleline),
  Range: (item: QuestionOrAnswer) => _isType(item, SurveyQuestionType.Range),
  Nps: (item: QuestionOrAnswer) => _isType(item, SurveyQuestionType.Nps),
};
