import moment, { Moment, unitOfTime } from 'moment';
import { identity, uniq } from 'ramda';

export type TimeMoment = Moment;
export type TimeType = Moment | Date | number | string | undefined;
export type TimeUnit = unitOfTime.Base;

const DATE = 'MM/DD/YYYY';

const FORMAT = {
  DEFAULT: DATE,
  DATE,
  TIME: 'HH:mm',
  YEAR: 'YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  MMM_DD_YYYY: 'MMM DD, YYYY',
  LL: 'LL', // September 4, 1986
  LLL: 'LLL', // September 4, 1986 8:30 PM
  LLLL: 'LLLL', // Thursday, September 4, 1986 8:30 PM
  ll: 'll', // Sep 4, 1986
  DATE_TIME_UTC: 'MMM D, YYYY [at] H:mm [UTC]', // Jan 15, 2024 at 0:00 UTC
};

const PERIOD = {
  DAYS: 'days' as TimeUnit,
  MINUTES: 'minutes' as TimeUnit,
  HOURS: 'hours' as TimeUnit,
};

const isValid = (date: any): boolean => moment(date).isValid();

const isSameDay = (...dates: TimeType[]): boolean => uniq(dates.map((d) => formatTime(d, FORMAT.MMM_DD_YYYY, true))).length === 1;

/**
 * README:
 * All interactions with time, across the app should be via this class and defined formats,
 * instead of requiring moment in different places
 */
export class Time {
  static FORMAT = FORMAT;
  static PERIOD = PERIOD;

  static create = moment;
  static isValid = isValid;
  static isSameDay = isSameDay;
  static format = formatTime;
  static formatUtc = formatTimeUtc;
  static diffDays = diffDays;
  static max = (...dates: TimeType[]) => moment.max(dates.filter(identity).map((d) => Time.create.utc(d)));

  private _date: Moment;

  constructor(date?: TimeType | null, utc?: boolean) {
    this._date = utc ? Time.create.utc(date || undefined) : Time.create(date || undefined);
  }

  format = (format: string): string => formatTime(this._date, format);
  formatUtc = (format: string): string => formatTimeUtc(this._date, format);

  getDate = (): Moment => this._date;
  getTimestamp = (): number => this._date.valueOf();

  diffDays = (toDate: TimeType, precise = false) => diffDays(this._date, toDate, precise);

  isSameDay = (...dates: TimeType[]) => isSameDay(this._date, ...dates);
}

function formatTime(date: TimeType, format: string = FORMAT.DEFAULT, utc: boolean = false): string {
  if (moment.isMoment(date)) {
    return date.format(format);
  }
  return utc ? moment.utc(date || undefined).format(format) : moment(date || undefined).format(format);
}

function formatTimeUtc(date: TimeType, format: string = DATE) {
  return formatTime(date, format, true);
}

function diffDays(fromDate: TimeType, toDate: TimeType, precise?: boolean): number {
  return moment(toDate).diff(fromDate, PERIOD.DAYS, precise);
}
