import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { acceptInvitation } from '../../api';
import JWT from '../../utils/jwt';
import fullPath from '../../utils/full_path';
import '../../components/styles/molecules/alert_box.scss';

const FORM_ERRORS = {
  noInvitationToken: 'Invitation Token is required.',
  invalidPassword: 'Your password must be at least 8 characters long and contain at least: one '
    + 'uppercase letter, one lowercase letter, one number and one special character (e.g. !@#$%^&*).',
  emptyProfesionalValue: 'Professional Role is required.',
};

const API_ERRORS = {
  invalidPassword: FORM_ERRORS.invalidPassword,
  invalidToken: 'The invite token you are using is invalid. If you have received more than one invite email, '
    + 'please use the link from the most recent.',
  unknownError: 'An unknown error occurred.',
};

const containsNumber = /\d/;
const containsLowerCaseLetter = /[a-z]/;
const containsUpperCaseLetter = /[A-Z]/;
const containsSpecialCharacater = /[^a-zA-Z\d\s:]/;

export default class AcceptInvitation extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    }),
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      isSubmitting: false,
      password: '',
      professionalRole: '',
      newsletter: true,
    };

    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.onFormElementChange = this.onFormElementChange.bind(this);
    this.onFormNewsletterToggle = this.onFormNewsletterToggle.bind(this);
  }

  componentDidMount() {
    document.title = 'Welcome to Alchemer';
    JWT.clear();
  }

  async handleFormSubmission(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({ isSubmitting: true, errors: [] });

    const { match: { params: { invitationToken = '' } = {} } } = this.props;
    const { password, professionalRole, newsletter } = this.state;

    const submissionErrors = this.hasInvalidFormElements(invitationToken, password, professionalRole);

    if (submissionErrors) {
      this.setFormSubmissionErrors(submissionErrors);
      return;
    }

    try {
      await acceptInvitation(password, professionalRole, newsletter, invitationToken);
      window.location.assign(fullPath('/login'));
    } catch (error) {
      const errorsFromResponse = this.hasErrorResponse(error);
      this.setFormSubmissionErrors(errorsFromResponse);
    }
  }

  onFormElementChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onFormNewsletterToggle() {
    this.setState({ newsletter: !this.state.newsletter });
  }

  setFormSubmissionErrors(errors = []) {
    this.setState({ errors, isSubmitting: false });
  }

  hasInvalidFormElements(invitationToken, password, professionalRole) {
    const messages = [];

    if (!invitationToken) {
      messages.push(FORM_ERRORS.noInvitationToken);
      return messages;
    }

    if (password === '' || !this.isValidPassword(password)) {
      messages.push(FORM_ERRORS.invalidPassword);
      return messages;
    }

    if (!professionalRole) {
      messages.push(FORM_ERRORS.emptyProfesionalValue);
      return messages;
    }

    return null;
  }

  hasErrorResponse(error) {
    const messages = [];

    if (error && error.response && error.response.error && Array.isArray(error.response.error)) {
      if (error.response.error.some((e) => e.match(/Invitation token is invalid/))) {
        messages.push(API_ERRORS.invalidToken);
      }

      if (error.response.error.some((e) => e.match(/Password/))) {
        messages.push(API_ERRORS.invalidPassword);
      }
    } else if (error && error.message) {
      messages.push(error.message);
    } else {
      messages.push(API_ERRORS.unknownError);
    }

    return messages;
  }

  isValidPassword(password = '') {
    return password.length >= 8
      && containsNumber.test(password)
      && containsLowerCaseLetter.test(password)
      && containsUpperCaseLetter.test(password)
      && containsSpecialCharacater.test(password);
  }

  render() {
    const { errors, isSubmitting, password, professionalRole, newsletter } = this.state;

    return (
      <div className="invitation-edit auth onboarding-container">
        <div className="content-block-border content-block-welcome">
          <h1>Welcome to Alchemer Mobile</h1>
          <h2>Let&apos;s get you set up.</h2>
        </div>
        <div className="content-block content-block-border">
          <div className="align-center">
            <div className="alert-box">You will be prompted to log in after you accept this invitation.</div>
            <div className="content-block align-left">
              Your password must be at least 8 characters long and contain at least:
              <ul>
                <li>one uppercase letter</li>
                <li>one lowercase letter</li>
                <li>one number</li>
                <li>one special character (e.g. !@#$%^&*)</li>
              </ul>
            </div>
            <form acceptCharset="UTF-8" className="invitation-edit align-left" name="accept-invite" onSubmit={this.handleFormSubmission}>
              <fieldset>
                <div className="password">
                  <label htmlFor="password">Password</label>
                  <input type="password" name="password" value={password} onChange={this.onFormElementChange} className="password fs-hide" required size="30" aria-required />
                </div>
                <div className="professional_role">
                  <label htmlFor="professionalRole">What is your role at your company?</label>
                  <select name="professionalRole" className="professional_role" value={professionalRole} onChange={this.onFormElementChange} id="professional_role" required aria-required>
                    <optgroup label="Role">
                      <option disabled="" value="">Your Role</option>
                      <option value="product_management">Product Management</option>
                      <option value="development">Development</option>
                      <option value="marketing">Marketing</option>
                      <option value="customer_success">Customer Success</option>
                      <option value="administrative">Administrative</option>
                    </optgroup>
                  </select>
                </div>
                <br />
                <label className="form-newsletter check-block" htmlFor="newsletter">
                  <input type="checkbox" name="newsletter" checked={newsletter} onChange={this.onFormNewsletterToggle} className="newsletter" />
                  Receive tips &amp; resources in a twice-monthly newsletter
                </label>
              </fieldset>
              <div className="submit-container align-center">
                {errors.length ? <div className="error-text">{this.state.errors.map(error => <p key={error}>{error}</p>)}</div> : null}
                <button className="alchemer large wide" type="submit" disabled={isSubmitting}>Save & Continue</button>
              </div>
            </form>
          </div>
        </div>
        <div className="content-block content-block-bottom align-center">
          <p className="terms-of-service">
            <span>By joining, you agree to Alchemer Mobile </span>
            <a href="https://www.apptentive.com/terms/">Terms & Conditions, </a>
            <a href="https://www.apptentive.com/privacy/">Privacy Policy, </a>
            <a href="https://www.apptentive.com/privacy/#cookies">Cookie Policy.</a>
          </p>
        </div>
      </div>
    );
  }
}
