import { getHasSeenDownloadCenter } from '../selectors/user';
import { displayDownloadCenterWelcome } from './ui';

// Download Modal
export const DISPLAY_DOWNLOAD_MODAL = 'DISPLAY_DOWNLOAD_MODAL';
export const displayDownloadModal = () => ({
  type: DISPLAY_DOWNLOAD_MODAL,
  payload: {},
  meta: {},
});

export const CLEAR_DOWNLOAD_MODAL = 'CLEAR_DOWNLOAD_MODAL';
export const clearDownloadModal = () => ({
  type: CLEAR_DOWNLOAD_MODAL,
  payload: {},
  meta: {},
});

export const UPDATE_DOWNLOAD_MODAL_MSG = 'UPDATE_DOWNLOAD_MODAL_MSG';
export const updateDownloadModalMessage = (body) => ({
  type: UPDATE_DOWNLOAD_MODAL_MSG,
  payload: body,
  meta: {},
});
export const UPDATE_NUM_NEW_DOWNLOADS = 'UPDATE_NUM_NEW_DOWNLOADS';
export const updateNumNewDownloads = (numNewDownloads) => ({
  type: UPDATE_NUM_NEW_DOWNLOADS,
  payload: { numNewDownloads },
  meta: {},
});

// Create Current Users Download
export const CREATE_DOWNLOAD_PENDING = 'CREATE_DOWNLOAD_PENDING';
export const createDownloadPending = (userId, data) => ({
  type: CREATE_DOWNLOAD_PENDING,
  payload: data,
  meta: { data, userId },
});
export const CREATE_DOWNLOAD_SUCCESS = 'CREATE_DOWNLOAD_SUCCESS';
export const createDownloadSuccess = (userId, data, json) => ({
  type: CREATE_DOWNLOAD_SUCCESS,
  payload: json,
  meta: { data, userId },
});
export const CREATE_DOWNLOAD_FAILURE = 'CREATE_DOWNLOAD_FAILURE';
export const createDownloadFailure = (userId, data, error) => ({
  type: CREATE_DOWNLOAD_FAILURE,
  payload: error,
  error: true,
  meta: { data, userId },
});

export const receivePusherNotification = (msgBody) => (dispatch, _getState) => {
  let notificationBody;
  try {
    notificationBody = JSON.parse(msgBody);
  } catch (e) {
    console.error('Invalid pusher message body', msgBody);
  }
  // Notification is for a Download Center download
  if (notificationBody) {
    // Export Completed notification
    if (notificationBody.ui_state && notificationBody.ui_state.numNewDownloads) {
      dispatch(updateNumNewDownloads(notificationBody.ui_state.numNewDownloads));
    }
    dispatch(updateDownloadModalMessage(notificationBody));
    dispatch(displayDownloadModal());
    setTimeout(() => {
      dispatch(clearDownloadModal());
    }, 4000);
  }
};

export const createDownload = (userId, data) => (dispatch, getState, { api }) => {
  const state = getState();
  const hasSeenDownloadCenter = getHasSeenDownloadCenter(state);

  dispatch(createDownloadPending(userId, data));
  if (!hasSeenDownloadCenter) {
    dispatch(displayDownloadCenterWelcome());
  }

  return api.createDownload(userId, data)
    .then(json => dispatch(createDownloadSuccess(userId, data, json)))
    .catch(ex => dispatch(createDownloadFailure(userId, data, ex)));
};

// Update Current Users Download
export const UPDATE_DOWNLOAD_PENDING = 'UPDATE_DOWNLOAD_PENDING';
export const updateDownloadPending = (userId, data) => ({
  type: UPDATE_DOWNLOAD_PENDING,
  payload: data,
  meta: { data, userId },
});
export const UPDATE_DOWNLOAD_SUCCESS = 'UPDATE_DOWNLOAD_SUCCESS';
export const updateDownloadSuccess = (userId, data, json) => ({
  type: UPDATE_DOWNLOAD_SUCCESS,
  payload: json,
  meta: { data, userId },
});
export const UPDATE_DOWNLOAD_FAILURE = 'UPDATE_DOWNLOAD_FAILURE';
export const updateDownloadFailure = (userId, data, error) => ({
  type: UPDATE_DOWNLOAD_FAILURE,
  payload: error,
  error: true,
  meta: { data, userId },
});
export const updateDownload = (userId, data) => (dispatch, _getState, { api }) => {
  dispatch(updateDownloadPending(userId, data));
  return api.updateDownload(userId, data)
    .then(json => dispatch(updateDownloadSuccess(userId, data, json)))
    .catch(ex => dispatch(updateDownloadFailure(userId, data, ex)));
};

// Retry Current Users Download
export const RETRY_DOWNLOAD_PENDING = 'RETRY_DOWNLOAD_PENDING';
export const retryDownloadPending = (userId, data) => ({
  type: RETRY_DOWNLOAD_PENDING,
  payload: data,
  meta: { data, userId },
});
export const RETRY_DOWNLOAD_SUCCESS = 'RETRY_DOWNLOAD_SUCCESS';
export const retryDownloadSuccess = (userId, data, json) => ({
  type: RETRY_DOWNLOAD_SUCCESS,
  payload: json,
  meta: { data, userId },
});
export const RETRY_DOWNLOAD_FAILURE = 'RETRY_DOWNLOAD_FAILURE';
export const retryDownloadFailure = (userId, data, error) => ({
  type: RETRY_DOWNLOAD_FAILURE,
  payload: error,
  error: true,
  meta: { data, userId },
});
export const retryDownload = (userId, data) => (dispatch, _getState, { api }) => {
  dispatch(retryDownloadPending(userId, data));
  return api.retryDownload(userId, data)
    .then(json => dispatch(retryDownloadSuccess(userId, data, json)))
    .catch(ex => dispatch(retryDownloadFailure(userId, data, ex)));
};

// Delete Current Users Download
export const DELETE_DOWNLOAD_PENDING = 'DELETE_DOWNLOAD_PENDING';
export const deleteDownloadPending = (userId, data) => ({
  type: DELETE_DOWNLOAD_PENDING,
  payload: data,
  meta: { data, userId },
});
export const DELETE_DOWNLOAD_SUCCESS = 'DELETE_DOWNLOAD_SUCCESS';
export const deleteDownloadSuccess = (userId, data, json) => ({
  type: DELETE_DOWNLOAD_SUCCESS,
  payload: json,
  meta: { data, userId },
});
export const DELETE_DOWNLOAD_FAILURE = 'DELETE_DOWNLOAD_FAILURE';
export const deleteDownloadFailure = (userId, data, error) => ({
  type: DELETE_DOWNLOAD_FAILURE,
  payload: error,
  error: true,
  meta: { data, userId },
});
export const deleteDownload = (userId, data) => (dispatch, _getState, { api }) => {
  dispatch(deleteDownloadPending(userId, data));
  return api.deleteDownload(userId, data)
    .then(json => dispatch(deleteDownloadSuccess(userId, data, json)))
    .catch(ex => dispatch(deleteDownloadFailure(userId, data, ex)));
};

// Fetch Downloads
export const FETCH_DOWNLOADS_PENDING = 'FETCH_DOWNLOADS_PENDING';
export const fetchDownloadsPending = (userId) => ({
  type: FETCH_DOWNLOADS_PENDING,
  payload: { userId },
  meta: { userId },
});
export const FETCH_DOWNLOADS_SUCCESS = 'FETCH_DOWNLOADS_SUCCESS';
export const fetchDownloadsSuccess = (userId, json) => ({
  type: FETCH_DOWNLOADS_SUCCESS,
  payload: json,
  meta: { userId },
});
export const FETCH_DOWNLOADS_FAILURE = 'FETCH_DOWNLOADS_FAILURE';
export const fetchDownloadsFailure = (userId, error) => ({
  type: FETCH_DOWNLOADS_FAILURE,
  payload: error,
  error: true,
  meta: { userId },
});
export const fetchDownloads = (userId) => (dispatch, _getState, { api }) => {
  dispatch(fetchDownloadsPending(userId));
  return api.fetchDownloads(userId)
    .then(json => dispatch(fetchDownloadsSuccess(userId, json)))
    .catch(ex => dispatch(fetchDownloadsFailure(userId, ex)));
};
