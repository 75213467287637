import { atriumCall, report } from './util';

export const addTag = (conversationId, messageId, tag) => atriumCall(`/conversations/${conversationId}/messages/${messageId}/add_tags`, {
  method: 'POST',
  body: JSON.stringify({ tags: tag }),
  error: ex => {
    report(`[API] Error adding tag ${tag} for ${messageId}`, ex);
  },
});

export const removeTag = (conversationId, messageId, tag) => atriumCall(`/conversations/${conversationId}/messages/${messageId}/remove_tags`, {
  method: 'POST',
  body: JSON.stringify({ tags: tag }),
  error: ex => { report(`[API] Error removing tag ${tag} for ${messageId}`, ex); },
});
