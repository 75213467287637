import { SortOrder } from 'src/utils';

const CONVERSATIONS_FILTERS = {
  inbox: 'inbox',
  my_ongoing: (userId: string) => `assignee_id:${userId},ongoing`,
  my_starred: (userId: string) => `assignee_id:${userId},starred_by:${userId}`,
  my_archived: (userId: string) => `assignee_id:${userId},state:archived`,
  my_all: (userId: string) => `assignee_id:${userId}`,
  everyone_ongoing: 'ongoing',
  everyone_starred: (userId: string) => `starred_by:${userId}`,
  everyone_archived: 'state:archived',
  everyone_all: 'all',
};

export const generateConversationFilters = (userId: string) => Object.keys(CONVERSATIONS_FILTERS).reduce((acc, key: ConversationsFilter) => {
  const prop = CONVERSATIONS_FILTERS[key];
  return typeof prop === 'function' ? { ...acc, [key]: prop(userId) } : { ...acc, [key]: prop };
}, {});

export enum ConversationsSortBy {
  MessageCreated = 'latest_consumer_msg.created_at',
  LastMessage = 'latest_msg.created_at',
  AssignedTo = 'assignee.name',
  Status = 'state',
}

type ConversationsFilter = keyof typeof CONVERSATIONS_FILTERS;

// PropTypes src/proptypes/conversation.js
type ConversationId = string;
interface ConversationItem {
  id: ConversationId;
  latest_consumer_msg: {
    id: string;
    created_at: number;
  };
  latest_msg: {
    id: string;
    created_at: number;
    read_at: number;
  };
}

export interface ConversationsState {
  conversationList: Record<ConversationId, ConversationItem>;
  conversationSelected: ConversationId[];
  counts: Record<ConversationsFilter, number>;
  countsFetchError: boolean;
  countsLoading: boolean;
  fetchError: boolean;
  fetchErrorMessages: boolean;
  filter: string;
  filterLink: ConversationsFilter;
  hasMore: boolean;
  loading: boolean;
  loadingMessages: boolean;
  postingMessages: boolean;
  postErrorMessages: boolean;
  searchPending: boolean;
  searchPhrase: string;
  searchResults: [];
  showSubMenu: boolean;
  sortBy: { sortBy: ConversationsSortBy; order: SortOrder };
  tagUpdatePending: boolean;
  title: string;
}

export const conversationsInitialState: ConversationsState = {
  conversationList: {},
  conversationSelected: [],
  counts: {
    inbox: 0,
    my_ongoing: 0,
    my_starred: 0,
    my_archived: 0,
    my_all: 0,
    everyone_ongoing: 0,
    everyone_starred: 0,
    everyone_archived: 0,
    everyone_all: 0,
  },
  countsFetchError: false,
  countsLoading: true,
  fetchError: false,
  fetchErrorMessages: false,
  filter: 'inbox',
  filterLink: 'inbox',
  hasMore: false,
  loading: true,
  loadingMessages: true,
  postingMessages: false,
  postErrorMessages: false,
  searchPending: false,
  searchPhrase: '',
  searchResults: [],
  showSubMenu: false,
  sortBy: { sortBy: ConversationsSortBy.MessageCreated, order: SortOrder.desc },
  tagUpdatePending: false,
  title: 'Inbox',
};
