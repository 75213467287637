export const FETCH_ATTRIBUTE_CHOICES_PENDING = 'FETCH_ATTRIBUTE_CHOICES_PENDING';
export const FETCH_ATTRIBUTE_CHOICES_SUCCESS = 'FETCH_ATTRIBUTE_CHOICES_SUCCESS';
export const FETCH_ATTRIBUTE_CHOICES_FAILURE = 'FETCH_ATTRIBUTE_CHOICES_FAILURE';

export const fetchAttributeChoicesPending = (appId, type) => ({
  type: FETCH_ATTRIBUTE_CHOICES_PENDING,
  payload: { appId, type },
  meta: { appId, type },
});
export const fetchAttributeChoicesSuccess = (appId, type, json) => ({
  type: FETCH_ATTRIBUTE_CHOICES_SUCCESS,
  payload: json,
  meta: { appId, type },
});
export const fetchAttributeChoicesFailure = (appId, type, error) => ({
  type: FETCH_ATTRIBUTE_CHOICES_FAILURE,
  payload: error,
  error: true,
  meta: { appId, type },
});

/**
 * @param {string} appId
 * @param {device|person} type
 * @param {string|undefined} limit optional
 * @return {Promise}
 */
export const fetchAttributeChoices = (appId, type, limit) => (dispatch, getState, { api }) => {
  dispatch(fetchAttributeChoicesPending(appId, type));
  return api.fetchAttributeChoices(appId, type, limit)
    .then(json => dispatch(fetchAttributeChoicesSuccess(appId, type, json)))
    .catch(error => dispatch(fetchAttributeChoicesFailure(appId, type, error)));
};
