import { createSelector } from 'reselect';
import { isEmpty, uniq } from 'ramda';
import { State } from 'src/reducers';
import { isDemoEnv } from 'src/utils/environment';
import { IUser, Features, UserFeature } from 'src/types/core';

export const getUser = (state: State): IUser | {} => state.user;

const getUserAttribute = <T>(user: IUser | {}, getAttr: (user: IUser) => T | undefined, defaultValue: T): T => {
  if (!user || isEmpty(user)) return defaultValue;
  return getAttr(user as IUser) || defaultValue;
};

export const getFeatures = createSelector(
  getUser,
  (user) => getUserAttribute<Features[] | UserFeature[]>(
    user,
    (u) => {
      if (isDemoEnv()) {
        return [...u.features];
      }
      return uniq(u.features || []);
    },
    []
  )
);
export const getCurrentUserId = createSelector(
  getUser,
  (user) => getUserAttribute<string>(user, (u) => u.id, '')
);
export const getCurrentUserName = createSelector(
  getUser,
  (user) => getUserAttribute<string>(user, (u) => u.name, '')
);
export const getCurrentUserEmail = createSelector(
  getUser,
  (user) => getUserAttribute<string>(user, (u) => u.email, '')
);
export const getCurrentUserRole = createSelector(
  getUser,
  (user) => getUserAttribute<string>(user, (u) => u.role, '')
);
export const getCurrentUserProfessionalRole = createSelector(
  getUser,
  (user) => getUserAttribute<string>(user, (u) => u.professionalRole, '')
);
export const getUIState = createSelector(
  getUser,
  (user) => getUserAttribute<any>(user, (u) => u.uiState, {})
);

export const getDismissedInsightOnboarding = createSelector(
  getUIState,
  (uiState) => uiState.dismissed_reviews_onboarding || false
);

export const getHasSeenDownloadCenter = createSelector(
  getUIState,
  (ui) => ui.has_seen_download_center || false
);

export const getUiNumNewDownloads = createSelector(
  getUIState,
  (ui) => ui.numNewDownloads || 0
);

export const getDisplayFsExportInfoModal = createSelector(
  getUIState,
  (ui) => ui.has_seen_fs_export_info || false
);

export const getHasDownloadedFsExport = createSelector(
  getUIState,
  (ui) => ui.has_not_downloaded_fs_export || false
);
