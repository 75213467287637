import 'mdn-polyfills/Array.from';
import React, { Component } from 'react';
import analytics from '../../data/analytics';
import AppStoreIdTypeahead from '../../components/molecules/appStoreIdTypeahead';
import { createOrganization, updateUser } from '../../api';
import fullPath from '../../utils/full_path';

import './styles/welcome.scss';

class Welcome extends Component {
  static labelKey = (option) => (option && option.app_name ? option.app_name : '');

  state = {
    errors: [],
    showNew: true,
    showExisting: false,
    storeApp: null,
    title: '',
    submitting: false,
  };

  componentDidMount() {
    document.title = 'Welcome to Alchemer';
    analytics.trackPageView('/onboarding/company');
  }

  handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    // User Attributes
    this.setState({ submitting: true, errors: [] });

    if (!this.form.elements.professional_role.value) {
      this.setState({ errors: ['Professional Role is required.', ...this.state.errors], submitting: false });
      return;
    }

    // Organization & App Attributes
    if (!this.form.elements.organization_name.value) {
      this.setState({ errors: ['Company Name is required.', ...this.state.errors], submitting: false });
      return;
    }
    if (!this.form.elements.platform.value) {
      this.setState({ errors: ['Platform is required.', ...this.state.errors], submitting: false });
      return;
    }
    if (!this.state.title) {
      this.setState({ errors: ['App Name is required.', ...this.state.errors], submitting: false });
      return;
    }

    analytics.trackEvent('onboarding', 'click', 'completing setup', 1);

    const app = {
      platform: this.form.elements.platform.value,
      title: this.state.title,
      store_id: this.state.storeApp ? this.state.storeApp.store_app_id : null,
    };

    // Icon: Upload, AppStore or nothing for the default
    if (this.state.storeApp && this.state.storeApp.icon_url) {
      app.icon_url = this.state.storeApp.icon_url;
    }

    // Update User & Organization
    const user = {
      professional_role: this.form.elements.professional_role.value,
    };

    const organization = {
      name: this.form.elements.organization_name.value,
      apps_attributes: [app],
    };

    updateUser({ user });
    createOrganization(organization).then(() => {
      /* istanbul ignore next */
      window.location.assign(fullPath('/loading'));
    });
  };

  toggleShowing = () => {
    this.setState({
      showNew: !this.state.showNew,
      showExisting: !this.state.showExisting,
    });
  };

  contactApptentive = () => {
    analytics.trackPageView('/onboarding/new-existing/contact-apptentive');
    window.location.assign(
      encodeURI(
        'mailto:accounts@apptentive.com?Subject=Joining an Apptentive Account&Body=Please fill out the information below for help contacting your account administrator.\n\nYour Name:\nCompany:\nRole:\nName of the app(s) I want to join:\n\nWe will get back to you shortly during regular business hours (Pacific Standard Time).'
      )
    );
  };

  contactAdmin = () => {
    analytics.trackPageView('/onboarding/new-existing/email-admin');
    window.location.assign(
      encodeURI(
        "mailto:ENTER_ADMIN_EMAIL?Subject=Apptentive Account Invitation&Body=Hello,\nI am trying to join our Apptentive account, and I need an invitation.\n\nTo add me, log into Apptentive, click 'Settings', then 'Team Members'. Thank you!"
      )
    );
  };

  autocompleteInput = (title = '') => {
    this.setState({ title });
  };

  autocompleteSelect = (storeApp) => {
    if (storeApp) {
      if (storeApp.platform && storeApp.platform === 'android') {
        this.form.elements.platform.value = 'Android';
      } else if (storeApp.platform && storeApp.platform === 'itunes') {
        this.form.elements.platform.value = 'iOS';
      }
      this.setState({ storeApp, title: storeApp.app_name });
    }
  };

  dismissErrors(event) {
    if (event) {
      event.preventDefault();
    }
    this.setState({ errors: [] });
  }

  renderShowNewWelcome() {
    return (
      <form
        acceptCharset="UTF-8"
        className="app"
        id="app"
        name="app"
        onSubmit={this.handleSubmit}
        ref={(form) => (this.form = form)}
      >
        <div className="form-section organization-info content-block-border">
          <div className="signup-info"><p>All fields are required.</p></div>
          <div className="signup-form-field">
            <select className="platform" name="platform" defaultValue="" required>
              <optgroup label="Platform">
                <option disabled value="">
                  Choose Platform
                </option>
                <option value="iOS">iOS</option>
                <option value="Android">Android</option>
                <option value="Web">Web</option>
              </optgroup>
            </select>
          </div>
          <div className="search-container">
            <AppStoreIdTypeahead
              handleInputChange={this.autocompleteInput}
              handleOnOptionSelected={this.autocompleteSelect}
              labelKey={Welcome.labelKey}
              placeholder="Search For Your App"
              platform={
                this.form && this.form.elements.platform && this.form.elements.platform.value
                  ? this.form.elements.platform.value
                  : ''
              }
            />
          </div>
          <div className="control signup-form-field">
            <input type="text" placeholder="Add Company Name" name="organization_name" required />
          </div>
          <select name="professional_role" defaultValue="" required>
            <optgroup label="Professional Role">
              <option disabled value="">
                Select Your Role...
              </option>
              <option value="product_management">Product Management</option>
              <option value="development">Development</option>
              <option value="marketing">Marketing</option>
              <option value="customer_success">Customer Success</option>
              <option value="administrative">Administrative</option>
            </optgroup>
          </select>
        </div>
        <div className="form-section content-block">
          {this.state.errors.length ? (
            <div className="error-text">
              {this.state.errors.map((error) => (
                <p key={error}>{error}</p>
              ))}
            </div>
          ) : null}
          <button className="create alchemer large wide" type="submit" disabled={this.state.submitting}>
            Start Trial
          </button>
        </div>
      </form>
    );
  }

  renderShowExistingWelcome() {
    return (
      <div className="join-existing">
        <div className="content-block-border">
          <div className="top">
            <h1>Join an Existing Account</h1>
          </div>
          <div className="left">
            <h3>I know who my account admin is.</h3>
            <p>Great! send our pre-composed email to your account admin to let them know that you want in.</p>
            <button type="button" onClick={this.contactAdmin}>
              Email Admin
            </button>
          </div>
          <div className="right">
            <h3>I do not know my account admin.</h3>
            <p>No problem. Contact us, and we will help you figure out who can add you to your team&apos;s account.</p>
            <button type="button" onClick={this.contactApptentive}>
              Contact Alchemer Mobile
            </button>
          </div>
        </div>
        <div className="footer content-block">
          If your company does not already have an Alchemer Mobile account,
          <button className="button-normalize" type="button" onClick={this.toggleShowing}>
            create a new account.
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="invitation-edit auth onboarding-container">
        <div className="content-block-border content-block-welcome">
          <h1>Welcome to Alchemer Mobile</h1>
          <p>
            Let&lsquo;s complete your 30 day self-serve trial* setup so we can start gathering customer feedback together.
          </p>

          <p> Does your company have an active Alchemer Mobile account?&nbsp;
            <button className="button-normalize existing-company" type="button" onClick={this.toggleShowing}>
              Join it here
            </button>
          </p>
        </div>
        {this.state.showNew ? this.renderShowNewWelcome() : null}
        {this.state.showExisting ? this.renderShowExistingWelcome() : null}
        <div className="content-block content-block-bottom align-center">
          <p>
            *Once your 30-day trial expires, you will need to&nbsp;
            <a href="mailto: sales@apptentive.com" rel="noopener noreferrer">
              contact a member of our Sales Team
            </a> to continue using Alchemer Mobile
            .
          </p>
          <p className="terms-of-service">
            <span>By joining, you agree to Alchemer Mobile </span>
            <a href="https://www.apptentive.com/terms/">Terms & Conditions, </a>
            <a href="https://www.apptentive.com/privacy/">Privacy Policy, </a>
            <a href="https://www.apptentive.com/privacy/#cookies">Cookie Policy.</a>
          </p>
        </div>
        <a className="logout" href="/logout">
          Log Out
        </a>
      </div>
    );
  }
}

export default Welcome;
