import jwt_decode from 'jwt-decode';
import Session from './shared_session';
import * as oauth from './oauth';

export default class jwt {
  /**
   * Retrieve id token from session storage.
   * @deprecated Should use getApiToken instead
   * @returns {string} representation of id token jwt
   */
  static getLegacyToken() {
    if (oauth.jwt()) {
      jwt.setLegacyToken(oauth.jwt());
      oauth.clearJwt();
    }
    return Session.get('token');
  }

  /**
   * Set id token into session storage.
   * @param {string} token JWT string to set
   * @deprecated Should use setApiToken instead
   */
  static setLegacyToken(token) {
    Session.set('token', token);
  }

  /**
   * Get api token from session storage.
   * @returns {string} representation of access token jwt
   */
  static getApiToken() {
    return Session.get('api-token');
  }

  /**
   * Set access token into session storage.
   * @param {string} token JWT string to set
   */
  static setApiToken(token) {
    Session.set('api-token', token);
  }

  /**
   * Decodes provided jwt into json object.
   * @param {string} token JWT string to decode
   * @returns {any | null} parsed JWT or null if invalid
   */
  static decode(token) {
    if (!token) {
      console.warn('No JWT token');
      return null;
    }
    if (typeof token !== 'string') {
      console.warn('Something other than a JWT token was passed in.', token);
      return null;
    }

    const decodedPayload = jwt_decode(token);

    return decodedPayload;
  }

  /**
   * Clear all managed local storage values.
   */
  static clearLocalStorage() {
    // Iterate over localStorage and remove the keys that meet the conditions
    try {
      const keys = [];
      for (let i = 0, l = localStorage.length; i < l; i++) {
        /* istanbul ignore else */
        if (localStorage.key(i)) {
          const prefix = localStorage.key(i).substring(0, 6);
          if (prefix === 'auth0.' || prefix === 'com.au' || prefix === 'shared') {
            keys.push(localStorage.key(i));
          }
        }
      }
      for (let i = 0, l = keys.length; i < l; i++) {
        /* istanbul ignore else */
        if (keys[i]) {
          localStorage.removeItem(keys[i]);
        }
      }
    } catch (error) {
      /* istanbul ignore next */
      console.error('Error Clearing localStorage', error);
    }
  }

  /**
   * Remove all token values from session storage.
   */
  static clear() {
    Session.remove('token');
    Session.remove('api-token');
  }

  /**
   * Determine if the remaining lifetime of the access token.
   * @returns {number} time until expiration
   */
  static ttl() {
    const token = jwt.getApiToken();
    const session = jwt.decode(token);
    return session ? (session.exp * 1000) - Date.now() : 0;
  }

  /**
   * Determine if the access token is currently expired or not.
   * @returns {boolean} True if token is not expired, False if token is expired
   */
  static valid() {
    return jwt.ttl() > 0;
  }

  /**
   * Clear all session storage values.
   */
  static destroy() {
    jwt.clearLocalStorage();
    jwt.clear();
  }
}
