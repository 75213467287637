import { IconName } from 'src/components/molecules';

export enum AggsSentiment {
  ALL = '-1000..1000',
  NEGATIVE = '-1000..-1',
  NEUTRAL = '0..599',
  POSITIVE = '600..1000',
}

export const SENTIMENT_LABELS: Record<string, string> = {
  [AggsSentiment.ALL]: 'All Sentiment',
  [AggsSentiment.NEGATIVE]: 'Negative',
  [AggsSentiment.NEUTRAL]: 'Neutral',
  [AggsSentiment.POSITIVE]: 'Positive',
};

export enum AggsRating {
  ALL = '1..5',
  FANS = '4..5',
  OPPORTUNE = '1..2',
  ONE = '1.0',
  TWO = '2.0',
  THREE = '3.0',
  FOUR = '4.0',
  FIVE = '5.0',
}

export const RATING_LABELS: Record<string, string> = {
  [AggsRating.FIVE]: 'FIVE',
  [AggsRating.FOUR]: 'FOUR',
  [AggsRating.THREE]: 'THREE',
  [AggsRating.TWO]: 'TWO',
  [AggsRating.ONE]: 'ONE',
};

export enum AggsType {
  MESSAGE = 'message',
  REVIEW = 'review',
  SURVEY = 'survey_answer',
}

export enum AggsDomen {
  Region = 'region-distribution',
  Sentiment = 'sentiment-distribution',
  Rating = 'rating-distribution',
  Count = 'count-histogram',
  Type = 'type-distribution',
  Fan = 'fs-distribution',
  Survey = 'survey-title-distribution',
}

export enum PhraseSentiment {
  NEGATIVE = '-1000.0-0.0',
  NEUTRAL = '0.0-600.0',
  POSITIVE = '600.0-*',
}

export const PHRASE_SENTIMENT_LABELS: Record<string, string> = {
  [PhraseSentiment.NEGATIVE]: 'unhappy',
  [PhraseSentiment.NEUTRAL]: 'neutral',
  [PhraseSentiment.POSITIVE]: 'happy',
};

export enum PhraseState {
  PINNED = 'PINNED',
  MUTED = 'MUTED',
}

export const DEFAULT_INSIGHTS_FILTERS = {
  regions: [],
  types: [AggsType.REVIEW, AggsType.MESSAGE, AggsType.SURVEY],
  sentiment: AggsSentiment.ALL,
  rating: AggsRating.ALL,
  phrases: [],
};

// README: https://github.com/apptentive/fan-signals-core-go/blob/master/fan-status.go
export enum FanSignalStatus {
  NEW_FAN = 'Fan',
  NEW_RISK = 'Opportunity',
  REPEAT_FAN = 'RepeatFan',
  REPEAT_RISK = 'RepeatOpportunity',
  SHIFTED_TO_FAN = 'ReclaimedFan',
  SHIFTED_TO_RISK = 'LostFan',
  NON_RESPONDENT = 'NonRespondent',
}

export const FAN_SIGNAL_LABELS = {
  [FanSignalStatus.NEW_FAN]: 'New Fan',
  [FanSignalStatus.NEW_RISK]: 'New Risk',
  [FanSignalStatus.REPEAT_FAN]: 'Repeat Fan',
  [FanSignalStatus.REPEAT_RISK]: 'Repeat Risk',
  [FanSignalStatus.SHIFTED_TO_FAN]: 'Shifted to Fan',
  [FanSignalStatus.SHIFTED_TO_RISK]: 'Shifted to Risk',
  [FanSignalStatus.NON_RESPONDENT]: 'Non-Respondent',
};

export const FAN_SIGNAL_ICONS: Record<FanSignalStatus, IconName> = {
  [FanSignalStatus.NEW_FAN]: 'newFan',
  [FanSignalStatus.NEW_RISK]: 'newRisk',
  [FanSignalStatus.REPEAT_FAN]: 'repeatFan',
  [FanSignalStatus.REPEAT_RISK]: 'repeatRisk',
  [FanSignalStatus.SHIFTED_TO_FAN]: 'shiftedToFan',
  [FanSignalStatus.SHIFTED_TO_RISK]: 'shiftedToRisk',
  [FanSignalStatus.NON_RESPONDENT]: 'nonRespondent',
};

export enum FanSignalsExportType {
  CURRENT_SIGNAL = 'CurrentStatus',
  SIGNALS_OVER_TIME = 'SignalsOverTime',
  SIGNAL_SUMMARY = 'Summary',
}
