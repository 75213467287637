import { identity, pick, path } from 'ramda';
import { createActions } from 'redux-actions';
import moment from 'moment';

import { Platform } from 'src/types/core';
import { getAppsList, getCurrentAppId } from '../selectors/current_app';
import { getDashboardEndDate, getDashboardStartDate } from '../selectors/dashboard';
import { AppDataActions } from './app-data';

export const FETCH_ROLLUP_PENDING = 'FETCH_ROLLUP_PENDING';
export const FETCH_ROLLUP_SUCCESS = 'FETCH_ROLLUP_SUCCESS';
export const FETCH_ROLLUP_FAILURE = 'FETCH_ROLLUP_FAILURE';
export const noIdsError = new Error('No Project Ids Present');

export const BUST_MASTER_ROLLUP_CACHE_PENDING = 'BUST_MASTER_ROLLUP_CACHE_PENDING';
export const BUST_MASTER_ROLLUP_CACHE_SUCCESS = 'BUST_MASTER_ROLLUP_CACHE_SUCCESS';
export const BUST_MASTER_ROLLUP_CACHE_FAILURE = 'BUST_MASTER_ROLLUP_CACHE_FAILURE';

const creators = createActions({
  [FETCH_ROLLUP_PENDING]: [identity, pick(['appIds'])],
  [FETCH_ROLLUP_SUCCESS]: [path(['json']), pick(['appIds'])],
  [FETCH_ROLLUP_FAILURE]: [path(['error']), pick(['appIds'])],
  [BUST_MASTER_ROLLUP_CACHE_PENDING]: [pick(['appId']), pick(['appId'])],
  [BUST_MASTER_ROLLUP_CACHE_SUCCESS]: [pick(['appId']), pick(['appId'])],
  [BUST_MASTER_ROLLUP_CACHE_FAILURE]: [path(['error']), pick(['appId'])],
});

export const {
  fetchRollupPending,
  fetchRollupSuccess,
  fetchRollupFailure,
  bustMasterRollupCachePending,
  bustMasterRollupCacheSuccess,
  bustMasterRollupCacheFailure,
} = creators;

export const fetchRollup =
  () =>
    (dispatch, getState, { api }) => {
      const state = getState();
      const endDate = +moment().format('x');
      const startDate = +moment().subtract(30, 'days').format('x');
      const appIds = getAppsList(state)
        .filter((app) => Boolean(!app.mau) && app.platform !== Platform.Web)
        .map((a) => a.id);

      dispatch(fetchRollupPending({ appIds }));
      if (appIds.length < 1) {
        return dispatch(fetchRollupFailure({ appIds, error: noIdsError }));
      }
      return api
        .fetchRollup(appIds, startDate, endDate)
        .then((json) => {
          dispatch(fetchRollupSuccess({ appIds, json }));
          const appDataEntities = json.rollup.map((rollup) => ({
            id: rollup.id,
            love_ratio: rollup.love_ratio || '',
            mau: rollup.mau || '',
            current_rating: rollup.current_rating || '',
            all_ratings: rollup.all_ratings || '',
            interactions: rollup.interactions || '',
          }));
          appDataEntities.forEach((entity) => {
            dispatch({
              type: AppDataActions.SAVE_APP_DATA_SUCCESS,
              payload: entity,
              meta: { appId: entity.id },
            });
          });
        })
        .catch((error) => dispatch(fetchRollupFailure({ appIds, error })));
    };

export const bustMasterRollupCache = () => (dispatch, getState, { api }) => {
  const state = getState();
  const appId = getCurrentAppId(state);
  const startDate = getDashboardStartDate(state);
  const endDate = getDashboardEndDate(state);

  dispatch(bustMasterRollupCachePending({ appId }));
  return api.bustMasterRollupCache(appId, startDate, endDate)
    .then(_json => dispatch(bustMasterRollupCacheSuccess({ appId })))
    .catch(error => dispatch(bustMasterRollupCacheFailure({ appId, error })));
};
