/* global Raven */
import { createSelector } from 'reselect';
import { path, filter, propOr, propEq, zipWith } from 'ramda';
import { getCurrentStoreApp } from './current_app';

export const getDateRange = state => state.dateRange || {};
export const startDateSelector = createSelector(getDateRange, range => range.startDate);
export const endDateSelector = createSelector(getDateRange, range => range.endDate);
export const getNamedDateRange = createSelector(getDateRange, range => range.name);
export const getGraphSelector = (state, { graph }) => state[graph];
export const getReviewsCount = state => path(['reviewsCount', 'count'], state) || 0;

export const surveyResponseRateSelector = (state) => {
  let responseRate = [];

  if (state.surveyCount && Array.isArray(state.surveyCount.data) && state.surveyCount.data.length === 2) {
    const isSurveysDisplayed = propEq('label', 'survey.launch');
    const surveysDisplayed = filter(isSurveysDisplayed, state.surveyCount.data)[0].datums;

    const isSurveysCompleted = propEq('label', 'survey.submit');
    const surveysCompleted = filter(isSurveysCompleted, state.surveyCount.data)[0].datums;

    const getRatio = (a, b) => {
      try {
        if (a.date !== b.date) {
          throw new Error('survey response ratio event date mismatch');
        }
      } catch (e) {
        if (typeof Raven !== 'undefined') {
          Raven.captureException(e);
        } else {
          console.error(e);
        }
      }
      return { date: a.date, y: Math.min(1, (b.y / a.y)) };
    };
    responseRate = zipWith(getRatio, surveysDisplayed, surveysCompleted);
  }

  return [
    {
      datums: responseRate,
      label: 'survey.responseRate',
    },
  ];
};

// Dashboard
export const getDashboard = state => state.dashboard || {};
export const getDashboardStartDate = createSelector(
  getDashboard,
  dashboard => startDateSelector(dashboard)
);
export const getDashboardEndDate = createSelector(
  getDashboard,
  dashboard => endDateSelector(dashboard)
);

export const getDashboardDateRange = createSelector(
  getDashboardStartDate,
  getDashboardEndDate,
  (startDate, endDate) => ({ startDate, endDate })
);

export const getDashboardNamedDate = createSelector(
  getDashboard,
  dashboard => getNamedDateRange(dashboard)
);
export const getDashboardDauData = createSelector(
  getDashboard,
  dashboard => dashboard.dauData || []
);
export const getDashboardNewAppId = createSelector(
  getDashboard,
  dashboard => dashboard.newAppId || ''
);

export const getSurveyResponseRate = createSelector(
  getDashboard,
  dashboard => surveyResponseRateSelector(dashboard)
);
export const getReachData = createSelector(
  getDashboard,
  dashboard => dashboard.reachData || {}
);

export const getDateAnnotations = createSelector(
  getDashboard,
  dashboard => dashboard.dateAnnotations || []
);

export const getDashboardReviewCount = createSelector(getDashboard, getReviewsCount);
export const getSelectedCountries = state => {
  const selectedCountries = path(['form', 'star-ratings-intl', 'values', 'star-ratings-filter', 'country-filter'], state) || '';
  return selectedCountries.split(',').filter(country => (!!country));
};

export const createDashboardGraphSelector = (graph) => createSelector(
  getDashboard,
  dashboard => getGraphSelector(dashboard, { graph }) || {}
);
export const createLoadingSelector = (graphSelector) => createSelector(
  graphSelector,
  graph => graph.loading
);
export const createFetchErrorSelector = (graphSelector) => createSelector(
  graphSelector,
  graph => graph.fetchError
);
export const createDataSelector = (graphSelector) => createSelector(
  graphSelector,
  graph => graph.data || []
);

export const loveRatioAggregate = createDashboardGraphSelector('loveRatioAggregate');
export const loveRatioPreviousPeriod = createDashboardGraphSelector('loveRatioPreviousPeriod');
export const loveRatioOverTime = createDashboardGraphSelector('loveRatioOverTime');

export const getGraphs = {
  appleRatingsDialogAggregate: createDashboardGraphSelector('appleRatingsDialogAggregate'),
  appleRatingsDialogAggregatePreviousPeriod: createDashboardGraphSelector('appleRatingsDialogAggregatePreviousPeriod'),
  appleRatingsDialogOvertime: createDashboardGraphSelector('appleRatingsDialogOvertime'),
  inAppRatingsDialogAggregate: createDashboardGraphSelector('inAppRatingsDialogAggregate'),
  inAppRatingsDialogAggregatePreviousPeriod: createDashboardGraphSelector('inAppRatingsDialogAggregatePreviousPeriod'),
  inAppRatingsDialogOvertime: createDashboardGraphSelector('inAppRatingsDialogOvertime'),
  averageRatings: createDashboardGraphSelector('averageRatings'),
  loveRatioAggregate,
  loveRatioPreviousPeriod,
  loveRatioOverTime,
  ratingsDialogAggregate: createDashboardGraphSelector('ratingsDialogAggregate'),
  ratingsDialogAggregatePreviousPeriod: createDashboardGraphSelector('ratingsDialogAggregatePreviousPeriod'),
  ratingsOverTime: createDashboardGraphSelector('ratingsOverTime'),
  surveyCount: createDashboardGraphSelector('surveyCount'),
  surveyAggregates: createDashboardGraphSelector('surveyAggregates'),
  notesCount: createDashboardGraphSelector('notesCount'),
  notesAggregates: createDashboardGraphSelector('notesAggregates'),
  dailyActiveUsers: createDashboardGraphSelector('dailyActiveUsers'),
  monthlyActiveUsers: createDashboardGraphSelector('monthlyActiveUsers'),
  retention: createDashboardGraphSelector('retention'),
  reviewsCount: createDashboardGraphSelector('reviewsCount'),
  inboundMessageCount: createDashboardGraphSelector('inboundMessageCount'),
  interactionCount: createDashboardGraphSelector('interactionCount'),
  ratingsDialogOvertime: createDashboardGraphSelector('ratingsDialogOvertime'),
};

export const loveRatioAggregateLoading = createLoadingSelector(getGraphs.loveRatioAggregate);
export const loveRatioPreviousPeriodLoading = createLoadingSelector(getGraphs.loveRatioPreviousPeriod);
export const loveRatioOverTimeLoading = createLoadingSelector(getGraphs.loveRatioOverTime);

export const getIsGraphLoading = {
  appleRatingsDialogAggregate: createLoadingSelector(getGraphs.appleRatingsDialogAggregate),
  appleRatingsDialogAggregatePreviousPeriod: createLoadingSelector(getGraphs.appleRatingsDialogAggregatePreviousPeriod),
  appleRatingsDialogOvertime: createLoadingSelector(getGraphs.appleRatingsDialogOvertime),
  inAppRatingsDialogAggregate: createLoadingSelector(getGraphs.inAppRatingsDialogAggregate),
  inAppRatingsDialogAggregatePreviousPeriod: createLoadingSelector(getGraphs.inAppRatingsDialogAggregatePreviousPeriod),
  inAppRatingsDialogOvertime: createLoadingSelector(getGraphs.inAppRatingsDialogOvertime),
  averageRatings: createLoadingSelector(getGraphs.averageRatings),
  loveRatioAggregate: loveRatioAggregateLoading,
  loveRatioPreviousPeriod: loveRatioPreviousPeriodLoading,
  loveRatioOverTime: loveRatioOverTimeLoading,
  ratingsDialogAggregate: createLoadingSelector(getGraphs.ratingsDialogAggregate),
  ratingsDialogAggregatePreviousPeriod: createLoadingSelector(getGraphs.ratingsDialogAggregatePreviousPeriod),
  ratingsOverTime: createLoadingSelector(getGraphs.ratingsOverTime),
  surveyCount: createLoadingSelector(getGraphs.surveyCount),
  surveyAggregates: createLoadingSelector(getGraphs.surveyAggregates),
  notesCount: createLoadingSelector(getGraphs.notesCount),
  notesAggregates: createLoadingSelector(getGraphs.notesAggregates),
  dailyActiveUsers: createLoadingSelector(getGraphs.dailyActiveUsers),
  monthlyActiveUsers: createLoadingSelector(getGraphs.monthlyActiveUsers),
  retention: createLoadingSelector(getGraphs.retention),
  reviewsCount: createLoadingSelector(getGraphs.reviewsCount),
  inboundMessageCount: createLoadingSelector(getGraphs.inboundMessageCount),
  interactionCount: createLoadingSelector(getGraphs.interactionCount),
  ratingsDialogOvertime: createLoadingSelector(getGraphs.ratingsDialogOvertime),
};

export const loveRatioAggregateErrored = createFetchErrorSelector(getGraphs.loveRatioAggregate);
export const loveRatioPreviousPeriodErrored = createFetchErrorSelector(getGraphs.loveRatioPreviousPeriod);
export const loveRatioOverTimeErrored = createFetchErrorSelector(getGraphs.loveRatioOverTime);

export const getGraphHasFetchError = {
  appleRatingsDialogAggregate: createFetchErrorSelector(getGraphs.appleRatingsDialogAggregate),
  appleRatingsDialogAggregatePreviousPeriod: createFetchErrorSelector(getGraphs.appleRatingsDialogAggregatePreviousPeriod),
  appleRatingsDialogOvertime: createFetchErrorSelector(getGraphs.appleRatingsDialogOvertime),
  inAppRatingsDialogAggregate: createFetchErrorSelector(getGraphs.inAppRatingsDialogAggregate),
  inAppRatingsDialogAggregatePreviousPeriod: createFetchErrorSelector(getGraphs.inAppRatingsDialogAggregatePreviousPeriod),
  inAppRatingsDialogOvertime: createFetchErrorSelector(getGraphs.inAppRatingsDialogOvertime),
  averageRatings: createFetchErrorSelector(getGraphs.averageRatings),
  loveRatioAggregate: loveRatioAggregateErrored,
  loveRatioPreviousPeriod: loveRatioPreviousPeriodErrored,
  loveRatioOverTime: loveRatioOverTimeErrored,
  ratingsDialogAggregate: createFetchErrorSelector(getGraphs.ratingsDialogAggregate),
  ratingsDialogAggregatePreviousPeriod: createFetchErrorSelector(getGraphs.ratingsDialogAggregatePreviousPeriod),
  ratingsOverTime: createFetchErrorSelector(getGraphs.ratingsOverTime),
  surveyCount: createFetchErrorSelector(getGraphs.surveyCount),
  surveyAggregates: createFetchErrorSelector(getGraphs.surveyAggregates),
  notesCount: createFetchErrorSelector(getGraphs.notesCount),
  notesAggregates: createFetchErrorSelector(getGraphs.notesAggregates),
  dailyActiveUsers: createFetchErrorSelector(getGraphs.dailyActiveUsers),
  monthlyActiveUsers: createFetchErrorSelector(getGraphs.monthlyActiveUsers),
  retention: createFetchErrorSelector(getGraphs.retention),
  reviewsCount: createFetchErrorSelector(getGraphs.reviewsCount),
  inboundMessageCount: createFetchErrorSelector(getGraphs.inboundMessageCount),
  interactionCount: createFetchErrorSelector(getGraphs.interactionCount),
  ratingsDialogOvertime: createFetchErrorSelector(getGraphs.ratingsDialogOvertime),
};

export const loveRatioAggregateData = createDataSelector(getGraphs.loveRatioAggregate);
export const loveRatioPreviousPeriodData = createDataSelector(getGraphs.loveRatioPreviousPeriod);
export const loveRatioOverTimeData = createDataSelector(getGraphs.loveRatioOverTime);

export const getGraphData = {
  appleRatingsDialogAggregate: createDataSelector(getGraphs.appleRatingsDialogAggregate),
  appleRatingsDialogAggregatePreviousPeriod: createDataSelector(getGraphs.appleRatingsDialogAggregatePreviousPeriod),
  appleRatingsDialogOvertime: createDataSelector(getGraphs.appleRatingsDialogOvertime),
  inAppRatingsDialogAggregate: createDataSelector(getGraphs.inAppRatingsDialogAggregate),
  inAppRatingsDialogAggregatePreviousPeriod: createDataSelector(getGraphs.inAppRatingsDialogAggregatePreviousPeriod),
  inAppRatingsDialogOvertime: createDataSelector(getGraphs.inAppRatingsDialogOvertime),
  averageRatings: createDataSelector(getGraphs.averageRatings),
  loveRatioAggregate: loveRatioAggregateData,
  loveRatioPreviousPeriod: loveRatioPreviousPeriodData,
  loveRatioOverTime: loveRatioOverTimeData,
  ratingsDialogAggregate: createDataSelector(getGraphs.ratingsDialogAggregate),
  ratingsDialogAggregatePreviousPeriod: createDataSelector(getGraphs.ratingsDialogAggregatePreviousPeriod),
  ratingsOverTime: createDataSelector(getGraphs.ratingsOverTime),
  surveyCount: createDataSelector(getGraphs.surveyCount),
  surveyAggregates: createDataSelector(getGraphs.surveyAggregates),
  notesCount: createDataSelector(getGraphs.notesCount),
  notesAggregates: createDataSelector(getGraphs.notesAggregates),
  dailyActiveUsers: createDataSelector(getGraphs.dailyActiveUsers),
  monthlyActiveUsers: createDataSelector(getGraphs.monthlyActiveUsers),
  retention: createDataSelector(getGraphs.retention),
  inboundMessageCount: createDataSelector(getGraphs.inboundMessageCount),
  interactionCount: createDataSelector(getGraphs.interactionCount),
  ratingsDialogOvertime: createDataSelector(getGraphs.ratingsDialogOvertime),
};

export const getCurrentStoreAppAllTimeReviewCounts = createSelector(
  getCurrentStoreApp, propOr(0, 'all_time_reviews')
);
export const getCurrentStoreAppCurrentReviewCounts = createSelector(
  getCurrentStoreApp, propOr(0, 'current_reviews')
);

export const getProfile = createSelector(
  getDashboard,
  dashboard => dashboard.profile || {}
);

export const getChangePassword = createSelector(
  getDashboard,
  dashboard => dashboard.changePassword || {}
);
