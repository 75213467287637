import { createActions } from 'redux-actions';
import moment from 'moment';
import { appIdIdentity, jsonIdentity, errorIdentity } from './utils/identities';
import { getCurrentAppId, getWentLiveOn } from '../selectors/current_app';
import { getDashboardEndDate } from '../selectors/dashboard';

export const FETCH_RETENTION_PENDING = 'FETCH_RETENTION_PENDING';
export const FETCH_RETENTION_SUCCESS = 'FETCH_RETENTION_SUCCESS';
export const FETCH_RETENTION_FAILURE = 'FETCH_RETENTION_FAILURE';
export const { fetchRetentionPending, fetchRetentionSuccess, fetchRetentionFailure } = createActions({
  [FETCH_RETENTION_PENDING]: [
    ({ startDate, endDate, period }) => ({ startDate, endDate, period }),
    appIdIdentity,
  ],
  [FETCH_RETENTION_SUCCESS]: [
    jsonIdentity,
    ({ appId, wentLiveOn }) => ({ appId, wentLiveOn }),
  ],
  [FETCH_RETENTION_FAILURE]: [
    errorIdentity,
    appIdIdentity,
  ],
});

export const fetchRetention = () => (dispatch, getState, { api }) => {
  const state = getState();
  const appId = getCurrentAppId(state);
  const endDate = getDashboardEndDate(state);
  const end = moment(endDate).utc().add(5, 'months').format('YYYY-M-D');
  const start = moment(endDate).utc().subtract(5, 'months').format('YYYY-M-D');
  const wentLiveOn = getWentLiveOn(state);

  dispatch(fetchRetentionPending({ start, end, period: 'monthly', appId }));
  return api.fetchRetention(start, end, 'monthly', appId)
    .then(json => { dispatch(fetchRetentionSuccess({ appId, json, wentLiveOn })); })
    .catch(error => { dispatch(fetchRetentionFailure({ appId, error })); });
};
