import moment from 'moment';
import { Time } from './Time';

export const daysAgo = (days = 30): Date => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return date;
};

/**
 * Return correct range for last period
 * 02.25.2020 - 7days => Should be range [02.19.2020 - 02.25.2020]
 * Because of days: [19, 20, 21, 22, 23, 24, 25]
 */
export const daysAgoInbound = (days: number) => Time.create(daysAgo(days - 1)).startOf('day');

/**
 * Histogram chart converts UTC values back into local time, so the UTC offset needs to be added in order for the dates to align with other graphs
 *
 * @param {number} epochDate The date timestamp to start from.
 * @returns {Date} The date with the UTC offset applied.
 */
export function addUtcOffsetToEpoch(epochDate: number): Date {
  const currentTimeZoneHoursOffset: number = moment().utcOffset() / 60;
  return moment
    .utc(epochDate)
    .startOf('day')
    .add(-currentTimeZoneHoursOffset, 'hours')
    .toDate();
}
