/* eslint-disable consistent-return */
import { Action } from 'redux';
import { daysAgo } from 'src/utils/time';
import {
  getCurrentAppId,
  getCurrentAppAllEventsIsBootstrapped,
  getCurrentAppAllEventsIsLoading,
  getCurrentOrgId,
} from 'src/selectors/current_app';
import { EventStateService } from 'src/services';
import { DispatchFunc, GetState } from 'src/reducers';
import { AppEventItem, AppEventList, SimpleEventItem } from 'src/reducers/events';

export interface EventsApi {
  fetchEvents: (appId: string, queryString: string) => Promise<any>;
  createEvent: (appId: string, label: string) => Promise<any>;
  updateEvent: (appId: string, label: string, archive: boolean) => Promise<any>;
  deleteEvent: (appId: string, label: string) => Promise<any>;
  fetchEventsTimeseries: (appId: string, labels: string[], start: number, end: number) => Promise<any>;
}

export enum EventsActions {
  REQUEST_EVENTS = 'REQUEST_EVENTS',
  RECEIVE_EVENTS = 'RECEIVE_EVENTS',
  ERRORED_EVENTS = 'ERRORED_EVENTS',

  BOOTSTRAP_ALL_EVENTS_PENDING = 'BOOTSTRAP_ALL_EVENTS_PENDING',
  BOOTSTRAP_ALL_EVENTS_SUCCESS = 'BOOTSTRAP_ALL_EVENTS_SUCCESS',

  FETCH_ALL_EVENTS_PENDING = 'FETCH_ALL_EVENTS_PENDING',
  FETCH_ALL_EVENTS_SUCCESS = 'FETCH_ALL_EVENTS_SUCCESS',
  FETCH_ALL_EVENTS_ERROR = 'FETCH_ALL_EVENTS_ERROR',

  REQUEST_CREATE_EVENT = 'REQUEST_CREATE_EVENT',
  RECEIVE_CREATE_EVENT = 'RECEIVE_CREATE_EVENT',
  ERRORED_CREATE_EVENT = 'ERRORED_CREATE_EVENT',

  REQUEST_UPDATE_EVENT = 'REQUEST_UPDATE_EVENT',
  RECEIVE_UPDATE_EVENT = 'RECEIVE_UPDATE_EVENT',
  ERRORED_UPDATE_EVENT = 'ERRORED_UPDATE_EVENT',

  REQUEST_DELETE_EVENT = 'REQUEST_DELETE_EVENT',
  RECEIVE_DELETE_EVENT = 'RECEIVE_DELETE_EVENT',
  ERRORED_DELETE_EVENT = 'ERRORED_DELETE_EVENT',

  REQUEST_EVENT_TIMESERIES = 'REQUEST_EVENT_TIMESERIES',
  RECEIVE_EVENT_TIMESERIES = 'RECEIVE_EVENT_TIMESERIES',
  ERRORED_EVENT_TIMESERIES = 'ERRORED_EVENT_TIMESERIES',

  FETCH_MULTUPLE_APPS_EVENTS_PENDING = 'FETCH_MULTUPLE_APPS_EVENTS_PENDING',
  FETCH_MULTUPLE_APPS_EVENTS_SUCCESS = 'FETCH_MULTUPLE_APPS_EVENTS_SUCCESS',
  FETCH_MULTUPLE_APPS_EVENTS_ERROR = 'FETCH_MULTUPLE_APPS_EVENTS_ERROR',

  CREATE_MULTUPLE_APPS_EVENTS_PENDING = 'CREATE_MULTUPLE_APPS_EVENTS_PENDING',
  CREATE_MULTUPLE_APPS_EVENTS_SUCCESS = 'CREATE_MULTUPLE_APPS_EVENTS_SUCCESS',
  CREATE_MULTUPLE_APPS_EVENTS_ERROR = 'CREATE_MULTUPLE_APPS_EVENTS_ERROR',
}

export type EventsReducerAction = Action<EventsActions> & {
  payload:
    | Partial<{
        appId: string;
        orgId: string;
        events: { items: SimpleEventItem[] } | AppEventList[] | AppEventItem[];
        start: Date | number;
        end: Date | number;
        queryHash: Record<string, string | boolean>;
      }>
    | string
    | string[];
  error?: Error | boolean;
} & { meta?: Record<string, string | boolean | Date | number> | undefined };

export type EventsDispatch = DispatchFunc<EventsReducerAction>;

export const events = {
  bootstrapAllEvents: () => {
    return async (dispatch: EventsDispatch, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);
      const isBootstrapped = getCurrentAppAllEventsIsBootstrapped(state);
      const isLoading = getCurrentAppAllEventsIsLoading(state);

      dispatch({ type: EventsActions.BOOTSTRAP_ALL_EVENTS_PENDING, payload: { appId } });

      if (isBootstrapped || isLoading) {
        dispatch({ type: EventsActions.BOOTSTRAP_ALL_EVENTS_SUCCESS, payload: { appId } });
        return;
      }

      dispatch({ type: EventsActions.FETCH_ALL_EVENTS_PENDING, payload: { appId } });

      return EventStateService.getTargetingEvents(appId, false, 'last_seen_at', 'desc')
        .then((events) => dispatch({ type: EventsActions.FETCH_ALL_EVENTS_SUCCESS, payload: { appId, events } }))
        .catch((error) => dispatch({ type: EventsActions.FETCH_ALL_EVENTS_ERROR, payload: { appId }, error }));
    };
  },

  fetchEvents: (
    appId: string,
    queryHash: Record<string, string | boolean>,
    metaHash: Record<string, string | boolean>,
    start = daysAgo(30),
    end = new Date(),
  ) => {
    return async (dispatch: EventsDispatch) => {
      // TODO: Remove start/end conversion once these are standardized.
      const start_date = ((start as any) / 1000).toFixed(0);
      const end_date = ((end as any) / 1000).toFixed(0);

      const keys = Object.keys(queryHash);
      const queryString = keys.reduce((str, key) => {
        if (typeof queryHash[key] !== 'undefined') {
          if (key === 'starts_after') {
            str += `&${key}=${encodeURIComponent(queryHash[key])}`;
          } else if (key === 'include_archived') {
            if (queryHash[key]) str += `&${key}=true`;
          } else {
            str += `&${key}=${queryHash[key]}`;
          }
        }
        return str;
      }, `start_date=${start_date}&end_date=${end_date}`);

      dispatch({
        type: EventsActions.REQUEST_EVENTS,
        payload: { start, end, queryHash },
        meta: { appId, ...metaHash },
      });
      return EventStateService.fetchEvents(appId, queryString)
        .then((json) => dispatch({ type: EventsActions.RECEIVE_EVENTS, payload: json, meta: { appId } }))
        .catch((error) =>
          dispatch({ type: EventsActions.ERRORED_EVENTS, payload: error, error: true, meta: { appId } }),
        );
    };
  },

  fetchMultipleAppsEvents: (apps: string[]) => {
    return async (dispatch: EventsDispatch, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());

      dispatch({ type: EventsActions.FETCH_MULTUPLE_APPS_EVENTS_PENDING, payload: {} });

      return EventStateService.getMultipleAppsTargetingEvents(orgId, apps, false, 'last_seen_at', 'desc')
        .then((events) => dispatch({ type: EventsActions.FETCH_MULTUPLE_APPS_EVENTS_SUCCESS, payload: { events } }))
        .catch((error) => dispatch({ type: EventsActions.FETCH_MULTUPLE_APPS_EVENTS_ERROR, payload: {}, error }));
    };
  },

  createNewEvent: (appId: string, label: string) => {
    return async (dispatch: EventsDispatch) => {
      dispatch({ type: EventsActions.REQUEST_CREATE_EVENT, payload: label, meta: { appId, label } });
      return EventStateService.createEvent(appId, label)
        .then((json) =>
          dispatch({
            type: EventsActions.RECEIVE_CREATE_EVENT,
            payload: Object.keys(json).length ? { ...json, archived: false } : json,
            meta: { appId, label },
          }),
        )
        .catch((error) =>
          dispatch({ type: EventsActions.ERRORED_CREATE_EVENT, payload: error, error: true, meta: { appId, label } }),
        );
    };
  },

  createNewEventCurrentApp: (label: string) => {
    return async (dispatch: EventsDispatch, getState: GetState) => {
      const appId = getCurrentAppId(getState());
      return dispatch(events.createNewEvent(appId, label) as any);
    };
  },

  createMultipleAppsNewEvent: (apps: string[], label: string) => {
    return async (dispatch: EventsDispatch, getState: GetState) => {
      const orgId = getCurrentOrgId(getState());

      dispatch({ type: EventsActions.CREATE_MULTUPLE_APPS_EVENTS_PENDING, payload: {} });

      return EventStateService.createMultipleAppsEvent(orgId, apps, label)
        .then((events) => dispatch({ type: EventsActions.CREATE_MULTUPLE_APPS_EVENTS_SUCCESS, payload: { events } }))
        .catch((error: Error) =>
          dispatch({ type: EventsActions.CREATE_MULTUPLE_APPS_EVENTS_ERROR, payload: {}, error }),
        );
    };
  },

  updateArchivedEvents: (appId: string, label: string, archive = true) => {
    return async (dispatch: EventsDispatch) => {
      dispatch({ type: EventsActions.REQUEST_UPDATE_EVENT, payload: label, meta: { appId, label, archive } });
      return EventStateService.updateEvent(appId, label, archive)
        .then((json) =>
          dispatch({
            type: EventsActions.RECEIVE_UPDATE_EVENT,
            payload: { ...json, archive },
            meta: { appId, label },
          }),
        )
        .catch((error) =>
          dispatch({
            type: EventsActions.ERRORED_UPDATE_EVENT,
            payload: error,
            error: true,
            meta: { appId, label, archive },
          }),
        );
    };
  },

  updateUnseenEvents: (appId: string, label: string) => {
    return async (dispatch: EventsDispatch) => {
      dispatch({ type: EventsActions.REQUEST_DELETE_EVENT, payload: label, meta: { appId, label } });
      return EventStateService.deleteEvent(appId, label)
        .then((json) => dispatch({ type: EventsActions.RECEIVE_DELETE_EVENT, payload: json, meta: { appId, label } }))
        .catch((error) =>
          dispatch({ type: EventsActions.ERRORED_DELETE_EVENT, payload: error, error: true, meta: { appId, label } }),
        );
    };
  },

  fetchEventsTimeseries: (appId: string, labels: string[] = [], startDate = daysAgo(30), endDate = new Date()) => {
    return async (dispatch: EventsDispatch) => {
      const start = new Date(startDate).valueOf();
      const end = new Date(endDate).valueOf();
      dispatch({
        type: EventsActions.REQUEST_EVENT_TIMESERIES,
        payload: labels,
        meta: { appId, start, end },
      });
      return EventStateService.fetchEventsTimeseries(appId, labels, start, end)
        .then((json) =>
          dispatch({ type: EventsActions.RECEIVE_EVENT_TIMESERIES, payload: json, meta: { appId, start, end } }),
        )
        .catch((error) =>
          dispatch({
            type: EventsActions.ERRORED_EVENT_TIMESERIES,
            payload: error,
            error: true,
            meta: { appId, start, end },
          }),
        );
    };
  },
};
