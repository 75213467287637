import { createAction, createActions } from 'redux-actions';

import { appIdIdentity, jsonIdentity, errorIdentity } from './utils/identities';
import sdkEngage from '../utils/sdk_engage';

// Fetching Group (single)
export const REQUEST_FETCH_GROUP = 'REQUEST_FETCH_GROUP';
export const RECEIVE_FETCH_GROUP = 'RECEIVE_FETCH_GROUP';
export const ERRORED_FETCH_GROUP = 'ERRORED_FETCH_GROUP';
export const { requestFetchGroup, receiveFetchGroup, erroredFetchGroup } = createActions({
  REQUEST_FETCH_GROUP: [
    ({ groupId }) => ({ groupId }),
    appIdIdentity,
  ],
  RECEIVE_FETCH_GROUP: [
    jsonIdentity,
    ({ appId, groupId }) => ({ appId, groupId }),
  ],
  ERRORED_FETCH_GROUP: [
    errorIdentity,
    ({ appId, groupId }) => ({ appId, groupId }),
  ],
});
export const fetchGroup = (appId, groupId) => (dispatch, getState, { api }) => {
  dispatch(requestFetchGroup({ appId, groupId }));
  return api.fetchGroup(appId, groupId)
    .then(json => dispatch(receiveFetchGroup({ appId, groupId, json })))
    .catch(error => dispatch(erroredFetchGroup({ appId, groupId, error })));
};

// Fetching Group Conversations
export const REQUEST_FETCH_GROUP_CONVERSATIONS = 'REQUEST_FETCH_GROUP_CONVERSATIONS';
export const RECEIVE_FETCH_GROUP_CONVERSATIONS = 'RECEIVE_FETCH_GROUP_CONVERSATIONS';
export const ERRORED_FETCH_GROUP_CONVERSATIONS = 'ERRORED_FETCH_GROUP_CONVERSATIONS';
export const { requestFetchGroupConversations, receiveFetchGroupConversations, erroredFetchGroupConversations } = createActions({
  REQUEST_FETCH_GROUP_CONVERSATIONS: [
    ({ groupId }) => ({ groupId }),
    appIdIdentity,
  ],
  RECEIVE_FETCH_GROUP_CONVERSATIONS: [
    jsonIdentity,
    ({ appId, groupId }) => ({ appId, groupId }),
  ],
  ERRORED_FETCH_GROUP_CONVERSATIONS: [
    errorIdentity,
    ({ appId, groupId }) => ({ appId, groupId }),
  ],
});
export const fetchGroupConversations = (appId, groupId) => (dispatch, getState, { api }) => {
  dispatch(requestFetchGroupConversations({ appId, groupId }));
  return api.fetchGroupConversations(appId, groupId)
    .then(json => dispatch(receiveFetchGroupConversations({ appId, groupId, json })))
    .catch(error => dispatch(erroredFetchGroupConversations({ appId, groupId, error })));
};

// Fetching Groups (all)
export const REQUEST_FETCH_GROUPS = 'REQUEST_FETCH_GROUPS';
export const RECEIVE_FETCH_GROUPS = 'RECEIVE_FETCH_GROUPS';
export const ERRORED_FETCH_GROUPS = 'ERRORED_FETCH_GROUPS';
export const { requestFetchGroups, receiveFetchGroups, erroredFetchGroups } = createActions({
  REQUEST_FETCH_GROUPS: [
    appIdIdentity,
    appIdIdentity,
  ],
  RECEIVE_FETCH_GROUPS: [
    jsonIdentity,
    appIdIdentity,
  ],
  ERRORED_FETCH_GROUPS: [
    errorIdentity,
    appIdIdentity,
  ],
});
export const fetchGroups = (appId) => (dispatch, getState, { api }) => {
  dispatch(requestFetchGroups({ appId }));
  return api.fetchGroups(appId)
    .then(json => dispatch(receiveFetchGroups({ appId, json })))
    .catch(error => dispatch(erroredFetchGroups({ appId, error })));
};

// Adding Conversation to Group
export const REQUEST_ADD_CONVERSATION_TO_GROUP = 'REQUEST_ADD_CONVERSATION_TO_GROUP';
export const RECEIVE_ADD_CONVERSATION_TO_GROUP = 'RECEIVE_ADD_CONVERSATION_TO_GROUP';
export const ERRORED_ADD_CONVERSATION_TO_GROUP = 'ERRORED_ADD_CONVERSATION_TO_GROUP';
export const { requestAddConversationToGroup, receiveAddConversationToGroup, erroredAddConversationToGroup } = createActions({
  REQUEST_ADD_CONVERSATION_TO_GROUP: [
    ({ appId, groupId, conversationId }) => ({ appId, groupId, conversationId }),
    appIdIdentity,
  ],
  RECEIVE_ADD_CONVERSATION_TO_GROUP: [
    jsonIdentity,
    ({ appId, groupId, conversationId }) => ({ appId, groupId, conversationId }),
  ],
  ERRORED_ADD_CONVERSATION_TO_GROUP: [
    errorIdentity,
    ({ appId, groupId, conversationId }) => ({ appId, groupId, conversationId }),
  ],
});

// Removing Conversation from Group
export const REQUEST_REMOVE_CONVERSATION_FROM_GROUP = 'REQUEST_REMOVE_CONVERSATION_FROM_GROUP';
export const RECEIVE_REMOVE_CONVERSATION_FROM_GROUP = 'RECEIVE_REMOVE_CONVERSATION_FROM_GROUP';
export const ERRORED_REMOVE_CONVERSATION_FROM_GROUP = 'ERRORED_REMOVE_CONVERSATION_FROM_GROUP';
export const { requestRemoveConversationFromGroup, receiveRemoveConversationFromGroup, erroredRemoveConversationFromGroup } = createActions({
  REQUEST_REMOVE_CONVERSATION_FROM_GROUP: [
    ({ appId, groupId, conversationId }) => ({ appId, groupId, conversationId }),
    appIdIdentity,
  ],
  RECEIVE_REMOVE_CONVERSATION_FROM_GROUP: [
    jsonIdentity,
    ({ appId, groupId, conversationId }) => ({ appId, groupId, conversationId }),
  ],
  ERRORED_REMOVE_CONVERSATION_FROM_GROUP: [
    errorIdentity,
    ({ appId, groupId, conversationId }) => ({ appId, groupId, conversationId }),
  ],
});
export const removeConversationFromGroup = (appId, groupId, conversationId) => (dispatch, getState, { api }) => {
  dispatch(requestRemoveConversationFromGroup({ appId, groupId, conversationId }));
  return api.removeConversationFromGroup(appId, groupId, conversationId)
    .then(json => dispatch(receiveRemoveConversationFromGroup({ appId, groupId, conversationId, json })))
    .catch(error => dispatch(erroredRemoveConversationFromGroup({ appId, groupId, conversationId, error })));
};

// Create Group
export const REQUEST_CREATE_GROUP = 'REQUEST_CREATE_GROUP';
export const RECEIVE_CREATE_GROUP = 'RECEIVE_CREATE_GROUP';
export const ERRORED_CREATE_GROUP = 'ERRORED_CREATE_GROUP';
export const { requestCreateGroup, receiveCreateGroup, erroredCreateGroup } = createActions({
  REQUEST_CREATE_GROUP: [
    ({ data }) => (data),
    appIdIdentity,
  ],
  RECEIVE_CREATE_GROUP: [
    jsonIdentity,
    appIdIdentity,
  ],
  ERRORED_CREATE_GROUP: [
    errorIdentity,
    ({ appId, data }) => ({ appId, data }),
  ],
});
export const createGroup = (appId, data) => (dispatch, getState, { api }) => {
  sdkEngage('new_group');
  dispatch(requestCreateGroup({ appId, data }));
  return api.createGroup(appId, data)
    .then(json => dispatch(receiveCreateGroup({ appId, json })))
    .catch(error => dispatch(erroredCreateGroup({ appId, data, error })));
};

// Update Group
export const REQUEST_UPDATE_GROUP = 'REQUEST_UPDATE_GROUP';
export const RECEIVE_UPDATE_GROUP = 'RECEIVE_UPDATE_GROUP';
export const ERRORED_UPDATE_GROUP = 'ERRORED_UPDATE_GROUP';
export const { requestUpdateGroup, receiveUpdateGroup, erroredUpdateGroup } = createActions({
  REQUEST_UPDATE_GROUP: [
    ({ appId, groupId, data }) => ({ appId, groupId, data }),
    appIdIdentity,
  ],
  RECEIVE_UPDATE_GROUP: [
    jsonIdentity,
    ({ appId, groupId, data }) => ({ appId, groupId, data }),
  ],
  ERRORED_UPDATE_GROUP: [
    errorIdentity,
    ({ appId, groupId, data }) => ({ appId, groupId, data }),
  ],
});
export const updateGroup = (appId, groupId, data) => (dispatch, getState, { api }) => {
  dispatch(requestUpdateGroup({ appId, groupId, data }));
  return api.updateGroup(appId, groupId, data)
    .then(json => dispatch(receiveUpdateGroup({ appId, groupId, json })))
    .catch(error => dispatch(erroredUpdateGroup({ appId, groupId, data, error })));
};

// Delete Groups
export const REQUEST_DELETE_GROUP = 'REQUEST_DELETE_GROUP';
export const RECEIVE_DELETE_GROUP = 'RECEIVE_DELETE_GROUP';
export const ERRORED_DELETE_GROUP = 'ERRORED_DELETE_GROUP';
export const { requestDeleteGroup, receiveDeleteGroup, erroredDeleteGroup } = createActions({
  REQUEST_DELETE_GROUP: [
    ({ groupId }) => ({ groupId }),
    appIdIdentity,
  ],
  RECEIVE_DELETE_GROUP: [
    ({ appId, groupId }) => ({ appId, groupId }),
    appIdIdentity,
  ],
  ERRORED_DELETE_GROUP: [
    errorIdentity,
    ({ appId, groupId }) => ({ appId, groupId }),
  ],
});
export const deleteGroup = (appId, groupId) => (dispatch, getState, { api }) => {
  dispatch(requestDeleteGroup({ appId, groupId }));
  return api.deleteGroup(appId, groupId)
    .then(_json => dispatch(receiveDeleteGroup({ appId, groupId })))
    .catch(error => dispatch(erroredDeleteGroup({ appId, groupId, error })));
};

export const addConversationToGroup = (appId, groupId, conversationId) => (dispatch, getState, { api }) => {
  dispatch(requestAddConversationToGroup({ appId, groupId, conversationId }));
  return api.addConversationToGroup(appId, groupId, conversationId)
    .then(json => dispatch(receiveAddConversationToGroup({ appId, groupId, conversationId, json })))
    .catch(error => dispatch(erroredAddConversationToGroup({ appId, groupId, conversationId, error })));
};

export const createGroupAndAddConversation = (appId, conversationId, data) => (dispatch, getState, { api }) => {
  dispatch(requestCreateGroup({ appId, data }));
  return api.createGroup(appId, data)
    .then(json => {
      dispatch(receiveCreateGroup({ appId, json: data }));
      dispatch(requestAddConversationToGroup({ appId, groupId: json.id, conversationId }));
      return api.addConversationToGroup(appId, json.id, conversationId);
    })
    .then(json => dispatch(receiveAddConversationToGroup({ appId, groupId: json.id, conversationId, json })))
    .catch(error => dispatch(erroredCreateGroup({ appId, data, error })));
};

export const TOGGLE_GROUP_SELECTED = 'TOGGLE_GROUP_SELECTED';
export const toggleSelectGroup = createAction(
  TOGGLE_GROUP_SELECTED,
  ({ groupId }) => ({ groupId }),
  appIdIdentity,
);
