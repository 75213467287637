import { Component } from 'react';
import fullPath from '../../utils/full_path';
import JWT from '../../utils/jwt';
import Lock from './auth0';

class ForgotPassword extends Component {
  componentDidMount() {
    if (JWT.valid()) {
      window.location.assign(fullPath('/loading'));
      return;
    }
    this.auth0Login();
  }

  auth0Login() {
    Lock.show();

    // This is getting rendered outside of the react dom tree, thus can't be JSX.
    const banner = document.createElement('div');
    banner.className += 'auth0-banner';
    banner.innerHTML = 'Welcome to our upgraded registration experience! If you have any questions or need assistance, drop us a line at support@apptentive.com.';
    document.body.appendChild(banner);
  }

  render() {
    return null;
  }
}

export default ForgotPassword;
