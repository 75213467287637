import { Action, AnyAction } from 'redux';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isDevMode } from '../../utils/environment';

let instance: StoreActions | null = null;

export interface SubscribeDisposer {
  dispose(): void;
}

export type Payload<T = any> = Action<any> & { payload: T; error?: any; meta?: any };

export class StoreActions {
  static getInstance = getInstance;

  private subject = new Subject();
  private listeners: Map<Action['type'], Subscription[]> = new Map();

  private addListener = (type: Action['type'], subscriber: Subscription): Subscription[] => {
    const typeListeners = this.listeners.get(type) || [];
    typeListeners.push(subscriber);
    if (!this.listeners.get(type)) {
      this.listeners.set(type, typeListeners);
    }
    return typeListeners;
  };

  emit = (action: AnyAction) => this.subject.next(action);

  on(type: Action['type'], handler: (action: Payload) => void): SubscribeDisposer {
    const action$ = this.subject.pipe(filter<Action>((action) => action.type === type));
    const subscriber = action$.subscribe(handler);
    const listeners = this.addListener(type, subscriber);
    return {
      dispose: () => {
        subscriber.unsubscribe();
        listeners.splice(listeners.indexOf(subscriber), 1);
      },
    };
  }

  off(type: Action['type']) {
    (this.listeners.get(type) || []).forEach((listener) => listener.unsubscribe());
    this.listeners.delete(type);
  }

  unsubscribeAll() {
    Array.from(this.listeners.keys()).forEach((type) => this.off(type));
    this.listeners.clear();
  }
}

function getInstance() {
  if (!instance) {
    instance = new StoreActions();
    if (isDevMode()) {
      Object.assign(window, { StoreActions: instance });
    }
  }
  return instance;
}
