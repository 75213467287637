import { createSelector } from 'reselect';
import { indexBy, prop } from 'ramda';

import * as ContactForm from './contact_form';
import * as NotificationBanner from './notification_banner';

// Contact Form
const getContactForm = (state) => state.contactForm;
export const isContactFormOpen = createSelector(
  getContactForm,
  contactForm => ContactForm.isOpen(contactForm)
);

// Banners
const getNotificationBanner = state => state.notificationBanner;
export const isNotificationOpen = createSelector(
  getNotificationBanner,
  notificationBanner => NotificationBanner.isOpen(notificationBanner)
);

// Roll Up
export const getRollupStatus = state => state.rollup || { data: [] };
export const getRollupLoading = createSelector(
  getRollupStatus,
  rollup => rollup.loading
);
export const getRollupError = createSelector(
  getRollupStatus,
  rollup => rollup.error
);
export const getRollup = createSelector(
  getRollupStatus,
  rollup => indexBy(prop('id'), rollup.data)
);

// Email Preferences
export const getEmailPreferencesRoot = state => state.emailPreferences || {};
export const getEmailPreferences = createSelector(
  getEmailPreferencesRoot,
  emailPreferences => emailPreferences.emailPreferences || []
);
export const getEmailPreferencesErrors = createSelector(
  getEmailPreferencesRoot,
  emailPreferences => emailPreferences.fetchError || false
);
export const getEmailPreferencesLoading = createSelector(
  getEmailPreferencesRoot,
  emailPreferences => emailPreferences.loading || false
);

// App Memberships
export const getAppMembershipsRoot = state => state.appMemberships || {};
export const getAppMemberships = createSelector(
  getAppMembershipsRoot,
  appMembership => appMembership.appMemberships || []
);
export const getAppMembershipsLoading = createSelector(
  getAppMembershipsRoot,
  appMembership => appMembership.loading || false
);
export const getAppMembershipsErrored = createSelector(
  getAppMembershipsRoot,
  appMembership => appMembership.fetchError || false
);

// Downloads
export const getDownloadsRoot = state => state.downloads || {};
export const getDownloads = createSelector(
  getDownloadsRoot,
  downloads => downloads.downloads || []
);
export const getDownloadsErrors = createSelector(
  getDownloadsRoot,
  downloads => downloads.fetchError || false
);
export const getDownloadsLoading = createSelector(
  getDownloadsRoot,
  downloads => downloads.loading || false
);
