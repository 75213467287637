import React, { Component } from 'react';
import { Icon } from 'src/components/molecules';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import createStore from '../store/create_store';
import Footer from '../../components/footer';

import '../../components/styles/base.scss';
import '../../components/styles/auth.scss';
import '../../components/styles/onboarding.scss';

// eslint-disable-next-line react/prefer-stateless-function
class AuthRoot extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    return (
      <Provider store={createStore({})}>
        {window.location.pathname === '/loading' ? (
          <div className="alchemer-container">
            {this.props.children}
          </div>
        ) : (
          <div className="onboarding-container auth login">
            <div className="container">
              <div className="apptentive-logo">
                <Icon name="alchemerLogo" className="alchemer" />
              </div>
              {this.props.children}
            </div>
            <Footer />
          </div>
        )}
      </Provider>
    );
  }
}

export default AuthRoot;
