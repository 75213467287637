import React, { useRef } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { bemPrefix } from 'src/utils';
import { Feature, FeatureContext, NoAudience } from 'src/interactions/newinteractions/common/limited-audience';

import { ToolbarPlugin } from './plugins/toolbar-plugin';
import { editorConfig } from './helpers';

const bem = bemPrefix('rich-text-mock');

const noop = () => null;

export const RichTextMock = ({ context }: { context: FeatureContext }) => {
  const toolbarRef = useRef(null);

  return (
    <NoAudience className={bem()} feature={Feature.RICH_TEXT} context={context} targetRef={toolbarRef}>
      <LexicalComposer initialConfig={editorConfig as any}>
        <ToolbarPlugin value={''} onChange={noop} disabled toolbarRef={toolbarRef} />
      </LexicalComposer>
    </NoAudience>
  );
};
