/* eslint-disable no-use-before-define, @typescript-eslint/no-use-before-define */

import {
  SurveyQuestionType,
  QuestionAnswerChoice,
  InteractionTrigger,
  InteractionItem,
  ISurveyQuestionSet,
} from 'src/types/core';

export interface SurveyV12Item extends Pick<InteractionItem, 'active' | 'criteria'> {
  id: string;
  appId: string;
  name: string;
  displayType?: string;
  renderAs: string;
  templateLocked?: boolean;
  fromTemplate?: string;
  createdAt?: string;
  updatedAt?: string;
  required?: boolean;
  multipleResponses?: boolean;
  showSuccessMessage?: boolean;
  codePoints?: InteractionItem['code_points'];
  selfTargeting?: boolean;
  contactUrl?: string;
  contactUrlText?: string;
  disclaimerText?: string;
  showTermsAndConditions?: boolean;
  showDisclaimer?: boolean;
  termsAndConditionsLabel?: string;
  termsAndConditionsLink?: string;
  styleName?: string;
  responseRate?: number;
  responseRateCachedTime?: string;
  questionSets: ISurveyQuestionSet[];
  date?: string;
  successMessage?: string;
  responseCount: number;
  triggeredBy?: InteractionTrigger[];
  title?: string;
  terms?: string;
  description?: string | null;
  startTime?: InteractionItem['start_time'];
  endTime?: InteractionItem['end_time'];
  respMax?: number | null;
  viewPeriod?: number;
  viewCount?: number;
  viewLimit?: number;
  submitButtonText: string;
  nextButtonText: string;
  minApiVersion?: number;
}

export { SurveyQuestionType, QuestionAnswerChoice };

export interface SurveyResponseStat {
  id: string;
  answer_count: number;
  answers: { choice: string; count: number }[];
  other_choice: boolean;
  other_text_count: number;
  question: string;
  required: boolean;
  type: SurveyQuestionType;
}

export interface SurveyTextAnswers
  extends Pick<SurveyResponseStat, 'answer_count' | 'other_choice' | 'required' | 'type'> {
  id: string; // QuestionId
  answers: { choice: string; created_at: number, qm_session?: string }[];
  question: string;

  // Pagination data for answers
  has_more: boolean;
  limit: number;
  page: number;
}

export interface FetchSurveysV12Resp {
  surveys: SurveyV12Item[];
  page: number;
  total: number;
  limit: number;
  active: boolean;
}

export enum SurveyRenderAs {
  LIST = 'list',
  PAGED = 'paged'
}
