import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { bemPrefix } from 'src/utils/bem';
import { GtmID } from 'src/types/google-tag-manager';
import { Divider } from '../divider';
import { TableList, TableListProps, TableItemProps } from '../table';
import { Icon } from '../icons';
import { SearchInput, SearchRef } from '../search-field';

import './collapsable-list.scss';

export interface CollapsableListProps
  extends Pick<
    TableListProps,
    'items' | 'headers' | 'isLoading' | 'noResultsMessage' | 'renderItem' | 'renderItemField' | 'getSortData'
  > {
  className?: string;
  title: React.ReactNode;
  rightSearchText?: string | number;
  calcItemsVolume?(items: any): number;
  searchPlaceholder?: string;
  expanded?: boolean;
  gtmIdToggle?: GtmID;
  onToggleExpand?(expanded: boolean): void;
  filterBySearch(text: string, item: TableItemProps): void;
}

const bem = bemPrefix('collapsable-list');
const noop = () => undefined;
const isUndefined = (value: any): value is undefined => typeof value === 'undefined';

export const CollapsableList: React.FC<CollapsableListProps> = ({
  className = '',
  title,
  rightSearchText = 0,
  filterBySearch,
  searchPlaceholder = 'Filter by name',
  items,
  gtmIdToggle,
  expanded = false,
  onToggleExpand = noop,
  noResultsMessage = 'No available content for this filter',
  calcItemsVolume = noop,
  ...props
}) => {
  const [isCollapsed, setCollapsed] = useState(!expanded);
  const [query, setQuery] = useState('');
  const searchRef = useRef<SearchRef>(null);
  const onToggleCollapse = useCallback(() => {
    setCollapsed(!isCollapsed);
    setQuery('');
  }, [isCollapsed]);

  useEffect(() => {
    setCollapsed(!expanded);
    setQuery('');
  }, [expanded]);

  useEffect(() => onToggleExpand(!isCollapsed), [isCollapsed]);

  const filteredItems = useMemo(
    () => (query ? items.filter((item) => filterBySearch(query.toLowerCase(), item)) : items),
    [items, query, filterBySearch]
  );

  const getRightText = useMemo(() => rightSearchText || calcItemsVolume(filteredItems), [
    rightSearchText,
    filteredItems,
    calcItemsVolume,
  ]);

  return (
    <div className={`${bem('', [isCollapsed ? '' : 'expanded'])} ${className}`}>
      <div className={bem('title')} onClick={onToggleCollapse} gtm-id={gtmIdToggle}>
        <div className={bem('label')}>{title}</div>
        <Icon name="chevron" className={bem('chevron', { reversedIcon: !isCollapsed })} />
      </div>
      {!isCollapsed && (
        <>
          <div className={bem('sub-title')}>
            <SearchInput
              className={bem('search')}
              onChange={setQuery}
              onSearch={noop}
              iconName="filter"
              submitSearch={false}
              searchRef={searchRef}
              placeholder={searchPlaceholder}
            />
            {!isUndefined(getRightText) && <div className={bem('right-search')}>{getRightText}</div>}
          </div>
          <TableList
            className={bem('table')}
            {...props}
            items={filteredItems}
            noResultsMessage={noResultsMessage}
            noPagination
          />
        </>
      )}
      <Divider className={bem('bottom-divider')} />
    </div>
  );
};
