import { ISurveyQuestion, ISurveyQuestionSet } from 'src/types/core';
import { MultiAppsSurvey, SurveyQuestionType, QuestionAnswerChoice } from './surveys-multi-apps.types';

export const DEFAULT_QUESTION_SET_DATA: ISurveyQuestionSet = {
  logicalId: '',
  order: 0,
  invokes: [],
  questions: [],
};

const DEFAUL_QUESTION_DATA = {
  required: false,
  value: '',
};

export const SURVEY_QUESTIONS: Record<SurveyQuestionType, string> = {
  [SurveyQuestionType.Multichoice]: 'Single Select',
  [SurveyQuestionType.Multiselect]: 'Multiple Select',
  [SurveyQuestionType.Singleline]: 'Free Form',
  [SurveyQuestionType.Range]: 'Range',
  [SurveyQuestionType.Nps]: 'Net Promoter Score®',
};

export const DefaultQuestionAnswerChoice: QuestionAnswerChoice = {
  value: '',
  type: 'select_option',
};

export const DefaultQuestionOtherChoice = {
  other_choice: false,
  other_choice_text: 'Other',
};

export const DefaultQuestions: Record<SurveyQuestionType, ISurveyQuestion> = {
  [SurveyQuestionType.Multichoice]: {
    ...DEFAUL_QUESTION_DATA,
    type: SurveyQuestionType.Multichoice,
    randomize: false,
    answer_choices: [{ ...DefaultQuestionAnswerChoice }, { ...DefaultQuestionAnswerChoice }],
    instructions: 'select one',
    ...DefaultQuestionOtherChoice,
  },
  [SurveyQuestionType.Multiselect]: {
    ...DEFAUL_QUESTION_DATA,
    type: SurveyQuestionType.Multiselect,
    answer_choices: [{ ...DefaultQuestionAnswerChoice }, { ...DefaultQuestionAnswerChoice }],
    min_selections: 0,
    instructions: 'select all that apply',
    ...DefaultQuestionOtherChoice,
  },
  [SurveyQuestionType.Singleline]: {
    ...DEFAUL_QUESTION_DATA,
    type: SurveyQuestionType.Singleline,
    multiline: false,
    freeform_hint: 'Please provide a response',
  },
  [SurveyQuestionType.Range]: {
    ...DEFAUL_QUESTION_DATA,
    type: SurveyQuestionType.Range,
    min: 1,
    max: 10,
    min_label: 'Not at all',
    max_label: 'Completely',
  },
  [SurveyQuestionType.Nps]: {
    ...DEFAUL_QUESTION_DATA,
    type: SurveyQuestionType.Nps,
    min: 0,
    max: 10,
    min_label: 'Not Likely',
    max_label: 'Extremely Likely',
  },
};

export const NEW_EMPTY_SURVEY_MULTI_APPS: Partial<MultiAppsSurvey> = {
  id: undefined,
  orgId: undefined,
  type: undefined,
  createdBy: '',
  interactionData: [],
  required: false,
  name: '',
  title: '',
  description: '',
  questionSetAttributes: [],
};
