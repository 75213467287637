import { createSelector } from 'reselect';
import { State } from '../reducers';
import { InsightsState, AggsType, AggsSentiment, AggsRating } from '../reducers/insights';

export const getInsights = (state: State): InsightsState => state.insights || {};
export const getCurrentSearch = createSelector<State, any, InsightsState['currentSearch']>(
  getInsights,
  ({ currentSearch }) => currentSearch || {}
);

// aggs
export const getAggs = createSelector(
  getInsights,
  ({ aggs }) => aggs || {}
);
export const getFetchAggsPending = createSelector(
  getInsights,
  ({ fetchAggsPending }) => fetchAggsPending || false
);
export const getFetchAggsError = createSelector(
  getInsights,
  ({ fetchAggsError }) => fetchAggsError || false
);

export const getHistogram = createSelector(
  getAggs,
  ({ histogram }) => histogram || []
);
export const getRatingDistribution = createSelector(
  getAggs,
  ({ ratingDistribution }) => ratingDistribution || []
);

export const getSurveyDistribution = createSelector(
  getAggs,
  ({ surveyNamesDistribution }) => surveyNamesDistribution || []
);

// sentiment
export const getSentimentDistribution = createSelector<State, any, InsightsState['aggs']['sentimentDistribution']>(
  getAggs,
  ({ sentimentDistribution }) => sentimentDistribution || []
);
export const getFetchSentimentDistributionPending = createSelector(
  getAggs,
  ({ fetchSentimentDistributionPending }) => fetchSentimentDistributionPending || false
);
export const getFetchSentimentDistributionError = createSelector(
  getAggs,
  ({ fetchSentimentDistributionError }) => fetchSentimentDistributionError || false
);

// types
export const getTypesDistribution = createSelector(
  getAggs,
  ({ typesDistribution }) => typesDistribution || {}
);
export const getFetchTypesDistributionPending = createSelector(
  getAggs,
  ({ fetchTypesDistributionPending }) => fetchTypesDistributionPending || false
);
export const getFetchTypesDistributionError = createSelector(
  getAggs,
  ({ fetchTypesDistributionError }) => fetchTypesDistributionError || false
);

// regions
export const getRegions = createSelector(
  getAggs,
  ({ regions }) => regions || []
);
export const getFetchRegionsPending = createSelector(
  getAggs,
  ({ fetchRegionsPending }) => fetchRegionsPending || false
);
export const getFetchRegionsError = createSelector(
  getAggs,
  ({ fetchRegionsError }) => fetchRegionsError || false
);

export const getRegionsDistribution = createSelector(
  getAggs,
  ({ regionsDistribution }) => regionsDistribution || {}
);
export const getFetchRegionsDistributionPending = createSelector(
  getAggs,
  ({ fetchRegionsDistributionPending }) => fetchRegionsDistributionPending || false
);
export const getFetchRegionsDistributionError = createSelector(
  getAggs,
  ({ fetchRegionsDistributionError }) => fetchRegionsDistributionError || false
);

// data
export const getData = createSelector(
  getInsights,
  ({ data }) => data || []
);
export const getFetchDataPending = createSelector(
  getInsights,
  ({ fetchDataPending }) => fetchDataPending || false
);
export const getFetchDataError = createSelector(
  getInsights,
  ({ fetchDataError }) => fetchDataError || false
);
export const getDataPagination = createSelector(
  getInsights,
  ({ dataPagination }) => dataPagination || {}
);

// phrases
export const getPhrases = createSelector(
  getInsights,
  ({ phrases }) => phrases || { pinned: [], muted: [], default: [] }
);
export const getFetchPhrasesPending = createSelector(
  getInsights,
  ({ fetchPhrasesPending }) => fetchPhrasesPending || false
);
export const getFetchPhrasesError = createSelector(
  getInsights,
  ({ fetchPhrasesError }) => fetchPhrasesError || false
);
export const getUpdatePhrasePending = createSelector(
  getInsights,
  ({ updatePhrasePending }) => updatePhrasePending || false
);
export const getUpdatePhraseError = createSelector(
  getInsights,
  ({ updatePhraseError }) => updatePhraseError || false
);

export const getIsPendingExport = createSelector(
  getInsights,
  ({ exportPending }) => exportPending
);

export const getSavedInsights = createSelector(
  getInsights,
  (state) => [...state.savedInsights]
);

export const getSavedInsightById = createSelector(
  [getSavedInsights, (_store: State, insightsId: string) => insightsId],
  (savedInsights, insightsId) => savedInsights.find((insight) => insight.id === insightsId)
);

export const getSavedInsightsPending = createSelector(
  getInsights,
  (state) => state.savedInsightsPending
);

export const getSelectedTypes = createSelector(
  getCurrentSearch,
  (currentSearch) => (currentSearch.types ? [...currentSearch.types] : [])
);

export const getSelectedRegions = createSelector(
  getCurrentSearch,
  (currentSearch) => (currentSearch.regions ? [...currentSearch.regions] : [])
);

export const getSelectedSentiment = createSelector(
  getCurrentSearch,
  (currentSearch) => currentSearch.sentiment || AggsSentiment.ALL
);

export const getSelectedRating = createSelector(
  getCurrentSearch,
  (currentSearch) => currentSearch.rating || AggsRating.ALL
);

export const getInsightsFormFilters = createSelector(
  getSelectedRegions,
  getSelectedTypes,
  getSelectedSentiment,
  (regions, types, sentiment) => ({ regions, types, sentiment })
);

// count
export const getSelectedCountByDistribution = createSelector(
  getTypesDistribution,
  getCurrentSearch,
  ({ survey, ...typesDistribution }, { types: selectedTypes = [] }) => {
    const types = { ...typesDistribution, [AggsType.SURVEY]: survey };
    return selectedTypes.length > 0
      ? selectedTypes.reduce((acc, type) => acc + types[type], 0)
      : Object.values(types).reduce((acc: number, count: number) => acc + count, 0);
  }
);
