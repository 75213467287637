import { identity, pick, path, prop } from 'ramda';
import { createActions } from 'redux-actions';
import { getCurrentAppId } from '../selectors/current_app';
import { getDashboardStartDate, getDashboardEndDate } from '../selectors/dashboard';

export const FETCH_DAILY_ACTIVE_USERS_PENDING = 'FETCH_DAILY_ACTIVE_USERS_PENDING';
export const FETCH_DAILY_ACTIVE_USERS_SUCCESS = 'FETCH_DAILY_ACTIVE_USERS_SUCCESS';
export const FETCH_DAILY_ACTIVE_USERS_FAILURE = 'FETCH_DAILY_ACTIVE_USERS_FAILURE';
export const FETCH_MONTHLY_ACTIVE_USERS_PENDING = 'FETCH_MONTHLY_ACTIVE_USERS_PENDING';
export const FETCH_MONTHLY_ACTIVE_USERS_SUCCESS = 'FETCH_MONTHLY_ACTIVE_USERS_SUCCESS';
export const FETCH_MONTHLY_ACTIVE_USERS_FAILURE = 'FETCH_MONTHLY_ACTIVE_USERS_FAILURE';

const creators = createActions({
  [FETCH_DAILY_ACTIVE_USERS_PENDING]: [identity, pick(['appId'])],
  [FETCH_DAILY_ACTIVE_USERS_SUCCESS]: [path(['json']), pick(['appId', 'startDate', 'endDate'])],
  [FETCH_DAILY_ACTIVE_USERS_FAILURE]: [prop('err'), pick(['appId', 'startDate', 'endDate'])],
  [FETCH_MONTHLY_ACTIVE_USERS_PENDING]: [identity, pick(['appId'])],
  [FETCH_MONTHLY_ACTIVE_USERS_SUCCESS]: [path(['json']), pick(['appId', 'startDate', 'endDate'])],
  [FETCH_MONTHLY_ACTIVE_USERS_FAILURE]: [prop('err'), pick(['appId', 'startDate', 'endDate'])],
});
export const {
  fetchDailyActiveUsersPending, fetchDailyActiveUsersSuccess, fetchDailyActiveUsersFailure,
  fetchMonthlyActiveUsersPending, fetchMonthlyActiveUsersSuccess, fetchMonthlyActiveUsersFailure,
} = creators;

export const fetchDailyActiveUsers = () => (dispatch, getState, { api }) => {
  const state = getState();
  const appId = getCurrentAppId(state);
  const startDate = getDashboardStartDate(state);
  const endDate = getDashboardEndDate(state);
  dispatch(fetchDailyActiveUsersPending({ appId, startDate, endDate }));
  return api.fetchActiveUsers(appId, startDate, endDate, 'daily')
    .then(json => dispatch(fetchDailyActiveUsersSuccess({ appId, startDate, endDate, json })))
    .catch(err => dispatch(fetchDailyActiveUsersFailure({ appId, startDate, endDate, err })));
};
export const fetchMonthlyActiveUsers = () => (dispatch, getState, { api }) => {
  const state = getState();
  const appId = getCurrentAppId(state);
  const startDate = getDashboardStartDate(state);
  const endDate = getDashboardEndDate(state);
  dispatch(fetchMonthlyActiveUsersPending({ appId, startDate, endDate }));
  return api.fetchActiveUsers(appId, startDate, endDate, 'monthly')
    .then(json => dispatch(fetchMonthlyActiveUsersSuccess({ appId, startDate, endDate, json })))
    .catch(err => dispatch(fetchMonthlyActiveUsersFailure({ appId, startDate, endDate, err })));
};
