import { prop as getProp } from 'ramda';
import { API, Rest } from 'src/api';
import { Tag } from 'src/reducers/tags';
import { IAppParams } from 'src/actions/utils/app_params';
import { AggsType } from 'src/reducers/insights';
import { isDemoEnv } from 'src/utils/environment';
import { fetchInsightsTagsDemoData } from 'src/api/demo_util';
import demoAppIds from 'src/utils/demo_app_ids';

import { BaseService } from './base-service';
import { IInsightsParams, InsightsService } from './insights-service';

export interface TagBaseParams {
  elasticsearchId: string;
  type: string;
  tagName: string;
  tagDate: number;
}

export interface TagParams extends IAppParams, TagBaseParams {
  userId: string;
  orgId: string;
  tagName: string;
}

export type TagGlobalParams = Omit<TagParams, 'elasticsearchId' | 'type' | 'tagDate'>;

export interface TagModel {
  app_id: string;
  elasticsearch_id: string;
  entity_type: AggsType;
  org_id: string;
  store_app_id: string;
  tag_name: string;
  user_id: string;
}

export class TagsService extends BaseService {
  static fetchAllTags(appIds: string, _orgIds: string): Promise<{ tags: Tag[] } | void> {
    return this.handleAbortablePromise(
      'fetchAllTags',
      Rest.httpPost(API.TAGS.ALL, {
        body: { app_ids: [appIds] },
        errorTitle: '[API] Error fetching search tags for appIds and ordIds',
      })
    );
  }

  static fetchMostUsedTags(appId: string): Promise<string[]> {
    return Rest.httpGet(API.TAGS.MOST_USED(appId), {
      query: { size: 15 },
      errorTitle: '[API] Error fetching insights data by tags',
    }).promise;
  }

  static fetchAppTags(appId: string): Promise<string[]> {
    return Rest.httpGet(API.TAGS.APP(appId), { errorTitle: '[API] Error fetching tags by appId' }).promise;
  }

  static fetchAggregationTags(params: IInsightsParams): Promise<{ tags: Tag[] } | void> {
    const query = InsightsService.aggsParamsToQueryRequest(params);

    if (isDemoEnv() && !demoAppIds.includes(params.appId)) {
      return fetchInsightsTagsDemoData();
    }

    return this.handleAbortablePromise(
      'fetchAggregationTags',
      Rest.httpGet(API.TAGS.AGGS(params.appId), {
        query: {
          ...query,
          ...InsightsService.resolveReviews(params.types),
          aggs: ['tags-name-and-count'],
        },
      })
    );
  }

  static fetchConversationTags(conversationId: string): Promise<string[]> {
    return Rest.httpGet(API.TAGS.CONVERSATION(conversationId), {
      errorTitle: '[API] Error fetching tags by conversationId',
    }).promise;
  }

  static fetchConversationsTags(conversationIds: string[]): Promise<Record<string, string[]>> {
    return Rest.httpPost(API.TAGS.CONVERSATIONS, {
      body: conversationIds,
      errorTitle: '[API] Error fetching tags by conversationIds',
    }).promise;
  }

  static fetchTagsV2(elasticSearchIds: string[]): Promise<{ tags: TagModel[] }> {
    return Rest.httpPost<{ tags: TagModel[] }>('/insights/tags/es', {
      body: { elasticSearchIds },
      errorTitle: '[API] Error fetch tags',
    }).promise;
  }

  static addTagV2(params: TagParams): Promise<TagModel> {
    return Rest.httpPost<{ tags: TagModel[] }>(API.TAG.CREATE, {
      body: tagParamsToBodyRequest(params),
      errorTitle: '[API] Error adding tag',
    }).promise.then((data) => data.tags[0]);
  }

  static renameTagV2({ newTagName, ...params }: TagParams & { newTagName: string }): Promise<{ tags: TagModel[] }> {
    return Rest.httpPut(API.TAG.RENAME, {
      body: tagParamsToBodyRequest(params),
      query: {
        new_tag_name: newTagName,
      },
      errorTitle: '[API] Error renaming tag',
    }).promise;
  }

  static removeTagV2(params: TagParams): Promise<TagModel> {
    return Rest.httpPut<{ tags: TagModel[] }>(API.TAG.REMOVE, {
      body: tagParamsToBodyRequest(params),
      errorTitle: '[API] Error removing tag',
    }).promise.then((data) => data.tags[0]);
  }

  // TODO: Avoid to read on BE props elasticsearch_id, type, tagDate
  static renameTagGlobally(newTagName: string, params: TagGlobalParams): Promise<{ tags: TagModel[] }> {
    return Rest.httpPut(API.TAG.RENAME_GLOBAL, {
      body: {
        ...tagParamsToBodyRequest(params),
        created_on_date: 0,
        elasticsearch_id: '',
        entity_type: AggsType.MESSAGE,
      },
      query: {
        new_tag_name: newTagName,
      },
      errorTitle: '[API] Error renaming tag globally',
    }).promise;
  }

  static removeTagGlobally(params: TagGlobalParams): Promise<{ tags: TagModel[] }> {
    return Rest.httpPut(API.TAG.REMOVE_GLOBAL, {
      body: {
        // TODO: Remove redundant created_on_date, elasticsearch_id, entity_type after BE fix an issue with validation
        ...tagParamsToBodyRequest(params),
        created_on_date: Date.now(),
        elasticsearch_id: '',
        entity_type: '',
      },
      errorTitle: '[API] Error removing tag global',
    }).promise;
  }
}

function tagParamsToBodyRequest(data: TagParams | TagGlobalParams) {
  const getValue = (prop: keyof TagParams) => getProp(prop as any, data);

  return {
    ...InsightsService.omitEmptyValues({
      user_id: data.userId,
      org_id: data.orgId,
      app_id: data.appId,
      store_app_id: data.storeAppId,
      elasticsearch_id: getValue('elasticsearchId'),
      entity_type: getValue('type'),
      created_on_date: getValue('tagDate'),
    }),
    tag_name: data.tagName,
  };
}
