import { Component } from 'react';
import JWT from '../../utils/jwt';

class Logout extends Component {
  componentDidMount() {
    this.logout(document.referrer, window.location.origin);
  }

  logout(referrer, origin) {
    // We allow a blank referrer so navigating via url works.
    // Blank referrers require an additional confirmation to avoid exploit.
    if (!referrer || referrer.match(new RegExp(`^${origin}`))) {
      if (!referrer && !window.confirm('Are you sure you want to logout?')) { // eslint-disable-line no-alert
        window.location.assign('/loading');
        return;
      }

      JWT.destroy();

      // Redirect to the Auth0 /logout endpoint
      // https://auth0.com/docs/logout/guides/logout-auth0
      const returnTo = `${origin}/login`;
      window.location.assign(`https://${process.env.AUTH0_DOMAIN}/v2/logout?returnTo=${encodeURIComponent(returnTo)}&client_id=${process.env.AUTH0_CLIENT_ID}`);
    } else {
      window.location.assign('/loading');
    }
  }

  render() {
    return null;
  }
}

export default Logout;
