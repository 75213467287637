// Add App Member
export const ADD_APP_MEMBER_PENDING = 'ADD_APP_MEMBER_PENDING';
export const ADD_APP_MEMBER_SUCCESS = 'ADD_APP_MEMBER_SUCCESS';
export const ADD_APP_MEMBER_FAILURE = 'ADD_APP_MEMBER_FAILURE';

const addAppMemberPending = (appId, inviteEmail, name, role) => ({
  type: ADD_APP_MEMBER_PENDING,
  payload: { appId, inviteEmail, name, role },
  meta: { appId, inviteEmail, name, role },
});
export const addAppMemberSuccess = (appId, inviteEmail, name, role, json) => ({
  type: ADD_APP_MEMBER_SUCCESS,
  payload: json,
  meta: { appId, inviteEmail, name, role },
});
export const addAppMemberFailure = (appId, inviteEmail, name, role, error) => ({
  type: ADD_APP_MEMBER_FAILURE,
  payload: error,
  error: true,
  meta: { appId, inviteEmail, name, role },
});
export const addAppMember = (appId, inviteEmail, name, role) => (dispatch, getState, { api }) => {
  dispatch(addAppMemberPending(appId, inviteEmail, name, role));
  return api.addAppMember(appId, inviteEmail, name, role)
    .then(json => dispatch(addAppMemberSuccess(appId, inviteEmail, name, role, json)))
    .catch(ex => dispatch(addAppMemberFailure(appId, inviteEmail, name, role, ex.message)));
};

// Update App Member
export const UPDATE_APP_MEMBER_PENDING = 'UPDATE_APP_MEMBER_PENDING';
export const UPDATE_APP_MEMBER_SUCCESS = 'UPDATE_APP_MEMBER_SUCCESS';
export const UPDATE_APP_MEMBER_FAILURE = 'UPDATE_APP_MEMBER_FAILURE';

const updateAppMemberPending = (appId, appMemberId, body) => ({
  type: UPDATE_APP_MEMBER_PENDING,
  payload: { appId, appMemberId, body },
  meta: { appId, appMemberId, body },
});
export const updateAppMemberSuccess = (appId, appMemberId, body, json) => ({
  type: UPDATE_APP_MEMBER_SUCCESS,
  payload: json,
  meta: { appId, appMemberId, body },
});
const updateAppMemberFailure = (appId, appMemberId, body, error) => ({
  type: UPDATE_APP_MEMBER_FAILURE,
  payload: error,
  error: true,
  meta: { appId, appMemberId, body },
});
export const updateAppMember = (appId, appMemberId, body) => (dispatch, getState, { api }) => {
  dispatch(updateAppMemberPending(appId, appMemberId, body));
  return api.updateAppMember(appId, appMemberId, body)
    .then(json => dispatch(updateAppMemberSuccess(appId, appMemberId, body, json)))
    .catch(ex => dispatch(updateAppMemberFailure(appId, appMemberId, body, ex.message)));
};

// Remove App Member
export const REMOVE_APP_MEMBER_PENDING = 'REMOVE_APP_MEMBER_PENDING';
export const REMOVE_APP_MEMBER_SUCCESS = 'REMOVE_APP_MEMBER_SUCCESS';
export const REMOVE_APP_MEMBER_FAILURE = 'REMOVE_APP_MEMBER_FAILURE';

const removeAppMemberPending = (appId, appMemberId) => ({
  type: REMOVE_APP_MEMBER_PENDING,
  payload: { appId, appMemberId },
  meta: { appId, appMemberId },
});
export const removeAppMemberSuccess = (appId, appMemberId, json) => ({
  type: REMOVE_APP_MEMBER_SUCCESS,
  payload: json,
  meta: { appId, appMemberId },
});
const removeAppMemberFailure = (appId, appMemberId, error) => ({
  type: REMOVE_APP_MEMBER_FAILURE,
  payload: error,
  error: true,
  meta: { appId, appMemberId },
});

export const removeAppMember = (appId, appMemberId) => (dispatch, getState, { api }) => {
  dispatch(removeAppMemberPending(appId, appMemberId));
  return api.removeAppMember(appId, appMemberId)
    .then(json => dispatch(removeAppMemberSuccess(appId, appMemberId, json)))
    .catch(ex => dispatch(removeAppMemberFailure(appId, appMemberId, ex.message)));
};
