import { createActions } from 'redux-actions';
import { appIdIdentity, jsonIdentity, errorIdentity } from './utils/identities';
import sdkEngage from '../utils/sdk_engage';

// Fetching Quick Responses
export const REQUEST_QUICK_RESPONSES = 'REQUEST_QUICK_RESPONSES';
export const RECEIVE_QUICK_RESPONSES = 'RECEIVE_QUICK_RESPONSES';
export const ERRORED_QUICK_RESPONSES = 'ERRORED_QUICK_RESPONSES';
export const { requestQuickResponses, receiveQuickResponses, erroredQuickResponses } = createActions({
  REQUEST_QUICK_RESPONSES: [
    appIdIdentity,
    appIdIdentity,
  ],
  RECEIVE_QUICK_RESPONSES: [
    jsonIdentity,
    appIdIdentity,
  ],
  ERRORED_QUICK_RESPONSES: [
    errorIdentity,
    appIdIdentity,
  ],
});

export const fetchQuickResponses = (appId) => (dispatch, getState, { api }) => {
  dispatch(requestQuickResponses({ appId }));
  return api.fetchQuickResponses(appId)
    .then(json => dispatch(receiveQuickResponses({ appId, json })))
    .catch(error => dispatch(erroredQuickResponses({ appId, error })));
};

// Creating Quick Responses
export const REQUEST_CREATE_QUICK_RESPONSE = 'REQUEST_CREATE_QUICK_RESPONSE';
export const RECEIVE_CREATE_QUICK_RESPONSE = 'RECEIVE_CREATE_QUICK_RESPONSE';
export const ERRORED_CREATE_QUICK_RESPONSE = 'ERRORED_CREATE_QUICK_RESPONSE';
const { requestCreateQuickResponse, receiveCreateQuickResponse, erroredCreateQuickResponse } = createActions({
  REQUEST_CREATE_QUICK_RESPONSE: [
    ({ data }) => ({ data }),
    appIdIdentity,
  ],
  RECEIVE_CREATE_QUICK_RESPONSE: [
    ({ json }) => (json || []),
    appIdIdentity,
  ],
  ERRORED_CREATE_QUICK_RESPONSE: [
    errorIdentity,
    ({ appId, data }) => ({ appId, data }),
  ],
});

export const createQuickResponse = (appId, data, options = {}) => (dispatch, getState, { api }) => {
  sdkEngage('new_quick_response');
  dispatch(requestCreateQuickResponse({ appId, data }));
  return api.createQuickResponse(appId, data)
    .then(json => {
      dispatch(receiveCreateQuickResponse({ appId, json }));
      window.location.assign(`/apps/${appId}/quick_responses`);
    })
    .catch((error) => {
      dispatch(erroredCreateQuickResponse({ appId, data, error }));
      if (options.noisy) throw error;
    });
};

// Deleting Quick Responses
export const REQUEST_DELETE_QUICK_RESPONSE = 'REQUEST_DELETE_QUICK_RESPONSE';
export const RECEIVE_DELETE_QUICK_RESPONSE = 'RECEIVE_DELETE_QUICK_RESPONSE';
export const ERROR_DELETE_QUICK_RESPONSE = 'ERROR_DELETE_QUICK_RESPONSE';
const { requestDeleteQuickResponse, receiveDeleteQuickResponse, errorDeleteQuickResponse } = createActions({
  REQUEST_DELETE_QUICK_RESPONSE: [
    ({ responseId, data }) => ({ responseId, data }),
    appIdIdentity,
  ],
  RECEIVE_DELETE_QUICK_RESPONSE: [
    jsonIdentity,
    ({ responseId, appId }) => ({ responseId, appId }),
  ],
  ERROR_DELETE_QUICK_RESPONSE: [
    errorIdentity,
    ({ responseId, data, appId }) => ({ responseId, data, appId }),
  ],
});

export const deleteQuickResponse = (appId, responseId, data) => (dispatch, getState, { api }) => {
  dispatch(requestDeleteQuickResponse({ appId, responseId, data }));
  return api.deleteQuickResponse(appId, responseId, data)
    .then(json => {
      dispatch(receiveDeleteQuickResponse({ appId, responseId, json }));
      window.location.assign(`/apps/${appId}/quick_responses`);
    })
    .catch((error) => {
      dispatch(errorDeleteQuickResponse({ appId, responseId, data, error }));
    });
};

// Updating Quick Responses
export const REQUEST_UPDATE_QUICK_RESPONSE = 'REQUEST_UPDATE_QUICK_RESPONSE';
export const RECEIVE_UPDATE_QUICK_RESPONSE = 'RECEIVE_UPDATE_QUICK_RESPONSE';
export const ERROR_UPDATE_QUICK_RESPONSE = 'ERROR_UPDATE_QUICK_RESPONSE';
const { requestUpdateQuickResponse, receiveUpdateQuickResponse, errorUpdateQuickResponse } = createActions({
  REQUEST_UPDATE_QUICK_RESPONSE: [
    ({ appId, responseId, data }) => ({ appId, responseId, data }),
    appIdIdentity,
  ],
  RECEIVE_UPDATE_QUICK_RESPONSE: [
    ({ json }) => (json || []),
    ({ appId, responseId }) => ({ appId, responseId }),
  ],
  ERROR_UPDATE_QUICK_RESPONSE: [
    errorIdentity,
    ({ appId, responseId, data }) => ({ appId, responseId, data }),
  ],
});

export const updateQuickResponse = (appId, responseId, data, options = {}) => (dispatch, getState, { api }) => {
  dispatch(requestUpdateQuickResponse({ appId, responseId, data }));
  return api.updateQuickResponse(appId, responseId, data)
    .then(json => {
      dispatch(receiveUpdateQuickResponse({ appId, responseId, json }));
      window.location.assign(`/apps/${appId}/quick_responses`);
    })
    .catch((error) => {
      dispatch(errorUpdateQuickResponse({ appId, responseId, data, error }));
      if (options.noisy) throw error;
    });
};

export const QUICK_RESPONSE_MODAL_TOGGLE = 'QUICK_RESPONSE_MODAL_TOGGLE';
export const toggleQuickResponseModal = () => ({
  type: QUICK_RESPONSE_MODAL_TOGGLE,
});
