import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { fetchNotices } from 'src/actions';
import { GetState } from 'src/reducers';
import { getCurrentAppPlatform } from 'src/selectors/current_app';
import { getCurrentUserId } from 'src/selectors/user';
import { Platform, Translation } from 'src/types/core';

export type TranslationData = Record<string, Translation>

// Fetch App Translations
export const FETCH_APP_TRANSLATIONS_PENDING = 'FETCH_APP_TRANSLATIONS_PENDING';
export const fetchAppTranslationsPending = (appId: string) => ({
  type: FETCH_APP_TRANSLATIONS_PENDING,
  payload: { appId },
  meta: { appId },
});
export const FETCH_APP_TRANSLATIONS_SUCCESS = 'FETCH_APP_TRANSLATIONS_SUCCESS';
export const fetchAppTranslationsSuccess = (appId: string, json: TranslationData) => ({
  type: FETCH_APP_TRANSLATIONS_SUCCESS,
  payload: json,
  meta: { appId },
});
export const FETCH_APP_TRANSLATIONS_FAILURE = 'FETCH_APP_TRANSLATIONS_FAILURE';
export const fetchAppTranslationsFailure = (appId: string, error: string) => ({
  type: FETCH_APP_TRANSLATIONS_FAILURE,
  payload: error,
  error: true,
  meta: { appId },
});

const filterWebTranslationData = (data: TranslationData):TranslationData => {
  const res:TranslationData = {}
  const arr = Object.entries(data)
  arr.forEach(el => {
    const [code, translationData] = el
    res[code] = {
      ...data[code],
      missing: Object.fromEntries(Object.entries(translationData.missing).filter(el => el[0] !== 'RatingDialog')),
      stale: Object.fromEntries(Object.entries(translationData.stale).filter(el => el[0] !== 'RatingDialog')),
      ready: Object.fromEntries(Object.entries(translationData.ready).filter(el => el[0] !== 'RatingDialog')),
    }
  })
  return res
};

export const fetchAppTranslations = (appId: string) => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: GetState, { api }: any) => {
  dispatch(fetchAppTranslationsPending(appId));
  return api.fetchAppTranslations(appId)
    .then((json: TranslationData) => {
      const data = getCurrentAppPlatform(getState()) === Platform.Web ? filterWebTranslationData(json) : json;
      dispatch(fetchAppTranslationsSuccess(appId, data));
    })
    .catch((ex: Error) => dispatch(fetchAppTranslationsFailure(appId, ex.message)));
};

// Update App Translations
export const UPDATE_APP_TRANSLATIONS_PENDING = 'UPDATE_APP_TRANSLATIONS_PENDING';
export const updateAppTranslationsPending = (appId: string, translations: any) => ({
  type: UPDATE_APP_TRANSLATIONS_PENDING,
  payload: { appId, translations },
  meta: { appId, translations },
});
export const UPDATE_APP_TRANSLATIONS_SUCCESS = 'UPDATE_APP_TRANSLATIONS_SUCCESS';
export const updateAppTranslationsSuccess = (appId: string, translations: any, json: any) => ({
  type: UPDATE_APP_TRANSLATIONS_SUCCESS,
  payload: json,
  meta: { appId, translations },
});
export const UPDATE_APP_TRANSLATIONS_FAILURE = 'UPDATE_APP_TRANSLATIONS_FAILURE';
export const updateAppTranslationsFailure = (appId: string, translations: any, error: string) => ({
  type: UPDATE_APP_TRANSLATIONS_FAILURE,
  payload: error,
  error: true,
  meta: { appId, translations },
});

export const updateAppTranslations = (appId: string, translations: any) => (
  dispatch: ThunkDispatch<{}, {}, AnyAction>,
  getState: GetState, { api }: any) => {
  const userId = getCurrentUserId(getState());
  dispatch(updateAppTranslationsPending(appId, translations));
  return api.updateAppTranslations(appId, translations)
    .then((json: any) => dispatch(updateAppTranslationsSuccess(appId, translations, json)))
    .then(() => dispatch(fetchNotices(userId)))
    .catch((ex: Error) => dispatch(updateAppTranslationsFailure(appId, translations, ex.message)));
};
