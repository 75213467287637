import { InsightsService } from 'src/services';
import { DEFAULT_INSIGHTS_FILTERS, AggsType } from 'src/reducers/insights';
import {
  getDashboardStartDate,
  getDashboardEndDate,
  getSelectedCountries,
} from 'src/selectors/dashboard';
import {
  getCurrentStoreAppId,
  getCurrentAppId,
} from 'src/selectors/current_app';

export const FETCH_REVIEWS_COUNT_PENDING = 'FETCH_REVIEWS_COUNT_PENDING';
export const FETCH_REVIEWS_COUNT_SUCCESS = 'FETCH_REVIEWS_COUNT_SUCCESS';
export const FETCH_REVIEWS_COUNT_FAILURE = 'FETCH_REVIEWS_COUNT_FAILURE';
const fetchReviewsCountPending = (appId) => ({
  type: FETCH_REVIEWS_COUNT_PENDING,
  payload: { appId },
  meta: { appId },
});
const fetchReviewsCountSuccess = (appId, json) => ({
  type: FETCH_REVIEWS_COUNT_SUCCESS,
  payload: json,
  meta: { appId },
});
const fetchReviewsCountFailure = (appId, err) => ({
  type: FETCH_REVIEWS_COUNT_FAILURE,
  payload: err,
  meta: { appId },
});

export const fetchReviewsCountIntl = () => (dispatch, getState) => {
  // get params for request
  const state = getState();
  const appId = getCurrentAppId(state);
  const storeAppId = getCurrentStoreAppId(state);
  dispatch(fetchReviewsCountPending(appId));
  if (!storeAppId) {
    return dispatch(fetchReviewsCountFailure(appId, 'No storeId for an Insights request'));
  }
  return InsightsService.fetchTypesAgg({
    appId,
    storeAppId,
    types: [],
    startDate: getDashboardStartDate(state),
    endDate: getDashboardEndDate(state),
    regions: getSelectedCountries(state),
    sentiment: DEFAULT_INSIGHTS_FILTERS.sentiment,
    rating: DEFAULT_INSIGHTS_FILTERS.rating,
    phrases: DEFAULT_INSIGHTS_FILTERS.phrases,
  })
    .then((data) => {
      const review = data.aggs.type_distribution.find(item => item.type === AggsType.REVIEW);
      const total = review ? review.count : 0;
      dispatch(fetchReviewsCountSuccess(appId, { insights: { total } }));
    })
    .catch(err => dispatch(fetchReviewsCountFailure(appId, err)));
};
