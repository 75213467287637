import { createSelector } from 'reselect';
import { getCurrentUserId } from './user';
import filterConversations from '../utils/filter_conversations';

export const getConversations = (state) => state.conversations || {};
export const getFilter = createSelector(getConversations, (conversations) => conversations.filter || 'inbox');
export const getSortBy = createSelector(getConversations, (conversations) => ({ ...conversations.sortBy }));
export const getCounts = createSelector(getConversations, (conversations) => conversations.counts);

export const getConversationsFilterLink = createSelector(getConversations, (conversations) => conversations.filterLink || 'inbox');

export const getConversationsHasMore = createSelector(
  getConversations,
  conversations => conversations.hasMore || false
);
export const getConversationsLoading = createSelector(
  getConversations,
  conversations => conversations.loading || false
);
export const getConversationsSearchPending = createSelector(
  getConversations,
  conversations => conversations.searchPending || false
);
export const getConversationsSearchPhrase = createSelector(
  getConversations,
  conversations => conversations.searchPhrase || ''
);
export const getConversationsSearchResults = createSelector(
  getConversations,
  conversations => conversations.searchResults || []
);
export const getConversationsTitle = createSelector(
  getConversations,
  conversations => conversations.title || ''
);
export const getConversationsLoadingMessages = createSelector(
  getConversations,
  conversations => conversations.loadingMessages || false
);
export const getConversationsPostErrorMessages = createSelector(
  getConversations,
  conversations => conversations.postErrorMessages || false
);
export const getConversationsPostingMessages = createSelector(
  getConversations,
  conversations => conversations.postingMessages || false
);

export const getConversationList = createSelector(
  getConversations,
  (conversations) => conversations.conversationList
);

export const getConversationSelected = createSelector(
  getConversations,
  (conversations) => conversations.conversationSelected || []
);

export const getFilteredConversations = createSelector(
  getFilter,
  getSortBy,
  getCurrentUserId,
  getConversationList,
  (filter, sortBy, currentUserId, conversationList) => filterConversations({ filter, sortBy, currentUserId }, conversationList)
);

export const makeGetConversation = (getConversationId) => createSelector(
  getConversationList,
  getConversationId,
  (conversations, id) => (conversations ? conversations[id] : {})
);
