import { daysAgo } from 'src/utils/time';
import { AggsType, AggsRating, AggsSentiment } from 'src/reducers/insights';
import { InsightsService, IInsightsParams } from 'src/services/insights-service';
import { GetState, DispatchFunc } from 'src/reducers';
import { getAppParams } from '../utils/app_params';

interface dayGraphItem {
  date: number;
  y: number;
}

interface dayMessageCountItem {
  date: string;
  count: number;
}

interface InboundMessageCount {
  data: {
    datums: dayGraphItem[];
    label: string;
  }[];
  type: string;
}

export interface InboundMessageCountResp {
  aggs: {
    count_histogram: dayMessageCountItem[];
  };
}

export enum FETCH_INBOUND_MESSAGE_COUNT {
  PENDING = 'REQUEST_INBOUND_MESSAGE_COUNT',
  SUCCESS = 'RECEIVED_INBOUND_MESSAGE_COUNT',
  ERROR = 'ERROR_FETCHING_INBOUND_MESSAGE_COUNT',
}

const parseResp = (resp: InboundMessageCountResp): InboundMessageCount => ({
  type: 'INBOUND_MESSAGES',
  data: [
    {
      label: 'inbound-messages',
      datums: resp.aggs.count_histogram.map((item: any) => ({ date: new Date(item.date).valueOf(), y: item.count })),
    },
  ],
});

const requestInboundMessageCounts = (appId: string, start: Date, end: Date) => ({
  type: FETCH_INBOUND_MESSAGE_COUNT.PENDING,
  payload: { start, end },
  meta: { appId },
});

const receivedInboundMessageCounts = (appId: string, start: Date, end: Date, json: InboundMessageCount) => ({
  type: FETCH_INBOUND_MESSAGE_COUNT.SUCCESS,
  payload: json,
  meta: { appId, start, end },
});

const errorInboundMessageCounts = (appId: string, start: Date, end: Date, error: Error) => ({
  type: FETCH_INBOUND_MESSAGE_COUNT.ERROR,
  payload: error,
  error: true,
  meta: { appId, start, end },
});

export const fetchInboundMessageCounts = (start = daysAgo(30), end = new Date()) => (
  dispatch: DispatchFunc,
  getState: GetState
) => {
  const { appId, storeAppId } = getAppParams(getState);
  const startDate = start.valueOf();
  const endDate = end.valueOf();
  const data: IInsightsParams = {
    appId,
    storeAppId,
    types: [AggsType.MESSAGE],
    endDate,
    startDate,
    rating: AggsRating.ALL,
    regions: [],
    sentiment: AggsSentiment.ALL,
    page: 0,
    phrases: [],
    survey_title: [],
    tags: [],
    text: '',
    fs_state: [],
  };
  dispatch(requestInboundMessageCounts(appId, start, end));
  return InsightsService.fetchInsightsAggs(data)
    .then((json: InboundMessageCountResp) => dispatch(receivedInboundMessageCounts(appId, start, end, parseResp(json))))
    .catch((ex: Error) => dispatch(errorInboundMessageCounts(appId, start, end, ex)));
};
