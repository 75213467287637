import moment from 'moment';
import { atriumCall, report } from './util';
import { IDateAnnotation } from '../types/core';

export type DateAnnotationData = Pick<IDateAnnotation, 'label' | 'startDate' | 'endDate' | 'comment' | 'color'>;

// Convert date string in the YYYY-MM-DD form to a Date
function utcStringToDate(dateString: string | null | undefined): Date | null {
  if (!dateString) return null;

  return moment(dateString as string).toDate();
}

export function toDateAnnotation(data: any): IDateAnnotation {
  return {
    ...data,
    type: 'custom',
    startDate: utcStringToDate(data.startDate),
    endDate: utcStringToDate(data.endDate),
  };
}

export async function fetchDateAnnotations(appId: string): Promise<IDateAnnotation[]> {
  const data = (await atriumCall(`/apps/${appId}/date_annotations`, {
    error: (e: Error) => report('[API] Error fetching date annotations', e),
  })) as any[];

  const annotations: IDateAnnotation[] = data.map(toDateAnnotation);
  return annotations;
}

function createBodyData(data: DateAnnotationData): string {
  return JSON.stringify({
    label: data.label,
    start_date: data.startDate ? moment(data.startDate).format('YYYY-MM-DD') : null,
    end_date: data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : null,
    comment: data.comment,
    color: data.color,
  });
}

export async function createDateAnnotation(appId: string, data: DateAnnotationData): Promise<IDateAnnotation> {
  const result = await atriumCall(`/apps/${appId}/date_annotations`, {
    method: 'POST',
    body: createBodyData(data),
    error: (e: Error) => report('[API] Error creating date annotation', e),
  });

  return toDateAnnotation(result);
}

export async function updateDateAnnotation(
  appId: string,
  annotationId: string,
  data: DateAnnotationData
): Promise<IDateAnnotation> {
  const result = await atriumCall(`/apps/${appId}/date_annotations/${annotationId}`, {
    method: 'PUT',
    body: createBodyData(data),
    error: (e: Error) => report('[API] Error updating date annotation', e),
  });

  return toDateAnnotation(result);
}

export async function fetchDateAnnotation(appId: string, annotationId: string): Promise<IDateAnnotation> {
  const result = await atriumCall(`/apps/${appId}/date_annotations/${annotationId}`, {
    error: (e: Error) => report('[API] Error fetching date annotation', e),
  });

  return toDateAnnotation(result);
}

export async function deleteDateAnnotation(appId: string, annotationId: string): Promise<void> {
  await atriumCall(`/apps/${appId}/date_annotations/${annotationId}`, {
    method: 'DELETE',
    error: (e: Error) => report('[API] Error deleting date annotation', e),
  });
}
