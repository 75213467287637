export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = MINUTE * 60 * 24;

export enum DateLastPeriod {
  DAYS_7 = '7-days',
  DAYS_14 = '14-days',
  DAYS_30 = '30-days',
  DAYS_60 = '60-days',
  DAYS_90 = '90-days',
  DAYS_180 = '180-days',
  DAYS_365 = '365-days',
}

export const TIME_PERIOD: Record<DateLastPeriod, number> = {
  [DateLastPeriod.DAYS_7]: DAY * 7,
  [DateLastPeriod.DAYS_14]: DAY * 14,
  [DateLastPeriod.DAYS_30]: DAY * 30,
  [DateLastPeriod.DAYS_60]: DAY * 60,
  [DateLastPeriod.DAYS_90]: DAY * 90,
  [DateLastPeriod.DAYS_180]: DAY * 180,
  [DateLastPeriod.DAYS_365]: DAY * 365,
};

export const TIME_PERIOD_LABEL: Record<DateLastPeriod, string> = {
  [DateLastPeriod.DAYS_7]: 'Past 7 Days',
  [DateLastPeriod.DAYS_14]: '14 Days',
  [DateLastPeriod.DAYS_30]: '30 Days (Default)',
  [DateLastPeriod.DAYS_60]: '60 Days',
  [DateLastPeriod.DAYS_90]: '90 Days',
  [DateLastPeriod.DAYS_180]: '180 Days',
  [DateLastPeriod.DAYS_365]: '365 Days',
};

export const secondsToDays = (sec: number): number => (sec * 1000) / DAY;
export const daysToSeconds = (days: number): number => (DAY / 1000) * days;

const TIMESTAMP_LEN = String(Date.now()).length;
const isTimestamp = (date: number): boolean => String(date).length === TIMESTAMP_LEN;
export const timestampToSeconds = (date: number) => (isTimestamp(date) ? Math.floor(date / SECOND) : date);
export const secondsToTimestamp = (sec: number) => (isTimestamp(sec) ? sec : sec * SECOND);

const resolveEndDate = (endDate: number) => (String(endDate).endsWith('999') ? endDate + 1 : endDate);

export const getTimePeriodName = (periodTime: number): DateLastPeriod | undefined => Object.keys(TIME_PERIOD).find((key: DateLastPeriod) => TIME_PERIOD[key] === periodTime) as DateLastPeriod;

export const getDateRangePeriodName = (startDate: number, endDate: number) => getTimePeriodName(resolveEndDate(endDate) - startDate) || DateLastPeriod.DAYS_30;

export const lastPeriodTimeToLabel = (periodTime: number): string | undefined => {
  const timeKey = getTimePeriodName(periodTime);
  const timeLabel = timeKey && TIME_PERIOD_LABEL[timeKey].replace(/Past | \(Default\)/g, '');
  return timeKey ? `Last ${timeLabel}` : undefined;
};
