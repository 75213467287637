import { Action } from 'redux-actions';
import { RECEIVED_CURRENT_USER_FEATURES } from 'src/actions/index';
import { StoreActions } from 'src/store/store-actions';
import { UserFeature, Features } from 'src/types/core';

import { isDevMode, isNetlifyDev, isNetlifyStaging } from '../environment';

export enum FeatureFlag {
  TOAST_NOTIFICATIONS = 'TOAST_NOTIFICATIONS',
  FAN_SIGNALS_WEBHOOK = 'FAN_SIGNALS_WEBHOOK',
  MULTIPLE_LOVE_DIALOGS = 'MULTIPLE_LOVE_DIALOGS',
  CONVERSATION_ID = 'CONVERSATION_ID',
  UNLIMITED_FAN_SIGNALS_EXPORT = 'UNLIMITED_FAN_SIGNALS_EXPORT',
  RIGHTNOW_INTEGRATION = 'RIGHTNOW_INTEGRATION',
  QUANTUM_METRIC_LINK = 'QUANTUM_METRIC_LINK',
  SUMMARY_GRAPH_LABELS = 'SUMMARY_GRAPH_LABELS',
  RICH_TEXT = 'RICH_TEXT',
  GRID_NAV = 'GRID_NAV',
  SURVEY = 'SURVEY',
  WORKFLOW = 'WORKFLOW',
  PULSE = 'PULSE',
  ADV_CUSTOMER_RESEARCH = 'ADV_CUSTOMER_RESEARCH',
  INTERACTIONS_MULTITARGET = 'INTERACTIONS_MULTITARGET',
  WORKFLOW_INITIATOR = 'WORKFLOW_INITIATOR',
  CONTEXTUAL_PAGE_TITLES = 'CONTEXTUAL_PAGE_TITLES',
}

// README: Default values for feature toggles. There should be set needed values for diff envs (staging, shared-dev...)
const defaultSettings: Record<FeatureFlag, boolean> = {
  [FeatureFlag.TOAST_NOTIFICATIONS]: false,
  [FeatureFlag.FAN_SIGNALS_WEBHOOK]: false, // depends on RECEIVED_CURRENT_USER_FEATURES
  [FeatureFlag.MULTIPLE_LOVE_DIALOGS]: false,
  [FeatureFlag.CONVERSATION_ID]: false,
  [FeatureFlag.UNLIMITED_FAN_SIGNALS_EXPORT]: false,
  [FeatureFlag.RIGHTNOW_INTEGRATION]: false,
  [FeatureFlag.QUANTUM_METRIC_LINK]: false,
  [FeatureFlag.SUMMARY_GRAPH_LABELS]: false,
  [FeatureFlag.RICH_TEXT]: false,
  [FeatureFlag.GRID_NAV]: false,
  [FeatureFlag.SURVEY]: false,
  [FeatureFlag.WORKFLOW]: false,
  [FeatureFlag.PULSE]: false,
  [FeatureFlag.ADV_CUSTOMER_RESEARCH]: false,
  [FeatureFlag.INTERACTIONS_MULTITARGET]: false,
  [FeatureFlag.WORKFLOW_INITIATOR]: false,
  [FeatureFlag.CONTEXTUAL_PAGE_TITLES]: false,
};

type Listener = (settings: typeof defaultSettings) => void;
// README: In future, default flag values will be reading from server or some .env file via variables
class FeatureSettings {
  Flag = FeatureFlag;
  settings = { ...defaultSettings };
  listeners: Listener[] = [];

  constructor() {
    this.defineByEnv();
  }

  defineByEnv = () => {
    if (isNetlifyDev() || isNetlifyStaging() || isDevMode()) {
      Object.keys(this.settings).forEach((flag: FeatureFlag) => this.set(flag, true));
    }
  };

  get = (flag: FeatureFlag): boolean => this.settings[flag];
  set = (flag: FeatureFlag, value: boolean) => {
    this.settings[flag] = value;
    this.listeners.forEach((callback) => callback({ ...this.settings }));
  };

  reset = () => {
    Object.keys(defaultSettings).forEach((key: FeatureFlag) => this.set(key, defaultSettings[key]));
    this.defineByEnv();
  };

  onChange = (callback: Listener) => {
    this.listeners.push(callback);
    return () => {
      const idx = this.listeners.findIndex((c) => c === callback);
      if (idx >= 0) {
        this.listeners.splice(idx, 1);
      }
    };
  };

  subscribeActions = (storeActions: StoreActions) => {
    storeActions.on(RECEIVED_CURRENT_USER_FEATURES, ({ payload }: Action<UserFeature[]>) => this.readFeatures(payload));
  };

  readFeatures = (features: UserFeature[] | Features[] = []) => {
    this.reset();
    features.forEach((featureFlag) => {
      switch (featureFlag) {
        case UserFeature.ToastNotifications:
          this.set(FeatureFlag.TOAST_NOTIFICATIONS, true);
          break;
        case UserFeature.FanSignalsWebhooks:
          this.set(FeatureFlag.FAN_SIGNALS_WEBHOOK, true);
          break;
        case UserFeature.MultipleLoveDialogs:
          this.set(FeatureFlag.MULTIPLE_LOVE_DIALOGS, true);
          break;
        case UserFeature.ConversationId:
          this.set(FeatureFlag.CONVERSATION_ID, true);
          break;
        case UserFeature.UnlimitedFanSignalsExport:
          this.set(FeatureFlag.UNLIMITED_FAN_SIGNALS_EXPORT, true);
          break;
        case UserFeature.RightNowIntegration:
          this.set(FeatureFlag.RIGHTNOW_INTEGRATION, true);
          break;
        case UserFeature.Quantum_metric_link:
          this.set(FeatureFlag.QUANTUM_METRIC_LINK, true);
          break;
        case UserFeature.SummaryGraphLabels:
          this.set(FeatureFlag.SUMMARY_GRAPH_LABELS, true);
          break;
        case UserFeature.RichText:
          this.set(FeatureFlag.RICH_TEXT, true);
          break;
        case UserFeature.GridNav:
          this.set(FeatureFlag.GRID_NAV, true);
          break;
        case UserFeature.Survey:
          this.set(FeatureFlag.SURVEY, true);
          break;
        case UserFeature.Workflow:
          this.set(FeatureFlag.WORKFLOW, true);
          break;
        case UserFeature.Pulse:
          this.set(FeatureFlag.PULSE, true);
          break;
        case UserFeature.AdvCustomerResearch:
          this.set(FeatureFlag.ADV_CUSTOMER_RESEARCH, true);
          break;
        case UserFeature.InteractionsMultitarget:
          this.set(FeatureFlag.INTERACTIONS_MULTITARGET, true);
          break;
        case UserFeature.WorkflowInitiator:
          this.set(FeatureFlag.WORKFLOW_INITIATOR, true);
          break;
        case UserFeature.ContextualPageTitles:
          this.set(FeatureFlag.CONTEXTUAL_PAGE_TITLES, true);
          break;
        default:
      }
    });
  };
}

export const featureSettings = new FeatureSettings();

// Only for tests, instead of export whole FeatureSettings class
export const createTestSettingsInstance = () => new FeatureSettings();

if (isDevMode()) {
  Object.assign(window, { featureSettings });
}
