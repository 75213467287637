/* eslint-disable import/no-import-module-exports */
/* eslint-disable import/first */
import '../utils/polyfills';

import React from 'react';
import ReactDom from 'react-dom/client';
import AuthRoot from './components/root';
import '../utils/childnode-remove';
import { initSentry } from '../utils/sentry';

initSentry();

const root = document.getElementById('app');

const render = (Component) => {
  ReactDom.createRoot(root).render(<Component />);
};

render(AuthRoot);

if (module.hot) {
  module.hot.accept('./components/root', () => render(AuthRoot));
}
