import {
  getCurrentAppId,
  getCurrentAppStore,
  getCurrentStoreAppId,
} from '../selectors/current_app';

export const FETCH_APP_VERSIONS_SUCCESS = 'FETCH_APP_VERSIONS_SUCCESS';
export const fetchAppVersionsSuccess = ({ appVersions, store, storeAppId, appId }) => ({
  meta: { appId, store, storeAppId },
  payload: appVersions.data || [],
  type: FETCH_APP_VERSIONS_SUCCESS,
});

export const FETCH_APP_VERSIONS_FAILURE = 'FETCH_APP_VERSIONS_FAILURE';
export const fetchAppVersionsFailure = ({ store, storeAppId, appId, error }) => ({
  error: true,
  meta: { appId, store, storeAppId },
  payload: error,
  type: FETCH_APP_VERSIONS_FAILURE,
});

export const fetchAppVersionsIntl = (store, storeAppId, start, end) => (dispatch, getState, { api }) => {
  const state = getState();
  const appId = getCurrentAppId(state);
  if (!store) {
    store = getCurrentAppStore(state);
  }
  if (!storeAppId) {
    storeAppId = getCurrentStoreAppId(state);
  }

  if (!store) {
    const error = new Error('Missing required parameter: store');
    return dispatch(fetchAppVersionsFailure({ store, storeAppId, appId, error }));
  }
  if (!storeAppId) {
    const error = new Error('Missing required parameter: storeAppId');
    return dispatch(fetchAppVersionsFailure({ store, storeAppId, appId, error }));
  }

  return api.fetchAppVersionsIntl(store, storeAppId, start, end)
    .then(appVersions => dispatch(fetchAppVersionsSuccess({ appVersions, store, storeAppId, appId })))
    .catch(error => dispatch(fetchAppVersionsFailure({ store, storeAppId, appId, error })));
};
