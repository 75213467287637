export function getUtcIsoString(date: Date) {
  const year = date.getFullYear();
  const month = ensureLeadingZero(date.getMonth() + 1);
  const dateNum = ensureLeadingZero(date.getDate());
  const hours = ensureLeadingZero(date.getHours());
  const mins = ensureLeadingZero(date.getMinutes());

  return `${year}-${month}-${dateNum}T${hours}:${mins}:00.000Z`;
}

export const daysInFuture = (days = 30, isStartOfDay = false): Date => {
  const date = new Date()
  date.setDate(date.getDate() + days)

  if (isStartOfDay) {
    date.setHours(0)
    date.setMinutes(0)
  }

  return date;
};

export function getLocalDatePlusOffset(dateStr: string) {
  const date = new Date(Date.parse(dateStr));
  const offset = date.getTimezoneOffset() * 60000;
  return new Date(new Date(date).valueOf() + offset);
}

export function getLocalDateMinusOffset(dateStr: string) {
  const date = new Date(Date.parse(dateStr));
  const offset = date.getTimezoneOffset() * 60000;
  return new Date(new Date(date).valueOf() - offset);
}

export function createDate(year: number, month: number, date: number, hours = 0, minutes = 0, seconds = 0, ms = 0) {
  return new Date(year, month, date, hours, minutes, seconds, ms);
}

export function createDateFromString(date: string) {
  return new Date(date);
}

// returns Date format YYYY-MM-DD
export const localDateString = (date: number | Date) => {
  if (typeof date === 'number') {
    date = new Date(date);
  }
  const year = date.getFullYear();
  const month = ensureLeadingZero(date.getMonth() + 1);
  const day = ensureLeadingZero(date.getDate());
  return `${year}-${month}-${day}`;
};

// returns Date format HH:mm:ss
export const localTimeString = (date: number | Date) => {
  if (typeof date === 'number') {
    date = new Date(date);
  }
  const hours = ensureLeadingZero(date.getHours());
  const mins = ensureLeadingZero(date.getMinutes());
  const sec = ensureLeadingZero(date.getSeconds());
  return `${hours}:${mins}:${sec}`;
};

function ensureLeadingZero(numb: number): string {
  return numb < 10 ? `0${numb}` : numb.toString();
}

export const msToHours = (ms: number) => ms / 3600000
