import React, { Component } from 'react';
import { Icon } from 'src/components/molecules';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import facts from '../../utils/facts';
import fullPath from '../../utils/full_path';
import analytics from '../../data/analytics';
import JWT from '../../utils/jwt';
import Lock, { refreshSession } from './auth0';
import { fetchCurrentUser } from '../../api';
import SavedLocation from '../../utils/saved_location';

import '../../components/styles/loading.scss';

class Loading extends Component {
  longAuthHandler;

  componentDidMount() {
    setPageTitleOnMount('Loading', { oldTitle: 'Loading | Alchemer' });

    if (JWT.valid()) {
      this.authenticated();
      return;
    }

    Lock.on('authenticated', this.authenticated);

    // If Lock fails to respond or takes more than 10 seconds, we should try to navigate for them.
    this.longAuthHandler = setTimeout(this.verifyAuthenticationHappened, 10 * 1000);
    // If something causes the load to take more than 2 minutes, there is likely an error and we should logout.
    setTimeout(this.logout, 2 * 60 * 1000);
  }

  logout = () => {
    this.redirect(fullPath('/logout'));
  };

  // If we are falling back to the timer-based auth check, we need to go get the auth result
  // We will do this without showing anything to the user by attempting to use checkSession to get fresh tokens
  // A bit of a pain, but then it flows seamlessly into the authenticated flow with no implicit handling
  verifyAuthenticationHappened = () => {
    refreshSession()
      .then(this.authenticated)
      .catch((err) => {
        console.error('Error getting user session', err);
        JWT.destroy();
        this.logout();
      });
  };

  authenticated = (authResult) => {
    if (this.longAuthHandler) {
      clearTimeout(this.longAuthHandler);
      this.longAuthHandler = null;
    }

    if (authResult) {
      JWT.setApiToken(authResult.accessToken);
      JWT.setLegacyToken(authResult.idToken);
    }

    fetchCurrentUser()
      .then((user) => {
        // Check to see if we were already trying to go somewhere, or figure out where to go.
        let savedLocation = SavedLocation.check();
        if (savedLocation) {
          if (user.current_app_id) {
            savedLocation = savedLocation.replace('/current/', `/${user.current_app_id}/`);
          }
          this.redirect(savedLocation);
        } else if (user.current_app_id) {
          this.redirect(fullPath(`/apps/${user.current_app_id}/dashboard`));
        } else {
          // New signup path
          console.error('User has no current_app_id set:', JSON.stringify(user));
          analytics.identify({
            id: user.id,
            name: user.name,
            email: user.email,
            phone: user.company_telephone,
          });
          this.redirect(fullPath('/welcome'));
        }
      })
      .catch((error) => {
        console.error('Error Fetching Current User', error);
        this.redirect(fullPath('/logout'));
      });
  };

  redirect = (location) => {
    window.location.assign(location);
  };

  render() {
    const { fact, source } = facts[Math.floor(Math.random() * facts.length)];

    return (
      <div className="background-wrap alchemer-background-wrap">
        <div className="custom-alchemer-line loading" />
        <div className="loading-wrap">
          <Icon name="alchemerLoadingLogo" className="loading-logo" />
          <div className="loading-fact">
            <span className="fact">{fact}</span>
            <span className="source">{source}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;
