import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { epics as sessionEpics } from './session';

export const epic$ = new BehaviorSubject(combineEpics(sessionEpics));

export const rootEpic = (action$, store, dependencies) => epic$.pipe(
  mergeMap(epic => epic(action$, store, dependencies))
);
