import { Store, AnyAction, Dispatch } from 'redux';
import { createLogger } from 'redux-logger';

let LOGS_ON = false;
const isLogsOn = (): boolean => LOGS_ON;
const setLogs = (value: boolean): boolean => (LOGS_ON = value);

const loggerMiddleware = createLogger({ diff: true, collapsed: true });

const isDev = process.env.NODE_ENV === 'development';

export const logger = {
  middleware: (store: Store) => (next: Dispatch<AnyAction>) => (action: AnyAction) => (LOGS_ON && isDev ? loggerMiddleware(store)(next)(action) : next(action)),
  enrichRunLogs: (store: Store) => {
    if (!isDev) {
      return;
    }

    Object.assign(store, {
      _runLogs: () => setLogs(true),
      _muteLogs: () => setLogs(false),
    });
  },
};

export { isLogsOn, setLogs };
