import { EventDistributionData, InteractionItem } from 'src/types/core';

// README: Will be defined after BE will be done
export interface LoveDialogItem extends InteractionItem {
  id: string;
  title: string;
}

// Primary
export interface RatingFlowItem {
  active: boolean;
  id: string;
  enjoyment: RatingEnjoyment;
  message_center: RatingMessageCenterEtended;
  rating_prompt: RatingPrompt;
  thank_you_note: RatingThankYouNote;
  share_code_points?: boolean;
  version: 1 | 2;
}

export interface RatingEnjoyment extends Pick<InteractionItem, 'active' | 'code_points' | 'criteria'> {
  id: string;
  title: string;
  no_target_id?: string; // used only for saving
  no_text: string;
  yes_text: string;
  launches_before_shown: number;
  seconds_between_shows: number | null;
  seconds_after_first_launch: number | null;
  show_for_every_version: boolean;
  display_type?: string | null;
  version: 1;
}

export interface RatingPrompt extends Pick<InteractionItem, 'active' | 'code_points'> {
  id: string;
  delay_after_love_prompt: number;
  in_rating_flow: boolean;
  display_type?: string | null;
  version: 1;
}

export interface RatingThankYouNote extends Pick<InteractionItem, 'active' | 'code_points'> {
  id: string;
  dismiss_text: string;
  title: string;
  version: 1;
}

export interface RatingMessageCenter {
  greeting_body: string;
  greeting_title: string;
  status_body: string;
  status_body_disabled: boolean;
  title: string;
  profile: {
    request: boolean;
    require: boolean;
  };
}

export interface RatingMessageCenterEtended extends Omit<RatingMessageCenter, 'profile'> {
  active: boolean;
  id: string;
  automated_message_body: string;
  composer_close_cancel: string;
  composer_close_confirm: string;
  composer_close_discard: string;
  composer_close_text: string;
  composer_hint_text: string;
  composer_send_button: string;
  composer_send_fail: string;
  composer_send_ok: string;
  composer_send_start: string;
  composer_title: string;
  greeting_body: string;
  greeting_title: string;
  status_body: string;
  status_body_disabled: boolean;
  title: string;
  version: 2;
  profile: {
    edit_save_button: string;
    edit_skip_button: string;
    edit_title: string;
    email_hint: string;
    email_hint_required: string;
    email_required_explain: string;
    init_save_button: string;
    init_save_button_required: string;
    init_skip_button: string;
    init_title: string;
    name_hint: string;
    request: boolean;
    require: boolean;
  };
}

export interface LoveDialogStats {
  loading: boolean;
  error: boolean;
  currentData: EventDistributionData[];
  prevData: EventDistributionData[];
}

export enum LoveDialogEventTypes {
  YES = 'enjoyment_dialog.yes',
  NO = 'enjoyment_dialog.no',
  LAUNCH = 'enjoyment_dialog.launch'
}

export enum McProfileOptions {
  REQUEST = 'Request Email',
  REQUIRE = 'Require Email',
  NONE = 'None',
}
