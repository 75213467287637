// selectors
import { getCurrentAppId, getCurrentStoreAppId } from '../../selectors/current_app';
import { GetState } from '../../reducers';

export interface IAppParams {
  appId: string;
  storeAppId: string;
}

export const getAppParams = (getState: GetState): IAppParams => {
  const state = getState();
  const appId = getCurrentAppId(state);
  const storeAppId = getCurrentStoreAppId(state);
  return {
    appId,
    storeAppId,
  };
};
