import { RatingFlowItem } from 'src/reducers/love-dialog';
import { LoveDialogItem, McProfileOptions, RatingEnjoyment } from 'src/reducers/love-dialog/love-dialog.types';
import { eventNameToHuman } from 'src/utils/events';
import { getReportPercents } from 'src/interactions/newinteractions/report';
import { Platform } from 'src/types/core';
import { SurveyV12Item } from '../surveys-v12';
import { SurveyV12Model } from '../surveys-v12/survey.models';

const defaultModel: RatingFlowItem = {
  active: false,
  id: '',
  enjoyment: { code_points: [] } as any,
  message_center: { profile: {} } as any,
  rating_prompt: { code_points: [] } as any,
  thank_you_note: { code_points: [] } as any,
  share_code_points: false,
  version: 2,
};

export class LoveDialogModel {
  static Defaults = defaultModel;

  static setData(model: RatingFlowItem, data: Partial<RatingFlowItem>): RatingFlowItem {
    return { ...model, ...data };
  }

  static invokeEventsFromPrimary(item: RatingFlowItem): string[] {
    return item.enjoyment ? LoveDialogModel.invokeEvents(item.enjoyment) : [];
  }

  static invokeEvents({ code_points }: Partial<LoveDialogItem>): string[] {
    if (Array.isArray(code_points)) {
      return (code_points || []).map((code) => eventNameToHuman(code));
    }
    if (typeof code_points === 'string') {
      return [eventNameToHuman(code_points)];
    }
    return [];
  }

  // TODO: Update after data be ready. Now mocked.
  static calcLovePercent(_item: LoveDialogItem): string {
    return `(*) ${getReportPercents(Math.random() * 100, 100)}`;
  }

  // TODO: Update after data be ready. Now mocked.
  static calcResponseRate(_item: LoveDialogItem): string {
    return `(*) ${getReportPercents(Math.random() * 100, 100)}`;
  }

  // https://github.com/apptentive/pupum/blob/c3c20e08f47a5f9e9fe7d8735adcad5b3253590c/legacy/assets/views/love_prompt/tab_prompt.js#L220
  static findInSurveysTrigger(flow: RatingFlowItem, surveys: SurveyV12Item[]): SurveyV12Item[] {
    return surveys
      .filter((item) => item.selfTargeting === false)
      .filter(
        (survey) => SurveyV12Model.isTriggeredBy(survey, flow.enjoyment.id) && SurveyV12Model.isFromLoveDialog(survey.codePoints)
      );
  }

  static findSurveyTriggeredBySection(flow: RatingFlowItem, surveys: SurveyV12Item[], isYesSection: boolean) {
    const sectionCodePoint = isYesSection ? 'enjoyment_dialog.yes' : 'enjoyment_dialog.no'
    return this.findInSurveysTrigger(flow, surveys).find(survey => survey.codePoints?.includes(sectionCodePoint))
  }

  static getMCProfile(mc: RatingFlowItem['message_center'], data: McProfileOptions) {
    let profile = { ...mc.profile }
    switch (data) {
      case McProfileOptions.REQUIRE: {
        profile = { ...profile, request: false, require: true }
        break
      }
      case McProfileOptions.REQUEST: {
        profile = { ...profile, request: true, require: false }
        break
      }
      default: {
        profile = { ...profile, request: false, require: false }
        break
      }
    }

    return { ...mc, profile } as RatingFlowItem['message_center']
  }

  static handleTargetingOptions(flows: RatingFlowItem[], platform: Platform) {
    if (platform !== Platform.Web) {
      return flows
    }

    return flows.map(flow => ({ ...flow,
      enjoyment: {
        ...flow.enjoyment,
        show_for_every_version: false,
        seconds_after_first_launch: null,
        seconds_between_shows: null,
      } as RatingEnjoyment }))
  }
}
