import cookie from 'react-cookie';

const oneMonth = 2.628e+9;

export const NOTIFICATION_BANNER_CLOSE = 'NOTIFICATION_BANNER/CLOSE';

export const closeNotificationBanner = () => (dispatch) => {
  const expires = new Date(new Date().valueOf() + oneMonth);
  const notificationName = 'cls2018-notification-seen';
  cookie.save(notificationName, true, { expires });
  dispatch({
    type: NOTIFICATION_BANNER_CLOSE,
    payload: 'open',
    meta: { bannerType: notificationName },
  });
};
