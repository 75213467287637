export const DISPLAY_DOWNLOAD_CENTER_WELCOME = 'DISPLAY_DOWNLOAD_CENTER_WELCOME';
export const displayDownloadCenterWelcome = () => ({
  type: DISPLAY_DOWNLOAD_CENTER_WELCOME,
  display: true,
});

export const CLEAR_DOWNLOAD_CENTER_WELCOME = 'CLEAR_DOWNLOAD_CENTER_WELCOME';
export const clearDownloadCenterWelcome = () => ({
  type: CLEAR_DOWNLOAD_CENTER_WELCOME,
  display: false,
});

export const DISPLAY_FS_EXPORT_INFO_MODAL = 'DISPLAY_FS_EXPORT_INFO_MODAL';
export const displayFsExportInfoModal = () => ({
  type: DISPLAY_FS_EXPORT_INFO_MODAL,
  display: true,
});

export const CLEAR_FS_EXPORT_INFO_MODAL = 'CLEAR_FS_EXPORT_INFO_MODAL';
export const clearFsExportInfoModal = () => ({
  type: CLEAR_FS_EXPORT_INFO_MODAL,
  display: false,
});
