import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { omit, isEmpty } from 'ramda';
import { featureSettings, bemPrefix } from 'src/utils';
import { State } from 'src/reducers';
import { getDashboardDateRange } from 'src/selectors/dashboard';
import { FanSignalStatus, FanSignalsExportType } from 'src/reducers/insights';
import { fanSignals, FsExportConfig } from 'src/actions/fan-signals';
import { eventMetrics, Events } from 'src/actions/event-metrics';
import { createDate } from 'src/utils/date_format';
import {
  IconName,
  DownloadOption,
  Button,
  DateRangeCustomSelector,
  DropDown,
  DropdownOption,
  Icon,
} from 'src/components/molecules';
import {
  DateLastPeriod,
  TIME_PRESETS,
  TimePreset,
  CustomPeriodName,
  getStartEndOptionByPeriod,
  Time,
} from 'src/utils/time';
import { FanSignalsDialogOptionsList } from './fan-signals-dialog-options-list';
import { FSExportLabel } from './fs-export-label';

import './download-fan-signals-dialog-options.scss';

const FS_ALL = 'FS_ALL';

const OPTIONS: { label: string; icon?: IconName; value: OptionValue }[] = [
  { label: 'Select All', value: FS_ALL },
  { label: 'New Fan', icon: 'newFan', value: FanSignalStatus.NEW_FAN },
  { label: 'New Risk', icon: 'newRisk', value: FanSignalStatus.NEW_RISK },
  { label: 'Shifted to Fan', icon: 'shiftedToFan', value: FanSignalStatus.SHIFTED_TO_FAN },
  { label: 'Shifted to Risk', icon: 'shiftedToRisk', value: FanSignalStatus.SHIFTED_TO_RISK },
  { label: 'Repeat Fan', icon: 'repeatFan', value: FanSignalStatus.REPEAT_FAN },
  { label: 'Repeat Risk', icon: 'repeatRisk', value: FanSignalStatus.REPEAT_RISK },
];

const CUSTOM_PERIOD = {
  id: 'custom_period',
  value: 'custom' as CustomPeriodName,
  label: 'Custom date range...',
  name: 'custom' as CustomPeriodName,
};

type DatePeriod = DateLastPeriod | 'custom';

const getLabel = (label: string) => {
  if (label.includes('Past')) {
    return '7 Days';
  }
  if (label.includes('(Default)')) {
    return '30 Days';
  }
  return label;
};

type OptionValue = FanSignalStatus | typeof FS_ALL;

const bem = bemPrefix('download-fan-signals-dialog-options');
const noop = () => null;

export const DEFAULT_STATE = OPTIONS.reduce((acc, { value }) => ({ ...acc, [value]: true }), {} as Record<
  OptionValue,
  boolean
>);

export const invokeFsValues = (state: typeof DEFAULT_STATE): FanSignalStatus[] => {
  return Object.keys(omit([FS_ALL], state)).filter((status: FanSignalStatus) => state[status]) as FanSignalStatus[];
};

export interface DownloadFanSignalsDialogOptionsProps {
  dashboardRange: { startDate: number; endDate: number };
  onExport(config: FsExportConfig): void;
  onClose?(): void;
  onViewChangeId?(id: string): void;
  onShowExportModal?: void;
  sendEvent(event: string): void;
}

export const DownloadFanSignalsDialogOptions: React.FC<DownloadFanSignalsDialogOptionsProps> = ({
  dashboardRange,
  onExport,
  onClose = noop,
  onViewChangeId = noop,
  onShowExportModal = noop,
  sendEvent = noop,
}) => {
  const [state, setState] = useState(DEFAULT_STATE);
  const [viewState, setViewState] = useState<FanSignalsExportType | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<DatePeriod>(DateLastPeriod.DAYS_30);
  const [dateRange, setDateRange] = useState<ReturnType<typeof getStartEndOptionByPeriod>>(
    getStartEndOptionByPeriod(DateLastPeriod.DAYS_30)
  );
  const isUnlimitedExport = useMemo(() => featureSettings.get(featureSettings.Flag.UNLIMITED_FAN_SIGNALS_EXPORT), [
    featureSettings,
  ]);

  useEffect(() => {
    sendEvent(Events.EXPORT_MENU_MAIN);
  }, []);

  const monthAgoNum = new Date().setDate(new Date().getDate() - 29);
  const monthAgoDate = new Date(monthAgoNum);
  const minDate = !isUnlimitedExport ? monthAgoDate : createDate(1971, 0, 0);

  const getTimePresetOptions = useCallback(() => {
    const options: TimePreset[] = isUnlimitedExport
      ? TIME_PRESETS
      : TIME_PRESETS.map((item) => {
        if (
          item.name !== DateLastPeriod.DAYS_7
          && item.name !== DateLastPeriod.DAYS_14
          && item.name !== DateLastPeriod.DAYS_30
        ) {
          return { ...item, disabled: true };
        }
        return item;
      });

    return options
      .map((item) => {
        return {
          ...item,
          label: getLabel(item.label),
          id: item.label.toLowerCase().replace(/ /g, '_'),
          value: item.name,
        };
      })
      .concat(CUSTOM_PERIOD as any);
  }, [isUnlimitedExport]);

  const onChangeValue = useCallback(
    (value: DateLastPeriod | CustomPeriodName, item: TimePreset & DropdownOption) => {
      setSelectedPeriod(value);
      if (value === CUSTOM_PERIOD.value) {
        setDateRange(dashboardRange);
      } else {
        setDateRange({
          startDate: item.startDate.valueOf(),
          endDate: item.endDate.valueOf(),
        });
      }
    },
    [dashboardRange]
  );

  const onSelect = useCallback((value: FanSignalsExportType) => {
    setViewState(value);
    onViewChangeId(value);
  }, []);

  const onOptionChange = useCallback(
    (status: OptionValue) => (e: React.ChangeEvent<HTMLInputElement>) => {
      switch (status) {
        case FS_ALL: {
          const newState = Object.keys(state).reduce(
            (acc, option: OptionValue) => ({ ...acc, [option]: e.target.checked }),
            {} as typeof DEFAULT_STATE
          );
          setState(newState);
          break;
        }
        default:
          setState({ ...state, [status]: e.target.checked });
      }
    },
    [state]
  );

  const onDateRangeChange = useCallback((start: number, end: number) => {
    if (!isUnlimitedExport) {
      start = Time.diffDays(start, end) > 30 ? monthAgoNum : start;
    }
    setDateRange({ startDate: start, endDate: end });
  }, []);

  const getCustStartDate = useCallback(() => {
    if (!isUnlimitedExport && Time.diffDays(dateRange.startDate, dateRange.endDate) > 30) {
      return monthAgoNum;
    }
    return dateRange.startDate;
  }, [dateRange, isUnlimitedExport]);

  const allFsChecked = useMemo(() => {
    const values = OPTIONS.map(({ value }) => value).filter((value) => value !== FS_ALL);
    return !values.find((v) => !state[v]);
  }, [state]);

  const onSubmit = useCallback(() => {
    if (!viewState) {
      return;
    }
    if (viewState === FanSignalsExportType.CURRENT_SIGNAL) {
      sendEvent(Events.EXPORT_SIGNALS_BY_CUSTOMER);
    } else if (viewState === FanSignalsExportType.SIGNALS_OVER_TIME) {
      sendEvent(Events.EXPORT_SIGNALS_OVER_TIME);
    } else {
      sendEvent(Events.EXPORT_SIGNALS_BY_DAY);
    }

    onExport({
      exportType: viewState,
      statuses: invokeFsValues(state),
      isUnlimitedExport,
      ...dateRange,
    });
    onClose();
  }, [state, viewState, dateRange, onExport, onClose]);

  const isCurrent = viewState === FanSignalsExportType.CURRENT_SIGNAL;
  const showDatePicker = selectedPeriod === CUSTOM_PERIOD.value;

  return (
    <div className={bem()}>
      {!viewState && <FanSignalsDialogOptionsList onSelect={onSelect} isUnlimitedExport={isUnlimitedExport} />}
      {viewState && (
        <>
          <FSExportLabel type={viewState} />
          <form>
            <div className="download-options">
              {OPTIONS.map((option) => (
                <DownloadOption
                  className={bem('option')}
                  key={option.value}
                  name={option.value}
                  id={option.value}
                  checked={option.value === FS_ALL ? allFsChecked : state[option.value]}
                  onChange={onOptionChange(option.value)}
                >
                  <span>{option.label}</span>
                </DownloadOption>
              ))}
            </div>
            <div className={bem('date-container', { hidden: isCurrent })}>
              <h4>Date Range</h4>
              <DropDown
                options={getTimePresetOptions()}
                onSelect={onChangeValue}
                selectedValue={selectedPeriod}
                optionsTitle="Fan Signals within the last..."
              />
              {showDatePicker && (
                <>
                  <div className={bem('date-picker-title')}>
                    <span>start</span>
                    <span>end</span>
                  </div>
                  <DateRangeCustomSelector
                    startDate={getCustStartDate()}
                    endDate={dateRange.endDate}
                    onRangeChange={onDateRangeChange}
                    minDate={minDate}
                  />
                </>
              )}
            </div>
            {!isUnlimitedExport && (
              <div className={bem('info')}>
                <Icon name="exportInfo" className={bem('info-icon')} />
                <div className={bem('info-text')}>
                  <span className={bem('info-span')}>Do More With Exports!</span> Extend your date range capabilities
                  beyond the standard 30 days.
                  <span className={bem('info-link')} onClick={onShowExportModal}>
                    Learn More
                  </span>
                </div>
              </div>
            )}
            <div className="options-buttons">
              <Button className="submit" onClick={onSubmit} disabled={isEmpty(invokeFsValues(state))}>
                Export
              </Button>
              <Button className="cancel" onClick={onClose}>
                Cancel
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

DownloadFanSignalsDialogOptions.displayName = 'DownloadFanSignalsDialogOptions';

export const DownloadFanSignalsDialogOptionsContainer = connect<
  Pick<DownloadFanSignalsDialogOptionsProps, 'dashboardRange'>,
  Omit<DownloadFanSignalsDialogOptionsProps, 'dashboardRange'>
>(
  (state: State) => ({
    dashboardRange: getDashboardDateRange(state),
  }),
  (dispatch: Function) => ({
    onExport: (config: FsExportConfig) => dispatch(fanSignals.runExport(config)),
    sendEvent: (event: Events) => dispatch(eventMetrics.sendEvent(event)),
  })
)(DownloadFanSignalsDialogOptions);
