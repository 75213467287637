import { ApiKeyType, ApiKey } from 'src/types/api_key';
import { Rest } from './rest';

export const getApiKeys = (targetId: string, keyType: ApiKeyType): Promise<ApiKey[]> => Rest.httpGet<ApiKey[]>(
  `/keys/${keyType}/${targetId}`, { errorTitle: '[API] Error fetching keys' }
).promise;

export const createApiKey = (targetId: string, keyType: ApiKeyType, createdById: string, description: string): Promise<ApiKey> => Rest.httpPost<ApiKey>(
  `/keys/${targetId}?key_type=${keyType}&desc=${description}&created_by=${createdById}&metric=true&exp=true&raw=true`,
  {
    errorTitle: '[API] Error creating key',
    body: {},
  },
).promise;

export const renameApiKey = (keyId: string, newDescription: string): Promise<void> => Rest.httpPut<void>(
  `/keys/${keyId}`,
  {
    errorTitle: '[API] Error updating key',
    body: { description: newDescription },
  },
).promise;

export const revokeApiKey = (keyId: string): Promise<void> => Rest.httpDelete<void>(
  `/keys/${keyId}/revoke`,
  { errorTitle: '[API] Error revoking key' },
).promise;

export const deleteApiKey = (keyId: string): Promise<void> => Rest.httpDelete<void>(
  `/keys/${keyId}`,
  { errorTitle: '[API] Error deleting key' },
).promise;
