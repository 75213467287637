import React from 'react';

export const KeyboardKey = {
  SPACE: ' ',
  ENTER: 'Enter',
  ESC: 'Escape',
};

export const onKeyPress = (onClick: () => void) => (event: React.KeyboardEvent) => {
  if ([KeyboardKey.SPACE, KeyboardKey.ENTER, KeyboardKey.ESC].includes(event.key)) {
    event.preventDefault();
    onClick();
  }
};

export function isKeyboardEvent(event: React.SyntheticEvent | React.KeyboardEvent): event is React.KeyboardEvent {
  return ['keydown', 'keypress'].includes(event.type);
}

const emptyLineP = '<p dir="ltr"><br></p>';

export const removeHtmlEmptyLines = (str: string) => str.replace(emptyLineP, '');

export function getTextFromHtml(html?: string): string | undefined {
  if (!html) {
    return html;
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return doc.body.textContent?.trim() || '';
}

export function getIsStringContainHtml(text: string | null | undefined) {
  if (!text) {
    return false;
  }

  const parsedText = removeHtmlEmptyLines(text);
  const newLineSpan = '</span><br><span';
  const newLineP = '</p><p';

  const hasBreaks = /<br\/?>/.test(text);
  const hasMultilines = parsedText.includes(newLineSpan) || parsedText.includes(newLineP);
  const hasCustomTags = /<(a|b|strong|em|i|u|s|ul|ol|li)\b[^>]*>.*?<\/\1>/.test(text);
  const hasCustomStyles = /(margin:|padding|text-decoration|font-style)/.test(text);
  return Boolean(hasBreaks || hasMultilines || hasCustomTags || hasCustomStyles);
}
