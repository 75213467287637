// App Memberships
export const FETCH_APP_MEMBERSHIPS_PENDING = 'FETCH_APP_MEMBERSHIPS_PENDING';
export const fetchAppMembershipsPending = (userId) => ({
  type: FETCH_APP_MEMBERSHIPS_PENDING,
  payload: { userId },
  meta: { userId },
});
export const FETCH_APP_MEMBERSHIPS_SUCCESS = 'FETCH_APP_MEMBERSHIPS_SUCCESS';
export const fetchAppMembershipsSuccess = (userId, json) => ({
  type: FETCH_APP_MEMBERSHIPS_SUCCESS,
  payload: json,
  meta: { userId },
});
export const FETCH_APP_MEMBERSHIPS_FAILURE = 'FETCH_APP_MEMBERSHIPS_FAILURE';
export const fetchAppMembershipsFailure = (userId, error) => ({
  type: FETCH_APP_MEMBERSHIPS_FAILURE,
  payload: error,
  error: true,
  meta: { userId },
});
export const fetchAppMemberships = (userId) => (dispatch, getState, { api }) => {
  dispatch(fetchAppMembershipsPending(userId));
  return api.fetchAppMemberships(userId)
    .then(json => dispatch(fetchAppMembershipsSuccess(userId, json)))
    .catch(ex => dispatch(fetchAppMembershipsFailure(userId, ex)));
};
