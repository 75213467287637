import Auth0Lock from 'auth0-lock';

// https://auth0.com/docs/libraries/lock/v11/configuration#initialscreen-string-
let initialScreen = '';
switch (window.location.pathname) {
  case '/forgot-password':
    initialScreen = 'forgotPassword';
    break;
  case '/login':
  default:
    initialScreen = 'login';
    break;
}

const options = {
  auth: {
    // This is required to also retrieve the appropriate access token from auth0
    audience: process.env.ATRIUM_URL,
    params: {
      // Scopes are legacy info, identities can be used to auth with web, but the access token is now used
      scope: 'openid profile name email user_metadata identities',
    },
    redirectUrl: process.env.AUTH0_REDIRECT_URI,
    responseType: 'token id_token',
  },
  closable: false,
  configurationBaseUrl: process.env.AUTH0_CDN_URL,
  rememberLastLogin: false,
  languageDictionary: {
    title: 'Alchemer Digital',
    signUpTitle: 'Alchemer Mobile',
    signUpTerms: 'To view how we process and manage your personal information visit our <a href="https://www.apptentive.com/privacy/">Privacy Policy</a>',
  },
  socialButtonStyle: 'small',
  theme: {
    logo: 'https://apptentive-assets.s3.amazonaws.com/alchemer-logo.png',
    primaryColor: '#31827F',
  },
  additionalSignUpFields: [
    {
      name: 'name',
      icon: 'https://secure.gravatar.com/avatar/af3c661a01db6265b323e253699e8cd4?s=80&d=mm&r=x',
      placeholder: 'your full name',
      storage: 'root',
    },
    {
      name: 'phone_number',
      icon: 'https://apptentive-assets.s3.amazonaws.com/phone-call%402x.png',
      placeholder: 'your phone number',
      validator: (phone_number) => {
        const regex = /^(?:[+\d].*\d|\d)$/;
        if (phone_number.match(regex)) {
          return true;
        }

        return {
          valid: false,
          hint: 'Valid phone number is required',
        };
      },
    },
  ],
  allowSignUp: false,
  initialScreen,
  leeway: 30,
};

const Lock = new Auth0Lock(
  process.env.AUTH0_CLIENT_ID,
  process.env.AUTH0_DOMAIN,
  options
);

Lock.on('authorization_error', (error) => {
  console.error('Auth0 Authorization Error', error);
});

Lock.on('unrecoverable_error', (error) => {
  console.error('Auth0 Unrecoverable Error', error);
});

/**
 * Perform a session refresh with auth0 with no user prompt.
 * @returns {Promise<{idToken: string, accessToken: string}>} containing updated JWT for the user's session.
 *  */
export const refreshSession = () => {
  return new Promise((resolve, reject) => {
    Lock.checkSession({ prompt: 'none' },
      (err, authResult) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(authResult);
      }
    );
  });
};

export default Lock;
