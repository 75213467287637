export default {
  1: {
    total: 100,
    retained: {
      1: 100,
      2: 73.13,
      3: 68.38,
      4: 63.82,
      5: 62.53,
      6: 36.48,
    },
  },
  2: {
    total: 100,
    retained: {
      1: 100,
      2: 71.89,
      3: 66.22,
      4: 64.08,
      5: 37.25,
      6: 0,
    },
  },
  3: {
    total: 100,
    retained: {
      1: 100,
      2: 70.86,
      3: 67.32,
      4: 38.71,
      5: 0,
      6: 0,
    },
  },
  4: {
    total: 100,
    retained: {
      1: 100,
      2: 72.18,
      3: 41.26,
      4: 0,
      5: 0,
      6: 0,
    },
  },
  5: {
    total: 100,
    retained: {
      1: 100,
      2: 44.26,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    },
  },
  6: {
    total: 100,
    retained: {
      1: 100,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
    },
  },
};
