export default {
  ratingsDialogOvertime: {
    rate: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 40,
      11: 42,
      12: 45,
      13: 41,
      14: 40,
      15: 38,
      16: 39,
      17: 44,
      18: 42,
      19: 41,
      20: 49,
      21: 50,
      22: 51,
      23: 52,
      24: 55,
      25: 51,
      26: 48,
      27: 50,
      28: 55,
      29: 54,
      30: 52,
      31: 50,
    },
    remind: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 91,
      11: 85,
      12: 89,
      13: 94,
      14: 92,
      15: 91,
      16: 89,
      17: 91,
      18: 91,
      19: 90,
      20: 102,
      21: 101,
      22: 105,
      23: 108,
      24: 98,
      25: 102,
      26: 101,
      27: 100,
      28: 114,
      29: 102,
      30: 105,
      31: 101,
    },
    decline: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 98,
      11: 102,
      12: 101,
      13: 104,
      14: 102,
      15: 101,
      16: 105,
      17: 109,
      18: 99,
      19: 100,
      20: 119,
      21: 121,
      22: 117,
      23: 122,
      24: 121,
      25: 125,
      26: 122,
      27: 121,
      28: 120,
      29: 120,
      30: 115,
      31: 122,
    },
  },
  inAppRatingsDialogOvertime: {
    shown: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 51212,
      11: 50712,
      12: 51712,
      13: 53412,
      14: 52612,
      15: 51112,
      16: 50212,
      17: 55212,
      18: 52212,
      19: 51212,
      20: 66259,
      21: 64234,
      22: 63643,
      23: 61753,
      24: 67903,
      25: 66134,
      26: 65138,
      27: 64603,
      28: 63321,
      29: 62794,
      30: 61234,
      31: 65265,
    },
    not_shown: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 26148,
      11: 25851,
      12: 25105,
      13: 28736,
      14: 26148,
      15: 24373,
      16: 22516,
      17: 25584,
      18: 26148,
      19: 26120,
      20: 27112,
      21: 26148,
      22: 26148,
      23: 26148,
      24: 26148,
      25: 22438,
      26: 26148,
      27: 26148,
      28: 26148,
      29: 25529,
      30: 24716,
      31: 26148,
    },
    fallback: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 16342,
      12: 16210,
      13: 16420,
      14: 17102,
      15: 15102,
      16: 15555,
      17: 16033,
      18: 16021,
      19: 16032,
      20: 16332,
      21: 21231,
      22: 20001,
      23: 22112,
      24: 21234,
      25: 21743,
      26: 21512,
      27: 20123,
      28: 19123,
      29: 23213,
      30: 22112,
      31: 21022,
    },
  },
};
