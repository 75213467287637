import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router';

import AuthLayout from './layout';
import Loading from './loading';
import Login from './login';
import Logout from './logout';
import ForgotPassword from './forgot_password';
import AcceptInvitation from './accept_invitation';
import ResendInvitation from './resend_invitation';
import EmailPreferencesUpdate from './email_preferences_update';
import Welcome from './welcome';
import { history } from '../../store/history';

export const AuthRoutes = () => (
  <AuthLayout>
    <Switch>
      <Route path="/loading" component={Loading} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/logout" component={Logout} exact />
      <Route path="/forgot-password" component={ForgotPassword} exact />
      <Route path="/invitation/accept/:invitationToken" component={AcceptInvitation} exact />
      <Route path="/invitation/resend" component={ResendInvitation} exact />
      <Route path="/email-preferences-update" component={EmailPreferencesUpdate} exact />
      <Route path="/welcome" component={Welcome} exact />
      <Redirect from="/google-oauth" to="/login" />
      <Redirect from="/github-oauth" to="/login" />
      <Redirect to="/login" />
    </Switch>
  </AuthLayout>
);

const Root = () => (
  <div className="auth-container">
    <Router history={history}>
      <Route component={AuthRoutes} />
    </Router>
  </div>
);

export default Root;
