import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { InputText, Button } from 'src/components/molecules';
import { bemPrefix } from 'src/utils';
import { isEmpty } from 'ramda';
import { NavLink } from 'react-router-dom';

import CustomerAttributesItem from './customer_attributes_item';

import './customer_attributes.scss';

const bem = bemPrefix('customer-attributes');

export interface ItemOptions {
  name: string;
  checked: boolean;
  disabled: boolean;
  primary?: boolean;
  type?: number | string | boolean;
}

export interface CustomerAttributesListProps {
  appId?: string;
  title: string;
  noItemsText: string;
  items: ItemOptions[];
  orderNames?: ItemOptions['name'][];
  onApply(names: ItemOptions['name'][]): void;
}

const invokeCheckedNames = (items: ItemOptions[]) => items.filter(({ checked }) => checked).map(({ name }) => name);

export const CustomerAttributesList: React.FC<CustomerAttributesListProps> = ({
  appId,
  items,
  noItemsText,
  title,
  orderNames,
  onApply,
}) => {
  const [filter, setFilter] = useState('');
  const [itemsState, setItemsState] = useState(items);
  const [itemsSelectedOrder, setItemsSelected] = useState<string[]>(orderNames || invokeCheckedNames(items));

  const filteredItems = useMemo(() => {
    return itemsState.filter((item) => item.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
  }, [itemsState, filter]);

  const onInputChange = useCallback((e: never) => setFilter(e), []);

  const onToggleItem = useCallback(
    (checked: boolean, itemName: string) => {
      if (checked) {
        const ordered = orderItemsByName([...itemsSelectedOrder, itemName], (name) => name);
        setItemsSelected(ordered);
      } else {
        setItemsSelected(itemsSelectedOrder.filter((name) => name !== itemName));
      }
      setItemsState(itemsState.map((item) => ({ ...item, checked: itemName === item.name ? checked : item.checked })));
    },
    [itemsSelectedOrder, onApply],
  );
  const onSubmit = useCallback(() => {
    onApply(itemsSelectedOrder);
  }, [onApply, itemsSelectedOrder]);

  useEffect(() => {
    if (orderNames) {
      setItemsSelected(orderNames);
    } else {
      setItemsSelected(invokeCheckedNames(items));
    }
    setItemsState(items);
  }, [orderNames, items]);

  const isDisabled = !itemsState.some((elem) => elem.checked && !elem.disabled);
  const noSearchResults = isEmpty(filteredItems) && !isEmpty(itemsState);

  return (
    <>
      <InputText
        type="search"
        placeholder="Filter by name..."
        className={bem('input-container')}
        value={filter}
        onChange={onInputChange}
      />
      <form>
        <div className={bem('options')}>
          {isEmpty(items) && <span className="options-span">{noItemsText}</span>}
          {noSearchResults ? (
            <span className="options-span">No available attributes for this search</span>
          ) : (
            filteredItems.map((item) => <CustomerAttributesItem key={item.name} item={item} onToggle={onToggleItem} />)
          )}
        </div>
        <div className={bem('manage-attributes')}>
          {appId ? (
            <div className={bem('manage-link')}>
              <p className="text">Need to manage attributes?</p>
              <NavLink className="link" to={`/apps/${appId}/settings/custom_data`}>
                Custom Data Management
              </NavLink>
            </div>
          ) : (
            <div />
          )}
          <div className={bem('form-button')}>
            <Button className="submit" onClick={onSubmit} disabled={isDisabled}>
              {title}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

CustomerAttributesList.displayName = 'CustomerAttributesList';

export function orderItemsByName<T>(items: T[], getName: (item: T) => string) {
  return items
    .reduce<[T[], T[], T[], T[]]>(
      (acc, item) => {
        switch (getName(item)) {
          case 'email':
            acc[0].push(item);
            break;
          case 'mparticle_id':
            acc[1].push(item);
            break;
          case 'conversation_id':
            acc[2].push(item);
            break;
          default:
            if (getName(item).startsWith('custom_data.')) {
              acc[3].push(item);
            }
        }
        return acc;
      },
      [[], [], [], []],
    )
    .reduce((acc, list) => [...acc, ...list]);
}
