import React, { useState, useCallback, useRef, forwardRef } from 'react';
import { bemPrefix } from 'src/utils';
import { SimplePopover, SimplePopoverProps } from 'src/components/molecules/popover';

import './hover-tooltip.scss';

interface HoverTooltipProps extends Pick<SimplePopoverProps, 'placement' | 'withArrow'> {
  className?: string;
  content: React.ReactNode;
  children: React.ReactNode;
}

type TooltipWithForwardedRefProps = HoverTooltipProps & React.RefAttributes<HTMLElement>;

const bem = bemPrefix('hover-tooltip');

export const TooltipWithForwardedRef: React.ForwardRefExoticComponent<TooltipWithForwardedRefProps> = forwardRef(
  ({ className = '', content, placement, children, withArrow = false }, ref: React.ForwardedRef<HTMLElement>) => (
    <>
      <div className={bem('wrapper')}>{children}</div>
      {!!ref && (
        <SimplePopover
          className={`${bem('content')} ${className}`}
          targetEl={ref as any}
          placement={placement}
          withArrow={withArrow}
          isOpen={!!ref}
        >
          {content}
        </SimplePopover>
      )}
    </>
  ),
);

export const HoverTooltip: React.FC<HoverTooltipProps> & { Placement: typeof SimplePopover.PopoverPlacement } = ({
  className = '',
  content,
  placement,
  children,
  withArrow = false,
}) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const onHover = useCallback(() => setShow(true), []);
  const onBlur = useCallback(() => setShow(false), []);

  return (
    <>
      <div className={bem('wrapper')} onMouseEnter={onHover} onMouseLeave={onBlur} ref={ref}>
        {children}
      </div>
      {show && ref.current && (
        <SimplePopover
          className={`${bem('content')} ${className}`}
          targetEl={ref.current}
          placement={placement}
          withArrow={withArrow}
          isOpen
        >
          {content}
        </SimplePopover>
      )}
    </>
  );
};

HoverTooltip.Placement = SimplePopover.PopoverPlacement;
