import { atriumCall, report } from './util';

export const addAppMember = (appId, invite_email, name, role) => atriumCall(`/apps/${appId}/app_members`, {
  method: 'POST',
  body: JSON.stringify({ invite_email, name, role }),
  error: ex => {
    report('[API] Error adding app member', invite_email, name, role, ex);
  },
});

export const updateAppMember = (appId, appMemberId, body) => atriumCall(`/apps/${appId}/app_members/${appMemberId}`, {
  method: 'PUT',
  body: JSON.stringify(body),
  error: ex => {
    report('[API] Error updating app member', body, ex);
  },
});

export const removeAppMember = (appId, appMemberId) => atriumCall(`/apps/${appId}/app_members/${appMemberId}`, {
  method: 'DELETE',
  error: ex => {
    report('[API] Error removing app member', appMemberId, ex);
  },
});
