import {
  getCurrentApp,
  getCurrentAppId,
} from 'src/selectors/current_app';

import { fetchAttributeChoices } from 'src/actions/attribute_choices';
import { fetchTargeting } from 'src/actions/targeting';
import { app } from 'src/actions/app-data/app-data.actions';
import { checkForErrors } from './utils/check_for_errors.js';

// Get App
export const REQUEST_APP = 'REQUEST_APP';
export const requestApp = (appId) => ({
  type: REQUEST_APP,
  payload: { appId },
  meta: { appId },
});

export const RECEIVED_APP = 'RECEIVED_APP';
export const receivedApp = (appId, data) => ({
  type: RECEIVED_APP,
  payload: data,
  meta: { appId },
});

export const ERROR_FETCHING_APP = 'ERROR_FETCHING_APP';
export const errorFetchingApp = (appId, error) => ({
  type: ERROR_FETCHING_APP,
  payload: error,
  error: true,
  meta: { appId },
});

const INVALID_APP_FETCH_REQUEST = 'INVALID_APP_FETCH_REQUEST';
const CACHE_TIMEOUT = 300000;
export const fetchApp = (appId) => {
  if (appId) {
    return async (dispatch, getState, { api }) => {
      const state = getState();
      if (appId === 'current' || appId === 'new') {
        const currentAppId = getCurrentAppId(state);
        appId = currentAppId;
      }

      const currentApp = getCurrentApp(state);
      if (currentApp && currentApp.id === appId) {
        const cacheAge = new Date() - currentApp.lastUpdated;
        if (currentApp.lastUpdated && cacheAge < CACHE_TIMEOUT) {
          return Promise.resolve(currentApp);
        }
      }

      dispatch(requestApp(appId));

      let appData = {};
      try {
        appData = await dispatch(app.fetchData(appId));
      } catch (e) {
        // err is handled within dispatch(app.fetchData(appId))
      }
      return api.fetchApp(appId)
        .then(checkForErrors)
        .then(json => dispatch(receivedApp(appId, { ...appData, ...json })))
        .catch(ex => dispatch(errorFetchingApp(appId, ex)));
    };
  }

  console.error('Called fetchApp without an app id.');
  return {
    type: INVALID_APP_FETCH_REQUEST,
    payload: new TypeError('Missing AppId'),
    error: true,
  };
};

export const fetchAppPersonAttributes = () => (dispatch, getState) => {
  const appId = getCurrentAppId(getState());
  return dispatch(fetchAttributeChoices(appId, 'person', 'limit=250'));
};

export const fetchAppDeviceAttributes = () => (dispatch, getState) => {
  const appId = getCurrentAppId(getState());
  return dispatch(fetchAttributeChoices(appId, 'device', 'limit=250'));
};

export const fetchAppTargeting = () => (dispatch, getState) => {
  const appId = getCurrentAppId(getState());
  return dispatch(fetchTargeting(appId));
};

// Update App
export const UPDATE_APP_PENDING = 'UPDATE_APP_PENDING';
export const updateAppPending = (appId, body) => ({
  type: UPDATE_APP_PENDING,
  payload: { appId, body },
  meta: { appId, body },
});
export const UPDATE_APP_SUCCESS = 'UPDATE_APP_SUCCESS';
export const updateAppSuccess = (appId, body, json) => ({
  type: UPDATE_APP_SUCCESS,
  payload: json,
  meta: { appId, body },
});
export const UPDATE_APP_FAILURE = 'UPDATE_APP_FAILURE';
export const updateAppFailure = (appId, body, error) => ({
  type: UPDATE_APP_FAILURE,
  payload: error,
  error: true,
  meta: { appId, body },
});
export const updateApp = (appId, body) => (dispatch, getState, { api }) => {
  dispatch(updateAppPending(appId, body));
  return api.updateApp(appId, body)
    .then(json => dispatch(updateAppSuccess(appId, body, json)))
    .catch(ex => dispatch(updateAppFailure(appId, body, ex)));
};

// Fetch AppStore Icon
export const FETCH_APPSTORE_ICON_PENDING = 'FETCH_APPSTORE_ICON_PENDING';
export const fetchAppstoreIconPending = ({ appId, storeAppId }) => ({
  type: FETCH_APPSTORE_ICON_PENDING,
  payload: { appId, storeAppId },
  meta: { appId, storeAppId },
});
export const FETCH_APPSTORE_ICON_SUCCESS = 'FETCH_APPSTORE_ICON_SUCCESS';
export const fetchAppstoreIconSuccess = ({ appId, storeAppId, json }) => ({
  type: FETCH_APPSTORE_ICON_SUCCESS,
  payload: json,
  meta: { appId, storeAppId },
});
export const FETCH_APPSTORE_ICON_FAILURE = 'FETCH_APPSTORE_ICON_FAILURE';
export const fetchAppstoreIconFailure = ({ appId, storeAppId, error }) => ({
  type: FETCH_APPSTORE_ICON_FAILURE,
  payload: error,
  error: true,
  meta: { appId, storeAppId },
});
export const fetchAppstoreIcon = (appId, store, storeAppId) => (dispatch, getState, { api }) => {
  dispatch(fetchAppstoreIconPending({ appId, storeAppId }));
  if (!storeAppId) {
    const message = 'Missing required parameter: storeAppId';
    return dispatch(fetchAppstoreIconFailure({ appId, storeAppId, error: message }));
  }
  return api.fetchAppStoreIdsV2(storeAppId, store)
    .then(json => dispatch(fetchAppstoreIconSuccess({ appId, storeAppId, json })))
    .catch(error => dispatch(fetchAppstoreIconFailure({ appId, storeAppId, error })));
};

// Fetch Auth Info
export const FETCH_AUTH_INFO_TOKEN_PENDING = 'FETCH_AUTH_INFO_TOKEN_PENDING';
export const fetchAuthInfoTokenPending = ({ appId }) => ({
  type: FETCH_AUTH_INFO_TOKEN_PENDING,
  payload: { appId },
  meta: { appId },
});
export const FETCH_AUTH_INFO_TOKEN_SUCCESS = 'FETCH_AUTH_INFO_TOKEN_SUCCESS';
export const fetchAuthInfoTokenSuccess = ({ appId, json }) => ({
  type: FETCH_AUTH_INFO_TOKEN_SUCCESS,
  payload: json,
  meta: { appId },
});
export const FETCH_AUTH_INFO_TOKEN_FAILURE = 'FETCH_AUTH_INFO_TOKEN_FAILURE';
export const fetchAuthInfoTokenFailure = ({ appId, error }) => ({
  type: FETCH_AUTH_INFO_TOKEN_FAILURE,
  payload: error,
  error: true,
  meta: { appId },
});
export const fetchAuthInfo = (appId) => (dispatch, getState, { api }) => {
  dispatch(fetchAuthInfoTokenPending({ appId }));
  return api.fetchAuthInfo(appId)
    .then(json => dispatch(fetchAuthInfoTokenSuccess({ appId, json })))
    .catch(error => dispatch(fetchAuthInfoTokenFailure({ appId, error })));
};

// Delete App
export const DELETE_APP_PENDING = 'DELETE_APP_PENDING';
export const deleteAppPending = ({ appId }) => ({
  type: DELETE_APP_PENDING,
  payload: { appId },
  meta: { appId },
});
export const DELETE_APP_SUCCESS = 'DELETE_APP_SUCCESS';
export const deleteAppSuccess = ({ appId, json }) => ({
  type: DELETE_APP_SUCCESS,
  payload: json,
  meta: { appId },
});
export const DELETE_APP_FAILURE = 'DELETE_APP_FAILURE';
export const deleteAppFailure = ({ appId, error }) => ({
  type: DELETE_APP_FAILURE,
  payload: error,
  error: true,
  meta: { appId },
});
export const deleteApp = (appId) => (dispatch, getState, { api }) => {
  dispatch(deleteAppPending({ appId }));
  return api.deleteApp(appId)
    .then(json => dispatch(deleteAppSuccess({ appId, json })))
    .catch(error => dispatch(deleteAppFailure({ appId, error })));
};

export const SAVE_APP_STYLES_PENDING = 'SAVE_APP_STYLES_PENDING';
export const SAVE_APP_STYLES_SUCCESS = 'SAVE_APP_STYLES_SUCCESS';
export const SAVE_APP_STYLES_ERROR = 'SAVE_APP_STYLES_ERROR';

/**
 * AppState['styles']
 * @param {*} styles: {name: string, value: string}[]
 */
export const saveAppStyles = (styles) => (dispatch, getState, { api }) => {
  const appId = getCurrentAppId(getState());

  dispatch({ type: SAVE_APP_STYLES_PENDING, payload: { appId, styles } });
  return api.saveAppStyles(appId, styles)
    .then(appData => {
      const dataStyles = appData.styles || {};
      const data = Object.keys(dataStyles)
        .reduce((res, key) => [...res, { name: key, value: dataStyles[key] }], []);

      dispatch({ type: SAVE_APP_STYLES_SUCCESS, payload: { appId, styles: data } });
    })
    .catch(error => dispatch({ type: SAVE_APP_STYLES_ERROR, payload: { appId, styles }, error }));
};
