import { atriumCall, report } from './util';

const fetchStoreApp = (store, storeId, regions) => {
  const query = regions ? { regions } : {};
  return atriumCall(
    `/app-store/store/${store}/apps/${storeId}`,
    {
      method: 'GET',
      query,
      error: e => report(
        '[API] Error Fetching Store App', storeId, e
      ),
    }
  );
};

export default fetchStoreApp;
