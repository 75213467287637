export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type SortHandler<S> = (sortBy: S, sortOrder: SortOrder) => void;

export function sortBy<T, K extends keyof T>(values: T[], orderByProp: K, sortOrder: SortOrder): T[] {
  let sorted = Array.from(values);
  sorted.sort((item1: T, item2: T) => {
    let value1 = item1[orderByProp] || '';
    let value2 = item2[orderByProp] || '';

    if (typeof value1 === 'string') {
      value1 = value1.toLowerCase();
    }
    if (typeof value2 === 'string') {
      value2 = value2.toLowerCase();
    }

    if (value1 < value2) {
      return -1;
    }
    if (value1 > value2) {
      return 1;
    }

    return 0;
  });

  if (sortOrder === SortOrder.desc) {
    sorted = sorted.reverse();
  }

  return sorted;
}
