import React from 'react';
import classNames from 'classnames';

import { bemPrefix } from 'src/utils';
import { Icon, IconName } from '../icons';
import { Button } from '../button';
import { HoverTooltip } from '../hover-tooltip/hover-tooltip';

import './icon-button.scss';
import { PopoverPlacement } from '../popover/simple-popover';

const bem = bemPrefix('icon-button');

interface IconButtonProps {
    tooltip: string;
    iconName: IconName;
    onClick: () => void;
    className?: string;
    disabled?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({ tooltip, iconName, onClick, className = '', disabled = false }) => {
  return (
    <HoverTooltip content={tooltip} placement={PopoverPlacement.bottomStart} className={bem('tooltip')}>
      <Button className={classNames(bem('button', iconName), className)} normalize onClick={onClick} disabled={disabled}>
        <Icon name={iconName} className={bem('icon', iconName)} />
      </Button>
    </HoverTooltip>
  )
}
