import { reset } from 'redux-form';
import { FsWebhookService } from 'src/services/fs-webhook-service';

// Fetch
export const FETCH_INTEGRATIONS_PENDING = 'FETCH_INTEGRATIONS_PENDING';
export const FETCH_INTEGRATIONS_SUCCESS = 'FETCH_INTEGRATIONS_SUCCESS';
export const FETCH_INTEGRATIONS_FAILURE = 'FETCH_INTEGRATIONS_FAILURE';
export const fetchIntegrationsPending = (appId) => ({
  type: FETCH_INTEGRATIONS_PENDING,
  payload: { appId },
  meta: { appId },
});
export const fetchIntegrationsSuccess = (appId, json) => ({
  type: FETCH_INTEGRATIONS_SUCCESS,
  payload: json,
  meta: { appId },
});
export const parseWebhookResp = (appId, webhook) => ({
  [webhook.id]: {
    id: webhook.id,
    active: true,
    type: 'fs_webhook',
    webhook_url: webhook.webhook_url,
    fs_state: webhook.fs_state,
    isLaunched: webhook.is_active,
  },
});
export const fetchIntegrationsFailure = (appId, error) => ({
  type: FETCH_INTEGRATIONS_FAILURE,
  payload: error,
  meta: { appId },
});
export const fetchIntegrations = (appId) => (dispatch, getState, { api }) => {
  dispatch(fetchIntegrationsPending(appId));
  return api.fetchIntegrations(appId)
    .then(json => {
      return FsWebhookService.fetchWebhooks(appId)
        .then((hooks) => {
          let integrations = json;
          if (hooks.data && hooks.data.length > 0) {
            const webhookIntegration = parseWebhookResp(appId, hooks.data[0]);
            integrations = { ...integrations, ...webhookIntegration };
          }
          dispatch(fetchIntegrationsSuccess(appId, integrations));
        })
        .catch(() => dispatch(fetchIntegrationsSuccess(appId, json)));
    })
    .catch(ex => dispatch(fetchIntegrationsFailure(appId, ex.message)));
};

// Create
export const CREATE_INTEGRATION_PENDING = 'CREATE_INTEGRATION_PENDING';
export const CREATE_INTEGRATION_SUCCESS = 'CREATE_INTEGRATION_SUCCESS';
export const CREATE_INTEGRATION_FAILURE = 'CREATE_INTEGRATION_FAILURE';
export const createIntegrationPending = (appId, data) => ({
  type: CREATE_INTEGRATION_PENDING,
  payload: { appId, data },
  meta: { appId },
});
export const createIntegrationSuccess = (appId, data, json) => ({
  type: CREATE_INTEGRATION_SUCCESS,
  payload: json,
  meta: { appId, id: Object.keys(json)[0], data },
});
export const createIntegrationFailure = (appId, data, error) => ({
  type: CREATE_INTEGRATION_FAILURE,
  payload: error.errors || error,
  meta: { appId, data },
});
export const createIntegration = (appId, data) => (dispatch, getState, { api }) => {
  dispatch(createIntegrationPending(appId, data));
  return api.createIntegration(appId, data)
    .then(json => dispatch(createIntegrationSuccess(appId, data, json)))
    .catch(ex => dispatch(createIntegrationFailure(appId, data, ex.response || ex.message)));
};
export const createWebhookIntegration = (appId, data) => (dispatch) => {
  dispatch(createIntegrationPending(appId, data));
  return FsWebhookService.createWebhook(data)
    .then(json => dispatch(createIntegrationSuccess(appId, parseWebhookResp(appId, data), parseWebhookResp(appId, json))))
    .catch(ex => dispatch(createIntegrationFailure(appId, data, ex.response || ex.message)));
};

// Update
export const UPDATE_INTEGRATION_PENDING = 'UPDATE_INTEGRATION_PENDING';
export const UPDATE_INTEGRATION_SUCCESS = 'UPDATE_INTEGRATION_SUCCESS';
export const UPDATE_INTEGRATION_FAILURE = 'UPDATE_INTEGRATION_FAILURE';
export const updateIntegrationPending = (appId, id, data) => ({
  type: UPDATE_INTEGRATION_PENDING,
  payload: { appId, id, data },
  meta: { appId },
});
export const updateIntegrationSuccess = (appId, id, data, json) => ({
  type: UPDATE_INTEGRATION_SUCCESS,
  payload: json,
  meta: { appId, id, data },
});
export const updateIntegrationFailure = (appId, id, data, error) => ({
  type: UPDATE_INTEGRATION_FAILURE,
  payload: error.errors || error,
  meta: { appId, id, data },
});
export const updateIntegration = (appId, data, id) => (dispatch, getState, { api }) => {
  dispatch(updateIntegrationPending(appId, id, data));
  return api.updateIntegration(appId, id, data)
    .then(json => dispatch(updateIntegrationSuccess(appId, id, data, json)))
    .catch(ex => dispatch(updateIntegrationFailure(appId, id, data, ex.response || ex.message)));
};
export const updateWebhookIntegration = (appId, data) => (dispatch) => {
  dispatch(updateIntegrationPending(appId, data));
  return FsWebhookService.updateWebhook(data)
    .then(json => dispatch(updateIntegrationSuccess(appId, data.id, parseWebhookResp(appId, data), parseWebhookResp(appId, json))))
    .catch(ex => dispatch(updateIntegrationFailure(appId, data.id, data, ex.response || ex.message)));
};

// Remove / Delete
export const REMOVE_INTEGRATION_PENDING = 'REMOVE_INTEGRATION_PENDING';
export const REMOVE_INTEGRATION_SUCCESS = 'REMOVE_INTEGRATION_SUCCESS';
export const REMOVE_INTEGRATION_FAILURE = 'REMOVE_INTEGRATION_FAILURE';
export const removeIntegrationPending = (appId, id) => ({
  type: REMOVE_INTEGRATION_PENDING,
  payload: { appId, id },
  meta: { appId },
});
export const removeIntegrationSuccess = (appId, id, json) => ({
  type: REMOVE_INTEGRATION_SUCCESS,
  payload: json,
  meta: { appId, id },
});
export const removeIntegrationFailure = (appId, id, error) => ({
  type: REMOVE_INTEGRATION_FAILURE,
  payload: error,
  meta: { appId, id },
});
export const removeIntegration = (appId, id, form) => (dispatch, getState, { api }) => {
  dispatch(removeIntegrationPending(appId, id));
  return api.removeIntegration(appId, id)
    .then(json => {
      dispatch(reset(form));
      return dispatch(removeIntegrationSuccess(appId, id, json));
    })
    .catch(ex => dispatch(removeIntegrationFailure(appId, id, ex.message)));
};
export const removeWebhookIntegration = (appId, id) => (dispatch) => {
  dispatch(removeIntegrationPending(appId, id));
  return FsWebhookService.deleteWebhook(id)
    .then(json => dispatch(removeIntegrationSuccess(appId, id, json)))
    .catch(ex => dispatch(removeIntegrationFailure(appId, id, ex.message)));
};
