import { useCallback, useState, useEffect } from 'react';
import { featureSettings, FeatureFlag } from './feature-settings';

export function useFeatureSettings() {
  const [, updateSettings] = useState<typeof featureSettings['settings']>();
  const hasFeature = useCallback((flag: FeatureFlag) => featureSettings.get(flag), []);

  const setFeature = useCallback((flag: FeatureFlag, value: boolean) => featureSettings.set(flag, value), []);

  useEffect(() => {
    const stopListen = featureSettings.onChange(updateSettings);
    return stopListen;
  }, []);

  return {
    flag: FeatureFlag,
    hasFeature,
    setFeature,
  };
}
