import { createActions } from 'redux-actions';
import { prop, pick } from 'ramda';
import { getCurrentAppStore, getCurrentStoreAppId } from '../selectors/current_app';

export const FETCH_STORE_APP_PENDING = 'FETCH_STORE_APP_PENDING';
export const FETCH_STORE_APP_SUCCESS = 'FETCH_STORE_APP_SUCCESS';
export const FETCH_STORE_APP_FAILURE = 'FETCH_STORE_APP_FAILURE';

export const { fetchStoreAppPending, fetchStoreAppSuccess, fetchStoreAppFailure } = createActions({
  [FETCH_STORE_APP_SUCCESS]: [prop('json'), pick(['store', 'storeId', 'regions'])],
  [FETCH_STORE_APP_FAILURE]: [prop('error'), pick(['store', 'storeId', 'regions'])],
}, FETCH_STORE_APP_PENDING);

export const fetchStoreApp = (store, storeId, regions) => (dispatch, getState, { api }) => {
  dispatch(fetchStoreAppPending({ store, storeId, regions }));
  if (!store || !storeId) {
    dispatch(fetchStoreAppFailure({ error: new Error('Missing store or storeId'), store, storeId, regions }));
    return Promise.resolve();
  }
  return api.fetchStoreApp(store, storeId, regions)
    .then(json => dispatch(fetchStoreAppSuccess({ json, store, storeId, regions })))
    .catch(error => dispatch(fetchStoreAppFailure({ error, store, storeId, regions })));
};

export const fetchCurrentStoreApp = (regions) => (dispatch, getState) => {
  const state = getState();
  const store = getCurrentAppStore(state);
  const storeId = getCurrentStoreAppId(state);
  return dispatch(fetchStoreApp(store, storeId, regions));
};
