import React from 'react';
import { bemPrefix, Time } from 'src/utils';

import './report-dates.scss';

const bem = bemPrefix('report-dates');

export interface InteractionReportDatesProps {
  className?: string;
  createdAt: string | undefined;
  updatedAt: string | undefined;
}

export const formatTime = (date: string | number) => {
  if (typeof date === 'number' && String(date).length === 10) {
    date *= 1000;
  }
  return Time.format(date, Time.FORMAT.MMM_DD_YYYY);
};

export const InteractionReportDates: React.FC<InteractionReportDatesProps> = ({
  className = '',
  createdAt,
  updatedAt,
}) => (
  <div className={`${bem()} ${className}`}>
    {createdAt && <span className={bem('created')}>{formatTime(createdAt)}</span>}
    {updatedAt && <span className={bem('updated')}>(Updated: {formatTime(updatedAt)})</span>}
  </div>
);
