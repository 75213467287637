/* eslint-disable no-underscore-dangle */
import FileSaver from 'file-saver';
import { reject as rejectValues, converge, isEmpty, isNil, or, omit, identity, values, sortBy } from 'ramda';
import { Time } from 'src/utils';
import { API, Rest } from 'src/api';
import { IAppParams } from 'src/actions/utils/app_params';
import {
  ICurrentSearch,
  AggsSentiment,
  AggsType,
  AggsRating,
  SavedInsight,
  DEFAULT_INSIGHTS_FILTERS,
  AggsDomen,
  FanSignalStatus,
} from 'src/reducers/insights';
import { isDemoEnv } from 'src/utils/environment';
import {
  fetchInsightsIntlDemoData,
  fetchPhrasesDemoData,
  fetchInsightsSurveysDemoData,
  fetchInsightsFsDemoData,
} from 'src/api/demo_util';

import { InsightsFilterParams } from 'src/dashboard/components/insights-context/insights-context.types';
import { ExportType } from './insights-export-ws/insights-export-ws.types';
import { BaseService } from './base-service';

export interface InsightsMetaParams extends Pick<IAppParams, 'appId'> {
  orgId: string;
  userId: string;
}

export interface ExportInsightData extends ICurrentSearch, IAppParams {
  orgId: InsightsMetaParams['orgId'];
  startDate: number;
  endDate: number;
}

export interface ExportInsightParams {
  data: boolean;
  aggs: boolean;
  phrases: boolean;
  tags: boolean;
}

export interface InsightsBaseParams extends ICurrentSearch {
  startDate: number;
  endDate: number;
  page?: number;
  per?: number;
}

export interface IInsightsParams extends IAppParams, InsightsBaseParams {}

export enum PhraseAction {
  pin = 'pin',
  unpin = 'unpin',
  mute = 'mute',
  unmute = 'unmute',
}

export interface IPhraseBaseParams {
  action: PhraseAction;
  phrase: string;
  date?: number;
}

export interface IPhraseParams extends IPhraseBaseParams {
  userId: InsightsMetaParams['userId'];
  appId: InsightsMetaParams['appId'];
}

export interface InsightsAggsParams extends IInsightsParams {
  aggs: AggsDomen[];
}

export interface InsightsPhrasesParams extends IInsightsParams {
  userId: InsightsMetaParams['userId'];
}

export interface InsightsAggsFetchParams extends Omit<InsightsAggsParams, 'phrases' | 'types'> {
  store_app_id: string;
  phrase: InsightsAggsParams['phrases'];
  sentiment: AggsSentiment;
  regions: any;
  start_date: any;
  end_date: any;
  types: string;
  exclude_outbound: true;
  exclude_automated: true;
}

const sentimentToServer: Record<string, string | undefined> = {
  '-1000..1000': undefined, // [AggsSentiment.ALL]: undefined
  '-1000..-1': '-1000.0..-0.001', // [AggsSentiment.NEGATIVE]: -1000..-1 => -1000.0..-0.001
  '0..599': '0..599.999', // [AggsSentiment.NEUTRAL]: 0..599 => 0..599.999
  '600..1000': '600..1000', // [AggsSentiment.POSITIVE]: 600..1000 => 600..1000
}

const dateToRequest = (date?: number | string) => (date ? Time.formatUtc(date, Time.FORMAT.YYYY_MM_DD) : undefined);
const regionsToRequest = (regions: InsightsAggsParams['regions']): string => regions.join(',');
const omitEmptyValues = (data: object): object => rejectValues(converge(or, [isEmpty, isNil]) as any)(data as any);
const isAllChecked = (data: object) => values(data).filter(identity).length === Object.keys(data).length;
const getCheckedValues = <T = Record<string, boolean>>(data: T): (keyof T)[] => Object.keys(data as any).filter((key: string) => (data as any)[key]) as any;

const resolveSentiment = (sentiment: AggsSentiment): string | undefined => sentimentToServer[sentiment];

const resolveTypes = (types: AggsType[]) => (isEmpty(types) ? [AggsType.MESSAGE, AggsType.REVIEW, AggsType.SURVEY] : types);

const resolveReviews = (types: AggsType[]) => (types.includes(AggsType.REVIEW)
  ? {}
  : {
    rating: DEFAULT_INSIGHTS_FILTERS.rating,
    regions: DEFAULT_INSIGHTS_FILTERS.regions,
  });

const aggsParamsToQueryRequest = ({
  storeAppId,
  regions,
  startDate,
  endDate,
  sentiment,
  phrases,
  ...queryData
}: InsightsAggsParams | IInsightsParams): Partial<InsightsAggsFetchParams> => {
  let types = !storeAppId && queryData.types.includes(AggsType.REVIEW)
    ? queryData.types.filter((item) => item !== AggsType.REVIEW).join(',')
    : queryData.types.join(',');

  if (!types && !storeAppId) {
    types = resolveTypes([])
      .filter((type) => type !== AggsType.REVIEW)
      .join(',');
  }

  return omitEmptyValues({
    store_app_id: storeAppId,
    ...omit(['appId'], queryData),
    types,
    phrase: phrases,
    sentiment: resolveSentiment(sentiment),
    regions: regionsToRequest(regions),
    start_date: dateToRequest(startDate),
    end_date: dateToRequest(endDate),
    exclude_outbound: true,
    exclude_automated: true,
  });
};

const RESULT_IDS = {
  fetchInsightsAggs: 'fetchInsightsAggs',
  fetchSentimentAgg: 'fetchSentimentAgg',
  fetchFanSignalsAgg: 'fetchFanSignalsAgg',
  fetchTypesAgg: 'fetchTypesAgg',
  fetchRegionsAgg: 'fetchRegionsAgg',
  fetchRegions: 'fetchRegions',
  fetchInsightsData: 'fetchInsightsData',
  fetchPhrases: 'fetchPhrases',
  fetchSurveyNames: 'fetchSurveyNames',
};

// README: demoAppIs has real data on demo.apptentive.com
// BE_DATA_DEMO_IOS_ID has data from BE
const BE_DATA_DEMO_IOS_ID = '5f6a61bf9d62b57991000006';
const isNotDemoAppBe = (appId: string) => {
  return isDemoEnv() ? appId !== BE_DATA_DEMO_IOS_ID : isDemoEnv();
};

export class InsightsService extends BaseService {
  static RESULT_IDS = RESULT_IDS;
  static dateToRequest = dateToRequest;
  static regionsToRequest = regionsToRequest;
  static aggsParamsToQueryRequest = aggsParamsToQueryRequest;
  static omitEmptyValues = omitEmptyValues;
  static getExportType = getExportType;
  static getExportFileName = getExportFileName;

  static resolveTypes = resolveTypes;
  static resolveReviews = resolveReviews;

  private static _fetchAggs(resultId: string, data: InsightsAggsParams): Promise<{ aggs: any }> {
    const query = aggsParamsToQueryRequest(data);
    return this.handleAbortablePromise(
      resultId,
      Rest.httpGet(API.INSIGHTS.APP(data.appId).AGGS, {
        query,
        errorTitle: '[API] Error fetching insights aggs',
      })
    );
  }

  static fetchInsightsAggs(data: IInsightsParams): Promise<{ aggs: any } | void> {
    const genericAggs = AggsDomen.Count;
    const reviewAggs = AggsDomen.Rating;

    const aggs = resolveTypes(data.types).includes(AggsType.REVIEW) ? [genericAggs, reviewAggs] : [genericAggs];
    const reviewArgs = resolveReviews(data.types);

    if (isNotDemoAppBe(data.appId)) {
      return fetchInsightsIntlDemoData(data.types).then((res) => ({
        aggs: {
          count_histogram: res.count_histogram,
          ratings_distribution: res.ratings_distribution,
        },
      }));
    }

    return InsightsService._fetchAggs(RESULT_IDS.fetchInsightsAggs, { ...data, ...reviewArgs, aggs });
  }

  static fetchSentimentAgg(data: IInsightsParams): Promise<{ aggs: any } | void> {
    if (isNotDemoAppBe(data.appId)) {
      return fetchInsightsIntlDemoData(data.types).then((res) => ({
        aggs: {
          sentiment_distribution: res.sentiment_distribution,
        },
      }));
    }
    return InsightsService._fetchAggs(RESULT_IDS.fetchSentimentAgg, {
      ...data,
      ...resolveReviews(data.types),
      sentiment: AggsSentiment.ALL,
      aggs: [AggsDomen.Sentiment],
    });
  }

  static fetchFanSignalsAgg(data: IInsightsParams): Promise<{ aggs: any } | void> {
    if (isNotDemoAppBe(data.appId)) {
      return fetchInsightsFsDemoData();
    }

    return InsightsService._fetchAggs(RESULT_IDS.fetchFanSignalsAgg, {
      ...data,
      ...resolveReviews(data.types),
      fs_state: [
        FanSignalStatus.NEW_FAN,
        FanSignalStatus.NEW_RISK,
        FanSignalStatus.NON_RESPONDENT,
        FanSignalStatus.REPEAT_FAN,
        FanSignalStatus.REPEAT_RISK,
        FanSignalStatus.SHIFTED_TO_FAN,
        FanSignalStatus.SHIFTED_TO_RISK,
      ],
      aggs: [AggsDomen.Fan],
    });
  }

  static fetchSurveyNames(data: IInsightsParams): Promise<{ aggs: any }> {
    if (isNotDemoAppBe(data.appId)) {
      return fetchInsightsSurveysDemoData();
    }

    return InsightsService._fetchAggs(RESULT_IDS.fetchSurveyNames, {
      ...data,
      ...resolveReviews(data.types),
      types: [AggsType.SURVEY],
      aggs: [AggsDomen.Survey],
      survey_title: [],
    });
  }

  static fetchTypesAgg(data: IInsightsParams): Promise<{ aggs: any }> {
    if (isNotDemoAppBe(data.appId)) {
      return fetchInsightsIntlDemoData(data.types).then((res) => ({
        aggs: {
          type_distribution: res.type_distribution,
        },
      }));
    }
    return InsightsService._fetchAggs(RESULT_IDS.fetchTypesAgg, {
      ...data,
      ...resolveReviews(data.types),
      types: [AggsType.REVIEW, AggsType.MESSAGE, AggsType.SURVEY],
      aggs: [AggsDomen.Type],
    });
  }

  static fetchRegionsAgg(data: IInsightsParams): Promise<{ aggs: any } | void> {
    return InsightsService._fetchAggs(RESULT_IDS.fetchRegionsAgg, {
      ...data,
      ...resolveReviews(data.types),
      regions: [],
      aggs: [AggsDomen.Region],
    });
  }

  static fetchRegions(
    data: Pick<IInsightsParams, 'appId' | 'storeAppId' | 'startDate' | 'endDate'>
  ): Promise<{ aggs: { region_distribution: any } } | void> {
    return InsightsService._fetchAggs(RESULT_IDS.fetchRegions, {
      ...data,
      regions: [],
      rating: AggsRating.ALL,
      phrases: [],
      text: '',
      tags: [],
      fs_state: [],
      types: [AggsType.REVIEW, AggsType.MESSAGE, AggsType.SURVEY],
      sentiment: AggsSentiment.ALL,
      aggs: [AggsDomen.Region],
      survey_title: [],
    });
  }

  static fetchInsightsData(data: IInsightsParams): Promise<any> {
    const query = {
      ...aggsParamsToQueryRequest({
        ...data,
        types: resolveTypes(data.types),
      }),
      ...resolveReviews(data.types),
    };

    if (isNotDemoAppBe(data.appId)) {
      return fetchInsightsIntlDemoData(data.types).then((res) => ({ page: res.insights }));
    }

    return this.handleAbortablePromise(
      RESULT_IDS.fetchInsightsData,
      Rest.httpGet(API.INSIGHTS.APP(data.appId).DATA, {
        query,
        errorTitle: '[API] Error fetching insights data',
      })
    );
  }

  static downloadExportFile(url: string, data: ExportInsightParams):Promise<void> {
    const { bucketName, fileKey } = getBucketFileNameFromUrl(url || '');
    const fileName = getExportFileName(data, true);
    return Rest.httpPost(API.INSIGHTS.EXPORT_FILE_URL, {
      body: { bucketName, fileKey },
      errorTitle: '[API] Error get presigned url',
    })
      .promise // eslint-disable-next-line
      .then(({ url: presignedUrl }) => {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.open('GET', presignedUrl, true);
          xhr.onload = () => {
            const ext = fileKey.split('.')[1];
            FileSaver.saveAs(xhr.response, `${fileName}.${ext}`, true);
            resolve();
          };
          xhr.onerror = () => reject();
          xhr.send();
        });
      });
  }

  static fetchPhrases(data: InsightsPhrasesParams): Promise<any> {
    const { userId, ...queryData } = data;

    const query = {
      ...aggsParamsToQueryRequest({
        ...queryData,
        types: resolveTypes(queryData.types),
      }),
      ...resolveReviews(data.types),
      per: 2e2,
    };

    if (isNotDemoAppBe(data.appId)) {
      return fetchPhrasesDemoData();
    }

    return this.handleAbortablePromise(
      RESULT_IDS.fetchPhrases,
      Rest.httpGet(API.INSIGHTS.APP(data.appId).SIGN_PHRASES_BY_USER(userId), {
        query,
        errorTitle: '[API] Error fetching phrases',
      })
    );
  }

  static updatePhrase(data: IPhraseParams): Promise<any> {
    const { action, appId, userId, phrase, date } = data;
    return Rest.httpPut(API.INSIGHTS.APP(appId).SIGN_PHRASES_ACTION(action), {
      body: {
        userId,
        phrase,
        createdOn: dateToRequest(date),
      },
      errorTitle: `[API] Error ${action}ing phrase ${phrase}`,
    }).promise;
  }

  static saveInsights(
    appMeta: InsightsMetaParams,
    data: { name: string; description: string; count: number; modifiedDate: number; filters: InsightsFilterParams }
  ): Promise<SavedInsight | void> {
    // TODO: "data.filters" should has start_date, end_date instead of camelCase
    const filters = omitEmptyValues({
      ...data.filters,
      ...resolveReviews(data.filters.types),
      sentiment: data.filters.sentiment === AggsSentiment.ALL ? undefined : data.filters.sentiment,
      types: resolveTypes(data.filters.types),
    });
    return Rest.httpPost(API.INSIGHTS.SAVED_INSIGHTS, {
      body: {
        user_id: appMeta.userId,
        org_id: appMeta.orgId,
        app_id: appMeta.appId,
        name: data.name,
        description: data.description,
        count: data.count,
        filters: JSON.stringify(filters),
      },
    }).promise.then((item: { insight: SavedInsight & { filters: string } }) => ({
      ...item.insight,
      filters: serializeSavedFilters(item.insight.filters),
    }));
  }

  static fetchSavedInsights(appId: string): Promise<SavedInsight[] | void> {
    return Rest.httpGet(API.INSIGHTS.SAVED_INSIGHTS_BY_APP(appId), {}).promise.then(
      (resp: { insights: (SavedInsight & { filters: string })[] }) => {
        const serialized = resp.insights.map((item) => ({
          ...item,
          filters: serializeSavedFilters(item.filters),
        }));
        return sortBy((item) => -item.modified_date, serialized);
      }
    );
  }

  static deleteSavedInsight(insightId: string): Promise<void> {
    return Rest.httpDelete(API.INSIGHTS.SAVED_INSIGHT_BY_ID(insightId), {}).promise;
  }

  static updateSavedInsight(
    { modified_date, ...insight }: SavedInsight // eslint-disable-line
  ): Promise<SavedInsight | void> {
    return Rest.httpPut(API.INSIGHTS.SAVED_INSIGHTS, {
      body: {
        ...insight,
        filters: JSON.stringify(insight.filters),
      },
    }).promise.then((item: { insight: SavedInsight & { filters: string } }) => ({
      ...item.insight,
      filters: serializeSavedFilters(item.insight.filters),
    }));
  }
}

// README: BE handle and override new values in "start_date, end_date". "startDate, endDate" props are obsolete.
type SavedFilters = InsightsFilterParams & {
  start_date: string;
  end_date: string;
  last_period?: string;
};
function serializeSavedFilters(filtersData: SavedFilters | string): InsightsFilterParams {
  const { start_date, end_date, ...filters } = typeof filtersData === 'string' ? (JSON.parse(filtersData) as SavedFilters) : filtersData;
  return {
    ...filters,
    startDate: filters.startDate ? filters.startDate : Number(start_date),
    endDate: filters.endDate ? filters.endDate : Number(end_date),
    last_period: filters.last_period ? Number(filters.last_period) : undefined,
  };
}

function getExportType(data: ExportInsightParams): ExportType {
  const typesMap: Record<keyof ExportInsightParams, ExportType> = {
    data: ExportType.content,
    aggs: ExportType.aggs,
    phrases: ExportType.phrases,
    tags: ExportType.tags,
  };

  if (isAllChecked(data)) {
    return ExportType.all;
  }

  const types = getCheckedValues(data).map((key) => typesMap[key]);

  return types[0];
}

// url: "https://insights-exports-shared-dev.s3.amazonaws.com/5ca3540beba9c3e2e8060220/content_export_2020-03-04_13-38_d67c7df1-d4a7-4fe3-8adb-3cc1f51d0341.zip"
// => {bucketName: "insights-exports-shared-dev", fileKey: "5ca3540beba9c3e2e8060220/content_export_2020-03-04_13-38_d67c7df1-d4a7-4fe3-8adb-3cc1f51d0341.zip"}
// url: "https://s3.amazonaws.com/insights-exports-production/5cebf34f7ceb81156b0000e2/content_export_2020-03-04_13-36_4ffce310-8998-44a1-b28b-5b93cbdd3b57.zip"
// => {"bucketName":"insights-exports-production","fileKey":"5cebf34f7ceb81156b0000e2/content_export_2020-03-04_13-36_4ffce310-8998-44a1-b28b-5b93cbdd3b57.zip"}
export function getBucketFileNameFromUrl(url: string) {
  let awsHost;
  let bucketName;
  let userFolderOrKey;
  let fileName;
  const isOldS3 = url.split('//')[1].startsWith('s3.');

  if (isOldS3) {
    [, bucketName, userFolderOrKey, fileName] = url.split('//')[1].split('/');
  } else {
    [awsHost, userFolderOrKey, fileName] = url.split('//')[1].split('/');
    bucketName = awsHost.split('.')[0];
  }
  return {
    bucketName,
    fileKey: fileName ? `${userFolderOrKey}/${fileName}` : userFolderOrKey,
  };
}

function getExportFileName(data: ExportInsightParams, withTime?: boolean) {
  const mapNames: Record<keyof ExportInsightParams, string> = {
    data: 'Content',
    aggs: 'Aggregations',
    phrases: 'Phrases',
    tags: 'Tags',
  };

  const datePrefix = Time.format(Date.now(), 'YYYY-MM-DD_HH-mm');

  const partNames = isAllChecked(data)
    ? 'All'
    : getCheckedValues(data)
      .map((key) => mapNames[key])
      .join('-');

  return withTime ? `Insights_${partNames}_${datePrefix}` : partNames;
}
