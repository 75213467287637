import { abortableAtriumCall, AbortableResult, report, ApiOptions } from './util'; // eslint-disable-line

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

type BaseConfig<I = {}, K = ''> = Omit<I, 'method' | 'error' | K> & {
  errorTitle?: string;
};

interface GetConfig extends BaseConfig<ApiOptions> {} // eslint-disable-line
interface PostConfig extends BaseConfig<ApiOptions, 'body'> {
  body: Record<string, any>;
}

export class Rest {
  static httpGet = httpGet;
  static httpPut = httpPut;
  static httpPost = httpPost;
  static httpDelete = httpDelete;
}

function httpGet<T = any>(url: string, { query = {}, errorTitle = '', ...other }: GetConfig): AbortableResult<T> {
  return abortableAtriumCall(url, {
    method: 'GET',
    query,
    error: (error: any) => report(errorTitle, url, query, error),
    throwAbort: true,
    ...other,
  });
}

function httpPut<T = any>(url: string, { body, errorTitle = '', ...other }: PostConfig): AbortableResult<T> {
  return abortableAtriumCall(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    error: (error: any) => report(errorTitle, url, body, error),
    throwAbort: true,
    ...other,
  });
}

function httpPost<T = any>(url: string, { body, errorTitle = '', ...other }: PostConfig): AbortableResult<T> {
  return abortableAtriumCall(url, {
    method: 'POST',
    body: JSON.stringify(body),
    error: (error: any) => report(errorTitle, url, body, error),
    throwAbort: true,
    ...other,
  });
}

function httpDelete<T = any>(url: string, { errorTitle = '', ...other }: BaseConfig): AbortableResult<T> {
  return abortableAtriumCall(url, {
    method: 'DELETE',
    error: (error: any) => report(errorTitle, url, error),
    throwAbort: true,
    ...other,
  });
}
