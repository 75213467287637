import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { LexicalComposer, InitialConfigType } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { BOLD_STAR } from '@lexical/markdown';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';

import { bemPrefix } from 'src/utils';
import { URL_MATCHERS, editorConfig, validateUrl } from './helpers';
import { getTextFromHtml } from '../util-handlers';
import { EditLinkPlugin, EDIT_LINK_COMMAND } from './plugins/edit-link-plugin';
import { FocusEventPlugin } from './plugins/focus-event-plugin';
import { ToolbarPlugin } from './plugins/toolbar-plugin';
import { TransformPlugin } from './plugins/transform-plugin';
import { InputLabel } from '../input';
import { InfoText } from '../info-text/info-text';

import './rich-text-editor.scss';

const bem = bemPrefix('rich-text-editor');

interface RichTextEditorProps {
  value?: string;
  label?: string;
  labelClassName?: string;
  required?: boolean;
  optional?: boolean;
  errorText?: string;
  placeholder?: string;
  helpText?: string;
  className?: string;
  disabled?: boolean;
  isSingleLine?: boolean;
  isInputStateRequired?: boolean;
  onChange: (text: string) => void;
}

const noop = () => null;

export const RichTextEditor: FC<RichTextEditorProps> & { selectorInput: string; EVENT_CHECK_VALIDITY: string } = ({
  value = '',
  placeholder = '',
  label,
  labelClassName,
  required,
  optional,
  errorText,
  helpText,
  className = '',
  disabled,
  isSingleLine = false,
  onChange,
}) => {
  const [hasError, setHasError] = useState(false);
  const [focused, setFocused] = useState(false);
  const [isEmptyValue, setIsEmptyValue] = useState(Boolean(!getTextFromHtml(value)));

  useEffect(() => {
    if (!required && hasError) {
      setHasError(false);
    }
  }, [required]);

  useEffect(() => {
    const isEmpty = Boolean(!getTextFromHtml(value));
    if (isEmpty !== isEmptyValue) {
      setIsEmptyValue?.(isEmpty);
    }
  }, [value]);

  const onBlur = () => {
    setFocused(false);
    if (required && isEmptyValue) {
      setHasError(true);
    }
  };

  const onFocus = () => {
    setFocused(true);
    if (hasError) {
      setHasError(false);
    }
  };

  const themedEditorConfig: InitialConfigType = {
    ...editorConfig,
    nodes: isSingleLine ? [] : [ListNode, ListItemNode, LinkNode, AutoLinkNode],
    theme: {
      text: {
        italic: bem('text-italic'),
        underline: bem('text-underline'),
        strikethrough: bem('text-strikethrough'),
        underlineStrikethrough: bem('text-underline-strikethrough'),
      },
    },
  };

  const renderPlugins = useMemo(() => {
    if (isSingleLine) return null;

    return (
      <>
        <ListPlugin />
        <LinkPlugin validateUrl={validateUrl} />
        <AutoLinkPlugin matchers={URL_MATCHERS} />
        <EditLinkPlugin />
        <NodeEventPlugin
          nodeType={LinkNode}
          eventType="click"
          eventListener={(event, editor) => {
            event.preventDefault();

            editor.dispatchCommand(EDIT_LINK_COMMAND, undefined);
          }}
        />
      </>
    );
  }, [isSingleLine]);

  return (
    <div className={className}>
      {label && <InputLabel className={labelClassName} label={label} required={required} optional={optional} />}
      <LexicalComposer initialConfig={themedEditorConfig as any}>
        <div
          className={classNames({
            [bem('', { focused, 'single-line': isSingleLine })]: true,
            'error-state': hasError,
            'disabled-state': disabled,
          })}
        >
          <ToolbarPlugin
            value={value}
            onChange={disabled ? noop : onChange}
            disabled={disabled}
            isMenuHidden={isSingleLine}
          />
          <div className={bem('container')}>
            <RichTextPlugin
              contentEditable={
                <ContentEditable
                  className={bem('input')}
                  disabled={disabled}
                  data-required={required}
                  data-empty={isEmptyValue}
                />
              }
              placeholder={<div className={bem('placeholder')}>{placeholder}</div>}
              ErrorBoundary={() => null}
            />
            {renderPlugins}
            <TransformPlugin isDisabled={disabled} isSingleLine={isSingleLine} />
            <MarkdownShortcutPlugin transformers={[BOLD_STAR]} />
            <FocusEventPlugin onBlur={onBlur} onFocus={onFocus} />
          </div>
        </div>
      </LexicalComposer>
      {hasError && <InfoText className={bem('error-text')} text={errorText || 'This field is required'} />}
      {helpText && <InfoText className={bem('help-text')} text={helpText} />}
    </div>
  );
};

RichTextEditor.selectorInput = `.${bem('input')}`;
RichTextEditor.EVENT_CHECK_VALIDITY = FocusEventPlugin.EVENT_SYNTHETIC_BLUR;
