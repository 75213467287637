import moment from 'moment';
import { Action } from 'redux';
import { GetState, DispatchFunc } from 'src/reducers';
import { getCurrentAppId, getCurrentAppStore, getCurrentStoreAppId, getIntlAppStorePref } from 'src/selectors/current_app';
import { AppStoreService, fetchRatingsPayload, fetchStarRatingsApiCall, FetchStarRatingsResp } from 'src/services/app-store-service';
import { Platform } from 'src/types/core';

export enum RatingActions {
  FETCH_RATINGS_PENDING = 'FETCH_RATINGS_PENDING',
  FETCH_RATINGS_SUCCESS = 'FETCH_RATINGS_SUCCESS',
  FETCH_RATINGS_ERROR = 'FETCH_RATINGS_ERROR',

  FETCH_RATINGS_UNIQUE_PENDING = 'FETCH_RATINGS_UNIQUE_PENDING',
  FETCH_RATINGS_UNIQUE_SUCCESS = 'FETCH_RATINGS_UNIQUE_SUCCESS',
  FETCH_RATINGS_UNIQUE_ERROR = 'FETCH_RATINGS_UNIQUE_ERROR',
}

export type RatingReducerAction = Action<RatingActions> & {
  payload: Partial<{
    appId: string;
    json?: FetchStarRatingsResp;
  }>;
};

export type RatingsDispatch = DispatchFunc<RatingReducerAction>;
export interface StarRatingActions {
  fetchRatings: (startDate: number, endDate: number) => Promise<RatingReducerAction>
  fetchRatingsUnique: () => Promise<RatingReducerAction>
}

const EMPTY_RATINGS_RESP: FetchStarRatingsResp = {
  data: [],
  has_more: false,
  min_key: 0,
  page_size: 0,
  type: 'RATINGS_HISTOGRAM',
}

const wrapWithPagination = (
  apiCall: fetchStarRatingsApiCall,
  args: fetchRatingsPayload
): Promise<FetchStarRatingsResp> => {
  const handlePagination = (json: FetchStarRatingsResp): Promise<FetchStarRatingsResp> => {
    return apiCall({ ...args, minKey: json.min_key })
      .then(ress => {
        if (!ress.has_more) {
          return new Promise(resolve => resolve({ ...ress, data: [...json.data, ...ress.data] }))
        }

        return handlePagination({ ...json, data: [...json.data, ...ress.data], min_key: ress.min_key })
      })
  }

  return apiCall(args)
    .then((json: FetchStarRatingsResp) => {
      if (!json.has_more) {
        return new Promise(resolve => resolve(json))
      }
      return handlePagination(json)
    })
}

export const starRatings = {
  fetchRatings: (startDate: number, end: number) => {
    return async (dispatch: RatingsDispatch, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);
      const store = getCurrentAppStore(state);
      const storeAppId = getCurrentStoreAppId(state);

      const start = moment(startDate).clone()

        // buffer of start date to the preceding week in order to compute diffs on the first week
        .startOf('isoWeek')
        .subtract(1, 'weeks')
        .toDate()
        .valueOf()
      // default to US if the store app is iTunes and isn't opted into international data
      // android can't be filtered so exclude the region

      const isRegionUS = !!(store && store.toLowerCase() !== Platform.Android.toLowerCase()) && !getIntlAppStorePref(state)

      dispatch({ type: RatingActions.FETCH_RATINGS_PENDING, payload: { appId } });
      if (!store || !storeAppId) {
        return dispatch({ type: RatingActions.FETCH_RATINGS_ERROR, payload: { appId } });
      }

      const payload: fetchRatingsPayload = {
        store,
        storeAppId,
        start,
        end,
        isRegionUS,
        minKey: 0,
      }

      return wrapWithPagination(AppStoreService.fetchStarRatings, payload)
        .then((json: FetchStarRatingsResp) => dispatch({ type: RatingActions.FETCH_RATINGS_SUCCESS, payload: { appId, json } }))
        .catch(() => dispatch({ type: RatingActions.FETCH_RATINGS_ERROR, payload: { appId, json: EMPTY_RATINGS_RESP } }))
    }
  },

  fetchRatingsUnique: () => {
    return async (dispatch: RatingsDispatch, getState: GetState) => {
      const state = getState();
      const appId = getCurrentAppId(state);
      const store = getCurrentAppStore(state);
      const storeAppId = getCurrentStoreAppId(state);
      dispatch({ type: RatingActions.FETCH_RATINGS_UNIQUE_PENDING, payload: { appId } });

      const isRegionUS = !!(store && store.toLowerCase() !== Platform.Android.toLowerCase()) && !getIntlAppStorePref(state)
      if (!store || !storeAppId) {
        return dispatch({ type: RatingActions.FETCH_RATINGS_UNIQUE_ERROR, payload: { appId } });
      }

      const payload: fetchRatingsPayload = {
        store,
        storeAppId,
        start: 0,
        end: 0,
        isRegionUS,
        minKey: 0,
      }

      return wrapWithPagination(AppStoreService.fetchStarRatingsUnique, payload)
        .then((json: FetchStarRatingsResp) => dispatch({ type: RatingActions.FETCH_RATINGS_UNIQUE_SUCCESS, payload: { appId, json } }))
        .catch(() => dispatch({ type: RatingActions.FETCH_RATINGS_UNIQUE_ERROR, payload: { appId, json: EMPTY_RATINGS_RESP } }));
    }
  },
}
