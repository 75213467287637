import React from 'react';
import { bemPrefix, Formatter } from 'src/utils';
import { stripHTML } from 'src/utils/strip_html';

import './interaction-action-answers.scss';

const bem = bemPrefix('interaction-action-answers');

const getResponses = (count: number, labels: [string, string]) => (count === 1 ? `${Formatter.number(count)} ${labels[0]}` : `${Formatter.number(count)} ${labels[1]}`);

export const getReportPercents = (count: number, total: number) => (total !== 0) ? `${((count * 100) / total).toFixed(2)}%` : '----';

export const REPORT_COLORS = [
  '#1F77B4',
  '#AEC7E8',
  '#FF7F0E',
  '#FFBB78',
  '#2CA02C',
  '#98DF8A',
  '#D62728',
  '#FF9896',
  '#9467BD',
  '#C5B0D5',
  '#8C564B',
  '#C49C94',
  '#E377C2',
  '#F7B6D2',
  '#7F7F7F',
  '#C7C7C7',
];

export interface InteractionActionAnswersProps {
  className?: string;
  answers: { label: string; count: number; subLabel?: string; classNamePrefix?: string }[];
  totalCount: number;
  responseLabels: [string, string];
  colors?: typeof REPORT_COLORS;
}

export const InteractionActionAnswers: React.FC<InteractionActionAnswersProps> = ({
  className = '',
  answers,
  totalCount,
  responseLabels = ['Response', 'Responses'],
  colors = REPORT_COLORS,
}) => (
  <ul className={`${bem()} ${className}`}>
    {answers.map((answer, i) => (
      <li key={answer.label} className={bem('answer-item', answer.classNamePrefix)}>
        <div className="left flex-center">
          <div className="color" style={{ background: colors[i] }} />
          {answer.subLabel ? (
            <div>
              <div className="text">{stripHTML(answer.label)}</div>
              <div className="text-desc">{answer.subLabel}</div>
            </div>
          ) : (
            <div className="text">{stripHTML(answer.label)}</div>
          )}
        </div>
        <div className="right">
          <div className="percent">{getReportPercents(answer.count, totalCount)}</div>
          <div className="count">{getResponses(answer.count, responseLabels)}</div>
        </div>
      </li>
    ))}
  </ul>
);
