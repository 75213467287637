import React from 'react';

import PropTypes from 'prop-types';

import './snack-bar.scss';

export class SnackBar extends React.Component {
  static propTypes = {
    acceptText: PropTypes.string,
    declineText: PropTypes.string,
    children: PropTypes.node,
    showButtons: PropTypes.bool,
    onAccept: PropTypes.func,
    onDecline: PropTypes.func,
    onMount: PropTypes.func,
  };

  static defaultProps = {
    acceptText: 'Okay',
    declineText: 'Cancel',
    children: null,
    showButtons: false,
  };

  componentDidMount() {
    if (this.props.onMount) {
      this.props.onMount();
    }
  }

  decline = () => {
    if (this.props.onDecline) {
      this.props.onDecline();
    }
  };

  accept = () => {
    if (this.props.onAccept) {
      this.props.onAccept();
    }
  };

  render() {
    const { acceptText, declineText, children, showButtons } = this.props;
    return (
      <div className="snack-bar">
        {children}
        {
          showButtons ? (
            <div className="buttons">
              <button className="decline" onClick={this.decline}>{declineText}</button>
              <button className="accept" onClick={this.accept}>{acceptText}</button>
            </div>
          ) : null
        }
      </div>
    );
  }
}

export default SnackBar;
