import 'mdn-polyfills/Number.isInteger';
import { merge, path } from 'ramda';
import { combineReducers } from 'redux';
import { Time, getDateRangePeriodName, daysAgoInbound } from 'src/utils/time';

import * as actions from 'src/actions';
import * as orgActions from 'src/actions/organizations';
import * as ratingsActions from 'src/actions/ratings';
import * as enjoymentActions from 'src/actions/enjoyment';
import { UPDATE_CURRENT_APP_SUCCESS } from 'src/actions/session';
import * as activeUserActions from 'src/actions/active_users';
import { history } from 'src/store/history';
import { FETCH_INBOUND_MESSAGE_COUNT } from 'src/actions/conversations/messages';
import { fanSignals } from './dashboard/fan-signals.reducer';
import { starRatings } from './dashboard/star-rating.reducer';
import { starRatingsUnique } from './dashboard/star-rating-unique.reducer';
import retention from './retention';

const createGraphReducer = (fetchAction, receiveAction, errorAction, dataSelector) => {
  const initial = {
    loading: true,
    fetchError: false,
    data: [],
  };
  return (state = initial, action = {}) => {
    switch (action.type) {
      case fetchAction:
        return merge(state, {
          loading: true,
          fetchError: false,
        });
      case receiveAction: {
        const data = dataSelector ? dataSelector(action) : path(['payload', 'data'], action);
        return merge(state, {
          loading: false,
          fetchError: false,
          data,
        });
      }
      case errorAction:
        return merge(state, {
          loading: false,
          fetchError: true,
        });
      case UPDATE_CURRENT_APP_SUCCESS: {
        return initial;
      }
      default:
        return state;
    }
  };
};

const newAppId = (state = null, action = {}) => {
  switch (action.type) {
    case actions.CLEAR_NEW_APP:
      return null;
    case actions.CREATE_APP_SUCCESS:
      if (action.payload && action.payload.id) {
        return action.payload.id;
      }
      return state;
    case orgActions.CREATE_ORGANIZATION_SUCCESS:
      if (action.payload && action.payload.created_app_id) {
        return action.payload.created_app_id;
      }
      return state;
    default:
      return state;
  }
};

const reviewsCount = (state = {}, action = {}) => {
  switch (action.type) {
    case ratingsActions.FETCH_REVIEWS_COUNT_SUCCESS:
      if (action.payload && action.payload.insights) {
        return { count: action.payload.insights.total };
      }
      return state;
    default:
      return state;
  }
};

const dauData = (state = [], action = {}) => {
  switch (action.type) {
    case actions.RECEIVE_DAU:
      return action.payload;
    default:
      return state;
  }
};

const reachData = (state = {}, action = {}) => {
  switch (action.type) {
    case actions.RECEIVED_REACH:
      return action.payload;
    default:
      return state;
  }
};

const dateAnnotations = (state = [], action = {}) => {
  switch (action.type) {
    case actions.RECEIVED_DATE_ANNOTATIONS:
      return action.payload;
    default:
      return state;
  }
};

const normalizeStartDateParam = () => {
  const asVal = parseInt(history.getCurrentLocation().query.startDate, 10);
  return Number.isInteger(asVal) ? asVal : null;
};
const normalizeEndDateParam = () => {
  const asVal = parseInt(history.getCurrentLocation().query.endDate, 10);
  return Number.isInteger(asVal) ? asVal : null;
};
export const initialDateRange = () => {
  const startDate = Time.create(normalizeStartDateParam() || daysAgoInbound(30)).startOf('day').valueOf();
  const endDate = Time.create(normalizeEndDateParam() || undefined).endOf('day').valueOf();
  const name = getDateRangePeriodName(startDate, endDate);
  return {
    startDate,
    endDate,
    name,
  };
};
export const dateRange = (state = initialDateRange(), action = {}) => {
  switch (action.type) {
    case actions.UPDATE_DASHBOARD_DATE_RANGE:
      return {
        startDate: action.payload.start,
        endDate: action.payload.end,
        name: action.payload.name,
      };
    default:
      return state;
  }
};

const xls = (state = {}, action = {}) => {
  switch (action.type) {
    case 'RECEIVED_XLS_FILE': {
      return true;
    }
    default:
      return state;
  }
};

export const initialProfile = {
  updateUserLoading: false,
  updateUserSuccess: false,
  updateUserErrored: false,
  updateUserErrors: [],
};
export const profile = (state = initialProfile, action = {}) => {
  switch (action.type) {
    case actions.UPDATE_USER_PENDING:
      return {
        updateUserLoading: true,
        updateUserSuccess: false,
        updateUserErrored: false,
        updateUserErrors: [],
      };
    case actions.UPDATE_USER_SUCCESS:
      return {
        updateUserLoading: false,
        updateUserSuccess: !action.payload.errors,
        updateUserErrored: !!action.payload.errors,
        updateUserErrors: action.payload.errors || [],
      };
    case actions.UPDATE_USER_FAILURE:
      return {
        updateUserLoading: false,
        updateUserSuccess: false,
        updateUserErrored: true,
        updateUserErrors: action.payload.errors || [],
      };
    default:
      return state;
  }
};

export const initialChangePassword = {
  changePasswordLoading: false,
  changePasswordSuccess: false,
  changePasswordErrored: false,
};
export const changePassword = (state = initialChangePassword, action = {}) => {
  switch (action.type) {
    case actions.CHANGE_PASSWORD_PENDING:
      return {
        changePasswordLoading: true,
        changePasswordSuccess: false,
        changePasswordErrored: false,
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        changePasswordLoading: false,
        changePasswordSuccess: true,
        changePasswordErrored: false,
      };
    case actions.CHANGE_PASSWORD_FAILURE:
      return {
        changePasswordLoading: false,
        changePasswordSuccess: false,
        changePasswordErrored: true,
      };
    default:
      return state;
  }
};

export default combineReducers({
  profile,
  changePassword,
  newAppId,
  dauData,
  reviewsCount,
  reachData,
  dateAnnotations,
  dateRange,
  xls,
  retention,
  fanSignals,
  starRatings,
  starRatingsUnique,
  inboundMessageCount: createGraphReducer(
    FETCH_INBOUND_MESSAGE_COUNT.PENDING,
    FETCH_INBOUND_MESSAGE_COUNT.SUCCESS,
    FETCH_INBOUND_MESSAGE_COUNT.ERROR
  ),
  interactionCount: createGraphReducer(
    actions.REQUEST_INTERACTION_COUNT,
    actions.RECEIVED_INTERACTION_COUNT,
    actions.ERROR_FETCHING_INTERACTION_COUNT
  ),
  surveyCount: createGraphReducer(
    actions.REQUEST_SURVEY_COUNT,
    actions.RECEIVED_SURVEY_COUNT,
    actions.ERROR_FETCHING_SURVEY_COUNT
  ),
  surveyAggregates: createGraphReducer(
    actions.REQUEST_SURVEY_AGGREGATES,
    actions.RECEIVE_SURVEY_AGGREGATES,
    actions.ERROR_FETCHING_SURVEY_AGGREGATES
  ),
  notesCount: createGraphReducer(
    actions.REQUEST_NOTES_COUNT,
    actions.RECEIVE_NOTES_COUNT,
    actions.ERROR_FETCHING_NOTES_COUNT
  ),
  notesAggregates: createGraphReducer(
    actions.REQUEST_NOTES_AGGREGATES,
    actions.RECEIVE_NOTES_AGGREGATES,
    actions.ERROR_FETCHING_NOTES_AGGREGATES
  ),
  loveRatioOverTime: createGraphReducer(
    enjoymentActions.REQUEST_LOVE_RATIO_OVER_TIME,
    enjoymentActions.RECEIVED_LOVE_RATIO_OVER_TIME,
    enjoymentActions.ERROR_REQUESTING_LOVE_RATIO_OVER_TIME
  ),
  loveRatioAggregate: createGraphReducer(
    enjoymentActions.REQUEST_LOVE_RATIO_AGGREGATION,
    enjoymentActions.RECEIVED_LOVE_RATIO_AGGREGATION,
    enjoymentActions.ERROR_REQUESTING_LOVE_RATIO_AGGREGATION
  ),
  loveRatioPreviousPeriod: createGraphReducer(
    enjoymentActions.REQUEST_LOVE_RATIO_PREVIOUS_PERIOD,
    enjoymentActions.RECEIVED_LOVE_RATIO_PREVIOUS_PERIOD,
    enjoymentActions.ERROR_REQUESTING_LOVE_RATIO_PREVIOUS_PERIOD
  ),
  ratingsDialogAggregate: createGraphReducer(
    enjoymentActions.REQUEST_RATINGS_DIALOG_AGGREGATION,
    enjoymentActions.RECEIVED_RATINGS_DIALOG_AGGREGATION,
    enjoymentActions.ERROR_REQUESTING_RATINGS_DIALOG_AGGREGATION
  ),
  ratingsDialogAggregatePreviousPeriod: createGraphReducer(
    enjoymentActions.REQUEST_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
    enjoymentActions.RECEIVED_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
    enjoymentActions.ERROR_REQUESTING_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD
  ),
  ratingsDialogOvertime: createGraphReducer(
    enjoymentActions.REQUEST_RATINGS_DIALOG_OVER_TIME,
    enjoymentActions.RECEIVED_RATINGS_DIALOG_OVER_TIME,
    enjoymentActions.ERROR_REQUESTING_RATINGS_DIALOG_OVER_TIME
  ),
  appleRatingsDialogAggregate: createGraphReducer(
    enjoymentActions.REQUEST_APPLE_RATINGS_DIALOG_AGGREGATION,
    enjoymentActions.RECEIVED_APPLE_RATINGS_DIALOG_AGGREGATION,
    enjoymentActions.ERROR_REQUESTING_APPLE_RATINGS_DIALOG_AGGREGATION
  ),
  appleRatingsDialogAggregatePreviousPeriod: createGraphReducer(
    enjoymentActions.REQUEST_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
    enjoymentActions.RECEIVED_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
    enjoymentActions.ERROR_REQUESTING_APPLE_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD
  ),
  appleRatingsDialogOvertime: createGraphReducer(
    enjoymentActions.REQUEST_APPLE_RATINGS_DIALOG_OVER_TIME,
    enjoymentActions.RECEIVED_APPLE_RATINGS_DIALOG_OVER_TIME,
    enjoymentActions.ERROR_REQUESTING_APPLE_RATINGS_DIALOG_OVER_TIME
  ),
  inAppRatingsDialogAggregate: createGraphReducer(
    enjoymentActions.REQUEST_IN_APP_RATINGS_DIALOG_AGGREGATION,
    enjoymentActions.RECEIVED_IN_APP_RATINGS_DIALOG_AGGREGATION,
    enjoymentActions.ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_AGGREGATION
  ),
  inAppRatingsDialogAggregatePreviousPeriod: createGraphReducer(
    enjoymentActions.REQUEST_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
    enjoymentActions.RECEIVED_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD,
    enjoymentActions.ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_AGGREGATION_PREVIOUS_PERIOD
  ),
  inAppRatingsDialogOvertime: createGraphReducer(
    enjoymentActions.REQUEST_IN_APP_RATINGS_DIALOG_OVER_TIME,
    enjoymentActions.RECEIVED_IN_APP_RATINGS_DIALOG_OVER_TIME,
    enjoymentActions.ERROR_REQUESTING_IN_APP_RATINGS_DIALOG_OVER_TIME
  ),
  dailyActiveUsers: createGraphReducer(
    activeUserActions.FETCH_DAILY_ACTIVE_USERS_PENDING,
    activeUserActions.FETCH_DAILY_ACTIVE_USERS_SUCCESS,
    activeUserActions.FETCH_DAILY_ACTIVE_USERS_FAILURE
  ),
  monthlyActiveUsers: createGraphReducer(
    activeUserActions.FETCH_MONTHLY_ACTIVE_USERS_PENDING,
    activeUserActions.FETCH_MONTHLY_ACTIVE_USERS_SUCCESS,
    activeUserActions.FETCH_MONTHLY_ACTIVE_USERS_FAILURE
  ),
  starRatingsIntl: createGraphReducer(
    ratingsActions.FETCH_RATINGS_INTL_PENDING,
    ratingsActions.FETCH_RATINGS_INTL_SUCCESS,
    ratingsActions.FETCH_RATINGS_INTL_FAILURE
  ),
  starRatingsIntlDay: createGraphReducer(
    ratingsActions.FETCH_RATINGS_INTL_UNIQUE_PENDING,
    ratingsActions.FETCH_RATINGS_INTL_UNIQUE_SUCCESS,
    ratingsActions.FETCH_RATINGS_INTL_UNIQUE_FAILURE,
    path(['payload', 'json']),
  ),
});
