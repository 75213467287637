import { isEmpty } from 'ramda';
import { SortOrder } from 'src/utils';
import { TagsService } from 'src/services';
import { fetchConversationMessages, fetchConversations, fetchConversation } from './conversations';

interface GetConversationsParams {
  appId: string;
  page: number;
  limit: number;
  filter: string;
  sort: {
    sortBy: string;
    order: SortOrder;
  };
}

export const fetchConversationMessagesWithTags = (...params: any[]): Promise<any> => new Promise(async (resolve, reject) => {
  try {
    const { messages } = await fetchConversationMessages(...params);
    const elasticSearchIds: string[] = messages.map(({ id }: any): string => id);
    const { tags } = await TagsService.fetchTagsV2(elasticSearchIds);

    resolve({
      messages: messages.map((message: any) => ({
        ...message,
        customer_tags: tags.reduce((acc: any[], { elasticsearch_id, tag_name: tagName }: any) => {
          if (elasticsearch_id === message.id) {
            const tag = {
              tagName,
            };
            acc.push(tag);
          }
          return acc;
        }, []),
      })),
    });
  } catch (e) {
    reject(e);
  }
});

const mapTags = (items: string[] | undefined): ({ tag: string })[] => (Array.isArray(items) ? items.map((tag) => ({ tag })) : []);

export const fetchConversationsWithTags = (params: GetConversationsParams) => new Promise(async (resolve, reject) => {
  try {
    const result = await fetchConversations(params);
    const ids = result.conversations.map(({ id }: any) => id);

    if (isEmpty(ids)) {
      resolve(result);
      return;
    }

    try {
      const tags = await TagsService.fetchConversationsTags(ids);

      resolve({
        ...result,
        conversations: result.conversations.map((item: any) => ({
          ...item,
          tags: mapTags(tags[item.id]),
        })),
      });
      return;
    } catch (e) {
      // if no tags - use old ones at least
      console.error('conversations map tags:', e);
    }
    resolve(result);
  } catch (e) {
    reject(e);
  }
});

export const fetchConversationWithTags = (appId: string, conversationId: string) => new Promise(async (resolve, reject) => {
  try {
    const conversation = await fetchConversation(appId, conversationId);
    try {
      const tags = await TagsService.fetchConversationTags(conversationId);
      resolve({
        ...conversation,
        tags: mapTags(tags),
      });
      return;
    } catch (e) {
      console.error('conversation map tags:', e);
    }

    resolve(conversation);
  } catch (e) {
    reject(e);
  }
});
