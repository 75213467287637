import React, { useRef, useCallback, useEffect } from 'react';
import { identity } from 'ramda';
import { bemPrefix } from '../../../utils';
import { InputText, InputTextProps } from '../input';
import { Button } from '../button';
import { Icon } from '../icons';

import './search-field.scss';

export interface SearchFieldProps extends Pick<InputTextProps, 'placeholder'> {
  className?: string;
  text?: string;
  submitSearch?: boolean;
  onSearch(text: string, tags?: string[]): void;
}

const bem = bemPrefix('search-field');

const PLACHOLDER_SEARCH = 'Search for any keywords or Tags with "tags: tag1, tag2"';
const TAGS_PREFIX = 'tags:';

// TODO: Refactor to make common
export const SearchField: React.FC<SearchFieldProps> = ({
  className = '',
  placeholder = PLACHOLDER_SEARCH,
  text = '',
  submitSearch = true,
  onSearch,
}) => {
  const inputEl = useRef<HTMLInputElement | null>(null);

  const onSubmit = useCallback(() => {
    if (inputEl.current) {
      const value = inputEl.current.value.trim();
      const tagsInText = getTagsFromSearchText(value);
      const onlyPrefix = value.startsWith(TAGS_PREFIX);
      if (tagsInText) {
        onSearch('', tagsInText);
      } else if (!onlyPrefix) {
        onSearch(value);
      }
    }
  }, [inputEl.current, onSearch]);

  const onEnter = useCallback(
    (_e: React.KeyboardEvent) => {
      if (submitSearch) {
        onSubmit();
      }
    },
    [submitSearch, onSubmit]
  );

  const onClear = useCallback(() => onSearch(''), [onSearch]);

  useEffect(() => {
    if (inputEl.current) {
      inputEl.current.value = text;
    }
  }, [inputEl.current && inputEl.current.value, text]);

  return (
    <div className={`${bem()} ${className}`}>
      <InputText
        className={bem('search-input')}
        type="search"
        placeholder={placeholder}
        onSubmit={onSubmit}
        onEnter={onEnter}
        innerRef={inputEl}
      />
      {text ? (
        <Button className={bem('button-search')}>
          <Icon name="close" onClick={onClear} title="Clear" />
        </Button>
      ) : (
        <Button className={bem('button-search')} type="submit" onClick={onSubmit}>
          Search
        </Button>
      )}
    </div>
  );
};

SearchField.displayName = 'SearchField';

function getTagsFromSearchText(text: string = ''): string[] | null {
  const tags = text.match(RegExp(`${TAGS_PREFIX}*([^;]*)`));

  if (tags && tags[1]) {
    return tags[1]
      .split(',')
      .map(
        (item) => item
          .toLowerCase()
          .trim() // remove extra start&end spaces
          .replace(/\s{2,}/g, ' ') // remove repeating spaces
      )
      .filter(identity);
  }

  return null;
}
