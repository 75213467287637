import React, { useCallback } from 'react';
import { bemPrefix } from 'src/utils';

interface Option {
  value: any;
  label: React.ReactNode;
  disabled?: boolean;
}

export interface InputRadioGroupProps {
  className?: string;
  name: string;
  options: Option[];
  selectedValue: Option['label'];
  onChange(value: Option['label']): void;
}

const bem = bemPrefix('input-radio-group');

const getId = (value: any) => `for_${String(value)}`;

export const InputRadioGroup: React.FC<InputRadioGroupProps> = ({
  className = '',
  name,
  selectedValue,
  options,
  onChange,
}) => {
  const onValueChange = useCallback((value: Option['value']) => () => onChange(value), [onChange]);

  return (
    <div className={`${bem('', String(selectedValue))} ${className}`}>
      {options.map((option) => (
        <label
          className={bem('item', { disabled: !!option.disabled })}
          htmlFor={getId(option.value)}
          key={getId(option.value)}
        >
          <input
            className={bem('item__input')}
            id={getId(option.value)}
            type="radio"
            name={name}
            value={option.value}
            disabled={option.disabled}
            checked={selectedValue === option.value}
            onChange={onValueChange(option.value)}
          />
          <div className={bem('item__text')}>{option.label}</div>
        </label>
      ))}
    </div>
  );
};
