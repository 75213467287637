import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { setPageTitleOnMount } from 'src/hooks/usePageTitle';
import '../../components/styles/molecules/alert_box.scss';
import '../../components/styles/email_preferences_update.scss';

import Check from '../../components/assets/check.jsx.svg';

// NOTE: This is only a status page. The actual GET is done against /email_preferences/type linked from the email itself, and redirects here aftewards.
export default class EmailPreferencesUpdate extends Component {
  static displayName = 'EmailPreferencesUpdate';
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        to: PropTypes.string,
        fail: PropTypes.string,
        from: PropTypes.string,
      }),
    }),
  };

  static defaultProps = {
    location: {
      query: {},
    },
  };

  componentDidMount() {
    setPageTitleOnMount('Email Preference Update', { oldTitle: 'Email Preference Update - Alchemer' });
  }

  parseParams = (location) => {
    const params = new URLSearchParams(location.search);
    if (params.get('fail') != null) {
      return {
        message: 'There was an issue changing your email preferences.',
        status_class: 'error',
      };
    }
    const to = params.get('to');
    const from = params.get('from') || '';
    if (to === 'unsubscribed') {
      return {
        message: `You have successfully unsubscribed from ${from} Review emails.`,
        status_class: 'success',
      };
    }
    if (to != null) {
      return {
        message: `You will now receive ${to} emails.`,
        status_class: 'success',
      };
    }
    return {
      message: 'There was an unknown error changing your email preferences.',
      status_class: 'error',
    };
  };

  render() {
    const { message, status_class } = this.parseParams(this.props.location);

    return (
      <div className="email-preferences-update">
        <div className={`alert-box radius ${status_class}`}>{message}</div>
        <br />
        <Check />
        <br />
        <a href="/email-preferences">Login to manage Email Preferences</a>
      </div>
    );
  }
}
