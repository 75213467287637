import { tryCatch } from 'ramda';
import { AppEventItem, AppEventList } from 'src/reducers/events';

const HUMANIZED_EVENT_NAMES: Record<string, string> = {
  'app.exit': 'App Exit',
  'app.launch': 'App Launch',
  'enjoyment_dialog.cancel': 'Love Dialog: Cancel',
  'enjoyment_dialog.dismiss': 'Love Dialog: Dismiss',
  'enjoyment_dialog.launch': 'Love Dialog: Launch',
  'enjoyment_dialog.yes': 'Love Dialog: Yes',
  'enjoyment_dialog.no': 'Love Dialog: No',
  'text_modal.launch': 'Note: Launch',
  'text_modal.cancel': 'Note: Cancel',
  'text_modal.dismiss': 'Note: Dismiss',
  'text_modal.interaction': 'Note: Button Click',
  'navigate_to_link.navigate': 'Note: Button Action Invoked',
  'survey.cancel': 'Survey: Cancel',
  'survey.close': 'Survey: Close',
  'survey.launch': 'Survey: Launch',
  'survey.send': 'Survey: Send',
  'survey.submit': 'Survey: Submit',
  'survey.question_response': 'Survey: Answered Question',
  'rating_dialog.launch': 'Apptentive Rating Dialog: Launch',
  'rating_dialog.decline': 'Apptentive Rating Dialog: Decline',
  'rating_dialog.rate': 'Apptentive Rating Dialog: Rate',
  'rating_dialog.remind': 'Apptentive Rating Dialog: Remind',
  'rating_dialog.yes': 'Apptentive Rating Dialog: Yes',
  'apple_rating_dialog.request': 'Apple Rating Dialog: Display Attempt',
  'apple_rating_dialog.shown': 'Apple Rating Dialog: Display Success',
  'apple_rating_dialog.not_shown': 'Apple Rating Dialog: Display Skipped',
  'apple_rating_dialog.fallback': 'Apple Rating Dialog: Fallback Interaction Invoked',
};

export const eventNameToHuman = (eventName: string): string => {
  const name = tryCatch(
    () => decodeURIComponent(eventName.replace('local#app#', '')),
    () => eventName.replace('local#app#', ''),
  )();
  return HUMANIZED_EVENT_NAMES[name] || name;
};

export const eventNameFromHuman = (eventName: string): string => `local#app#${encodeURIComponent(eventName)}`;

export function humanizedEventNameList(eventNames: string[] | string | undefined): string {
  if (!eventNames) {
    return '';
  }

  return Array.isArray(eventNames)
    ? eventNames.map((eventName) => eventNameToHuman(eventName)).join(', ')
    : eventNameToHuman(eventNames);
}

export type UniqueEvent = Pick<AppEventItem, 'label' | 'last_seen_at'>;

export const getUniqueEvents = (multipleEvents: AppEventList[]) => {
  const uniqueTitles = new Map<string, UniqueEvent>();
  multipleEvents?.forEach((app) => {
    app.items.forEach((item) => {
      const decodedLabel = tryCatch(
        () => decodeURIComponent(item.label),
        () => item.label,
      )();
      if (!uniqueTitles.has(item.label)) {
        uniqueTitles.set(item.label, { label: decodedLabel, last_seen_at: item.last_seen_at });
      }
    });
  });
  const sortedUniqueTitles = Array.from(uniqueTitles.values()).sort((a, b) => a.label.localeCompare(b.label));
  return sortedUniqueTitles;
};
