import 'mdn-polyfills/Array.prototype.includes';
import { isEmpty } from 'ramda';
import demoData from 'src/utils/demo_data_generator';
import demoAppIds from 'src/utils/demo_app_ids';
import { isDemoEnv } from 'src/utils/environment';
import { Platform } from 'src/types/core';
import { localDateString } from 'src/utils/date_format';

import { atriumCall, dedupeBy, report } from './util';
import {
  fetchRatingsDialogOvertimeDemoData,
  fetchRatingsDialogAggregationDemoData,
  fetchAppleRatingsDialogOvertimeDemoData,
  fetchAppleRatingsDialogAggregationDemoData,
  fetchInAppRatingsDialogOvertimeDemoData,
  fetchInAppRatingsDialogAggregationDemoData,
  fetchRetentionDemoData,
  generateActiveUsersDemoData,
} from './demo_util';

export { fetchDateAnnotations } from './date_annotations';

// Routes
export const fetchDAU = (appId, start, end) =>
  atriumCall(`/apps/${appId}/daily-active-users?start=${localDateString(start)}&end=${localDateString(end)}`, {
    error: (e) => report('[API] Error Fetching Daily Active Users for', appId, 'from', start, 'to', end, e),
  });

export const fetchApp = dedupeBy(
  (appId) => appId,
  async (appId) => {
    const appData = await atriumCall(`/apps/${appId}`, {
      error: (e) => report('[API] Error Fetching App', appId, e),
    });

    const storeId = appData.store_app_id;
    if (appData.platform === Platform.Web || !storeId) {
      return {
        ...appData,
        rating_before_apptentive: null,
      };
    }

    const store = appData.platform === Platform.iOS ? 'itunes' : appData.platform.toLowerCase();
    let ratingsData = {};
    try {
      ratingsData = await atriumCall(`/apps/${storeId}/${store}/ratings-before`, {
        error: (e) => report('[API] Error Fetching App Ratings', appId, e),
      });
    } catch (e) {
      console.error('Error Fetching App Ratings:', e);
    }

    return {
      ...appData,
      rating_before_apptentive:
        ratingsData.data && !isEmpty(ratingsData.data) ? ratingsData.data[0].before_integration : null,
    };
  },
);

export const saveAppStyles = (appId, styles) =>
  atriumCall(`/apps/${appId}/styles`, {
    method: 'PUT',
    body: JSON.stringify({ app: { id: appId, styles } }),
    error: (e) => report('[API] Error PUT update app styles', e),
  });

export const fetchReach = (appId) =>
  atriumCall(`/apps/${appId}/reach`, {
    error: (e) => report('[API] Error fetching Reach data', e),
  });

export const fetchRollup = (appIds, startDate, endDate) =>
  atriumCall(`/rollup?startDate=${localDateString(startDate)}&endDate=${localDateString(endDate)}`, {
    method: 'POST',
    body: JSON.stringify({ appIds }),
    error: (e) => report('[API] Error fetching rollup', e),
  });

export const bustMasterRollupCache = (appId, startDate, endDate) =>
  atriumCall(`/rollup/invalidateCache?startDate=${localDateString(startDate)}&endDate=${localDateString(endDate)}`, {
    method: 'POST',
    body: JSON.stringify({ appIds: [appId] }),
    error: (e) => report('[API] Error fetching rollup', e),
  });

export const fetchCurrentUserFeatures = (appId, userId) =>
  atriumCall(`/apps/${appId}/features?user_id=${userId}`, {
    error: (e) => report('[API] Error Fetching App', appId, e),
  });

export const fetchRatingsDialogAggregation = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchRatingsDialogAggregationDemoData(startDate, endDate, 'fetchRatingsDialogAggregation');
  }

  return atriumCall(
    `/apps/${appId}/ratings-dialog-aggregate?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}`,
    {
      method: 'GET',
      error: (e) =>
        report(`[API] Error Fetching Rating Dialog Aggregation for ${appId} from ${startDate} to ${endDate}`, e),
    },
  );
};

export const fetchRatingsDialogAggregationPreviousPeriod = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchRatingsDialogAggregationDemoData(startDate, endDate, 'fetchRatingsDialogAggregationPreviousPeriod');
  }

  return atriumCall(
    `/apps/${appId}/ratings-dialog-aggregate?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}`,
    {
      method: 'GET',
      error: (e) =>
        report(
          `[API] Error Fetching Rating Dialog Aggregation (Previous Period) for ${appId} from' ${startDate} to ${endDate}`,
          e,
        ),
    },
  );
};

export const fetchRatingsDialogOvertime = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchRatingsDialogOvertimeDemoData(startDate, endDate);
  }

  return atriumCall(
    `/apps/${appId}/ratings-dialog-timeseries?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}&all_dates=true`,
    {
      method: 'GET',
      error: (e) =>
        report(`[API] Error Fetching Rating Dialog Over Time for${appId} from' ${startDate} to ${endDate}`, e),
    },
  );
};

export const fetchAppleRatingsDialogAggregation = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchAppleRatingsDialogAggregationDemoData(startDate, endDate, 'fetchAppleRatingsDialogAggregation');
  }

  return atriumCall(
    `/apps/${appId}/apple-ratings-dialog-aggregate?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}`,
    {
      method: 'GET',
      error: (e) =>
        report(`[API] Error Fetching Apple Rating Dialog Aggregation for${appId} from' ${startDate} to ${endDate}`, e),
    },
  );
};

export const fetchAppleRatingsDialogAggregationPreviousPeriod = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchAppleRatingsDialogAggregationDemoData(
      startDate,
      endDate,
      'fetchAppleRatingsDialogAggregationPreviousPeriod',
    );
  }

  return atriumCall(
    `/apps/${appId}/apple-ratings-dialog-aggregate?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}`,
    {
      method: 'GET',
      error: (e) =>
        report(
          `[API] Error Fetching Apple Rating Dialog Aggregation (Previous Period) for${appId} from' ${startDate} to ${endDate}`,
          e,
        ),
    },
  );
};

export const fetchAppleRatingsDialogOvertime = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchAppleRatingsDialogOvertimeDemoData(startDate, endDate);
  }

  return atriumCall(
    `/apps/${appId}/apple-ratings-dialog-timeseries?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}&all_dates=true`,
    {
      method: 'GET',
      error: (e) =>
        report(`[API] Error Fetching Apple Rating Dialog Over Time for${appId} from' ${startDate} to ${endDate}`, e),
    },
  );
};

export const fetchInAppRatingsDialogAggregation = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchInAppRatingsDialogAggregationDemoData(startDate, endDate, 'fetchInAppRatingsDialogAggregation');
  }

  return atriumCall(
    `/apps/${appId}/in-app-ratings-dialog-aggregate?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}`,
    {
      method: 'GET',
      error: (e) =>
        report(`[API] Error Fetching In-App Rating Dialog Aggregation for${appId} from' ${startDate} to ${endDate}`, e),
    },
  );
};

export const fetchInAppRatingsDialogAggregationPreviousPeriod = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchInAppRatingsDialogAggregationDemoData(
      startDate,
      endDate,
      'fetchInAppRatingsDialogAggregationPreviousPeriod',
    );
  }

  return atriumCall(
    `/apps/${appId}/in-app-ratings-dialog-aggregate?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}`,
    {
      method: 'GET',
      error: (e) =>
        report(
          `[API] Error Fetching In-App Rating Dialog Aggregation (Previous Period) for${appId} from' ${startDate} to ${endDate}`,
          e,
        ),
    },
  );
};

export const fetchInAppRatingsDialogOvertime = (appId, startDate, endDate) => {
  if (isDemoEnv()) {
    return fetchInAppRatingsDialogOvertimeDemoData(startDate, endDate);
  }

  return atriumCall(
    `/apps/${appId}/in-app-ratings-dialog-timeseries?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}&all_dates=true`,
    {
      method: 'GET',
      error: (e) =>
        report(`[API] Error Fetching In-App Rating Dialog Over Time for${appId} from' ${startDate} to ${endDate}`, e),
    },
  );
};

export const fetchAppVersionsIntl = (store, storeAppId) =>
  atriumCall(`/app-store/v2/versions?store=${store}&store_app_id=${storeAppId}`, {
    method: 'GET',
    error: (e) => report('[API] Error Fetching App Versions for', storeAppId, 'on', store, e),
  });

export const fetchAppStoreIdsV2 = (text, platform) => {
  // Corresponding API validates platform name and only accepts 'itunes' and 'android'
  // See https://github.com/apptentive/app-store-service-v2/blob/master/src/main/scala/com/apptentive/appstore/v2/util/CustomValidators.scala
  // Convert platform name to match this requirement, mapping iOS to itunes
  if (platform) {
    platform = platform.toLowerCase();
    platform = platform === 'ios' ? 'itunes' : platform;
  }
  return atriumCall('/app-store/v2/apps', {
    query: {
      text,
      platform,
      per: 20,
    },
  });
};

export const changeAppStoreId = (appId, store_id) =>
  atriumCall(`/apps/${appId}/store_id`, {
    method: 'PUT',
    body: JSON.stringify({ app_store_id: store_id }),
    error: (e) => report('[API] Error Putting App Store Id for', appId, 'with', store_id, e),
  });

export const fetchInteractionCounts = (appId, start, end) =>
  atriumCall(
    `/apps/${appId}/interactions-timeseries?start_date=${localDateString(start)}&end_date=${localDateString(end)}&all_dates=true`,
    {
      method: 'GET',
      demoApp: isDemoEnv() || demoAppIds.includes(appId),
      demoSource: demoData(start, end).INTERACTIONS_TIMESERIES,
      error: (e) => report('[API] Error Fetching Interaction Counts for', appId, 'from', start, 'to', end, e),
    },
  );

export const fetchSurveyCounts = (appId, start, end) =>
  atriumCall(
    `/apps/${appId}/surveys-timeseries?start_date=${localDateString(start)}&end_date=${localDateString(end)}&all_dates=true`,
    {
      method: 'GET',
      demoApp: isDemoEnv() || demoAppIds.includes(appId),
      demoSource: demoData(start, end).SURVEYS_TIMESERIES,
      error: (e) => report('[API] Error Fetching Survey Counts for', appId, 'from', start, 'to', end, e),
    },
  );

// TODO: ADD DEMO DATA
export const fetchSurveyAggregates = (appId, start, end) =>
  atriumCall(
    `/apps/${appId}/surveys-aggregates?start_date=${localDateString(start)}&end_date=${localDateString(end)}&all_dates=true`,
    {
      method: 'GET',
      demoApp: isDemoEnv() || demoAppIds.includes(appId),
      demoSource: demoData(start, end).SURVEYS_AGGREGATE,
      error: (e) => report('[API] Error Fetching Survey Aggregates for', appId, 'from', start, 'to', end, e),
    },
  );

export const fetchNotesAggregates = (appId, start, end) =>
  atriumCall(
    `/apps/${appId}/notes-aggregates?start_date=${localDateString(start)}&end_date=${localDateString(end)}&all_dates=true`,
    {
      method: 'GET',
      demoApp: isDemoEnv() || demoAppIds.includes(appId),
      demoSource: demoData(start, end).NOTES_AGGREGATE,
      error: (e) => report('[API] Error Fetching Notes Aggregates for', appId, 'from', start, 'to', end, e),
    },
  );

export const fetchNotesCounts = (appId, start, end) =>
  atriumCall(
    `/apps/${appId}/notes-timeseries?start_date=${localDateString(start)}&end_date=${localDateString(end)}&all_dates=true`,
    {
      method: 'GET',
      demoApp: isDemoEnv() || demoAppIds.includes(appId),
      demoSource: demoData(start, end).NOTES_TIMESERIES,
      error: (e) => report('[API] Error Fetching Notes Aggregates for', appId, 'from', start, 'to', end, e),
    },
  );

export const changePassword = () =>
  atriumCall('/users/reset_password', {
    method: 'PUT',
    error: (e) => report('[API] Error Changing Password', e),
  });

// TODO: Remove 'createReportJob' actions/api and tests when Fan-Signals-New-Export feature is released
// https://beapi.apptentive.com/apps/:app_id/report_jobs?reporter_class=:reporterClass&target_class=:targetClass&target_id=:targetId
export const createReportJob = (appId, reporterClass, targetClass, targetId, query = {}, fmt = 'csv') =>
  atriumCall(
    `/apps/${appId}/report_jobs?reporter_class=${reporterClass}&target_class=${targetClass}&target_id=${targetId}&fmt=${fmt}`,
    {
      method: 'POST',
      body: JSON.stringify({ report_params: query }),
      error: (ex) => {
        report('[API] Error Starting Report Job for', appId, reporterClass, targetClass, targetId, ex);
      },
    },
  );

// https://beapi.apptentive.com/apps/:app_id/group_messages
export const fetchGroupMessages = (appId) =>
  atriumCall(`/apps/${appId}/group_messages`, {
    method: 'GET',
    error: (ex) => {
      report('[API] Error Fetching Group Messages for', appId, ex);
    },
  });

export const createGroupMessage = (appId, data) =>
  atriumCall(`/apps/${appId}/group_messages`, {
    method: 'POST',
    body: JSON.stringify(data),
    error: (ex) => {
      report('[API] Error Creating Group Message for', appId, 'with', data, ex);
    },
  });

// https://beapi.apptentive.com/apps/:app_id/groups
export const fetchGroups = (appId) =>
  atriumCall(`/apps/${appId}/groups`, {
    method: 'GET',
    error: (ex) => {
      report('[API] Error Fetching Groups for', appId, ex);
    },
  });

// https://beapi.apptentive.com/apps/:app_id/groups { name: '', description: '', group: { name: '', description: '', type: 'static' }}
export const createGroup = (appId, data) =>
  atriumCall(`/apps/${appId}/groups`, {
    method: 'POST',
    body: JSON.stringify(data),
    error: (ex) => {
      report('[API] Error Creating Group for', appId, 'with', data, ex);
    },
  });

// https://beapi.apptentive.com/apps/:app_id/groups/:group_id
export const fetchGroup = (appId, groupId) =>
  atriumCall(`/apps/${appId}/groups/${groupId}`, {
    method: 'GET',
    error: (ex) => {
      report('[API] Error Fetching Group for', appId, 'groupId', groupId, ex);
    },
  });

// https://beapi.apptentive.com/apps/:app_id/groups/:group_id/conversations
export const fetchGroupConversations = (appId, groupId) =>
  atriumCall(`/apps/${appId}/groups/${groupId}/conversations`, {
    method: 'GET',
    error: () => {
      report('[API] Error Fetching Group Conversations for', appId, 'groupId', groupId);
    },
  });

// https://beapi.apptentive.com/apps/:app_id/groups/:group_id { name: '', description: '', group: { name: '', description: '', type: 'static' }}
export const updateGroup = (appId, groupId, data) =>
  atriumCall(`/apps/${appId}/groups/${groupId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    error: (ex) => {
      report('[API] Error Updating Group for', appId, 'with', data, ex);
    },
  });

// https://beapi.apptentive.com/apps/:app_id/groups/:group_id
export const deleteGroup = (appId, groupId) =>
  atriumCall(`/apps/${appId}/groups/${groupId}`, {
    method: 'DELETE',
    error: (ex) => {
      report('[API] Error Deleting Group', groupId, ' for', appId, ex);
    },
  });

// http://beapi.apptentive.com/apps/:app_id/groups/:group_id/add Form Data: conversation=:conversation_id
export const addConversationToGroup = (appId, groupId, conversation) =>
  atriumCall(`/apps/${appId}/groups/${groupId}/add`, {
    method: 'PUT',
    setContentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    body: JSON.stringify({ conversation }),
    error: (ex) => {
      report('[API] Error Adding Conversation to Group', appId, groupId, conversation, ex);
    },
  });

// http://beapi.apptentive.com/apps/:app_id/groups/:group_id/del Form Data: conversation=:conversation_id
export const removeConversationFromGroup = (appId, groupId, conversation) =>
  atriumCall(`/apps/${appId}/groups/${groupId}/del`, {
    method: 'PUT',
    setContentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    body: JSON.stringify({ conversation }),
    error: (ex) => {
      report('[API] Error Removing Conversation from Group', appId, groupId, conversation, ex);
    },
  });

// Quick Responses
export const fetchQuickResponses = (appId) =>
  atriumCall(`/apps/${appId}/quick_responses`, {
    method: 'GET',
    error: () => {
      report('[API] Error Fetching Quick Responses for app', appId);
    },
  });

export const createQuickResponse = (appId, data) =>
  atriumCall(`/apps/${appId}/quick_responses`, {
    method: 'POST',
    body: JSON.stringify(data),
    error: (ex) => {
      report('[API] Error Creating Quick Response for', appId, 'with', data, ex);
    },
  });

export const updateQuickResponse = (appId, responseId, data) =>
  atriumCall(`/apps/${appId}/quick_responses/${responseId}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    error: (ex) => {
      report('[API] Error Updating Quick Response for', appId, 'with', data, ex);
    },
  });

export const deleteQuickResponse = (appId, responseId) =>
  atriumCall(`/apps/${appId}/quick_responses/${responseId}`, {
    method: 'DELETE',
    error: (ex) => {
      report('[API] Error Deleting Quick Response for', appId, ex);
    },
  });

export const deleteApp = (appId) =>
  atriumCall(`/apps/${appId}`, {
    method: 'DELETE',
    error: (ex) => {
      report('[API] Error deleting app', appId, ex);
    },
  });

export const updateApp = (appId, body) =>
  atriumCall(`/apps/${appId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    error: (e) => report('[API] Error Updating App for app', appId, 'with', JSON.stringify(body), e),
  });

export const fetchAuthInfo = (appId) =>
  atriumCall(`/apps/${appId}/auth_info`, {
    method: 'GET',
    error: (ex) => {
      report('[API] Error Fetching Auth Info for app', appId, ex);
    },
  });

/**
 * @param {string} appId
 * @param {device|person} type
 * @param {string} query limit=250&param=1
 * @returns {Promise}
 */
export const fetchAttributeChoices = (appId, type, query) => {
  let url = `/apps/${appId}/attributes/${type}`;
  if (query) {
    url += `?${query}`;
  }

  return atriumCall(url, {
    method: 'GET',
    error: (ex) => {
      report('[API] Error Fetching Attribute Choices for app', appId, 'type', type, query, ex);
    },
  });
};

/**
 * @param {string} appId
 * @returns {Promise}
 */
export const fetchTargeting = (appId) =>
  atriumCall(`/apps/${appId}/targeting`, {
    method: 'GET',
    error: (ex) => {
      report('[API] Error Fetching Targeting for app', appId, ex);
    },
  });

/**
 * @param {string} appId
 * @param {device|person} type
 * @param {string} field
 * @param {string} query
 * @returns {Promise<{app_id: string; field: string, model: string, values: string[]}>}
 */
export const fetchAttributesByField = (appId, type, field, query) =>
  atriumCall(`/apps/${appId}/attributes/${type}/${field}`, {
    method: 'GET',
    query: { query, limit: 10 },
    error: (ex) => {
      report('[API] Error Fetching Integrations for app', appId, ex);
    },
  });

export const fetchIntegrations = (appId) =>
  atriumCall(`/apps/${appId}/integrations`, {
    method: 'GET',
    error: (ex) => {
      report('[API] Error Fetching Integrations for app', appId, ex);
    },
  });

export const createIntegration = (appId, data) =>
  atriumCall(`/apps/${appId}/integrations`, {
    method: 'POST',
    body: JSON.stringify(data),
    error: (ex) => {
      report('[API] Error Creating Integration for app', appId, 'data', data, ex);
    },
  });

export const updateIntegration = (appId, id, data) =>
  atriumCall(`/apps/${appId}/integrations/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
    error: (ex) => {
      report('[API] Error Updating Integration for app', appId, 'id', id, 'data', data, ex);
    },
  });

export const removeIntegration = (appId, id) =>
  atriumCall(`/apps/${appId}/integrations/${id}`, {
    method: 'DELETE',
    error: (ex) => {
      report('[API] Error Deleting Integration for app', appId, 'id', id, ex);
    },
  });

export const fetchActiveUsers = (appId, startDate, endDate, period) => {
  if (isDemoEnv()) {
    return generateActiveUsersDemoData(startDate, endDate, period);
  }

  return atriumCall(
    `/apps/${appId}/active-users?start_date=${localDateString(startDate)}&end_date=${localDateString(endDate)}&period=${period}`,
    {
      method: 'GET',
      error: (e) => report('[API] Error Fetching Active Users for', appId, period, 'from', startDate, 'to', endDate, e),
    },
  );
};

export const fetchRetention = (startDate, endDate, period, appId) => {
  if (isDemoEnv()) {
    return fetchRetentionDemoData(startDate, endDate);
  }

  return atriumCall(
    `/apps/${appId}/retention?start_date=${startDate}&end_date=${endDate}&period=${period}`,
    {
      method: 'GET',
      error: e => report('[API] Error Fetching Retention for', appId, 'for dates between', startDate, 'and', endDate, 'for period', period, e),
    }
  );
};

export const fetchCheckUpdate = (sha) => atriumCall(`/update-check/${sha}`, {
  error: e => report('[API] Error Fetching Update Check', sha, e),
});

export const fetchAppTranslations = (appId) => atriumCall(`/apps/${appId}/translations/status`, {
  method: 'GET',
  error: e => report('[API] Error creating App Locales for app', appId, e),
});

export const updateAppTranslations = (appId, translations) => atriumCall(`/apps/${appId}/translations`, {
  method: 'POST',
  body: JSON.stringify({ translations }),
  error: e => report('[API] Error creating App Locales for app', appId, e),
});

export const fetchFanSignals = (appId) => atriumCall(`/apps/${appId}/fan_signals`, {
  method: 'GET',
  error: e => report('[API] Error fetching Fan Signals for app', appId, e),
});
